@import "variables.less";

//////////////////////////////////////////////////////////
// TABLE OF CONTENTS
//
// VARIABLES: COLORS
// VARIABLES: SPACING
// VARIABLES: GRAPHIC ELEMENTS AND ANIMATION
// TYPE
// BODY AND BACKGROUND PATTERN
// UTILITIES
// INFINITE WIDTH AND INFINITE SCROLL
// HOVER AND TOOLTIPS
// ONBOARDING TIPS
// ALERTS
// PROGRESS SPINNERS
// ICONS AND EMOJI
// MODALS: GENERAL
// MODALS: TERMS
// MODALS: SIGN-IN/SIGN-UP
// FILEPICKER
// FORMS
// FORMS: DRAFTJS
// DROPDOWNS
// SETTINGS
// CLOSE X
// BUTTONS
// HEADER
// PRIVATE CLASSROOM AREAS
// TOP TAGS LIST
// EXPLANATION IMAGES
// HOME PAGE PITCH
// HOME PAGE QUICK CREATE
// HOME PAGE SECTIONS
// CLAIM PAGE
// USER PAGE
// NOTIFICATIONS PAGE
// STATIC PAGE: CONTACT & FEEDBACK LINK
// STATIC PAGES: HISTORY & VALUES
// STATIC PAGE: PRIVACY POLICY
// STATIC PAGE: MANIFESTO
// STATIC PAGE: EDUCATION
// ADMIN PAGE
// UPSTREAM LINKS
// BLOCKS OF EVIDENCE
// DEALING CARDS ANIMATION
// RELEVANCE
// CARDS
// CLAIM IMAGES
// CLAIM TEXT
// CLAIM SCORE AND STATS
// CLAIM INFO (SOURCES, TAGS, FLARE, ETC)
// CLAIM INFO EDIT AND COMMENT AREAS
// FORMS FOR CREATING AND EDITING CLAIMS
// CHARACTER COUNTER
// SEARCH PAGE
// SHARE ICON AREA
// ARROWS: HEADS
// ARROWS: CONNECTING LINES
// STORYBOOK
// RESPONSIVE


//////////////////////////////////////////////////////////
// VARIABLES: COLORS

// grey type colors
@nearBlack: hsl(0, 0%, 18%); // try to save black for Point text
@greyDark: hsl(0, 0%, 30%);
@greyMid: hsl(0, 0%, 66%);
@grayTextDark:  #5B5B5B; // 91,  91,  91
@nearWhite: hsl(0, 0%, 94%);

@placeholderTextInitialColor: hsl(0deg 0% 50%);
@placeholderTextFocusColor: hsl(0deg 0% 70%);

// grey backgrounds
@nearBlackButtonHover:  hsl(0, 0%, 24%); // a bit lighter than @nearBlack to help :hover be more different
@greyLightBG: hsl(0, 0%, 94%);
@headerColor: @greyLightBG;

// grey lines
@greyDarkLine: hsl(0, 0%, 44%);
@greyLightLine:  hsl(0deg 0% 84%);
@stackCardDefaultBorderColor: hsl(210, 0%, 75%);
@stackCardDefaultBorderColorLight: fade(@stackCardDefaultBorderColor, 45%);

// blues
@blue: hsl(196, 100%, 39%);
@blueLight: lighten(@blue, 18%);
@editingAreaBG: hsla(178, 100%, 94%, 1);
@editingAreaBGLite: hsla(178, 85%, 91%, 0.3); // TO DO make this relationship procedural

// reds
@red: hsl(358, 100%, 30%);  //hsl(358, 70%, 40%);   // hsl(358, 100%, 40%); #CC0008; // score
@redLight:  desaturate(lighten(@red, 18%), 20%); //hsl(358, 80%, 48%); //hsl(0, 100%, 48%);
@stackCardCounterBG:  hsla(10, 100%, 96%, 1); // hsla(348, 100%, 96%, 1);  hsla(348, 100%, 94%, 1);
@stackCardCounterBorderColor: hsla(358, 60%, 75%, 1); //hsla(0, 72%, 80%, 1);  // #f6a3a3 ;

// yellows
@yellowWarning: hsl(60deg 100% 81%);
@adminColor: hsl(35, 100%, 90%);

@sandboxHeaderColor:  #deefff;

@modalOverlayColor: hsla(0, 0%, 0%, 0.75);

// for code that is currently commented out
@adminColorRel: hsl(50, 100%, 65%);
@relAreaBG: hsla(52, 95%, 93%, 1);


//////////////////////////////////////////////////////////
// VARIABLES: SPACING

// Global
@marginVerticalStandard: 0.9rem;

// Top of the page
@defaultHeaderMarginH: 0.675rem;
@contentMarginTop: 3.375rem;

// Forms
@marginVerticalForms: 0.5625rem;

// Card Dimensions (which essentially define our "Grid")
@cardWidth: 27.75rem;
@indent: 2.25rem;

@stackCardOffset: 0.25rem; // Must equate to a whole number of pixels (ie no fractions) else there's a pop in the animation
@stackCardOffsetH: @stackCardOffset * -1; // TO DO does this -1 need a unit?
@stackCardOffsetV: @stackCardOffset - .0625rem; //3px
@cardStackWidth: (4 * @stackCardOffset) + (3 * @cardBorderWidth);
@cardStackWidthParentCardsOnly: (3 * @stackCardOffset) + (2 * @cardBorderWidth);
//@cardListWidth: @cardWidth + ( 4 * @indent); // currently unused
@cardPadding: 0.875rem;
@cardPaddingMed: @cardPadding * .8;
@cardPaddingSmall: @cardPadding * .6;
@linkedClaimArrowIndent: 0.50625rem; // 9px;

@leftMarginMobile: 1.5rem;

@zindexCardAnimFromBelow: -100; // used in card animation
@zindexFormIcon:          10; // eg the eye icon used to mask passwords
@zindexArrow:             10; // arrows that are placed on top of card elements
@zindexCardActions:       100; // card action links (if user clicks elsewhere on the card, it expands)
@zindexDropdown:          1035; // used in Bootstrap
@zindexPopover:           1010; // used in Bootstrap
@zindexTooltip:           1030; // used in Bootstrap AND here
@zindexFixedNavbar:       1030; // used in Bootstrap
@zindexModalBackdrop:     1040; // used in Bootstrap AND here
@zindexModal:             1050; // used in Bootstrap

//////////////////////////////////////////////////////////
// VARIABLES: GRAPHIC ELEMENTS AND ANIMATION

// Border strokes
@cardBorderWidth: .0625rem; // 1px;

// Rounded Corners
@borderRadiusUX2: 0; //5px;
@borderRadiusUX2_rounder: 4px;
@borderRadiusBadges: 4px;

// Animation
@hoverTransitionDuration: .066s;
@hoverTransitionDurationButtons: .2s;
@editAreaRevealDuration: .33s;

@keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}


//////////////////////////////////////////////////////////
// TYPE

@sansFontFamily: "ff-meta-web-pro", sans-serif;
@sansCondensedFontFamily:"ff-meta-web-pro-condensed", sans-serif;;
@serifFontFamily: Georgia, Garamond, serif;

// using rem instead of em because sometimes with em the font-sizes get messed up due to parenting
@baseFontSizeUX2: 0.9rem; // 14.4px

@fontSizeModalHeading: 2rem;

@fontSizePointTitle:   @baseFontSizeUX2 * 1.0625; // ~15.3px
@fontSizeSecondaryUX2: @baseFontSizeUX2 * 0.9375; // ~14px
@fontSizeTertiaryUX2:  @baseFontSizeUX2 * 0.8333; // ~12px;

@fontSizeButtonsUX2:   @fontSizeSecondaryUX2;

@arrowFontSizeUX2:     @baseFontSizeUX2 * 1.125; // ~16px

@lineHeightSmall: 125%;
@lineHeightMedium: 133%;
@lineHeightLists: 140%;
@lineHeightBase: 150%;
@lineHeightXXXL: 400%;

@letterSpacingSubtle:   0.01em;

// Default typography
body, p, ul, ol {
    font-family: @sansFontFamily;
    font-size: @fontSizePointTitle;
    line-height: @lineHeightBase;
    letter-spacing: @letterSpacingSubtle;
    word-break: break-word;
}

// Headings
h1, h2, h3, h4, h5, h6, body {
  color: @nearBlack; // save black for claims on argument content pages
}
.fullPageText, .fullPageForm {
  &>h1, &>h2, &>h3, &>h4, &>h5, &>body {
    color: black; //  no need to "save black" on text pages that don't contain argument content
  }
}

h1 { font-size: 2.25em; } // ~ 35px
h2 { font-size: @baseFontSizeUX2 * 1.25; } // ~18px
h3 { font-size: @fontSizePointTitle; }
h4 { font-size: @fontSizePointTitle; }
h5 { font-size: @fontSizeSecondaryUX2; }
h6 { font-size: @fontSizeTertiaryUX2; }

.fullPageText h1, .fullPageForm h1 {
  line-height: @lineHeightSmall;
  margin-top: 3.125rem;
}

.fullPageText h2, .fullPageForm h2 {
  line-height: @lineHeightSmall;
}

h2 {
  margin-bottom: 0.675rem;
  margin-top: 0;
  padding-top: 3.5rem; // using padding instead of margin so that links the content isn't obscured behind the fixed header when users jump to h4 ids
}

h3 {
  margin-top: 1.5rem;
}

// Anchors
a {
	text-decoration: none;
	font-weight: 500;
}
a:hover {
  color: @blue ;
  cursor:pointer;
}
a:focus {
  outline-offset: 0.225rem;
  outline: thin dotted #333;
  text-decoration: unset;
}
a .blackLink, .blackLink {
  color: black;
  font-weight: 800;
}
a .blackLink:hover {
  color: @blue;
  text-decoration: underline;
}

ul, ol {
  line-height: @lineHeightLists;
  //font-size: 1rem;
  margin-top: 0.75rem;
  margin-bottom: 1.25rem !important;
  margin-left: 1.5rem;
}
@li_padding: 0.25rem;
li {
  display: list-item;
  line-height: @lineHeightLists;
  margin: 0;
  padding: 0;
  overflow: visible;
}
ul.biggerMargin {
  margin-top: 1rem;
}
ul.biggerMargin li {
   margin-bottom: 1rem;
}
li .bullet {
  margin-right: 0.225rem;
}

.faqList {
  margin-left: 2rem;
}
.liExtraSpaceAfterBullet {
  position: relative;
  left: 0.5rem;
}

.guideline {
  text-decoration: underline;
}

// hilite color when text is selected with the mouse
//@selectionHighlightColor: hsla(178, 85%, 86%, 1);
::selection {
  background-color: yellow; //@selectionHighlightColor;
  color: black;
}

.fullPageText, .fullPageForm {
  margin-left: 5.4375rem; // eye match to header
  max-width: 440px;
  margin-bottom: 6.75rem;
}
/*
.fullPageText p, .fullPageForm p
 {
  line-height: 140%;
  font-size: 1rem;
}*/

.fullPageFormHeading {
  margin-left: -0.1125rem; // not sure why this is needed
}

.fullPageText .bullet {
    margin-right: 0.45rem;
}

.textSection {
  padding-top: @contentMarginTop; // using padding instead of margin so that links the content isn't obscured behind the fixed header when users jump to ids
}

.textSection h1 {
  margin-bottom: 1.25rem;
  margin-top: 0;
  padding-top: 0;
}

.number {
  font-family: "usual", sans-serif !important;
}
.byline .number, .cardTopRow .number, .stats .number, .commentLink .number {
  font-size: 85%;
}
.commentLink .number {
  font-size: 80%;
  margin-right: .25rem;
}

// is this for alt-text?
img {
  color: @nearBlack;
  font-size: @fontSizeTertiaryUX2;
  font-family: @sansFontFamily;
}


//////////////////////////////////////////////
// BODY AND BACKGROUND PATTERN

@bgGridWidth: 5px;
@bgColor: hsla(0, 0%, 99%, 1);
@bgGridStroke: 1px; // 40%; //2px;
@bgGridColor: hsla(0, 0%, 95.5%, 1);

// scrollbar always visible to prevent horizontal shifting
html {overflow-y: scroll;}

// ideal grid rendering for most browsers
body {
  padding-top: @headerHeight; // Is this right?
  background-color: @bgColor;
  background-size: @bgGridWidth @bgGridWidth;
  background-image: linear-gradient(to right, @bgGridColor @bgGridStroke, transparent @bgGridStroke),
                    linear-gradient(to bottom, @bgGridColor @bgGridStroke, transparent @bgGridStroke);
}

/* Mozilla renders ugly dark line artifacts with the grid method above, so using a different approach for that browser */
@bgGridColorMoz: hsla(0, 0%, 0%, 3%);
@-moz-document url-prefix() {
  body {
     background-image: linear-gradient(to right, @bgGridColorMoz @bgGridStroke, transparent @bgGridStroke),
                       linear-gradient(to bottom, @bgGridColorMoz @bgGridStroke, transparent @bgGridStroke);
  }
}


//////////////////////////////////////////////////////////
// UTILITIES


// for debugging
.test {
  color: @red !important;
}
.quickTestRect {
  width: 100px;
  height: 100px;
  color: yellow;
  background-color: teal;
}
.error {
    color: @red;
}

// display
.hidden {
  display: none;
}
.disabled {
  color: @greyMid !important;
}

.flop {
  transform: scaleX(-1);
}
.flip {
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
}

// highlight admin features
.admin {
  background-color: @adminColor;
  width: max-content;
}
// this is for code that is commented out currently ; why is this a different color?
.relGroupHilite .admin {
  background-color: @adminColorRel;
}
.dropdown-menu .admin, .stats .admin, #userInfoArea .admin {
  width: 100%;
  box-sizing: border-box;
}

// positioning
.inlineBlock {
  display: inline-block;
}

.inlineflexBox {
  display: inline-flex;
  vertical-align: top;
  width: 100%
}

.blockUnstackOnNarrow {
  display: block;
  vertical-align: top;
}

.fullWidth {
  display: block;
  width: 100%;
  border-style: none; //solid;
  margin-top: 0px;
  padding-top: @marginVerticalStandard;
  margin-left: 0; // calc(~"-1 * (2% + 54px)"); // -1 * margin-left of container (until we move off of base.html)
}
.noMarginTop {
  margin-top: 0 !important;
}
.noMarginBottom {
  margin-bottom: 0 !important;
}
.noLeftMargin, .noMarginLeft {
    margin-left:0 !important;
}
.noRightMargin, .noMarginRight {
    margin-right:0 !important;
}

// margins, paddings and borders
.smallerVerticalMargin {
  margin-bottom: @marginVerticalStandard * .5555;
}

// for editImage and comments areas
.hideBorderTop {
  border-top-style: none !important;
}
.hideBorderBottom {
  border-bottom-style: none !important;
}
// add extra padding to make small icons easier to click on
.easierToClickOn, .bylineAdminItem  {
  position: relative;
  padding: 0.3375rem;
}

.transparentBG {
  background-color: transparent !important;
}


//////////////////////////////////////////////////////////
// INFINITE WIDTH AND INFINITE SCROLL

@mainContainerMarginLeft: 3rem; //calc(~"2% + 1rem"); // calc(~"2% + 3.7125rem");
#mainContainer {
  width: auto;  // THIS LINE USED TO BE REQUIRED FOR INFINITE WIDTH, BUT SEEMS TO NO LONGER BE THE CASE
  margin-top: 2.5rem;
  padding-left: @mainContainerMarginLeft; // using padding renders better than margin on the Surface tablets in the chrome emulator
  margin-bottom: 3.375rem;
  min-height: 960px; // prevents vertical scroll jumping in databases with few claims; also makes classic-on-apollo look all fuxed up
}
.infiniteWidth {
  // FOR INFINITE WIDTH:
  width: max-content;
  width: -moz-max-content;
  //margin-left: 72px;
}

// infinite scroll
.hideScrollbar {
  overflow:hidden;
}

.infinite-scroll-component {
  overflow: unset !important; // removes scrollbar, but doesn't seem to remove infiniteScroll behavior -JF
  //padding-top: 18px; // not sure why this is necessary, but it prevents a weird crop on the top
  //-ms-overflow-style: none; // IE 10+
  //overflow: -moz-scrollbars-none;  // Old Firefox versions, but doesn't work for new Firefox
}


//////////////////////////////////////////////////////////
// HOVER AND TOOLTIPS

// styled tooltips
/*
a[title]:hover:after {
  content: attr(title);
  background-color: pink;
  //position: absolute;
}
*/

.hover {
  position: relative;
  cursor:pointer;

  &:hover &__no-hover {
    //opacity: 0;
    text-decoration:underline;
  }

  &:hover &__hover {
    .hoverAction();
  }

  &:hover &__hover1 {
    .hoverAction();
  }

  &__hover {
    .hoverProps();
  }

  &__hover1 {
    .hoverProps();
    transition-delay: 0.75s;
  }

  &__no-hover {
    opacity: 1;
  }
}

.hoverProps {
  position: absolute;
  //top: 0; // this was creating a bug where the Rate Relevance hoverTip position was off
  opacity: 0;
  transform: scale(0); // this prevents hover being activated by mousing over the invisible menu, there might be a more official way to do this? -JF
}

.hoverAction {
  opacity: 1;
  transform: scale(1); // undos the scale(0) in &__hover below
}

.tipBringToFront {
  z-index: @zindexTooltip;
}

@hoverTipColorBG: black; //#313131; // #eeeeee;
@hoverTipColorType: @nearWhite;// black;
@hoverTipFadeDuration: 2s; // @hoverTransitionDurationButtons
.hoverTip {
  font-family: @sansFontFamily;
  font-size: @fontSizeTertiaryUX2;
  font-weight: normal;
  color: @hoverTipColorType;
  //border: 1px grey solid;
  background-color: @hoverTipColorBG;
  padding-top: 0.16875rem;
  padding-right: 0.75rem;
  padding-bottom: 0.225rem;
  padding-left: 0.75rem; // 7px;
  border-radius: @borderRadiusUX2_rounder;
  //box-shadow: 2px 2px 15px #aaaaaa;
  position: relative;
  left: -2.1375rem;
  top: -2.19375rem;
  width: max-content;
  animation-name: fadein; // I want this to fade in, but its not working yet, why? -JF?
  animation-duration: @hoverTipFadeDuration;
  animation-iteration-count: 1;
}

.linkArea .hoverTip {
  left: 2.19375rem;
  top: -4.33125rem;
}

.cardBottomActionRow .hoverTip {
  left: -3rem;
  top: -2.4rem;
}

// for # contributors
.byline .hoverTip {
  padding-top: 0.28125rem; // 5px; // not sure why this is nec -JF
  padding-bottom: 0.375rem; // 4px; // not sure why this is nec -JF
}
.contributors .hoverTip {
  left: -1.6875rem;
}

.contributors .caret-outer {
  top: 1px !important;
}

.lastEdit .hoverTip {
  left: -1.6875rem;
}

// TO DO: positioning seems to shift based on copy, lets wire this so that doesn't occur
.emojiBlock .hoverTip {
  left: -1.35rem;
  top: -2.25rem;
}
.commentBottomRowActions .hoverTip {
  left: -3.375rem;
}
.quickCommentsList .hoverTip {
  left: -5.5rem;
  top: -1.5rem;
}


//////////////////////////////////////////////////////
// ALERTS

@alertTypeColor: white;

.react-alert {
  background-color: @nearBlack;
  color: @alertTypeColor;
  padding: @cardPadding;
  padding-left: @cardPadding * 2;
  padding-right: @cardPadding * 2;
  display: block;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
  margin: 0px;
  width: 100vw;
  overflow-wrap: break-word;
  min-height: 8vw;
}

.react-alert-msg {
  display: inline-block;
  width: 90%;
}

.alertCloseButton {
  border: none;
  background-color: transparent;
  cursor: pointer;
  color: @alertTypeColor;
  font-size: 1.25rem;
  float: right;
}
.alertCloseButton:active, .alertCloseButton:focus {
  border: none;
  outline: none;
}

// Move alert up so that it is right below the input that it refers to
// TO DO: make the DOM for inputs and alerts more uniform so this code can simplify
.ys-editor + .alert,
.optionsContainer .alert,
.contactForm .alert,
.addSourceArea .alert {
  margin-top: -1 * @inputMarginBottom;
}


//////////////////////////////////////////////////////////
// PROGRESS SPINNERS

.progressFeedback {
  position: relative;
  margin-left: 0.675rem;
  //top: 4px;
  font-size: @fontSizeSecondaryUX2;
  color: @nearBlack;
  min-height: 3.15rem;
  display: block;
  //background-color: pink;
  margin-bottom:0;
}
.progressFeedback.counter{
  color: @red;
}
//TO DO: merge this class with above
.addEvidenceControlsArea .progressStateFeedback {
  position: relative;
  display: block;
}

.unlinkProgressFeedback {
  margin-left: 1.6875rem;
}

.spinner {
  display: inline-block;
  position: relative;
  top: 0;
  left: 0;
  margin-left: 0.225rem;
  margin-right: 0.225rem;
  width: 1.575rem;
}
.spinnerPointList {
  margin-left: 1.35rem;
}
.spinnerLabel {
  color: @nearBlack;
  line-height: @lineHeightMedium;
  letter-spacing: @letterSpacingSubtle;
  font-size: @fontSizePointTitle;
}


//////////////////////////////////////////////////////
// ICONS AND EMOJI

.iconButton, .sectionIcon {
  margin-right: 0.36rem;
}

.fa-dice, .fa-fire {
  font-size: 110%;
  //vertical-align: top;
}

.fa-star, .fa-user {
  font-size: 85%;
}

.fa-info-circle {
  font-size: 1rem;
  top: 0.0625rem;
  position: relative;
}

// this icon seems weighted to be a bit high
.fa-bars {
  position: relative;
  top: 0.0625rem;
}

.relevanceLearnMore .fa-info-circle {
  margin-left: 0.16875rem;
  margin-right: 0.225rem;
}
.scoreLearnMore .fa-info-circle {
  margin-right: 0.1875rem;
}

.emojiBlock .fa-plus {
  font-size: 85%;
}

.controversyIcon {
  color: @red;
  margin-left: 0.225em;
  font-size: 1em;
  padding-bottom: .0625rem; // 1px;
}

.stats .controversyIcon {
  color: white;
  margin-left: -0.0625rem; // -1px;
  margin-right: 0.125rem; // 2px
  padding: 0;
}

@iconBlockPadding: @cardPaddingMed;
.iconBlock {
  padding-left: 0.675rem !important;
  padding-right: 0.675rem !important;
}

.emojiMenuBlock {
  padding-right: @iconBlockPadding - 0.0625rem !important; // -1px
  padding-top: 0.75rem !important; // set by eye
}

.emojiMenuLink {
  font-size: 0.675em;
  position:relative;
  vertical-align: top;
  //bottom: 0.125rem; //-2px not sure why this is nec
}
.emojiMenuLink .emoji {
  font-size: 1em;
}

.emojiMenuLink i {
  margin-right: 0.09rem !important;
}

.emojiMenuPlus {
  position: relative;
  bottom: 0.125rem;
}

#emojiMenu {
  width: max-content;
  left: -45px !important;
  top: 38px;
}

#emojiMenu.question {
  left: 148px;
}

#emojiMenu .dropdown-caret {
  left: 0.61875rem;
}

#emojiMenu .caret-inner {
  left: 0.0625rem;
}

.emoji {
  display: inline-block;
}

#emojiMenu .emoji {
  //width: 1.33rem;
  font-size: 175%;
  //text-align: center;
}

#emojiMenu li {
  margin: 0;
}

#emojiMenu .emojiUserSelected {
  background-color: black;
  color: white;
}
.emojiUserSelected .emoji, .emojiUserSelected .menuItemLabel {
  color: white;
}

#emojiMenu .menuItemLabel {
  padding-left: 0.54rem;
  vertical-align: text-top;
  position: relative;
  bottom: 0.0625rem; // 1px;
}

.emojiList {
  //background-color: pink;
  display: inline-block;
  position: relative;
  left: -1 * @cardTopRowItemMarginRight; // remove preceeding margin because emoji padding is so big
}

.emojiBlock {
  //margin-left: 4px;
  //margin-right: 2px;
  // these two style calls are really for the delta character. applying them here for now to avoid spending time adding a custom class for the delta
  color: @nearBlack;
  font-weight: bold;
  font-size: 1.3em;
  display: inline-block;
  //width: 2.5rem; // incl width for the X to close and the number
  //background-color: pink;
  padding-top: 0.125rem; //2px
  padding-left: 0.25rem;
  padding-right: 0.25rem;
  padding-bottom: 0.125rem;
  margin-right: 0.25rem;
  outline: none;
  position: relative;
  //top: -3px;
  margin-top: -0.375rem;
}

.emojiBlock:hover {
  background-color: @blueLight;
}

.emojiBlock .number {
  //vertical-align: middle; // seems to make animation pop
  margin-left: 0.125rem; // 2px;
  font-size: 0.675rem; // needs to calculate to an exact number of pixels or else it pops on the deal animation
  color: @greyMid;
  font-weight: normal;
}

.emojiBlock:hover .number {
  color: black;
}

.emojiMindBlown {
  //font-size: 120%; // seems to be causing a pop in the animation
  position: relative;
  top: 0.1875rem;
}
// undo some style that's getting hard-coded in with the emoji
.emojiMindBlown svg {
  vertical-align: 0 !important;
}
.emojiLaugh {
  //font-size: 105%; // this seems to cause pops in the animation; maybe the trick is that the font size must calculate to an exact number of pixels
  position: relative;
  top: 0.0625rem;
}
.emojiLaugh svg {
  width: 1em !important; // undo some bad style that's getting hard-coded in with the emoji
}
.emojiDelta, .emojiLove {
  //font-size: 85%; // this seems to cause pops in the animation; maybe the trick is that the font size must calculate to an exact number of pixels
}
.byline .emojiLaugh, .byline .emojiDelta, .byline .emojiLove {
  opacity: 0.45;
}
/*
#emojiMenu .emojiLaugh, #emojiMenu .emojiDelta, #emojiMenu .emojiLove {
  opacity: 0.85;
}*/

// the triangle emoji is red by default
.emojiDelta {
  filter: brightness(0);
  transform: scale(1.55);
}

.emojiBlock .emojiDelta {
  transform: scale(1.75);
  position: relative;
  //top: 1px;
}

#profileDisclaimer .emoji {
  width: 1.18125rem;
  margin-left: 0.36rem;
}

// for code that is currently commented out in point.jsx
.emojiHoverIcon {
  opacity: 0;
}
.emoji:hover .emojiHoverIcon {
  opacity: 1;
}


//////////////////////////////////////////////////////////
// MODALS: GENERAL

.modal ul {
    margin-top: 0.9rem;
    margin-bottom: 1.35rem;
    margin-left: 0.9rem;
}

/* UNUSED I THINK
// the dark overlay behind react modals
.popup-overlay {
  background: transparent !important; // making it darker; using !important to step on style tags being added in the html by react-popup;  is there a better way to do this?
  z-index: 1000 !important;
}
*/

@modalWidthDefault: 640px;
#termsModal {
  width: unset;
  margin-left: -0.5 *  @modalWidthDefault;
}

.reactModal, #signupDialog, #loginDialog, #termsModal {
  position: absolute;
  left: 50%;
  margin-left: -0.5 *  @modalWidthDefault;
  top: 2.25rem;
  border: 1px solid @greyLightLine;
  background: white;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  outline: none;
  padding: 1.125rem;
}
.reactModal {
  width: @modalWidthDefault;
}

@modalWidthSignup: 560px;
#signupDialog, #loginDialog {
  width: @modalWidthSignup;
  margin-left: -0.5 *  @modalWidthSignup;
}

// TO DO: redo this modal and fix this
#submit_signupDialog {
  //margin-right: 1.5rem; // 24px;
}

.reactModalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: @modalOverlayColor;
  z-index: @zindexModalBackdrop;
}

@modalPaddingH: 3.5rem;
.modal-header, .modal-body, .modal-footer {
  padding-top: 2.25rem;
  padding-bottom: 1.5rem;
  padding-left:  @modalPaddingH;
  padding-right:  @modalPaddingH;
}

/*
// extra padding on the right looks good with text
.modal-header {
  padding-right: @modalPaddingH;
}*/

// TO DO: these seem like they can be synthesized
.modalHeadlineCopy {
  font-size: @fontSizeModalHeading !important;
  font-weight: bold;
}
.modal .heading {
  font-size: @fontSizeSecondaryUX2;
  margin-bottom: 1.25rem;
  //margin-top: 0.5rem;
}

.returningUserSignIn .heading {
  margin-bottom: 1.5rem;
}

.modal .heading.extraMarginTop {
   margin-top: 1.35rem;
}


.modal-body, .modal-footer {
  padding-top: 1rem;
}
.modal-footer {
  padding-bottom: @marginVerticalStandard * 4;
}
.model-section {
  margin-bottom: 0.9rem;
}

.modalHeadlineCopy {
  display: inline-block;
  width: max-content;
}

.modal .dialogSubHeading {
  margin-top: 2.5rem;
}

.modal-body.text-content {
  padding-right: @modalPaddingH;
  margin-top: 0.3375rem;
  max-width: 440px;
}

.modal-body.text-content li {
  margin-bottom: 0.9rem;
}

.modal-body ol {
  margin-left: 0.9rem;
  margin-bottom: 1.25rem;
  margin-top: 0.36rem;
}
.modal-body li {
  margin-bottom: 0.36rem;
}


//////////////////////////////////////////////////////////
// MODALS: TERMS

#termsModal {
  position: absolute;
}
#termsPopup .header {
  max-width: 440px;
}

// add extra padding on the right for the terms pop-up because it looks good with the text
@modalPaddingH_terms: @modalPaddingH + 0.5rem;
#termsPopup .modal-header,
#termsPopup .modal-body,
#termsPopup .modal-footer {
  padding-right: @modalPaddingH_terms;
}

// setting font-size to be as big as possible while placing the line break in a spot that works well with this copy
#termsPopup h1 {
 font-size: 1.875em;
}
#termsPopup h2 {
  padding-top: @marginVerticalStandard * 1.675;
  margin-bottom: @marginVerticalStandard * .25;
  font-size: @fontSizePointTitle; // @baseFontSizeUX2;
}

#termsPopup .firstItem {
  padding-top:0.75rem ;
  margin-top: 0;
}

.termsValuesPageLink {
  margin-top: @marginVerticalStandard * 2;
  margin-bottom: @marginVerticalStandard * .25;
}


//////////////////////////////////////////////////////////
// MODALS: SIGN-IN/SIGN-UP


#loginDialog .modal-body {
  overflow-y: unset; // remove horizontal scrollbar on narrow screens
}

#loginDialog .signInNewUsers {
  margin-bottom: 2rem;
}

#logInSpinner {
   margin-top: 0.675rem;
}

#frm_signupDialog input, #frm_signupDialog textarea  {
  //background-color: pink;
  width: 100%;
  box-sizing: border-box;
}

// remove top border so these fields remain boxed together
#login_userPassword {
  margin-bottom: 1rem;
}

@modalSignInWithPaddingH: 1.6875rem;
.signWithInButtons  {
  width: auto;
  padding-bottom: 0;
  padding-left: 0;
  padding-right: 0;
  padding-top: 0;
  display: block;
  margin-top: 0;
  margin-bottom: 2rem;
  margin-left: auto;
  margin-right: auto;
}

//@signInButtonWidth: 154px;
.signWithInButtons .buttonUX2, .signInNewUsers .buttonUX2  {
  //margin-bottom: 0.375rem;
  width: max-content;// @signInButtonWidth; // keep widths constant
}

.modal-body.modalBodySignInWith {
 padding-top: 0;
}

// TO DO: make these <buttons> instead of divs with a's
.modalBodySignInWith a {
  color: black;
  text-decoration: none;
}


/*
@redGoogleBranding: hsl(5, 81%, 56%); //Google branding red
#signInWithGoogle_Dlg {
  background-color: @redGoogleBranding;
  border-color: @redGoogleBranding;
}
@redGoogleBrandingHover: lighten(@redGoogleBranding, 5);
#signInWithGoogle_Dlg:hover {
  background-color: @redGoogleBrandingHover;
  border-color: @redGoogleBrandingHover;
}*/

#signInWithGoogle_Dlg {
  background-color: white;
}

#signInWithGoogle_Dlg .logoGoogle {
  width: 24px;
  margin-left: -0.25rem;
  margin-right: 0.75rem;
}

#signInWithGoogle_Dlg a {
  //color: @red;
}

#signInWithGoogle_Dlg:hover a {
  color: white;
}


// not sure why we're not getting this automatically from .buttonUX2
#signInWithEmail_Dlg:hover {
  background-color: @nearBlackButtonHover;
}
#signInWithEmail_Dlg:hover a {
  color: white;
}

.loginDialogDividerLine {
  // height: 176px;
  //margin-left: 8px;
  //margin-right: 8px;
  width: 100%; // 600px;
  display: inline-block;
  vertical-align: top;
  border-style: none;
  border-top-style: solid;
  //border-left-style: solid;
  border-color: @greyLightLine;
  border-width: 1px;
}

.signInFooter {
  //margin-top: -0.0625rem;
  padding-right: 0;
  padding-bottom: 0.9rem;
  padding-left: 0 !important;
  padding-right: 0 !important;
  padding-top: 0 !important;
  margin-bottom: 1.75rem;
  margin-top: 0.5rem;
}

.signInFooterItems {
  display: block;
  width: max-content;
}

#signupDialog {
  //height: 525px; // fixing the heigh creates a problem when alerts appear
  position: absolute;
  //width: 520px;
}

#showSignupDialog {
  margin-top: 0.45rem;
  margin-bottom: 0.9rem;
}

#signUpSpinner {
  display: inline-block;
  //float: right;
  margin-top: 0.625rem;
  //margin-right: 0.5rem;
  margin-left: 2rem;
}

#forgot_emailLoginDialog {
  //margin-top: 7px;
  margin-left: 1.5rem;
  font-size: @fontSizeButtonsUX2;
  font-weight: 400;
  color: black; // hsl(0, 0%, 59%);
  //float: right;
  position: relative;
  top: 0.8125rem;
}

#submit_signupDialog {
  margin-right: 1rem; //14px;
}

.backToLoginArea {
  display: inline-block;
  max-width: calc(100% - 130px); // width of the create user button
  vertical-align: middle;
  top: 9px;
  position: relative;
}

#backToLogin {
  display: inline-block;
}

/* unused django only?
.modalSubheading {
  padding-top: 1.35rem;
  margin-bottom: 0.675rem;
  color: @grayTextDark;
  font-size: 0.875rem; // 14px
  //font-family:"ff-meta-web-pro-condensed", sans-serif;
}
*/
.modalIndent {
  padding-left: .45rem;
}

.returningUsersHeading {
    padding-top: 0;
    //background-color: @gray;
}

.eyeMask {
  position: absolute;
  z-index: @zindexFormIcon;
  display: flex;
  align-items: center;
  right: 1rem;
  top: 0;
  bottom: 0;
  width: 1rem;
  justify-content: center;
}

// center visually
.eyeMask .fa-eye-slash, .eyeMask .fa-eye {
  position: relative;
  top: 1px;
}


//////////////////////////////////////////////////////////
// FILEPICKER

// fix button positioning
.fsp-picker input {
  margin-bottom: 0;
}

// fix vertical positioning
.fsp-url-source__form {
  bottom: 1.8rem;
}


//////////////////////////////////////////////////////////
// FORMS

label {
  font-size: unset;
  margin-bottom: 0.3375rem;
}

form label {
  margin-bottom: 0.675rem;
}

input {
  height: 1.35rem;
  padding: 0.225rem 0.3375rem;
  margin-bottom: 0.9rem;
}

input[type=radio], .settingsForm input[type=radio] {
  width: unset;
}

// TO DO: remove specifications based on type
@inputMarginBottom: 1.35rem;
@inputPaddingBottom: 1rem;
.inputFieldUX2, .DraftEditor-root,
input[type="text"],
input[type="email"],
input[type="password"] {
  display: block;
  width: 100%;
  overflow: hidden;
  height: unset;
  position: relative;
  box-sizing: border-box;
  font-size: @fontSizePointTitle;
  line-height: @lineHeightSmall;
  color: @nearBlack;
  padding: @cardPadding;
  padding-top: @inputPaddingBottom - 0.0625rem; //-1px; // dunno why this is visually necessary
  padding-bottom: @inputPaddingBottom;
  margin-bottom: @inputMarginBottom;
  border-color: @stackCardDefaultBorderColor;
  border-width: 1px;
  border-style: solid;
  border-radius: @borderRadiusUX2;
  box-shadow: none;
  background-color: white;
}

.radioGroup {
  display: block;
  margin-bottom: @marginVerticalStandard * 1.85;
}

.radioOptions label {
  display: inline-block;
  font-weight: 400;
  margin-right: 0.3125rem;
  margin-bottom: @marginVerticalStandard;
}

@settingsFormRadioDiameter: 1.5rem;
.settingsForm input[type=radio] {
    width: @settingsFormRadioDiameter;
    height: @settingsFormRadioDiameter;
    vertical-align: top;
}

// no margin on right-most radio button, giving us space to make these buttons bigger
// relies on the last button being "Default" - not ideal.
// TO DO: make this work with :last-child
.settingsForm input[value=Default] {
  margin-right: 0 !important;
}

/*
.radioButtonLabelDefault {
  display: inline-block;
  font-size:@fontSizeMediumCond; // update this to our current system
  color: @nearBlack;
  background:none; // is this nec?
  background-color: transparent;
  height: unset;
  margin-bottom: 0.45rem !important;
  margin-right: 1.35rem;
}*/

.control-label, .form-horizontal .control-label {
  color: @nearBlack;
}
/* deprecated I think
.modal label, .accordion-group-heading {
  color: @nearBlack;
  font-weight: normal;
  line-height: 150%;
}
.form-horizontal .control-label {
  float: none;
  line-height: 100%;
  padding-bottom: 0.7875rem;
  padding-top: 0.7875rem;
  padding-left: 0;
  margin-bottom: 0;
  font-size: 0.875em;
  background-color: transparent;
  width: unset;
}
*/

#signupDialog .heading {
 margin-bottom: 1.75rem;
}

/* don't think I want this anymore
.modal input {
  //height: 32px;
  margin-bottom: 0.9rem;
}
*/

.formInstruction {
  width: 100%; // 360px;
  margin-bottom: @marginVerticalStandard * 2;
}

textarea {
  resize: none;
}


//////////////////////////////////////////////////////
// FORMS: DRAFTJS EDITOR

// Draftjs textarea needs its own styling because its div structure is different
//  and it handles :focus, :active, etc using classes

.ys-editor {
  .DraftEditor-root {
    /*
    background-color: white;
    border: 1px solid @stackCardDefaultBorderColor;
    width: 100%;
    font-size: @fontSizePointTitle;
    line-height: 125%;
    color: hsl(0, 0%, 18%);
    padding: 16px;
    box-sizing: border-box;
*/
  }
}

// @-mentions (here "editor" refers to the draftjs Editor, not Editors on the site
.editorMention {
  //background-color: yellow;
  color: @blue;
}

.editorMention:hover {
  text-decoration: underline;
}

// Render comments created using the draftjs editor
.commentText .DraftEditor-root {
  background: transparent;
  border: none;
  padding: 0;
  margin-top: 0;
}


//////////////////////////////////////////////
// DROPDOWNS


@dropDownMenuPadding: 0.45rem;
@dropDownMenuPaddingVertical: .3125rem;
.dropdown-menu {
  cursor: pointer;
  padding-top: @dropDownMenuPaddingVertical;
  padding-bottom: @dropDownMenuPaddingVertical;
  display: block;
  min-width: unset; // over-riding bootstrap defaults
  //padding: 6px; // build padding into drop-down menu componants rather than the menu itself
  //padding-bottom: 12px;
  margin-top: 0.225rem;
  border-radius: @borderRadiusUX2;
    //background-color: #e1ffff;
  border: 1px solid @greyLightLine;
  box-shadow: 0 1px 10px rgba(0,0,0,0.1);
  -webkit-box-shadow: 0 1px 10px rgba(0,0,0,.1);
  -moz-box-shadow: 0 1px 10px rgba(0,0,0,.1);
}

.dropdown-menu .divider {
  border-bottom-color: @greyLightLine; // overridden below?
  border: none;
  height: 1px; // at one point 1.1px rendered better than 1px on high-res retina-style displays, but seems to no longer be the case
  margin-left: 0;
  margin-right:0;
  margin-top: 0; // @marginVerticalStandard  /4;
  margin-bottom: 0; // @marginVerticalStandard  /4;
  padding: 0;
  //height: 2px;
 // margin: 0;
  //background-color: rgba(0,0,0,.09);
}

.dropdown-menu>li>a, .dropdown-menu>li>span, .dropdown-menu>li>.dropdown-heading {
    padding-top: @dropDownMenuPadding * 0.4;
    padding-right:@dropDownMenuPadding * 2.5;
    padding-bottom: @dropDownMenuPadding * 0.4;
    padding-left: @dropDownMenuPadding * 2;
}

.dropdown-menu>li>a, .dropdown-menu>li>span {
  padding-top: 0.3375rem;
  padding-bottom: 0.3375rem;
  display: inline-block;
  width: 100%;
  //font-weight: 500;
}

.infoNonToggle, .infoToggle, .subInfoToggle {
  margin: 0;
  overflow: hidden;
}

.dropdown-menu>li>a:focus, .dropdown-menu>li>a:hover,
.dropdown-submenu:focus>a, .dropdown-submenu:hover>a {
  //color: @blue;
  font-weight: 800;
  //background-color: white;
  background-image: none;
  //text-decoration: underline;
}

.dropdown-menu>li>.menuShareLink {
  width: auto;
  padding-left:  @dropDownMenuPadding ;
  padding-right: @dropDownMenuPadding ;
}

.dropdown-menu .inlineIcons {
  padding-left: @dropDownMenuPadding;
}

.dropdown-menu > li > a:hover, .dropdown-menu > li > a:focus, .dropdown-submenu:hover > a, .dropdown-submenu:focus > a {
  background-color: @blueLight;
  color: black;
  //font-weight: 500;
}

.dropdown-menu > li > a, .dropdown-menu > li > span {
  padding-top: @marginVerticalStandard  * 0.7;
  padding-bottom: @marginVerticalStandard  * 0.7;
  color: black;
  box-sizing: border-box;
}

.dropdown-menu-with-caret {
  top: 1.625rem;
  left: -0.675rem;
  margin: 0;
}

.dropdown-caret, .hoverTip-caret {
  position: absolute;
  z-index: @zindexTooltip;
  left: 0.3375rem;;
  top: 1.675rem;
}
.dropdown-caret {
  top: -0.57375rem;
  left: 97px; // positioned for the More Menu
  width: 1rem;
  height: 0.675rem;
  overflow: hidden;
}

.contributorsMenu .dropdown-caret {
  left: 18px;
}

@caretBorderWidth: 8px;
.dropdown-caret .caret-outer, .hoverTip-caret .caret-outer {
  border-left: @caretBorderWidth solid transparent;
  border-right: @caretBorderWidth solid transparent;
  height: auto;
  left: 0;
  top: 0;
  width: auto;
  display: inline-block;
  position: absolute;
}
.dropdown-caret .caret-outer {
  border-bottom: @caretBorderWidth solid black;
  border-bottom-color: @greyLightLine;
  margin-left: -0.0625rem; // -1px;
}

.hoverTip-caret .caret-outer {
  border-top: @caretBorderWidth solid @hoverTipColorBG;
  border-bottom-color: @hoverTipColorBG;
}

.dropdown-caret .caret-inner, .hoverTip-caret .caret-inner  {
  top: 1.25px;
  left: 0; // 0.5px;
  border-left: @caretBorderWidth solid transparent;
  border-right: @caretBorderWidth solid transparent;
  border-bottom: @caretBorderWidth solid white;
  border-bottom-color: white;
  display: inline-block;
  margin-left: -0.0625rem; // -1px;
  position: absolute;
}

.stats .dropdown-caret {
  left: 9px;
}
.stats .caret-inner {
  border-bottom-color: @hoverTipColorBG;
  top: 2px;
}

.menuDivider {
    width: 100%;
    border-bottom-color: @greyLightLine;
    border-bottom-style: solid;
    border-bottom-width: .0625rem; // 1pm // 1.25px;   //(gps) at 1px it tends to disappear in some places
}

//////////////////////////////////////////////////////////
// SETTINGS

// width calibrated to fit all the notification email radio buttons on a single row on wide screens
#settingsPage {
  max-width: 460px;
}

#changePasswordLink {
  display: block;
  margin-top: @marginVerticalStandard * 2 ;
  width: max-content;
}

// this will become unnecessary once we change this from an input to a button
#submit_changePasswordButton {
  width: unset;
}

.settingsForm {
  margin-top: @marginVerticalStandard * 2;
  margin-bottom: @marginVerticalStandard * 8;
}

.settingsForm h2 {
  padding-top: 0;
  margin-top: @marginVerticalStandard * 3.5;
  margin-bottom: @marginVerticalStandard * 2.5;
}

.settingsForm input {
  width: 100%;
  margin-right: 0;
}

.notificationFrequencyRadios {
  display: block;
  margin-bottom: 1.575rem;
}

.notificationFrequencyRadios .radioGroup > label {
  font-weight: 800;
}
.notificationFrequencyRadios .radioButtonLabelDefault {
  font-weight: 400;
}

.radioOptionBlock {
  display: inline-block;
  margin-bottom: 1rem;
  margin-right: 1.25rem;
}
.radioOptionBlock label {
  margin-bottom: 0 !important;
}


//////////////////////////////////////////////
// CLOSE X

.editAreaClose, .emojiClose {
  float: right;
  font-size: 1.35em;
  position: relative;
  cursor: pointer;
  font-family: serif; // this is intentional. the multiplication X looks better like this
  font-weight: bold;
  margin-right: 0;
  //margin-top: 0.125rem; // 2px
}

.editAreaClose a {
  color: @greyMid;
  position: relative;
  top: -7px;
}
#gotItExplanation a {
  color: @nearBlack;
}

#gotItExplanation .buttonGotIt {
  margin-right: 0.5rem;
  margin-bottom: 0.5rem; // for when the next button wraps to the line below
}


.editAreaClose a:hover {
  color: @blue;
  text-decoration: none;
}

.emojiClose {
  font-size: 1.125em;
  float: none;
  //margin-left: 1px;
  vertical-align: middle;
}


//////////////////////////////////////////////////////////
// BUTTONS


@buttonUX2_paddingTop: 0.75rem;
@buttonUX2_paddingLeft: 1.25rem;
@buttonUX2_paddingRight: @buttonUX2_paddingLeft;
@buttonUX2_paddingBottom: @buttonUX2_paddingTop + .0625rem; // +1px
@buttonUX2_paddingLeftRightLongLabel: @buttonUX2_paddingLeft + 0.125rem; // +2px;
@buttonUX2_fontWeight: 700;
@buttonUX2_borderWidth: 0.0625rem; // 1px, written in rem bc its used in equations below

@tabUX2_color: @blue; // hsl(0, 0%, 25%);
@tabUX2_colorHover: @blueLight; // black;

.buttonUX2, .tabUX2, .buttonSecondaryUX2blue,
.buttonUX2 a, .modalBodySignInWith a {
  margin-bottom: 0;
  //font-family: ff-meta-web-pro-condensed,sans-serif;
  font-weight: @buttonUX2_fontWeight;
  font-size: @fontSizeButtonsUX2;
  font-style: normal;
  height: unset;
}

.buttonUX2, .tabUX2, .buttonSecondaryUX2blue {
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  border-style: solid !important;
  border-width: @buttonUX2_borderWidth;
  -webkit-border-radius: @borderRadiusUX2;
  -moz-border-radius: @borderRadiusUX2;
  border-radius: @borderRadiusUX2;
}
.buttonUX2, .buttonSecondaryUX2blue {
  padding-top: @buttonUX2_paddingTop;
  padding-left: @buttonUX2_paddingLeft;
  padding-right: @buttonUX2_paddingRight;
  padding-bottom: @buttonUX2_paddingBottom;
}

.buttonUX2marginR {
  margin-right: @marginVerticalStandard * .4;
}
.buttonUX2responsiveStack {
  //margin-bottom: @marginVerticalStandard * 1.25;
  display: inline-block;
}

.tabUX2 {
  padding-top: @buttonUX2_paddingTop * 0.666;
  padding-left: @buttonUX2_paddingLeft + @buttonUX2_borderWidth;
  padding-right: @buttonUX2_paddingRight + @buttonUX2_borderWidth;
  padding-bottom: @buttonUX2_paddingBottom * 0.666;
  color: @tabUX2_color;
  border: none !important;
  background: none;
  display: inline-block;
  margin-right: 0.225rem;
  font-size: @fontSizePointTitle;
}
.tabUX2:hover, .tabUX2:focus {
  background-color: @tabUX2_colorHover;
  border-color: @tabUX2_colorHover;
  color: white;
  outline: none;
  text-decoration: none;
}
@tabSelectedUnderlineThickness: 4px;
.tabUX2.selectedTab {
  text-decoration-line: underline;
  text-decoration-style: solid;
  text-decoration-thickness: @tabSelectedUnderlineThickness;
  text-decoration-color: @tabUX2_color;
}
.tabUX2.selectedTab:hover, .tabUX2.selectedTab:active, .tabUX2.selectedTab:focus {
  text-decoration-color: white;
  text-decoration-line: underline;
  text-decoration-style: solid;
  text-decoration-thickness: @tabSelectedUnderlineThickness;
}

.buttonUX2 {
  position: relative;
  //top:-2px;
  color: white;
  background-color: black; //@nearBlackButtonHover;
  border-color: black; // @nearBlackButtonHover;
  outline: none !important;
  vertical-align: top;
}
.buttonUX2:focus:enabled {
    outline: 2px solid @blue;
}
.buttonUX2:hover:enabled {
    background-color: black;
}
@buttonUX2ScaleDownOnClick: 0.975;
.buttonUX2:active:enabled,
.buttonSecondaryUX2blue:active:enabled,
[id^=signInWith]:active,
#header button:active:enabled {
  transform: scale(@buttonUX2ScaleDownOnClick);
  outline-style: none;
}

.buttonUX2:disabled {
  color: @nearWhite;
  background-color: @greyMid;
  border-color: @greyMid;
}
.buttonUX2Red:disabled {
  background-color: @stackCardCounterBorderColor;
  border-color: @stackCardCounterBorderColor;
}
.buttonUX2:focus:enabled {
  //border: 2px solid @blueLight;
  //box-shadow: 0 0 0px 2px @blueLight; // using this instead of border bc it doesn't repo
}

.buttonUX2:hover:enabled, .buttonUX2:focus:enabled {
  background-color: @nearBlackButtonHover;
  border-color:  @nearBlackButtonHover;
  transition: @hoverTransitionDurationButtons;
}
a.buttonUX2:hover:enabled, a.buttonUX2:focus:enabled {
  color: white;
}
a.buttonUX2:active:enabled, a.buttonUX2:focus:enabled {
  outline: none !important;
  border: none !important;
  box-shadow: none !important;
}

.buttonUX2Blue {
  background-color: @blue;
  border-color: @blue;
}
.buttonUX2Blue:hover:enabled, .buttonSecondaryUX2blue:hover:enabled, .buttonUX2Blue:focus:enabled {
  background-color: @blueLight;
  border-color: @blueLight;
  color: white;
}
/*
.buttonUX2Blue:focus {
  box-shadow: @blueLight;
  outline: none;
}*/
/*
.buttonUX2Red:focus:enabled {
  box-shadow: @redLight;
  outline: none;
}*/
.buttonSecondaryUX2blue {
  position: relative;
  color: @blue;
  border-color: @blue;
  outline: none !important;
  background: none;
}

.buttonSecondaryUX2black, .buttonSecondaryUX2Transp {
  color: @nearBlack;
  border-color: @nearBlack;
  outline: none !important;
  background: white;
}

.buttonSecondaryUX2Transp, .claimEditArea .buttonSecondaryUX2black {
  background: transparent;
}

.buttonSecondaryUX2black:hover, .buttonSecondaryUX2black:focus:enabled, .buttonSecondaryUX2Transp:hover {
  color: white;
  background-color: @nearBlack !important;
  border-color: @nearBlack !important;
}
.buttonSecondaryUX2red {
  color: @red;
  outline: none !important;
  border-color: @red; // @stackCardCounterBorderColor ;
  background-color: @stackCardCounterBG;
}
.buttonSecondaryUX2red:hover {
  color: white;
  background: @red !important;
  border-color: @red !important;
}
.cancelButton {
  background: none;
  border: none;
  font-size: @fontSizeSecondaryUX2;
  outline-style: none;
  display: inline-block;
  padding: 0;
  padding-left: 0.3375rem;
  color: @nearBlack;
  margin-left: 0.45rem;
}
.cancelButton:hover:enabled, .cancelButton:focus:enabled {
  text-decoration:underline;
  outline-style: none;
}

.cancelButton:active {
    transform: scale(@buttonUX2ScaleDownOnClick);
}

.cancelButtonNewComment {
  //color: grey;
}

.createClaimFormButton, .editClaimFormButton {
  display: inline-block;
  //font-size: @fontSizePointTitle;
  //margin-top: @marginVerticalStandard * 0.25;
}

// Create form: image option
#imageUploadSuccessMessage {
  margin-left: 1rem;
}
// where is this used?
label[for=ys-form-imageDescription] {
  margin-top: 1.675rem;
}

@buttonUX2BigScale:  1.45;
@buttonUX2BigPadding: 20px;
.imageEditForm .imagePickerButton {
  margin-bottom: @marginVerticalForms * 2.7;
  //transform: scale(@buttonUX2BigScale);
  position: relative;
  //left: 24px;
  padding-top: @buttonUX2BigPadding;
  padding-bottom: @buttonUX2BigPadding;
  font-size: 125%;
  width: 200px;
}

.createClaimCreationArea .addEvidenceAreaNote {
  margin-top: @marginVerticalStandard;
}

.createClaimCreationArea .imagePickerButton {
  transform: none;
  left: 0;
  margin-bottom: 0;
  margin-top: 0;
  color: @nearBlack;
  border-color: @nearBlack;
  outline: none !important;
  background: white;
}
.createClaimCreationArea .imagePickerButton:hover {
  color: white;
}

/*@buttonUX2BigScaleDownOnClick: 0.98; //.992;
.imageEditForm .imagePickerButton:active:enabled {
  transform: scale(@buttonUX2BigScaleDownOnClick * @buttonUX2BigScale);
}*/

.buttonLower {
  margin-top: @marginVerticalStandard * 1.25;
}


//////////////////////////////////////////////////////
// ONBOARDING TIPS
//
// styling classes from https://github.com/HiDeoo/intro.js-react#introjs-options

.introjs-helperNumberLayer {
  text-shadow: none;
  background: none;
  background-color: @nearBlack;
  box-shadow: none;
}

.onboardTipHiliteSeeEvidence {
  background-color: pink !important;
}

// the little window that reveals the feature being highlighted
.introjs-helperLayer {
  border-radius: 0;
  border: none;
  box-shadow: none;
  transform: scale(0.91); // TO DO: Figure out how to set size of this box directly, perhaps on a per-tip basis
}

.introjs-tooltipbuttons {
  text-align: center;
}

.introjs-tooltip {
  border-radius: 0;
  padding: 1.25rem;
}

.introjs-bullets ul {
  margin-top: 1.75rem;
  margin-bottom: 1.875rem !important;
}

@introjsBulletWidth: 0.6125rem;
.introjs-bullets ul li a {
  width: @introjsBulletWidth;
  height:  @introjsBulletWidth;
}

// hide the skip button
.introjs-skipbutton {
 display: none;
}
.introjs-nextbutton, .introjs-donebutton {
   float: right;
}
.introjs-donebutton {
  display: block !important;
}
.introjs-tooltipbuttons a[role=button] {
  background-image: none;
  text-shadow: none;
  border: @nearBlack;
  background-color: @nearBlack;
  color: white;
  width: 48%;
  box-sizing: border-box;
  margin: 0.125rem;
  padding: 0.675rem 0.875rem;
}
.introjs-tooltipbuttons a.introjs-prevbutton {
  border: black;
  background-color: white;
  color: black;
}
.introjs-tooltipbuttons a[role=button]:hover {
  border: black;
  background-color: black;
  color: white;
}


//////////////////////////////////////////////////////////
// HEADER


// TO DO: lets rename this to be headerUser bc the username is not always displayed
.headerUserName {
  //font-family: @sansCondFontFamily;
  color: black;
  font-weight: bold;
  position:relative;
}

.headerUserName a {
    color: black;
}

.headerLinkIcon {
    max-height: 1.25rem;
    padding:0;
    margin:0;
    margin-right: 0.125rem; // 2px;
    position: relative;
    //top: -1px;
}

@headerHeight: 2.7rem;
#header {
  background: @headerColor;
  height: @headerHeight;
  margin-bottom: 0;
  border-width: 1px;
  border-color: @stackCardDefaultBorderColor;
  border-bottom-style: solid !important;
  margin-left: 0;
}

#header form {
  margin-bottom: 0;
}

.headerItem {
  display: inline-block;
  //float: none;
  color: black !important;
  //font-family: "ff-meta-web-pro-condensed", sans-serif;
  font-size: @fontSizePointTitle; // @baseFontSizeUX2; // @fontSizeHeaderUX2;
  font-weight: 700;
  line-height: 120%; // TO DO: remove this and update vertical spacing as needed
  margin-top: 0.25rem;
  margin-right: 0;
  margin-left: @defaultHeaderMarginH;
  //margin-top: 14px;
  vertical-align: middle;
}
.headerItem > a {
   color: black ;
}
.headerItem > a:hover, .headerItem > a:focus, .btn-link:hover, .btn-link:focus {
   color: @blue ;
}

@headerLinkIconSize: 1rem;
.headerLinkIcon {
  font-size: @headerLinkIconSize;
 // top: 3px;
  margin-right: 0;
  color: black;
}
.headerLinkIcon:hover, a:hover .headerLinkIcon, .headerLinkIcon:focus, a:focus .headerLinkIcon {
   color: @blue ;
   outline: none;
   text-decoration: none !important;
}

#headerLogo {
  margin-left: 1.5%;
  margin-right: 0.75rem;
}

#headerLogo .logoDino {
  height: @headerHeight - 0.5rem;
  margin-right: 0.375rem;
}
// use height instead of font-size bc this is an svg and to keep height consistant bt WHYSAURUS and Ys
// use rem instead of em bc this is actually an image and not type
#headerLogo .logoText {
  height: 0.9rem;
}

#CreatePoint {
  //margin-left: @defaultHeaderMarginH;
  margin-left: 0;
}

#CreatePoint:hover, .userMenuLink:hover {
  text-decoration: none; // prevent underline in blank spaces bt labels and icons
}
#CreatePoint:hover .headerLinkLabel, .userMenuLink:hover .headerLinkLabel {
  text-decoration: underline;
}

#makePointIcon {
  margin-right: 0.225rem;
  //top: 0.0625rem; // 1px;
}

#searchArea {
  height: @headerHeight; //48px;
  // line-height: 3rem; // 48px; why is this so high? maybe so that items flow down to the tag bar
  position: relative;
  width: 33%;
  display: inline-block;
  //margin-left: @defaultHeaderMarginH;
  vertical-align: top;
  margin-top: 0;
  /*form {
    margin-top: -4px;
  }*/
}
#searchArea .searchIcon {
  position: relative;
  height: 0.9rem;
  background-image: none;
  //margin-top: -4px;
  margin-right: 0;
  margin-left: 0.675rem;
  width: auto;
  float: left;
  transform: scaleX(-1);
  top: @headerHeight * -0.65;
}
#searchBox {
  padding-left: 2.15rem; // updated!
  padding-top: 0.75rem; //13px;
  padding-bottom: 0.7875rem; //14px;
  background-color: @headerColor;
  height: @headerHeight;
  //box-sizing: border-box;
  //top: 3px;
  border-top:none;
  border-bottom:none;
  margin-bottom: 0;
}
#searchBox:active, #searchBox:focus  {
  background-color: white;
  border-width: 1px;
  border-color: unset;
}
#searchBox::placeholder, #searchBox::-webkit-input-placeholder, #searchBox:-moz-placeholder,#searchBox::-moz-placeholder, #searchBox:-ms-input-placeholder {
  //font-size: 0.9em;
  color: @placeholderTextInitialColor;
  //font-style: italic;
  padding-top: 0.144rem;
}

#aboutMenu {
  //margin-left: @defaultHeaderMarginH - 4px; // needed to keep spacing consistent, not sure where this 4px is coming from
  position: relative;
  //top: 2px;
}

#aboutMenu .headerLinkIcon  {
  font-size: @headerLinkIconSize * 1.1;
}

.menuCaret {
  margin-left: .125rem;
  font-size: 120%;
  position: relative;
  top: 1.5px; // nudge position because of bump in font-size
}

#aboutMenu a:hover  {
  text-decoration: none !important;
}

a:hover #headerLabelAbout {
  text-decoration: underline;
}

#aboutDropdown {
  margin-top: @marginVerticalStandard - 0.1125rem; // - 2px;
  left: -11.5px;
}

.headerSocialMediaIconArea {
  //margin-left:  @defaultHeaderMarginH;
  margin-top: 0.9rem;
  margin-left: 0;
  margin-right: 0.135rem;
}

.headerSocialMediaIcon {
  font-size: @headerLinkIconSize * .85;
  margin-left: @defaultHeaderMarginH * .5 ;
  //color: #a9a9a9 !important;
}
.dropdownSocialMediaIconArea .headerSocialMediaIcon {
  margin-right: 0.225rem;
  margin-left: 0;
  top: 3px;
}
.dropdownSocialMediaIconArea .headerLinkIcon {
  font-size: @headerLinkIconSize * .9;
}

a.headerSocialMediaIcon:hover {
  //color: black !important;
  text-decoration: none !important;
}

#headerIconFb {
  font-size: @headerLinkIconSize * .78;
  top: -1.8px;
}

#notifications {
  //right: 0px;
  margin-top: 0.625rem;
  //margin-left: 1.25rem;
  position: relative;
  cursor:pointer;
}

// remove margins applied to li's by default
#notifications li, #notificationsAllArea li {
  margin: 0;
}

#notificationIcon {
    max-width: 1.575rem;
}

.notificationsSeeAll, .notificationsMarkAllRead {
  text-transform: capitalize;
}
.notificationsSeeAll {
  margin-right: 1.25rem; // so that it's not on top of the scrollbar
}
.notificationsMarkAllRead {
  margin-right: 0.45rem;
}

#notificationCount {
  padding-top: 0px;
  padding-right: 0.16875rem;
  padding-bottom: 0.225rem;
  padding-left: 0.16875rem;
  position: absolute;
  top: 20%;
  text-align: center;
  background-color: @blueLight; // #00ADEE;
  color: white;
  left: 77%;
  font-family: @sansCondensedFontFamily;
  font-size: @fontSizeTertiaryUX2;
  font-weight: 800;
  border-radius: 2px;
}

@notificationMaxWidth: 480px;
.notification-menu {
  margin-left: -272px; // a bit hacky bc I've tweaked bootstrap's behavior for .pull-right>.dropdown-menu
  border-radius:0px;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  -moz-overflow-scrolling: touch;
  -ms-overflow-scrolling: touch;
  -o-overflow-scrolling: touch;
  overflow-scrolling: touch;
  height: auto;
  max-height: calc(100vh - @headerHeight - 0.125rem); // not sure why the extra 0.125rem is nec
  width: @notificationMaxWidth;
  padding-top: 0;
  padding-bottom: 0;
  top: unset;
  margin-top: 0.61875rem;
  position: fixed;
  right: 0 !important;
}

.notification-menu li {
  margin-left: 0;
}

.notification-menu .notificationMenuItem {
  padding-left: 0.675rem;
}

@notificationLeftWidth: 1.25rem;
.notificationLeft {
    width: @notificationLeftWidth;
    display: inline-block;
    min-height: 1.6875rem;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    //float: left;
    vertical-align: top;
}

@notificationTextMarginLeft: 0.625rem;
.notificationText {
    display: inline-block;
    min-height: 1.6875rem;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    //float: left;
    margin-left: @notificationTextMarginLeft;
    padding-left: 0;
    width: calc(100% - @notificationPadding - @notificationLeftWidth - @notificationTextMarginLeft);
    margin-top: -0.0625rem; // -1px;
    white-space: normal;
    line-height: @lineHeightBase;
    font-size: @fontSizeSecondaryUX2;
}

.notificationMenuItem:hover,  .notificationMenuItem:focus {
  font-weight: normal !important;
}

@notificationPadding: 0.225rem;
.notificationMenuItem, #notificationMenuHeader, #notificationMenuFooter {
  cursor:pointer;
  padding: @notificationPadding;
  border-bottom: 1px solid #d7d7d7;
  width:auto;
  display: block;
}

#notificationMenuHeader, #notificationMenuFooter {
  padding-left: 2.25rem; // 42px;
  padding-bottom: 0.7875rem;
  padding-top: 0.7875rem;
}

.notificationMenuItem {
  padding-top: 0.45rem !important;
}
.notificationMenuItem:hover {
  background-color:@blueLight;
  color: black;
}

.notificationTimeStamp {
  margin-top: 0.0625rem; // 1px;
  margin-bottom: 0.45rem;
  font-size: @fontSizeTertiaryUX2;
  color: @greyMid;
  //line-height: 120%;
  position: relative;
  top: 0.16875rem;
  margin-right: 0.9rem;
}
li:hover .notificationTimeStamp {
  color: @greyDark;
}

.notificationAvatar {
  float:right;
  margin-top: 0.0625rem; // 1px;
  max-width: 1.2375rem;
}

.notificationCleared {
  background-color: @greyLightBG;
}

// User Menu Area

@headerFooterRightMargin: 0.45rem;
#userMenuArea {
  //margin-left: @defaultHeaderMarginH * .6666;
  margin-right: @headerFooterRightMargin;
  margin-left: 1.125rem;
  margin-top: 0.9rem; // different than standard bc of the pull-right float
  //position: fixed;
  //right: 0;
  //top: 0;
}

#userMenuArea #avatar {
    margin-left: 0.16875rem;
    height: 2rem;
}

#userMenuArea.headerUserName {
  margin-top: 0.3rem; // differs bc now the profile pic/avatar is being displayed
}

#userMenuArea .username {
  position: relative;
  top: 0.333rem;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  max-width: 6.375rem;
  display: inline-block;
}

#userMenuSignedIn {
  //position: absolute;
  //left: -1rem;
  //right: unset;
  min-width: unset;
  margin-top: 0.4rem; // 0.16875rem;
  right: -8px; // not sure why this is necessary (maybe different scrollbars impact what looks right?)
}

#userMenuSignedIn a {
  padding-right: 1.5rem; // add some extra so text isn't too close to the scrollbar
}

#usernameInMenu {
  font-weight: 800;
}

#signInFromHeader {
  position:relative;
  color: black;
}

// TO DO: standardize style on header in a more organized way
#signInFromHeader:hover {
  color: @blue;
}


//////////////////////////////////////////////////////////
// PRIVATE CLASSROOM AREAS

.areaChoose {
    margin-top: 0px;
    margin-bottom: -0.25rem; // -4px;
}

.menuHeading {
  height:auto;
  padding-top: 0.1875rem; // 3px;
  padding-right: 1.25rem; // 20px;
  padding-bottom: 0.1875rem; // 3px;
  padding-left: 0.625rem; // 10px;
}

#header.privateSandbox {
  background: @sandboxHeaderColor;
}

.privateAreaHeader {
  background: @sandboxHeaderColor;
  //height: @lineHeightXXXL;
  line-height: @lineHeightXXXL;
}

#areaLine {
  font-family: @serifFontFamily;
  font-size: @baseFontSizeUX2 * 1.75;
  font-weight: bold;
  color: black;
  margin-top: 0.625rem; // 10px;
  margin-bottom: 0.625rem; // 10px;
  text-align: center;
}


//////////////////////////////////////////////////////////
// TOP TAGS LIST

.topTagsListArea {
  background-color: @headerColor;
  padding-top: 0 !important;
  padding-bottom: 0;
  border-top-style: solid !important;
  border-width: 1px;
  border-color: @stackCardDefaultBorderColor;
  border-bottom-style: solid !important;
}

.topTagsList {
  display: block;
  margin-bottom: 0; //@marginVerticalStandard * 2; // - @cardStackWidth;
  margin-left: 2%; // dynamic margin to match header
  height: 50px;
  overflow: hidden;
  //background-color: pink;
  //padding-top: @cardPadding / 3;
  //padding-bottom: @cardPadding / 3;

}

@tagPaddingV: 0.875rem;
@tagPaddingH: 0.75rem;
.topTagsList .tabUX2 {
  font-weight: 500;
  color: @nearBlack;
  font-size: @fontSizeSecondaryUX2;
  padding: @tagPaddingV @tagPaddingH;
  margin-right: 0;
}


//////////////////////////////////////////////////////////
// EXPLANATION IMAGES

@homepageIllustrationHeight: 2.25rem;

#explanationRowHomepage {
  //border-bottom-style: none;
  //margin-left: @cardStackWidth;
  padding-left:  @mainContainerMarginLeft + 2rem;
  padding-right: 1rem;
  box-sizing: border-box;
  margin-bottom: @marginVerticalStandard * 0.5;
  padding-bottom: 2.75rem; // 48px;
  //margin-left: -3px;
  background-color: @editingAreaBG; // white;
  //width: inherit;
  //border-color: @stackCardDefaultBorderColor;
  //border-width: @cardBorderWidth;

}

@explanationBlockMarginL: @cardStackWidth + @cardPadding - 0.1875rem;
.explanationBlock {
  display: block;
  float: none;
  margin-bottom: 2.75rem;
  //margin-right: 2.33em;
  margin-left: 0;

}
.explanationBlock.smallerMarginRight {
  margin-right: 1.575em;
}

.explanationBlock .icon {
    font-size: 2.75rem;
    margin-right: 2.5rem;
    display: inline-block;
    width: 4rem;
    text-align: center;
    vertical-align: top;
    margin-bottom: @marginVerticalStandard * 1.25;
}

.explanationText {
  color: black;
  font-size: @fontSizePointTitle;
  //font-weight: 700;
  display: inline-block;
  text-align: left;
  vertical-align: top;
  //max-width: 270px;
  margin-bottom: 0.5rem;
  margin-left:0;
  //line-height: 140%;
  max-width: 415px;
  margin-top: -0.4rem; // -7px; // not sure why this is necessary to align text to image
}

.explanationBlock .headline {
    font-weight: 800;
    display: block;
    //text-transform: capitalize;
}
.explanationBlock .secondary {
    display: block;
}

.explanationImageCentered {
  display: inline-block;
  vertical-align: top;
  height: @homepageIllustrationHeight;
  margin-right: 0.9em;
}

#gotItExplanation {
  //margin-top: 12px;
  margin-left: 6.375rem;
  margin-right: 0; //36px;
  margin-bottom: 0;
  //margin-top: @marginVerticalStandard * -0.75;
}
.explanationLabel {
  display: inline-block;
  position: relative;
  //font-style: italic;
  font-weight: 800;
  margin-top: 0.75rem;
  margin-bottom: 3.25rem;
  font-size: @fontSizeModalHeading * 1.2;
  line-height: @lineHeightMedium;
  margin-left: 0.5rem;
}

.explanationTextCentered {
    color: black;
    font-size: @baseFontSizeUX2;
    //line-height: @lineHeightSmall;
    text-align:center;
    //font-weight:bold;
    max-width: 166px;
    margin:auto;
    margin-bottom: 0.5625rem;
}

.explanationTextCentered.lessWidth {
    max-width: 135px;
}

.explanationImageCentered {
    display:block;
    margin:auto;
    //margin-bottom:36px;
}

//////////////////////////////////////////////////////////
// QUICK CREATE

@mainPageClaimCreationButtonCalculatedWidth: 4.725rem;
.mainPageClaimCreationArea {
  margin-left: @cardHeadingOffsetToUndoStack;; // nudge left to account for the empty space taken up by invisible stack cards
  margin-top: @marginVerticalStandard * 2;
  margin-bottom: @marginVerticalStandard; //0
}

.mainPageClaimCreationLabel {
  //margin-left: 0.75rem; // 12px;
  font-size: @fontSizeModalHeading;
  margin-bottom: 2rem;
}

.claimCreationFormFieldBlock {
  display: inline-block;
  margin-right: 0.3375rem;
}

.mainPageClaimCreationArea .claimCreationFormFieldBlock {
  width: @cardWidth;
}

.createClaimCreationArea {
  width: @cardWidth;
  margin-top: @contentMarginTop;
}

.addEvidenceForm .claimCreationFormFieldBlock {
  width: 100%; // @cardWidth ; // subtract some to align right edge of Add button with right edge of the card above
}

@editTextAreaWidth: 100%; // ~"calc(100% - 68px)";// subtract enough for button
.editPointTextForm .claimCreationFormFieldBlock {
  width: @editTextAreaWidth;
}

.claimCreationFormButtonBlock {
  //width: 100%;
  display: inline-block;
  vertical-align: top;
  margin-top: 2.5rem;
  margin-bottom: @marginVerticalStandard;
}

.claimCreationFormButtonBlock .formInstruction {
  margin-top: 2rem;
}

.createClaimFormFirstOptionLabel {
  margin-top: 1.675rem;
}

#mainPageClaimCreationForm .claimCreationFormButtonBlock, .claimEditArea .claimCreationFormButtonBlock {
  margin-top: 0;
}


//////////////////////////////////////////////////////////
// HOME PAGE SECTIONS

@sectionVerticalSpace:  @marginVerticalStandard * 1.666;

.contentSection {
  margin-bottom: @sectionVerticalSpace;
}

.contentSectionCards {
  display: inline-block;
}

.contentSectionLabel {
  display: inline-block;
  padding: @cardPadding;
  margin-bottom: 0;
  font-size: @fontSizePointTitle;
  font-weight: 500;
  margin-top: 0;
  margin-right: 1rem;
}

.contentSectionHeading .contentSectionLabel {
  float: right;
  clear: right; // is this needed?
}

.contentSectionLabel a:hover {
  text-decoration: underline;
}

// spinner for loading content sections
.contentSectionLoading {
  display: inline-block;
  padding-left: @cardPadding;
  padding-top: 0;
  padding-bottom: 0;
  margin-left: 0.225rem;
  top: -3.6px;
  position: relative;
}

.contentSectionLabelCopy {
  //background-color:pink;
  font-size: @fontSizeSecondaryUX2;
  display: inline-block;
  max-width: 8rem;
}

.contentSectionSeeMore {
  margin-bottom: 1rem; // remove on narrow widths
}

.contentSectionLabelSeeMore {
  font-weight: normal;
}

.contentSectionMoreStack:hover .contentSectionLabelSeeMore {
  text-decoration: underline;
}

.contentSectionTitleDot {
  margin-left: 0.225rem;
  margin-right: 0.225rem;
  font-weight: normal;
}

.contentSectionSecondaryLink {
  //font-size: @baseFontSizeUX2;
  font-weight: normal;
  display: block;
  margin-top: 0.25rem;
  //margin-left: 2.375rem;
}

.contentSectionMoreStack, .contentSectionSeeLessLink {
  display: block;
  //margin-bottom:  @cardStackWidth + @marginVerticalStandard;
}

.contentSectionAfterLink {
  display: block;
  //margin-left: @cardStackWidth + @cardPadding;
  margin-bottom: @marginVerticalStandard;
  position: relative;
  top:  -2.5rem;
  left: 9rem;
  width: max-content;
  margin-left: @cardPadding;
}

.contentSectionSeeLessLink {
  //margin-left: @cardStackWidth + @cardPadding;
  float: right;
  padding: @cardPadding;
}

.sectionIcon {
  margin-right: 0.375rem;
  font-size: 150%;
  display: inline-block;
  vertical-align: top;
}
.iconBig {
  font-size: 3.75rem;
  display: inline-block;
  margin-right: 0.45rem;
  margin-bottom: 0.75rem;
  margin-left: -0.09rem;
}
.hashtagBig {
  font-size: 120%;
  font-weight: bold;
  margin-right: 0.1125rem; // 2px;
}

.buttonSection .buttonUX2 {
  //width: @cardWidth;
  box-sizing:border-box;
  text-align: left;
  padding-right: 1.35rem;
}

//////////////////////////////////////////////////////////
// CLAIM PAGE

.recPointsContainer {
  margin-top: @marginVerticalStandard * 2;
}

.recPointsContainer .contentSectionHeading {
  width: unset;
}

.recPointsContainer .contentSectionLabel {
  float: none;
  clear: none;
}

.recPointsContainer .contentSectionLabelCopy {
  max-width: unset;
}



//////////////////////////////////////////////////////////
// USER PAGE

#mainContainer.userPage {
  padding-left: unset;
}

.profileAreaColumn {
  //width: 240px;
  display: inline-block;
  float: left;
}

@userInfoAreaWidth: 230px;
#userInfoArea {
  margin-top: @profilePageMarginTop;
  padding: 2.475rem;
  box-sizing: border-box;
  width: @userInfoAreaWidth;
  padding-right: 1.8em; // using padding here instead of margin because it makes the re-use of @userInfoAreaWidth below a bit simpler
}

#display_userName {
  display: inline-block;
  margin-right: 0.2835rem;
  margin-top: 1rem;
  margin-bottom: 1rem;
  font-weight:bold;
  //line-height: @lineHeightDefault;
  font-size: @fontSizePointTitle * 1.25;
}

.profileBodyText {
    font-size: @fontSizePointTitle;
    font-weight:normal;
    //line-height: @lineHeightSmall;
}

.profilePic {
  margin-top: 0;
}

#profileTabbedArea {
  width: 360px;
  width: ~"calc(100% - @{userInfoAreaWidth})"; 
}

.profileStatsArea {
  margin-bottom: @marginVerticalStandard;
}

.profileItem {
  display: block;
  margin-bottom: @marginVerticalStandard;
}

.profileItem a {
  overflow: hidden;
  text-overflow: ellipsis; // for URLS especially
}

#profileTabbedArea .contentSectionHeading {
  margin-bottom: @marginVerticalStandard;
}

@profilePageMarginTop: 2.3625rem;
#profileDisclaimer {
  margin-top: @profilePageMarginTop;
  background-color: pink;
  padding: @cardPadding;
  padding-left: @cardPadding * 1.15; // a bit less because of the line-height
  border-radius: @borderRadiusUX2_rounder;
  width: @wideCardWidth; // @cardWidth;
  box-sizing: border-box;
  margin-left: @cardPadding * -1;
  margin-bottom: @marginVerticalStandard * 2;
}

.editProfileLink {
  display:block;
  margin-top: @marginVerticalStandard;
}

//////////////////////////////////////////////////////////
// NOTIFICATIONS PAGE

#notificationsPage {
  max-width: @notificationMaxWidth;
}

#notificationsPage .fullPageFormHeading {
  margin-bottom: @marginVerticalStandard * 2;
}

#notificationsAllArea {
  background-color: white;
  margin-left: 0;
  margin-bottom: 0;
  width: 100%;
  border-color: @stackCardDefaultBorderColor;
  border-width: @cardBorderWidth;
  border-style: solid;
}

#notificationsAllArea>li>a {
  padding-left: 0.5625rem; // 10px;
  padding-top: 0.45rem !important;
  padding-right: 1.5rem; // needed on very narrow widths so that text does not flow behind scrollar
}

#notificationsAllArea li {
	list-style:none;
  // border-bottom: 1px solid #B9BBBD;
}


//////////////////////////////////////////////////////////
// STATIC PAGE: CONTACT & FEEDBACK LINK

#footer {
    position:fixed;
    bottom: 0;
    right:0;
    //margin-bottom:18px;
    //background-color: #f0f0f0;
    padding: 0.675rem; // 12px;
}

.feedbackFooterLink {
  display: block;
  position: relative;
  padding: @headerFooterRightMargin;
  right: 0.9rem;
  font-weight: @buttonUX2_fontWeight;
  font-size: @baseFontSizeUX2 * 1.125;
  font-style: normal;
  color: black;
  width: fit-content; // not sure why it doesn't do this by default
}

.contactTitle {
  margin-bottom: 2.3625rem;
}

.contactForm {
    margin-top: 1.8rem;
}
.contactForm input {
   //height: 28px;
}

@contactWidthDefault: 24.75rem;
.contactForm, .contactForm .alert, .contactForm input, .contactForm input[type=text],.contactForm input[type=email],.contactForm textarea, .settingsForm .formInstruction  {
  //max-width: @contactWidthDefault;
  width: 100%;
  box-sizing: border-box;
}

.contactForm input.buttonUX2 {
  width: auto !important; // bc currently the contact form button is an <input>, not a <button> (TO DO: fix this)
}

.contactForm #instruction {
  margin-bottom: 2.25rem;
}


//////////////////////////////////////////////
// STATIC PAGES: HISTORY & VALUES

.historyHeadline, .valuesHeadline {
  margin-top: @marginVerticalStandard * 0.75;
}

.valuesHeadline {
  margin-bottom: @marginVerticalStandard * 0.75;;
}

.historyHeadline {
  margin-bottom: @marginVerticalStandard * 3;
}

.historyContent {
  margin-left: 0.9rem;
  margin-top: @marginVerticalStandard * 3;
  width: 640px;
}

.historyVersion {
  //margin-bottom: 64px;
  width: 100%;
}

.historyClaimText {
  font-size: @fontSizeModalHeading;
  margin-top: 0.675rem;
  margin-bottom: 1rem;
}

.historySection {
  margin-bottom: 1rem;
}

.historySubheading {
  margin-top: 1.35rem;
  margin-bottom: 0.45rem;
}

.historyItem {
  margin: 0;
  padding: 0;
}

.historyClaimVersionTag {
  font-size: @fontSizeTertiaryUX2;
  color: @greyMid;
  text-transform: uppercase;
  font-weight: 500;
  margin-left: 0.45rem;
}

.historyDivision {
  width: 50%;
  height: 0.16875rem;
  margin-top: 4rem;
  margin-bottom: 1.8rem;
  background-color: @nearBlack;
}


//////////////////////////////////////////////
// STATIC PAGE: PRIVACY POLICY


.policyHeader {
  margin-left: 2.5rem;
}

.policyContent {
  position: relative;
  left: -1.35rem;
}

@policySectionNumberWidth: 2rem;
.policySectionNumber {
  display: inline-block;
  width: @policySectionNumberWidth; // 1.6875rem;
  margin-right: 0.9rem;
  vertical-align: top;
}

@policySectionNumberMargin: 0.9rem;
h4 .policySectionNumber {
  margin-right: @policySectionNumberMargin;
}
li .policySectionNumber {
  margin-right: 0.3375rem;
  width: 1.333rem;
}

.policySectionCopy {
  display: inline-block;
  width: calc(100% - @policySectionNumberWidth - @policySectionNumberMargin);
}

.policyBulletList {
  margin-left: 3rem;
  list-style: none; // lists in the privacy policy page are numbered manually
}


//////////////////////////////////////////////
// STATIC PAGE: MANIFESTO

.manifestoShareButtons button {
  margin-right: 0.5rem;
}

.manifestoPrinciples {
  margin-bottom: 1.75rem;
}


//////////////////////////////////////////////
// STATIC PAGE: EDUCATION

.apushistory {
  h2 {
    margin-bottom: 1.6875rem;
  }
}

.walkthrough {
  margin-bottom: 6.75rem;
  img {
    width: 60%;
  }

  h2 {
    margin-bottom: 1.6875rem;
  }

  h4 {
    margin-top: 2.25rem;
    margin-bottom: 1.125rem;
  }
}

.screenshot {
    margin-bottom: 2rem;
}

.screenshotCaption {
    margin-bottom: 0.45rem;
}

.affix, .affix-top  {
    //margin-left: -0.9rem;
    padding: 0;
    padding-right: 0.9rem;
}

.affix-top  {
    margin-top: 3rem;
}

.affix {
    top: 135px;
    left: 2rem;
    width:inherit;
}

// scoot text to the right if there's an affix on the left
.besideAffix {
  margin-left: 7rem;
}

#educationInfoNav {
    list-style-position:outside;
    margin:0;
    padding-right: 0.45rem;
}

#educationInfoNav li {
    font-size: @fontSizeSecondaryUX2;
    line-height: 175%;
    list-style-type: none;
    list-style-position:outside;
}

.introQuote {
    font-style: italic;
}

h2.idLinkOffset:before {
  display: block;
  content: " ";
  margin-top: -3.9375rem;
  height: 3.9375rem; //
  visibility: hidden;
}

.assignmentBullets {
  //line-height: 133%;
}

.infoToggle, .infoNonToggle, .superInfoToggle, {
    margin: 0px;
    list-style-type: none;
    list-style-position:outside;
}


//////////////////////////////////////////////
// ADMIN PAGE

.adminTopButtons a, .adminPageTitle {
  display: inline-block;
  margin-right: 1rem;
  margin-left: 1rem;
}

.adminTopButtons {
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
}

.adminTopLink {
    margin-left: 0.5rem;
    margin-right: 0.5rem;
}

.adminTable {
    table-layout: fixed;
    //max-width: unset;
    //width: 100%;
    white-space: nowrap;
}

.adminTable td {
    border: 1px solid @greyDarkLine;
    padding: 0.125rem 0.5rem;
    white-space: normal;
    overflow: hidden;
    text-overflow: ellipsis;
    min-width: 90px;  // TO DO: set up different classes for different columns
}

.adminTable th {
    border: 1px solid @greyDarkLine;
    background-color: @adminColor;
    padding: 0.5rem;
}

.adminTableDetail {
    font-size: @fontSizeTertiaryUX2;
    color: @greyDark;
    font-family: @sansFontFamily;
    font-weight: 400;
    line-height: @lineHeightSmall;
    margin-top: 0.5rem;
    display: block;
    font-style: italic;
}

.adminTableDateCol {
    //max-width: 70px;
    //white-space: normal !important;
    text-align: center;
}

.adminTableSimpleNumber {
    white-space: normal !important;
    max-width: 68px;
    text-align: center;
}

.adminTableClassrooms {
    white-space: normal !important;
    max-width: 500px;
    text-align: center;
}

.adminTableEllipsisObvi {
    //max-width: 72px;
    white-space: normal;
}

.role-chooser {
    width:100px;
    margin: 0.125rem; // 2px;
}


//////////////////////////////////////////////
// UPSTREAM LINKS

.upstreamLinks {
  display: block;
  font-size: @fontSizeSecondaryUX2;
  color: @nearBlack;
  margin-bottom: @marginVerticalStandard * 1.5;
  width: @wideCardWidth;
  padding-right: 2rem;
  box-sizing: border-box;
  position: relative;
  left: (-1 * @cardPadding) - (@arrowHeadWidth / 2) - .0675rem;
}
.upstreamLinks .dropdown-caret {
  left: 1.2375rem;
}
.showParentLabel {
  font-weight: 500;
}
.parentClaimTitle {
  //color:  @nearBlack;
  margin-left: 1.125rem;
}

.upstreamLinks  > .dropdown-menu {
  left: 0.45rem;
  top: 42px;
  max-width: 93vw;
}
.upstreamLinks li {
  width: 100%;
}
.upstreamLinks li > a {
  text-overflow: ellipsis;
  width: 100%;
  display: block;
  overflow: auto;
  box-sizing: border-box;
}


//////////////////////////////////////////////
// BLOCKS OF EVIDENCE

.pointPageContainer {
  //margin-top: 2 * @marginVerticalStandard;
  //margin-bottom: 3.375rem;
  margin-left: 0px; //32px;
}

.profileAreaColumn .pointPageContainer {
  margin-top: @marginVerticalStandard;
}

.listedClaimGroup {
  width: auto;
  //max-width: 600px; // ONE COLUMN VERSION
}
.listedClaimGroup.expanded {
  margin-bottom: 0; // prevents a build-up of really big margins below arguments with multiple expansions
}

.evidenceRow {
  display: block;
  position: relative;
  left: @stackCardOffset * -3;
}


// EVIDENCE BLOCK REVEAL ANIMATION:
// See also: DEALING CARDS ANIMATION

@evidenceBlockRevealDuration: .36s;
@keyframes evidenceBlockReveal {
   0% { max-height: 1.125rem; opacity: 0;  }
   40% { opacity: 0; transform: translateY(-1.125rem); } //  transform: scaleY(.5)
   80% {  max-height: 2000px; transform: translateY(0) } //  transform: scaleY(1
   100% { max-height: 5000px; opacity: 1; }
}

.evidenceBlock, .qAnswers {
  display: inline-block;
  transform-origin: top left;
  animation-name: evidenceBlockReveal;
  animation-duration: @evidenceBlockRevealDuration;
  animation-timing-function: ease;
  //animation-timing-function: ease-out;
  animation-iteration-count: 1;
  //background-color: rgba(0, 0, 255, 0.3);
  position: relative;
  //z-index:-1;
  top: -1 * ((2 * @stackCardOffsetV) + (2 * @cardBorderWidth)); // nudge up to account for the empty space taken up by invisible stack cards
}
.evidenceBlock {
  margin-left: @indent;
}
.qAnswers {
  //margin-left: (-1 * @indent) + 16px;
}

// nudge left to account for the empty space taken up by invisible stack cards
@cardHeadingOffsetToUndoStack: -1 * ((3 * @stackCardOffset) + (3 * @cardBorderWidth));

.contentSectionHeading, .buttonSection, #tagSearchResultsArea {
  margin-left: @cardHeadingOffsetToUndoStack;
  //top: -1 * (3 * @stackCardOffset) !important; // nudge up to account for the empty space taken up by invisible stack cards
  //border-bottom-style: none !important; // hide bottom border to prevent double border
  margin-bottom: @marginVerticalStandard  * 0.25;
  width: @wideCardWidth; // Default to wide; gets reset below in responsive @media calls
  //background-color: pink;
}
.contentSectionHeading {
  //margin-left: @cardStackWidth + @cardPadding;
  //margin-bottom: 1.8rem;
  padding-top: 0;
  margin-top: -0.5rem;
  display: inline-block;
  width: 12.25rem; // wide enough for "More Arguments"
  //background-color: cyan;
  vertical-align: top;
}

.contentSection .contentSectionHeading {
  margin-left: -3rem; // less left margin on wide screens on homepage
}

.recPointsArea .contentSectionHeading {

}

.contentSectionHeading:focus {
  border: none;
  outline: none;
}

// TODO: vary this based on size of the cardstack above it
.contentSectionHeadingSeeLess {
  margin-top: @marginVerticalStandard * 1.33;
}

.contentSectionFooter {
  margin-top: @marginVerticalStandard;
  margin-bottom: @marginVerticalStandard;
}

.buttonSection {
  display: block;
  margin-left: @cardHeadingOffsetToUndoStack + 0.125rem; //+ 2px; // perhaps this is nec to account for the width of the border
  margin-top: 2.675rem;
  margin-bottom: 1.75rem;
  width: max-content;
}


/* I think this is from when Quick Create was on the home page, and is now longer needed —JF
@keyframes latestQuickCreateReveal {
   0% { max-height: 1.125rem; opacity: 0;  }
   40% { opacity: 0; ; } //  transform: scaleY(.5)
   85% {  max-height: 315px; } //  transform: scaleY(1
   100% { max-height: 315px; opacity: 1; }
}
.latestQuickCreate {
  transform-origin: top left;
  animation-name: latestQuickCreateReveal;
  animation-duration: (@evidenceBlockRevealDuration * .8);
  animation-timing-function: ease;
}*/

.createOptionsToggle {
  //margin-left: @cardPadding;
}

.optionsContainer {
  margin-top: @marginVerticalStandard;
  margin-bottom: 0.675rem;
}

.heading {
  color: @greyDark;
  text-transform: uppercase;
  font-size: @fontSizeTertiaryUX2; //@baseFontSize * 0.813; // ~13px
  font-weight: 500;
}

.evidenceTypeLabel {
  //color: @greyMid;
  //margin-bottom: @marginVerticalStandard * .25;
  text-transform: uppercase;
  //padding-right: @cardPadding;
  vertical-align: top;
}

.evidenceBlockHeading {
  margin-left: @indent + @cardBorderWidth + @cardPadding + @cardStackWidth;
  margin-bottom: @marginVerticalStandard; // * .8;
}

.supportHeading {
}

.counterHeading {
  color: @red;
}

.headingPlus {
  position:relative;
  top: -1px;
}

.sortBy {
  text-transform: normal;
  float: right;
}

.evidenceBlockCounter, .evidenceBlockSupport, .evidenceBlockBoth, .evidenceBlockEmpty {
  display: inline-block;
  position: relative;
  vertical-align: top;
  //float: left; // was using this earlier... but doesn't seem necessary with the vertical-align
  //transition: 1s;
  //width: @cardListWidth;
  //border-style: solid;
}
.evidenceBlockCounter, .evidenceBlockSupport, .evidenceBlockBoth {
  margin-bottom: 1.35rem;
}

@counterBlockMargin: 1.25rem; // @cardPadding + 0.5rem;
.evidenceBlockCounter {
  margin-left: @counterBlockMargin;
}
// no margin-left if the claim only has counter evidence
.evidenceBlockCounter.evidenceBlockFirstColAlignment {
  margin-left: 0;
}

@evidenceBlockFirstColAlignment: -0.5rem; //not clear on why this number is what it is -JF
.evidenceBlockFirstColAlignment {
  //left: @evidenceBlockFirstColAlignment !important;
}

.evidenceBlockEmpty {
}

//@evidenceListMarginTop: @marginVerticalStandard; // - 6px; // subtract some for the arrow
.evidenceList {
  //margin-top: @marginVerticalStandard * 1.5;
}

// Card Positioning
@stackMarginBottomBase: 0.675rem;
@stackMarginBottomInterval: @stackCardOffsetV;
.stackMarginBottom0 {
  margin-bottom: @stackMarginBottomBase;
}
.stackMarginBottom1 {
  margin-bottom: @stackMarginBottomBase + @stackMarginBottomInterval;
}
.stackMarginBottom2 {
  margin-bottom: @stackMarginBottomBase + (@stackMarginBottomInterval * 2);
}
.stackMarginBottom3 {
  margin-bottom: @stackMarginBottomBase + (@stackMarginBottomInterval * 3) + 1px;
}

@listedClaimRightPadding: @cardPadding * 2;
.listedClaim {
  display: inline-block;
  //padding-left: 3px;
  padding-right: @listedClaimRightPadding;
  //padding-top: @cardPaddingSmall;
  outline-style: none;
}

.removeOneIndent {
  position:relative;
  left: -1 * @indent;
}
.addOneIndent {
  position:relative;
  left: @indent;
}

.pointListEndMessage {
  margin-top: 1.6875rem;
  margin-left: 1.6875rem;
  margin-bottom: 10.125rem;
  display: block;
  font-size: @fontSizeTertiaryUX2;
  color: @greyDark;
}

@keyframes moreClaimsDivisionFadeOn {
   0% { opacity: 0;  }
   40% { opacity: 0; }
   100% { opacity: 1; }
}

.moreClaimsDivision {
  display: none; // don't display unless its in a feed like search or the homepage
}

// TO DO: add profile page to this list, once that page is built in React
@mysteriousMarginOffset: 0.5rem;
// #mainPageMainArea .moreClaimsDivision, #searchResultsArea .moreClaimsDivision {
.moreClaimsDivision {
  width: calc(@wideCardWidth + (2 * @cardPadding));
  display: block;
  position: relative;
  margin-left: 0; // @cardPadding; // (@indent *.5 ) - @mysteriousMarginOffset + @cardPadding; // add @cardPadding bc we're setting padding-left to 0 for the border
  //padding-top: 16px;
  padding-bottom: 0; // @cardPadding * 0.5;
  padding-left: 0;
  //border-bottom-style: solid;
  //border-top-width: 4px;
  //font-size: @fontSizePointTitle;
  margin-top: 1.8rem;
  margin-bottom: 1rem; // 0.3375rem;
  background-color: @stackCardDefaultBorderColor; //hsla(0, 0%, 82.5%, 1);
  height: 1px; // 1.1px; // seems to render better on retina  style displays
  animation-name: moreClaimsDivisionFadeOn;
  animation-iteration-count: 1;
  animation-timing-function: ease;
  animation-duration: @evidenceBlockRevealDuration * 1.4;
}

.evidenceBlockEmpty .moreClaimsDivision {
  margin-left: @indent *.5  + @cardPadding; // add @cardPadding bc we're setting padding-left to 0 for the border
  margin-top: 87px; // bc of funny positioning stuff being done with the buttons on empty evidence blocks
}


//////////////////////////////////////////////////////////
// DEALING CARDS ANIMATION
// See also EVIDENCE BLOCK REVEAL ANIMATION

@stackDealY: 18rem; // 320px;
@stackDealX: 0;
@stackDealDuration: @evidenceBlockRevealDuration * .5;
@stackDealTimingFunction: cubic-bezier(.25, 0, .9, .4);  // ease-out and accelerate
@stackDealNumberOfCards: 3;

@keyframes stackCardDealBottomKeys {
   0% { transform: translate(0, 0); }
   100% { transform: translate(0, @stackDealY); } //translate(@stackDealX, @stackDealY)
}
.stackCardDealBottom {
  animation-name: stackCardDealBottomKeys;
  animation-duration: @stackDealDuration;
  animation-timing-function: @stackDealTimingFunction;
  animation-iteration-count: 1;
}

@keyframes stackCardDealInvertXform {
   0% { transform: translate(0, 0); }
   100% { transform: translate( 0, (-1 * @stackDealY / @stackDealNumberOfCards)); } // 100% { transform: translate( (-1 * @stackDealX / @stackDealNumberOfCards), (-1 * @stackDealY / @stackDealNumberOfCards)); }
}
.stackCardDealInvertXform {
  animation-name: stackCardDealInvertXform;
  animation-duration: @stackDealDuration;
  animation-timing-function: @stackDealTimingFunction;
  animation-iteration-count: 1;
}

.stackCardDealFade {
  // make border disappear with alpha bc it won't reposition children
  // use !important to override red borders from .counter
  border-color: transparent !important;
  background: none !important;
  -o-transition: @stackDealDuration;
  -ms-transition: @stackDealDuration;
  -moz-transition: @stackDealDuration;
  -webkit-transition: @stackDealDuration;
  transition: @stackDealDuration;
  transition-timing-function: @stackDealTimingFunction;
}


//////////////////////////////////////////////
// RELEVANCE

// currently commented out
@keyframes relGroupHiliteReveal {
   0% {background-color: transparent;  }
   100% { background-color: @relAreaBG; }
}
.relGroupHilite {
  animation-name: relGroupHiliteReveal;
  animation-duration: @editAreaRevealDuration;
  animation-timing-function: ease;
  animation-iteration-count: 1;
  margin-bottom: @marginVerticalStandard * -1; // not sure why this is necessary
  background-color: @relAreaBG;
  max-width: @cardWidth * 1.1; // hacky - preventing this yellow area from expanding horizontally when evidence below it expands
}
////

@overlapWithClaim: @marginVerticalStandard;
.relevanceCtrlArea {
  //background-color: pink;
  //width: @cardWidth + (2 * @cardPadding);
  padding-bottom: @overlapWithClaim;
  margin-bottom: -1 * @overlapWithClaim;
  animation-name: relevanceCtrlAreaReveal;
  animation-duration: @editAreaRevealDuration;
  animation-timing-function: ease;
  animation-iteration-count: 1;
  background-color: @editingAreaBG;
  position: relative;
  display: block;
}

@keyframes relevanceCtrlAreaReveal {
   0% { max-height: 0; opacity: 0;  }
   12% { opacity: 1; }
   60% { max-height: 432px; } // max-height needs to be set manually to be >= height of the block-JF
   100% { opacity: 1; }
}

@editAreaPaddingV: 0.675rem;

.linkArea {
  //vertical-align: top;
  width: @cardWidth;
  //text-align: right;
  -o-transition:@hoverTransitionDuration;
  -ms-transition:@hoverTransitionDuration;
  -moz-transition:@hoverTransitionDuration;
  -webkit-transition:@hoverTransitionDuration;
  /* ...and now for the proper property */
  transition:@hoverTransitionDuration;
  transform-origin: bottom center;
  //left: 1px; // not sure why this is nec; really, it only actually seems necessary on claims that are 2+ levels down
  margin-bottom: -2rem; // -36px;
}

.linkArea:hover .number {
  text-decoration: underline;
  font-weight: 800;
}

.linkArea .number {
  text-decoration: none !important;
  vertical-align: top;
}

.relevanceLink:hover .arrowHeadUp  {
  border-bottom-color: white;
}

.relevanceLink:hover .arrowStemEvidence {
  border-left-color: white;
  border-bottom-color: white;
}

.relLinkAndStackCards {
  width: max-content;
  width: -moz-max-content;
}

.linkAreaBase {
  display: inline-block;
  position: relative;
  height: @relevanceLinkHeight;
  width: @indent;
}
.linkAreaBase.relRaterExpanded {
  background-color: @editingAreaBG !important;
}

.relevanceLink .commentIcon {
  display: inline-block;
  padding-left: @cardPadding;
  padding-right: @cardPadding;
  padding-bottom: @cardPadding * .8; //@cardPaddingSmall + 3px;
  padding-top: @cardPadding * .666;
  vertical-align: top;
  top: -11px;
  position: relative;

}

// borders on the relevance link
.relevanceLink, .relevanceCtrlArea, .linkAreaBase {
  border-style: solid;
  border-width: @cardBorderWidth;
  border-color: @stackCardDefaultBorderColor;
}
.relevanceLink.counter, .relevanceCtrlArea.counter, .linkAreaBase.counter {
  border-color: @stackCardCounterBorderColor
}
.relevanceCtrlArea, .relevanceLink {
  border-bottom-style: none;
}
.relevanceCtrlArea, .linkAreaBase {
  border-top-style: none;
  //border-right-style: none; // why was this line of code here?
}

@relevanceLinkHeight: 2.475rem; // 44px; //59px;
.relevanceLink {
  display: block;
  position: relative;
  //width: 283px; // align with the bar after agree/disagree //@cardWidth * .666;
  //font-weight: bold;
  //color: @greyDark;
  height: @relevanceLinkHeight;
  margin-bottom: -1px; // otherwise a thin gap appears below; not sure why
}

.relevanceLink, .linkAreaBase {
  color: @greyDark; // @nearBlack ;
  background-color: @greyLightBG;
}
.relevanceLink:hover:not(.relRaterExpanded), .linkArea:hover:not(.relRaterExpanded) .linkAreaBase {
  color: white;
  background-color: black;
}
.relevanceLink.counter, .linkAreaBase.counter {
  color: @red ;
  background-color: @stackCardCounterBG;
}
.relevanceLink.counter:hover:not(.relRaterExpanded), .linkArea:hover:not(.relRaterExpanded) .linkAreaBase.counter  {
  color: white ;
  background-color: @red;
}

.relevanceDisplay {
  display: inline-block;
  //width: 100%;
  position:relative;
  //right: 1px;
  font-size: @fontSizeButtonsUX2; //@baseFontSize * 0.75; // ~12px
  //top: (@relevanceLinkHeight * 0.2222);
  padding-top: @cardPaddingMed;
  left: @indent + 0.16875rem; // +3px; // TO DO: make this equation better
  vertical-align: top;
}
.relCtrlGroup {
  margin-left: @indent + 0.16875rem; //+ 3px; // @indent + @cardPadding + 16px; // + extra to account for the width of the cardStack
  padding-top: @cardPaddingMed;
  padding-right: @cardPaddingMed;
  //width: 88%;
}
.relCtrlVoteArea, .relCtrlCommentsArea .commentsArea {
  padding-bottom: 1.5rem;
}

.relevanceDisplayCopy {
  display: inline-block;
  vertical-align: top;
}

.relevanceDisplay .number {
  font-size: 88%;
}

.relCtrlVoteOptions {
  //margin-bottom: @marginVerticalStandard;
  position: relative;
  //left: -14px;
}

@relVoteButtonPadding: 1rem;
.relVoteLink, .relVoteLink button {
  font-size:  @baseFontSizeUX2 * 1.5; // ~24px;
  font-weight: bold;
  background-color: inherit;
  color: @blue;
  border-color: @blue;
  margin-right: -1 * @cardBorderWidth; //0.375rem;
  margin-bottom: 0.375rem; // for if/when buttons stack on narrow screens
  display: inline-block;
  //border: solid;
  //border-color: @editingAreaBG; // prevents offset when border appears
  padding-top: @relVoteButtonPadding;
  padding-bottom: @relVoteButtonPadding;
  padding-right: @relVoteButtonPadding - 0.125rem; // reduce because of the visual impact of the %
  padding-left: @relVoteButtonPadding;
  min-width: 40px;
  height: 22.5px;
  vertical-align: middle;
  text-align: center;
  border-radius: @borderRadiusUX2;
  border-width: @cardBorderWidth;
  border-style: solid;
  //color: black;
  //color: pink;
  outline: none; // TO DO: figure out :focus style
  box-sizing: content-box;
}
.relVoteLink:hover {
  //text-decoration: underline;
  //border-style: solid;
    //border-width: @relLinkBoxWidth;
  border-color: @blueLight;
  background-color: @blueLight;
  color: white;
}

// scale down on click
.relVoteLink:active {
  transform: scale(0.975);
}
.relVoteLink:disabled {
  //color: grey !important ;
  //pointer-events: none;
}

@relColor0: @blue; // hsla(0, 0%, 80%, 1); // black; // @blue; //
@relColor33:  @blue; //hsla(0, 0%, 60%, 1); // black; // @blue; //
@relColor66:  @blue; //hsla(0, 0%, 40%, 1); // black; // @blue; //
@relColor100:  @blue; //hsla(0, 0%, 20%, 1); // black; // @blue; //

// eliminate awkward double border because we're stacking the borders on top of eachother with margin-right: 1px
.relVoteLink0, .relVoteLink33, .relVoteLink66 {
  border-left: none;
}

/* color hover on each relevance button—black, grey, pink, red—but I think this adds too much cognitive load
.relVoteLink0:hover {
  background-color: @red; // @blueLight;
  border-color: @red;
}
.relVoteLink33:hover {
  background-color: lighten(@red, 50); // @blueLight;
  border-color: lighten(@red, 50);
}
.relVoteLink66:hover {
  background-color: lighten(@black, 45); // @blueLight;
  border-color: lighten(@black, 45);
}
.relVoteLink100:hover {
  background-color: @black; // @blueLight;
  border-color: @black;
}
*/

.myRelevanceVote100 {
  color: white;// !important ;
  background-color: @relColor100;
  border-color: @relColor100;
}
.myRelevanceVote66 {
  color: white;// !important ;
  background-color: @relColor66;
  border-color: @relColor66;
}
.myRelevanceVote33 {
  color: white;// !important ;
  background-color: @relColor33;
  border-color: @relColor33;
}
.myRelevanceVote0 {
  color: white;// !important ;
  background-color: @relColor0;
  border-color: @relColor0;
}

/*
.myRelevanceVoteHigh {
  color: black !important ;
  border-style: solid;
    border-width: @relLinkBoxWidth;
    border-color: black;
  //border-color: black;
}
.myRelevanceVoteLow {
  color: @red ;
  border-style: solid;
    border-width: @relLinkBoxWidth;
    border-color: @red;
  //border-color: @red;
}
*/

.relevanceExplanation, .relevanceStats {
  font-size: @fontSizeTertiaryUX2;
  color: @nearBlack; //@grayTextMedium;
  //line-height: @lineHeightSmall;
  margin-top: 0.5rem;
  //float: left;
  //margin-bottom: 7px;
  //width: 100%;
}
.relevanceExplanation {
  margin-top: 1rem; // @marginVerticalStandard;
  margin-bottom: 1rem; // @marginVerticalStandard;
}

.perctSignSmall {
  font-size: 60%;
  vertical-align: top;
  position: relative;
  top: -3px;
  text-decoration: none !important;
}
.perctSignSmallRelLink {
  font-size: 75%;
  vertical-align: top;
  position: relative;
  top: -1px;
  left: 1px;
  text-decoration: none !important;
  margin-right: 5px;
}

a:hover .perctSignSmall {
  display: inline-block;
  text-decoration: none !important;

}

.noRelevantClaimsArea {
  font-size: @fontSizeSecondaryUX2;
  font-weight: 500;
  margin-bottom: 3.6rem; // to counter-act the vertical offset on the long Add Evidence arrow
  display: block;
}
.toggleLowRelClaims {
  display: block;
  margin-left: @indent + (@arrowHeadWidth / 2); // not sure if this is the truly the correct equation
  margin-bottom: 2.8125rem; // 50px;
  position: relative;
  //color: @nearBlack;
  bottom: 0.3375rem;
}
 a.toggleLowRelClaims {
  color: @blue
}
 a.toggleLowRelClaims:hover {
  text-decoration: underline;
  //background-color: pink;
}
.toggleLowRelClaims .number {
  //font-size: 94%;
}
.noRelevantClaimsMessage {
  display: block;
  margin-left: @indent + @cardBorderWidth + 0.16875rem; // + @cardPadding + @cardStackWidth;
  color: @nearBlack;
  //margin-bottom: -12px;
}


//////////////////////////////////////////////////////////
// CARDS

.stackCardGroup {
  position: relative;
  display: inline-block;
  margin-bottom: -0.45rem;
  outline-style: none;
}

// indent stacks that have parents
.removeOneIndent .stackCardGroup {
  left: @indent; //@cardStackWidth; //@cardStackWidthParentCardsOnly - 1px;
}

.stackCardGroup:focus {
  outline-style: none;
}

.stackCard {
  border-color: @stackCardDefaultBorderColor;
  border-width: @cardBorderWidth;
  border-style: solid;
  background-color: white;
  width: @cardWidth;
  position: relative;
  left: @stackCardOffsetH;
  top: -1 * @stackCardOffsetV;
  box-sizing: border-box;
  transform-origin: top left;
  vertical-align: top;
}

// uncomment once wideCard can be attached to editImage and commentArea

@wideCardWidth: 640px;
.wideCard {
  width: @wideCardWidth;
}

.stackCardHidden {
  border-color: transparent !important; // make border disappear with alpha bc it won't reposition children
  background: none !important;
}

// TO DO: rename this
.point-card {
  display: block;
  overflow-wrap: break-word;
}

.pointCardPaddingH {
  padding-left: @cardPadding;
  padding-right: @cardPaddingMed;
}
.pointCardPaddingHExtra {
  padding-right: @cardPadding + 0.16875rem;
}

.relExtraMarginBottom {
  margin-bottom: @marginVerticalStandard *.5;
}

// stackCardGroupActive gets removed when the pointCard is in an editing state
.stackCardGroupActive:hover .stackCard, .contentSectionMoreStack:hover .stackCard {
  //background-color: @stackCardHoverColor;
  border-color: @greyDarkLine;
}

.stackCardGroupActive:hover {
  cursor: pointer;
}

.pointCardActive:hover .pointTitle a {
  text-decoration: underline;
  //color: @blue;

}
.stackCardGroupActive:focus .stackCard {
/*.listedClaim:focus .stackCard {*/
  //background-color:green !important; // for testing
  //background-color:white; // for testing
  background-color: @nearWhite; //white; // #f6fdff; //rgb(244, 253, 255);
  outline-style: none;
}

// red styling on CPs

.stackCard.counter {
  border-color: @stackCardCounterBorderColor ;
  //background-color: @stackCardCounterBG;

}
.stackCardGroupActive:focus .stackCard.counter {
  background-color: @stackCardCounterBG;
}
.stackCardGroupActive:hover .stackCard.counter {
  //background-color: @stackCardCounterBG;
  border-color: @red;
}

.cardBody {
  display: block;
}


//////////////////////////////////////////////////////////
// CLAIM IMAGES

.contentNoImage {
  display: inline-block;
  width: 100%;
}
// reduce line-length a bit on wideCards with no images
// TO DO: shouldn't this be done with .contentNoImage ?
.claimTextDisplay.wideCard {
  padding-right: 2rem;
  width: unset;
}

@imageWidth: 24%;
.contentWithImage {
  //display: inline-block;
  display: table-cell;
  width: 100% - @imageWidth;
}
.imageContainer {
  display: block;
  padding: 0.5rem;
  width: @imageWidth;
  height: calc(100% - @cardBottomActionBlockHeight); //subtract the height of the action bar below
  position: absolute;
  right: 0;
  box-sizing: border-box;
}
.imageContainerBig {
  position: relative;
  width: 100%;
  padding: @cardPadding;
  padding-bottom: @cardPadding * 1.66;
}
.claimImage {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.imageFaded {
  opacity: 0.24;
}
.imageCaption {
  display: inline-block;
  width: fit-content;
  //float:right;
  font-size: @fontSizeTertiaryUX2;
  color: @greyMid;
  //text-transform: uppercase;
  margin-top: 0.3375rem;
}


//////////////////////////////////////////////////////////
// CLAIM TEXT

.pointText {
  //margin-bottom: @marginVerticalInsideCards;
  //padding-right: 2%;
}

@pointCardMarginBottom: @marginVerticalStandard * 0.5;
.claimTextDisplay {
  display: block;
  padding-top: @cardPaddingMed;
  margin-bottom: 0.375rem; //@marginVerticalStandard * 0.75; //* .93; // @pointCardMarginBottom; // also impacts when editSources is open (and editClaimText is not)
}
// reduce line-length a bit on wideCards with no images
.claimTextDisplay.wideCard {
  padding-right: 1.8125rem;
  width: unset;
}

// TO DO: rebuild this with a wildcard
.pointTitle, h1.pointTitle, h2.pointTitle, h3.pointTitle, h4.pointTitle, h5.pointTitle, h6.pointTitle  {
  display: inline;
  color: black;
  font-weight:500; // using the "Book" weight of Meta, from TypeKit
  line-height: @lineHeightBase;
  letter-spacing: @letterSpacingSubtle;
  font-size: @fontSizePointTitle;
  margin: 0;
  padding-top: 0; // because some h's have padding-tops
  //margin-bottom: 17px;
    //padding-left: 1px;
    //padding-right:4px;
}

.pointTitle a {
  color: black;
}
.pointTitle a:focus {
  outline: none;
}


//////////////////////////////////////////////////////////
// CLAIM SCORE AND STATS

.score {
  font-size: 100%
}

.ux2ScoreInLine {
  display: inline-block;
  font-weight: bold;
  padding-left: 0.225rem;
}

.scorePreAnimate {
  display: inline-block;
}
// used to create space for font size animation
// (Reset fontSize) = 1/(Max fontSize)
.scoreAnimContainerMax { font-size: 125%; line-height: 0; }
.scoreAnimContainerReset { font-size: 80%; }

.negativeScore {
  color: @red;
}

.Score--bounce{
  animation:Score-keyframes--bounce .4s ease;
  transform-origin: left bottom;
}
@keyframes Score-keyframes--bounce{
  0% { transform:scale(1) }
  40% { transform:scale(1.1) }
  100% { transform:scale(1) }
}
.stats {
  //background: white;
  //border: 1px grey solid;
  width: 190px;
  position: relative;
  left: -1.35rem;
  top: 1.8125rem;
  padding: 0;
}

.stats .admin {
  color: @nearBlack;
}

.stats p {
  padding: 0.675rem;
  margin-bottom:0;
  font-size: @fontSizeSecondaryUX2;
  //line-height: @lineHeightMedium;
}

.scoreLearnMore i {
  display: inline-block;
  vertical-align: top;
  top: 4px;
}

.hashLinkCopy {
  display: inline-block;
  //width: 100px;
  vertical-align: top;
}


//////////////////////////////////////////////////////////
// CLAIM INFO (SOURCES, TAGS, FLARE, ETC)

@cardTopRowItemMarginRight: @marginVerticalStandard * 0.35;
.cardTopRowItem {
  a,button {
    color: @greyMid;
    //font-size: 0.75em;
  }
  margin-right: @cardTopRowItemMarginRight;
  vertical-align: top; // may not be needed anymore
}

.icon, .iconWithStat {
  margin-right: 0.125rem; //2px;
}

li .iconWithStat {
  width: 0.675rem;
  display: inline-block;
  margin-right: 0.3375rem;
}

.bylineItemHighlighted {
  padding-top: 1px !important;
  padding-bottom: 1px !important;
  border-radius: @borderRadiusBadges;
}

.recentlyUpdated {
  color: @nearBlack;
  background-color: @yellowWarning;
  //font-weight: bold;
}

.claimCardSecondaryInfo {
  margin-bottom: @marginVerticalStandard;
}

.byline {
  font-size: @fontSizeTertiaryUX2;
  color: @greyMid; //@grayTextMedium;
  width: inherit;
  display: inline-block;
  //vertical-align: bottom;
  float: none;
  //line-height: @lineHeightBase;
  //margin-bottom: @marginVerticalStandard * 0.1875; // 3px
  margin-bottom: -2px; // don't know why this is necessary
  //margin-right: 8px;
}
.byline a {
   color: @greyMid; //@grayTextMedium;
}
.bylineAuthor {
  display: inline-block;
  vertical-align: top;
  max-width: 130px; // need to leave room for number of contributors and number of comments
  text-overflow: ellipsis;
  white-space: nowrap; // needed for text-overflow: ellipsis;
  overflow: hidden; // needed for text-overflow: ellipsis;
  /*margin-right: 2px;*/
}
.bylineAuthor:hover {
  text-decoration: underline;
}

.moderatorIcon {
  margin-left: 0.125rem;
}

.cardTopRowItem a:hover {
  color: @blue;
}

.cardTopRowItem .easierToClickOn {
  //right: 4px; // compensate position for extra padding
  padding-top: 0.225rem;
  padding-bottom: 0.225rem;
}

.contributorsMenu {
  width: max-content;
}

.cardTopRowItem .dropdown-menu {
  width: max-content;
}

.sources  {
  margin-bottom: @marginVerticalStandard * 0.375;
  //margin-top: 2px;
}

.flareLabel {
  margin-right: 0.3375rem;
}

.sourceLabel {
  max-width: calc(100% - 2rem); // full width minus width of icon and padding
  display: inline-block;
  overflow: hidden;
  vertical-align: bottom;
  text-overflow: ellipsis;
  white-space: nowrap; // uncomment to allow multi-line sources
}

a.source {
  font-size: @fontSizeTertiaryUX2;
  color: @greyMid;
  display: inline; // inline-block causes the clickable area on sources with short titles will expand across the entire card
  margin-bottom: 0.25rem;
  margin-right: 0.25rem;
  width: 100%;
  //max-width: fit-content; // not sure why this was needed
}

.source:hover, a.source:hover {
  color: @blue;
}

.iconSourcesSmall, .iconTagsSmall, .iconEditorsPick {
  //font-size: 13.5px;
  margin-right: 0.0625rem; //1px;
  position: relative;
  display: inline-block;
}
.iconSourcesSmall, .iconEditorsPick  {
  //font-size: 13.5px;
  margin-right: 0.375rem;
}
.iconSourcesSmall, .iconTagsSmall  {
  font-size: 1.1em;
}
.iconEditorsPick {
  font-size: 1.1em;
}
.iconTagsSmall {
  margin-left: 0.0625rem; //1px;
}
.iconSourcesSmall {
  vertical-align: top; // so the icon pins to the top on sources that span multiple lines
  margin-left: 0.0625rem; //2px;
}

.sources .fa-book-open {
  position: relative;
  top: 2px;
}

.iconWithStat .fa-book-open {
  position: relative;
  left: -1px;
}

.bylineAdminItem {
  padding-top: 0.09rem;
  padding-bottom: 0.18rem;
  border-radius: @borderRadiusUX2_rounder;
}

.tags {
  display: inline;
}

.hashtag {
  font-size: 120%;
  font-weight: 500;
  vertical-align: top;
}

.tagLabel {
  max-width: 225px;
  display: inline-block;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  vertical-align: top; // unclear to me why this is needed here and not elsewhere in the byline —JF
}

a.tag {
  font-size: @fontSizeTertiaryUX2;
  color: @greyMid;
  display: inline-block;
  margin-bottom: 0.225rem;
  max-width: 100%;
  width: max-content; // so the link doesn't expand across the whole div when the source label is brief
}

.tags .tag {
  margin-right: 0.45rem;
}

.tag:hover, a.tag:hover {
  color: @blue;
}

.buttonUX2 .icon {
  margin-right: 0.3375rem;
}


//////////////////////////////////////////////////////////
// CLAIM ACTION ROW


.cardBottomActionRow, .cardBottomActionBlock, .cardBottomAction {
  border-color: @stackCardDefaultBorderColorLight;
  border-width: @cardBorderWidth;
  transform-origin: bottom left;
  //display: table-row;
}

.cardBottomActionRow {
  border-top-style: solid;
  width: 100%;
}

@cardBottomActionBlockHeight: 42px;
.cardBottomActionBlock {
  display: inline-block;
  vertical-align: top;
  padding-left: @cardPadding;
  padding-right: @cardPadding;
  padding-bottom: @cardPadding - 0.5625rem;
  padding-top: 0.5125rem; // set by eye; previously @cardPadding - 0.225rem;
  border-right-style: solid;
  //max-height: 42px; // prevent tiny differences in heights bt the blocks caused by the differences in how they are calculated
  height: 42px; // this, combined with box-sizing, seems to solve the tiny white line at the bottom of the box
  box-sizing: border-box;
}

// this class is used to peg an onboarding tip to
.agreeDisagree {
  display: inline-block;
}

.cardBottomActionBlock.agree, .cardBottomActionBlock.moreMenuBlock {
  border-right-style: none;
}
.seeEvidenceBlock {
  padding-right: @cardPadding * .75;
}
@agreeDisagreePaddingOutside: @cardPadding * .75;
@agreeDisagreePaddingInside: @cardPadding * .68;

.cardBottomActionBlock.agree {
  padding-right: @agreeDisagreePaddingInside;
  padding-left: @agreeDisagreePaddingOutside;
}
.cardBottomActionBlock.disagree {
  padding-right: @agreeDisagreePaddingOutside;
  padding-left: @agreeDisagreePaddingInside;
}

.stackCardGroupActive:focus .cardBottomActionRow, .stackCardGroupActive:focus .cardBottomAction  {
/*.listedClaim:focus .cardBottomActionRow, .listedClaim:focus .cardBottomAction  {*/
  font-weight: bold;
  color: @blue ;
}
/*.stackCardGroup:hover .cardBottomActionRow, .stackCardGroup:hover .cardBottomAction  {
    transform: scale(@cardBottomActionScaleUpOnHover) ;
  font-weight: bold;
  color: @blue ;
}*/

// pointCardActive gets removed when the pointCard is in an editing state
.pointCardActive:hover .cardBottomAction.seeEvidenceAction  {
  //transform: scale(@cardBottomActionScaleUpOnHover) ;
  //font-weight: bold;
  //background-color: @blue ;
  //color: white;
  //color: @blue;
  //text-decoration: underline;
}
.pointCardActive:hover .seeEvidenceBlock {
  //background-color: @blueLight;
}

.cardBottomAction.seeEvidenceAction:hover {
  //color: white !important;
}

.cardBottomAction {
  font-size: @fontSizeButtonsUX2;
  color: @nearBlack; //@greyMid; // @nearBlack; //black; // @greyMid ;  /*@blue ;*/
  font-weight: 500;
  //margin-bottom:2px;
  margin-right: 0;
  //margin-right:4px;
  //margin-top:4px;
  display:inline-block;
  z-index: @zindexCardActions; // so that clicks on these actions don't expand evidence on the point card
  line-height: 0; // needed to restrain height on icons
  //font-weight: bold

}

.pointCardActive:hover .seeEvidenceBlock {
  color: black ;
  text-decoration: none;
}

.pointCardActive .cardBottomActionBlock:hover .cardBottomAction {
  color: black ;
  text-decoration: none;
}
.stackCard .current-vote {
  color: white !important;
}
.stackCard .cardBottomActionBlock.agree.current-vote {
  background-color: black;
}
.stackCard .cardBottomActionBlock:hover .current-vote {
  //text-decoration: underline; // none;
}
.stackCard .cardBottomActionBlock.disagree.current-vote {
  background-color: @red;
}
.stackCard .cardBottomActionBlock.disagree.current-vote:hover {
  //background-color: @redLight;
}
.pointCardActive .cardBottomActionBlock:hover {
  background-color: @blueLight;
}

.pointCardActive .cardBottomActionBlock.agree.current-vote:hover {
  //background-color: @blackHover;
}


// scale down on click
@cardBottomActionScaleUpOnHover: 1.033;
//@cardBottomActionScaleDownOnClick: 0.96 * @cardBottomActionScaleUpOnHover;
@cardBottomActionScaleDownOnClick: 0.96;
.cardBottomAction:active, .cardBottomActionBlock:active .cardBottomAction {
    transform: scale(@cardBottomActionScaleDownOnClick) !important ;
    transform-origin: bottom;
}

// icons
.cardBottomAction i {
  margin-right: 0.225rem;
}

// fixed width for roll-over method of switching from No Evidence to Add Evidence, but unnecessary with the scaling approach
.seeEvidenceBlock {
  width: 120px; // 118px works for firefox/chrome, but Safari needs 119px; going up one more to be safe
  box-sizing: border-box;
}

.commentLink {
  position: relative;
  //top: 1px;
  //vertical-align: bottom;
  //bottom: -1px;
  //margin-left: @bottomActionMargin * 1.66;
}
.commentLink .iconWithStat {
  margin-right: .175rem;
}

.cardBottomActionRow .commentLink {
  font-size: 0.875em;
  color: @greyDark; // make it a touch lighter bc if its as dark as the words it'll pop  more than they do
}
.cardTopRowItem .hasComments, .cardBottomActionRow .hasComments {
  color: @nearBlack;
  //font-weight: 800;
}

.moreMenuLink {
  color: @nearBlack;
  //font-weight: 800;
  font-size: 0.75em !important;
  position: relative;
  margin-left: 0.1125rem;
  padding: 0;
  vertical-align: top;
  //float: right;
  top: 2px;
}
.moreMenuLink:hover {
  text-decoration:none;
}
.moreMenu, #emojiMenu {
  font-size: @fontSizeTertiaryUX2;
}

.moreMenu {
  width: 124px;
  left: auto !important;
  top: 36px;
  margin-right: -1px; //gets this perfectly flush, not sure why its nec, maybe bc of the border width of the card
}


@bottomActionMargin: 0.5rem;
.bottomActionDot {
  margin-right: @bottomActionMargin;
  margin-left: @bottomActionMargin;
  vertical-align: top;
}
.bottomActionDot:hover {
  text-decoration: none !important;
}

//////////////////////////////////////////////////////////
// CLAIM INFO EDIT AND COMMENT AREAS

@keyframes editAreaReveal {
   0% { max-height: 0; opacity: 0; padding-top: 0; }
   75% { max-height: 810px; padding-top: @cardPaddingSmall; } // max-height needs to be set manually to be >= height of the block
   100% { opacity: 1;  }
}
@keyframes commentsAreaReveal {
   0% { max-height: 0; opacity: 0; padding-top: 0; }
   75% { max-height: 920px; padding-top: @cardPaddingSmall; } // max-height needs to be set manually to be >= height of the block
   100% { opacity: 1;  }
}

.editClaimText, .editImage, .editSources, .commentsArea {
  //width: 100%;
  animation-duration: @editAreaRevealDuration;
  animation-timing-function: ease;
  animation-iteration-count: 1;
}
.editClaimText, .editImage, .editSources {
  //width: 100%;
  animation-name: editAreaReveal;
}
.editImage, .commentsArea  {
  width: @cardWidth ;
  box-sizing: border-box;
  position: relative;
  left: (-1 * @cardStackWidthParentCardsOnly) + 0.0625rem; // in the past this has wanted to be 1.5px to help prevent offset in certain browser/OS combos
  border-color: @stackCardDefaultBorderColor;
  border-width: 1px;
  border-style: solid;
}

.commentsArea.wideCard, .editImage.wideCard {
  width: @wideCardWidth;
}

.editImage {
  margin-top: -0.45rem !important; // gets rid of a gap above the editImage componant; dunno why that gap is there / why this is needed -JF
  margin-bottom: 0 !important;
}

.claimEditArea.commentsArea {
  //width: 100%;
  animation-name: commentsAreaReveal;
  display: block;
  //z-index: 10;
  top: (-2 * @stackCardOffsetV) - @cardBorderWidth - 0.03125rem; // not sure why this needs its own unique equation, or why the extra 0.5px helps on firefox on mac
  margin-bottom: 0px;
  margin-top: 0 ;
}

// Differences to the comments area for relevance comments
.relCtrlCommentsArea .commentsArea {
	left: 0;
	top: 0;
	background-color: transparent;
	border-style: none;
  border-top-style: solid !important;
	border-bottom-style: solid;
  background-color: transparent;
  padding-left: 0;
  padding-right: 0;
  margin-right: 1.25rem; // to control the length of the borders on the top and bottom
  width: unset;
  animation-name: unset;
}

.commentsList, .commentButtons {
  width: 100%;
  display: block;
}

.newCommentTextField {
  height: 5rem;
}

.comment .byline {
  //margin-top: @marginVerticalForms * 0.5;
}

@indentCommentReplies: 6%;
.commentIndent {
  width: 100% - @indentCommentReplies;
  margin-left: @indentCommentReplies;
}

// ".commentText" handles older comments; ".commentText .DraftEditor-root" handles newer comments
.commentText, .commentText .DraftEditor-root {
  font-size: @fontSizeSecondaryUX2;
  color: @nearBlack;
  //margin-top: @marginVerticalForms * 0.75;
  margin-bottom: @marginVerticalForms * 1.5;
  word-break: break-word; // for long urls pasted into comments
}
// .commentText handles older comments; .DraftEditor-root handles newer comments
.commentArchived .commentText, .commentResolved .commentText,
.commentArchived .DraftEditor-root, .commentResolved .DraftEditor-root,
{
  color: @greyMid;
}

.commentsArea .divider {
  border: none;
  height: 1px;
  //width: 80%;
  overflow: hidden;
  background-color: @greyLightLine;
  margin-top: 1.1875rem; // 21px
  margin-bottom: 1rem; // 19px
}

.commentBottomRowActions {
  //margin-bottom: @marginVerticalForms * 2.4;
}

@paddingCommentButtonH: 1rem;
@paddingCommentButtonV: 0.875rem;
.commentButton {
  //margin-top:@marginVerticalForms * 1;
  //margin-bottom: @marginVerticalForms !important;
  margin-right: 0.25rem;
  margin-bottom: 0.25rem;
  font-size: @fontSizeSecondaryUX2;
  padding: @paddingCommentButtonV @paddingCommentButtonH;
  //width: 7.6875rem;
  box-sizing: content-box;
}

.commentButton i {
  margin-right: 0.225rem;
}

.newCommentPublishButton {
  margin-top:0;
  margin-bottom: 0 !important;
}

.reportCommentIcon {
  font-size: 80%;
}

@quickCommentBadgePaddingTop: 0.25rem;
.quickCommentBadge {
  display: inline-block;
  color: @nearBlack;
  font-size: @fontSizeTertiaryUX2;
  font-weight: 500;
  //background-color: @yellowWarning;
  border-color: @nearBlack; // @stackCardDefaultBorderColorLight;
  border-width: 1px;
  border-style: dashed; // solid;
  /*border-radius: @borderRadiusBadges;*/
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  padding-top: 0.375rem;
  padding-bottom: 0.5rem;
  margin-right: -1px;
  margin-top: @marginVerticalStandard * 0.25;
  margin-bottom: @marginVerticalStandard * 0.125;
}

.comment .quickCommentBadge {
  margin-top: @marginVerticalStandard;//  *  0.85;
  margin-bottom: @marginVerticalStandard; // *  0.85;
}

.commentResolved .quickCommentBadge, .commentArchived .quickCommentBadge {
  color: @greyMid;
  border-color: @greyMid;
}

.newCommentCreationForm, .newCommentCreationForm textarea, .newCommentCreationForm .DraftEditor-root {
  font-size: @fontSizeSecondaryUX2;
}

.newCommentCreationForm .quickCommentBadge  {
  margin-bottom: 1.125rem; // @marginVerticalStandard * ;
}

.commentsList .quickCommentBadge {
  //margin-bottom: 0;
  padding-top: @quickCommentBadgePaddingTop + 0.0625rem; // not sure why this is nec to make the badges look the same in the claim card in comment list
}

.myQuickComment {
  background-color: @yellowWarning;
  //border-color: @yellowWarning;
}

.quickCommentsList {
 display: inline-block;
}

.quickCommentsList .quickCommentBadge:hover {
  background-color: @blueLight;
}
.quickCommentsList .quickCommentBadge.myQuickComment:hover {
  background-color: @blueLight;
  //border-color: @yellowWarning;
  //color: white;
}

.quickCommentBadgeText {
  margin-left: 0.125rem;
  margin-right: 0.125rem;
}

.quickCommentBadge .icon {
  margin-right: 0.15rem;
  font-size: 115%;
}

.quickCommentBadgeCount {
  margin-left: 0.16875rem; //3px;
  margin-right: 0.16875rem; //3px;
}

.quickCommentBadgeClose {
  font-size: 150%;
  padding-left: 0.125rem;
  vertical-align: text-bottom;
}

.proposalCopy {
  margin-top: 0.5rem;
  margin-bottom: 1.5rem;
}
.proposalSection {
  margin-bottom: @marginVerticalStandard;
}

.proposedEditVoteFor, .proposedEditVoteAgainst {
  margin-right: 0.0625rem;
  color: black;
}
.proposedEditVoteFor:hover, .proposedEditVoteAgainst:hover {
  color: @blue;
  text-decoration: none;
}

.proposedEditVoteCount {
  margin-right: 0.75rem;
}

.editAreaLink {
  font-size: @fontSizeSecondaryUX2;
  margin-right: 0.8675rem;
}
.editAreaLink:hover {
  text-decoration: underline;
}

.claimEditArea {
  background-color: @editingAreaBG;
  padding-top: @cardPadding;
  padding-bottom: @cardPadding * 1.2;
  padding-left: @cardPadding;
  padding-right: @cardPadding;
  margin-bottom: @marginVerticalStandard;
  margin-top:  @marginVerticalStandard * 0.5;
  display: inline-block;
  box-sizing: border-box;
  //width: 100%; // this messes up comments UI width on children of wideCards, and seems not to be necessary
}

// seems like this may no longer be necessary
.editClaimText, .editSources {
  // width: calc(100% - 0.00625rem); // so the bg color doesn't overlap with the border of the parent on hires displays
  //min-height: 64px; // sets height for the saving state with the spinner
}

.editClaimText {
  margin-top: 0;
}

.claimEditAreaHeading, .addEvidenceAreaHeading {
  width: 100%;
  display: block;
  //padding-top: @editAreaPaddingV;
}

.claimEditAreaHeading {
  margin-bottom: @cardPadding;
}

.editSourcesList {
  margin-bottom: @cardPadding;
}

.editSourcesList .source {
  width: 90%;
  display: inline-block;
}

.removeSourceLink a {
  color: @red;
  font-size: 90%;
  vertical-align: middle;
  position: relative;
  bottom: 0.0625rem; // 1px;
}
.removeSourceLink a:hover {
  text-decoration: none;
  color: @blue;
}

.editTagsList {
  margin-bottom: @cardPadding;
}

.editTagsList .source {
  font-size: @fontSizePointTitle;
  display: inline-block;
}

.removeTagLink {
  font-size: 1.5em;
  right: 0;
  top: -0.45rem;
  vertical-align: top;
  display: inline-block;
  color: @red;
}

.claimEditAreaSavingFeedback {
  margin-top: 0.45rem;
  display: inline-block;
  margin-left: -0.3375rem;
  padding-bottom: 0.8675rem;
}

.claimEditAreaNote {
  display: inline-block;
  color: @nearBlack;
  //font-size: @baseFontSizeUX2;
  font-weight: 500;
  //width: @editTextAreaWidth;
  margin-bottom: @marginVerticalStandard * 1.15;
  margin-top: 0.25rem;
  //font-style: italic
  width: 100%;
  /* border-bottom-style: solid;
  border-width: 1px;
  border-color:  hsl(0, 0%, 75%);
  padding-bottom: 1rem;
  margin-bottom: 1.5rem;*/
}

.claimEditAreaNote .number {
  font-size: 90%;
}

.hotTip {
  width: 100%;
  display: inline-block;
  margin-top: @marginVerticalStandard * 1.5;
  font-style: italic;
  //line-height: @lineHeightMedium;
}

.hotTip p {
  margin-bottom: 0;
}

.formInstruction {
  margin-bottom: 1.25rem; // @marginVerticalStandard;
  //font-size: @baseFontSizeUX2;
}

.quickCommentInstruction.last {
  margin-bottom: @marginVerticalStandard * 2;
}

.imageEditForm {
  margin-top: @marginVerticalForms;
  margin-bottom: @marginVerticalForms;
  display: inline-block;
  width: 100%;
}

.imageBigDisplay {
  margin-bottom: @marginVerticalForms * 2;
}

.imageEditForm .imageBigDisplay {
  display: block;
}


//////////////////////////////////////////////////////////
// FORMS FOR CREATING AND EDITING CLAIMS

.ys-editor:focus,
.ys-editor:active {
  background: pink !important;
}
/*
.public-DraftEditorPlaceholder-hasFocus {
  background: green !important;
}*/

.addEvidenceAreaHeading {
  margin-bottom: @marginVerticalStandard;
  //padding-left: @cardPadding;
  padding-top: @cardPadding * .333; // add a little extra padding to the top bc of the bigger heading font size
  box-sizing: border-box;
}

.editPointTextForm {
  display: inline-block;
  //width: calc(~"100% - 48px"); // full width minus space for cancel button
  //width: -moz-calc(~"100% - 48px");
  //width: -webkit-calc(~"100% - 48px");
  width: 100%;
  margin-top: 0.125rem; //2px;
  margin-bottom: 0;
  z-index: 1; // why is this here? —JF
  position: relative;
}

@keyframes editPointTextFieldReveal {
   0% { max-height: 0; opacity: 0; }
   75% { max-height: 172px; } // max-height needs to be set manually to be >= height of the block
   100% { opacity: 1;  }
}

#editPointTextField {
  //height: 120px;
  padding-top: 0.5625rem;
  animation-name: editPointTextFieldReveal;
  animation-duration: @editAreaRevealDuration * 1;
  animation-timing-function: ease;
  animation-iteration-count: 1;
}
#editPointTextField:focus {
  //height: 120px;
}
.twoLines {
  height: 74px !important;
}
.threeLines {
  height: 99px !important;
}
.fourLines {
  height: 115px !important;
}
.fiveLines {
  height: 133px !important;
}
.sixLines {
  height: 148px !important;
}

.addEvidenceForm {
  margin-top: 0;
  display: inline-block;
  position: relative;
  //top: -38px;
  //left: -1 * @nudgeAddEvidenceControlsArea; //undo nudge
  margin-bottom:  @marginVerticalStandard * 1.5;
  //width: @cardWidth;
  border-color: @stackCardDefaultBorderColor;
  border-width: @cardBorderWidth;
  border-style: solid;
  width: @cardWidth;// + 30px; // add some for buttons,etc
  box-sizing: border-box;
  //left: 24px; // to align with  evidence stack above
  background-color: @editingAreaBG;
  padding: @cardPadding;
}

.addEvidenceForm > .heading {
  display: block;
  margin-bottom: @marginVerticalStandard;
  padding-left: @cardPadding;
}

@nudgeAddEvidenceControlsArea: -0.675rem;
.addEvidenceControlsArea {
  vertical-align: top;
  display: inline-block;
  position: relative;
  top: @marginVerticalStandard * 0.59375; //@arrowStemEvidenceHeightDefault - @marginVerticalStandard - 2px; // subtract to move the button to the middle of the arrow
}

.addEvidencePrompt {
  padding-left: @cardPadding;
  margin-bottom: @marginVerticalStandard * 0.8;
  position: relative;
  //top: -2px;
}

.addEvidenceButtonsArea {
  display: block;
  position: relative;
  //top: 12px; // clever positioning tricks needed because of the arrow
}

.inputLabel {
  display: block;
  position: relative;
  font-size: @fontSizeSecondaryUX2;
  color: @greyDark;
  padding: @cardPadding;
  padding-bottom: 0.375rem;
  padding-left: 0;
}

// TO DO: remove this class once proper multi-line support is added to claim creation fields
.inputFieldUX2multi {
  margin-bottom: @marginVerticalForms;
}
@titleTextFieldInitial: 4rem;
@quickCreateTextFieldInitial: 3.75rem;
@titleTextfieldAdditional: 1.625rem;
.titleTextField {
  //height: 28px;
  height: @titleTextFieldInitial;
  padding-bottom: @cardPadding * 2;
  padding-top: 0.5625rem;
  margin-bottom: 0; //8px;
  //background-color: hsla(169, 100%, 97%, 1);
}
.titleTextField:active, .titleTextField:focus {
  height: @titleTextFieldInitial + @titleTextfieldAdditional;
}

#mainPageClaimCreationForm .titleTextField {
  height: @quickCreateTextFieldInitial;
}
#mainPageClaimCreationForm .titleTextField:active, #mainPageClaimCreationForm .titleTextField:focus {
  height: @quickCreateTextFieldInitial + @titleTextfieldAdditional;
}

//input[type="text"]::placeholder, input[type="email"]::placeholder
.inputFieldUX2::placeholder,
input::placeholder,
textarea::placeholder {
  color: @placeholderTextInitialColor;
  //font-family: arial, sans-serif; // switching to arial bc meta looks bad in italic, the f's drop too low, the dots in the lowercase i's blend with the stalks, etc
  //font-style: italic;
  -webkit-font-smoothing: antialiased ; // not supported in all browsers (as of spring, 2018)
  //font-size: @baseFontSizeUX2; // @fontSizeSecondaryUX2; // temp; consider moving back up to 17 to match PointTitle once we move to a multi-line textarea
  vertical-align: top; // why isn't this working?
  display: block;
  position: relative;
  top: 0;
}
.inputFieldUX2::-ms-input-placeholder,
input[type="text"]::-ms-input-placeholder, input[type="email"]::-ms-input-placeholder { /* IE 10-11*/
  color: @placeholderTextInitialColor;
  //font-style: italic;
}

input:focus::-webkit-input-placeholder {
  color: @greyMid; // lighten(grey, 20%);
}

// draftjs hook for controling their version of placeholder text
.public-DraftEditorPlaceholder-root {
  color: @placeholderTextInitialColor;
}
// draftjs hook for controling their version of placeholder text
.public-DraftEditorPlaceholder-hasFocus {
  color: @placeholderTextFocusColor;
}

@inputFieldUX2ActiveBorderWidth: 2px;
.inputFieldUX2:active, .inputFieldUX2:focus,
input[type="text"]:active, input[type="text"]:focus,
input[type="email"]:active, input[type="email"]:focus,
input[type="password"]:active, input[type="password"]:focus,
{
  box-shadow: none;
  //border-color: @nearBlack; //@blue; //@blueLight;
  border-color: @stackCardDefaultBorderColor;
  //border-width: @inputFieldUX2ActiveBorderWidth;
  outline: none;
}

// TO DO: remove this class once proper multi-line support is added to claim creation fields
.inputFieldUX2multi:active, .inputFieldUX2multi:focus {
  margin-bottom: @marginVerticalForms - @titleTextFieldActiveBorderWidth;
}

.addEvidenceForm .titleTextField {
  border-color: @nearBlack;
  border-width: @titleTextFieldActiveBorderWidth;
}
.titleTextField.counter {
  border-color: @red;
  border-width: @titleTextFieldActiveBorderWidth;
}
.titleTextField.counter:focus {
  border-color: @red ;
}

.titleTextField:active::placeholder, .titleTextField:focus::placeholder {
  //top: -12px; // to prevent placeholder text from changing position when the field gets taller; using this approach bc vertical-align isn't working on the placeholder
}

@titleTextFieldActiveBorderWidth: 2px;
@claimFeedbackAreaVerticalCheat: -0.45rem;
.claimFeedbackArea {
  //display: inline-block;
  font-size: @fontSizePointTitle;
  background-color: white; //hsla(0, 0%, 96%, 1); //@editingAreaBG; //white
  border-color: @nearBlack; //@blue; //@blueLight; // @stackCardDefaultBorderColor;
  border-width: @titleTextFieldActiveBorderWidth;
  border-style: solid;
  border-bottom-right-radius: @borderRadiusUX2;
  border-bottom-left-radius: @borderRadiusUX2;
  width: 100%;
  box-sizing: border-box;
  padding: 0px;
  //min-height: 60px;
  margin: 0;
  margin-top: @claimFeedbackAreaVerticalCheat; // bc of the positioning cheat on charCount
  margin-bottom: 0.9rem;
}

.claimFeedbackArea.counter {
  border-color: @red;
}

.existingClaimPicker {
  padding: @cardPadding;
  padding-top:  @cardPadding + (-0.5 * @claimFeedbackAreaVerticalCheat);
  background-color: @editingAreaBGLite;
}

@existingClaimMargin: 0.75rem;
.existingClaimPickerHeading {
  //font-weight: bold;
  //font-size: @baseFontSizeUX2; // is this right?
  margin-bottom: @existingClaimMargin * 1.2;
}

.addEvidenceAreaHeadingText {
  font-weight: bold;
  font-size: @fontSizePointTitle;
}

.existingClaimList {
  margin: 0;
}

.existingClaimList li {
  line-height: @lineHeightSmall;
  margin-bottom: @existingClaimMargin;
  text-decoration: underline;
  list-style-type: none;
}
.existingClaimList li:hover {
  text-decoration: underline;
  cursor: pointer;
}
/*
.existingClaimList li:hover::after {
  content: "- add this"
}
*/

.addEvidenceForm .error {
  padding-left: @cardPadding;
  padding-bottom: @cardPadding / 3;

}

.titleTextErrorArea {
  box-sizing: border-box;
}

.titleTextErrorAreaContent {
  background-color: @yellowWarning;
  display: block;
  font-size: @fontSizeSecondaryUX2; // @fontSizePointTitle;
  width: 100%;
  padding: @cardPadding;
  padding-top: @cardPadding + 0.16875rem; // to account for overlap
}

.addSourceArea {
  display: block;
  margin-left: 1px; // to account for the thick border of the claim input above
  margin-top: @marginVerticalStandard * .5 ;
}

.addEvidenceAreaNote {
  display: inline-block;
  color: @nearBlack;
  width: 100%;
  margin-top: @marginVerticalStandard * .75 ;
  margin-bottom: @marginVerticalStandard *  .75 ;
  //padding-left: @cardPadding;
}

.addSourceArea .addEvidenceAreaNote {
  margin-bottom: @marginVerticalStandard *  .5 ;
  padding-left: 0;
}

.addEvidenceUI {
  margin-bottom: @marginVerticalStandard * 1.75; // 1.85; // @addEvidenceUI_marginV;
  width: fit-content;
  //min-height: @arrowStemEvidenceHeightDefault; // prevents an effect where claims below pop-up when user hovers over this div
}
.addEvidenceUI:hover {
  //margin-bottom: @addEvidenceUI_marginV - @arrowStemEvidenceHeightAdd;
  transition: @arrowStemEvidenceHeightHoverDuration;
}

// after moving the add evidence buttons up above the evidence, this creates a funny reposition effect which is wrong;
// commenting for now and will remove eventually if I don't discover some other reason its needed.
.evidenceBlockEmpty .addEvidenceUI:hover {
  //margin-bottom: @addEvidenceUI_marginV; // undos the hover effect above for evidenceBlockEmpty's
}

.claimTitleField {
  display: inline-block;
  width: 100%;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  background-color: pink;
}


//////////////////////////////////////////////////////////
// CHARACTER COUNTER

.charCounter {
  font-size: @fontSizeTertiaryUX2;
  color: @greyMid;
  //margin-left: 310px;
  margin-right: 0.675rem;
  //text-align: right;
  //display: block;
  //z-index: 100;
  position: relative;
  max-width: 1.35rem;
  //background-color: pink;
  //margin-top: -24px; //top: -24px;
  float: right;
  // cheat position up into the text input area
  transform: translateY(-1.5rem);
  margin-bottom: -0.25 * @marginVerticalStandard;
}

.overMaxChars {
  color: @red;
}


//////////////////////////////////////////////////////
// SEARCH PAGE

#tagSearchResultsArea {
  margin-bottom: @marginVerticalStandard * 2;
}

#searchResultsHeader, #tagHeader {
  margin-top: @marginVerticalStandard;;
  margin-bottom: @marginVerticalStandard * 2;
  //margin-left: @cardPadding + @cardStackWidth - 3px; //offset seems nec for some reason
}

.searchResultsTag {
  padding: @cardPadding;
}

.searchResultsSectionHeading {
  color: @greyDark;
  text-transform: uppercase;
  font-size: @fontSizeTertiaryUX2;
  padding: @cardPadding;
  margin-bottom: 0;
  font-weight: 500;
  padding-bottom: @cardPadding * 0.8;
  padding-top: 0;
}


//////////////////////////////////////////////////////////
// SHARE ICON AREA

@keyframes shareIconAreaReveal {
   0% { opacity: 0; }// left: -24px; }
   25% { opacity: 0; }
   //75% { left: -2px; }
   100% { opacity: 1;  }
}

.shareIconArea {
  //display: none; // revealed on .listedClaimAndShare:hover below
  display: inline-block;
  position: relative;
  left: -1 * ((@stackCardOffset * 3) + (@cardBorderWidth * 2)) - @listedClaimRightPadding; // width of card stack and the padding on the listedClaim
  margin-left: @cardPaddingSmall * 0.66;
  top: -5px;
  font-size: 1em;
  vertical-align: top;
  //padding-top: 4px;
  animation-name: shareIconAreaReveal;
  animation-duration: @evidenceBlockRevealDuration * .6; // complete before evidence reveal so that the eye lands on the evidence
  animation-timing-function: ease;
  animation-iteration-count: 1;
}

.listedClaimAndShare:hover .shareIconArea {
  display: inline-block;
}

.shareIconArea a:hover {
  text-decoration: none !important;
}

a .claimShareIcon {
  display: block;
  //margin-bottom: 10px;
  padding: 0.3125rem; // makes the hover target bigger
  color: @greyMid;
}

.claimShareIcon:hover {
  color: @blue;
  cursor: pointer;
  transform: scale(1.33);
}
.claimShareIcon:active {
  transform: scale(1.15);
}



//////////////////////////////////////////////////////
// ARROWS: HEADS

@arrowHeadWidth: 0.3125rem;
.arrowHeadUp {
  width: 0;
  height: 0;
  border-left: @arrowHeadWidth solid transparent;
  border-right: @arrowHeadWidth solid transparent;
  border-bottom: (@arrowHeadWidth*2) solid @nearBlack;
}
.arrowHeadUp.counter {
  border-bottom-color: @red ;
}


//////////////////////////////////////////////////////
// ARROWS: CONNECTING LINES

@lineStyle: dotted; // solid

@lineWidthSolid: 1px;
@dottedLineWidth: 1px;
@connectingLineWidth: @dottedLineWidth;


.connectingLine {
  //color: @nearBlack;
  width: 1px;
  border-left-style: @lineStyle;
  border-width: @connectingLineWidth;
  position: absolute;
}
.linkArea.relRaterExpanded .connectingLine, .linkAreaBase.relRaterExpanded .connectingLineElbow {
  border-left-style: solid;
}
.linkAreaBase.relRaterExpanded .connectingLineElbow {
  border-bottom-style: solid;
}

.connectingLineHoriz {
  //height: 6px;
  border-top-style: @lineStyle;
  border-right-style: @lineStyle;
  border-width: @connectingLineWidth;
  position: absolute;
  top: -1 * @connectingLineWidth;
  border-top-right-radius:  @borderRadiusUX2;
}

// to change the height of the arrow, change both of these
@arrowStemChange: 0.45rem;
@arrowStemEvidenceHeightDefault: 2.25rem;  // + @arrowStemChange;
@offsetForLongEvidenceArrow: -1.9125rem; // + (-1 * @arrowStemChange);
@arrowStemEvidenceWidth: 0.675rem;

.arrowStemEvidence {
  width: @arrowStemEvidenceWidth;
  height: @arrowStemEvidenceHeightDefault;
  margin-left: (@arrowHeadWidth/2) + 0.16875rem;  // equation prob not right
  border-style: none;
  border-left: @connectingLineWidth @lineStyle @nearBlack;
  border-bottom: @connectingLineWidth @lineStyle @nearBlack;
  border-bottom-left-radius: @borderRadiusUX2 * 1.5;
}
.arrowStemEvidence.counter {
  border-bottom-color: @red !important;
  border-left-color: @red !important;

}

@arrowEvidenceButtonsHeight: 1.6875rem; //30px;
@arrowStemEvidenceCounterOffset: 0.3125rem;  // offset to create curved corner connector on connectingLineHoriz
// TO DO: this may apply this offset in a place we don't want: the arrow pointing pointing to the Add Evidence Against Form,
// when that form is somewhere below a .evidenceBlockCounter. So we need to figure out how to apply this offset more precisely.
/*
.evidenceBlockCounter .arrowStemEvidence.counter {
  position: relative;
  margin-top: @arrowStemEvidenceCounterOffset;
  height: @arrowEvidenceButtonsHeight - @arrowStemEvidenceCounterOffset;
} */


@arrowStemEvidenceShortHeight: 1.875rem;
.arrowStemEvidenceShort {
  height: @arrowStemEvidenceShortHeight;
}
@arrowEvidenceMarginL: 1.1875rem;
.arrowEvidence {
  display: inline-block;
  //margin-left: 21px;
  vertical-align: top;
  position: relative;
  left: -0.25rem; // for some reason this is off by 1px on levels of evidence below 1
  margin-right: 0.0625rem; //1px
  padding-left: @linkedClaimArrowIndent;

}

.arrowBacklinks {
  display: inline-block;
  margin-left: 0;
  margin-right: 0.3125rem; //6px; // @cardStackWidth; //  + @cardPadding + @cardBorderWidth;
  position: relative;
  top: -3px;
}
.arrowBacklinks > .arrowStemEvidence {
  border-color: unset;
  height: 1.35rem;
  width: 0.375rem; // @cardStackWidth + 0.125rem; //+ 3px; // TO DO: make equation more precise so 3px isn't needed
}
.arrowBacklinks > .arrowHeadUp {
  border-bottom-color: unset;
}

@connectCounterRevealDuration: .28s;
@keyframes connectCounterReveal {
 0% { opacity: 0; width: 0; z-index: @zindexCardAnimFromBelow;  }
 80% { opacity: 0; width: 0;  } // delay reveal until after the cards have been dealt
 100% { opacity: 1; width: 100%; z-index: @zindexCardAnimFromBelow; }
}

@connectCounterOffset: 0.75rem;
.connectCounter {
  color: @red;
  width:  ~"calc(100% - @{cardWidth} + @{counterBlockMargin} + @{linkedClaimArrowIndent} + 1.5px)";
  // height: 0;
  margin-left: @cardWidth;
  //top: 12px;
  z-index: @zindexCardAnimFromBelow; //place below the cardstack
  /*animation: connectCounterReveal;
  animation-duration: @connectCounterRevealDuration;
  animation-iteration-count: 1;*/
}

.connectingLineExpansionIndicator {
  height: calc(~"100% + 71px"); // TODO: set this more programmatically; eg calc(100% ~"-" @addEvidenceUI_marginV);
  //color: hsla(0, 0%, 80%, 1); // orange;
  opacity: 0.33;
  margin-left: -0.9rem; // TODO: set this more programmatically (not sure how..)
  bottom: @marginVerticalStandard ;
}

@connectingLineElbowIndent:  1.25rem;
.connectingLineRelevanceLink, .connectingLineRelevanceRater {
  left: @linkedClaimArrowIndent;
}

.connectingLineRelevanceLink {
  height: @relevanceLinkHeight - 0.0625rem; //- 1px;
}
.connectingLineRelevanceRater {
  height: 100%;
}
.connectingLineRelevanceRater.counter {
  color: @red;
}

@connectingLineRelevanceLinkAboveRelLinkLength: 1.6875rem;
.connectingLineRelevanceLink.aboveRelLink {
  margin-top: -1 * @connectingLineRelevanceLinkAboveRelLinkLength;
  height: @connectingLineRelevanceLinkAboveRelLinkLength;
  margin-left: @cardBorderWidth;
}
.connectingLineRelevanceLink.support {
  color: @nearBlack !important;
}
.connectingLineRelevanceLink.counter {
  color: @red !important;
}
.relevanceLink:hover .connectingLineRelevanceLink {
  color: white !important;
}
.linkArea:hover:not(.relRaterExpanded) .arrowStemEvidence {
  border-color: white !important;
}
.linkArea:hover:not(.relRaterExpanded) .arrowHeadUp  {
  border-bottom-color: white;
}

.connectingLineElbow.counter {
  color: @red;
}

.iconVote {
  display: inline-block;
  color: white;
  width: 1.125rem;
  position: relative;
  margin-left: 0.5625rem;
  stroke: @nearBlack;
  stroke-width: 2px;
}

.iconVote.counter {
  stroke: @red;
}
.iconVote.counter polygon {
  fill: @red;
}
.iconVote.support .iconVoteSlot {
  fill: @nearBlack;
}
.iconVote.counter .iconVoteSlot{
  fill: @red;
}
.iconVote.counter .iconVoteSlot:hover{
  fill: white;
}

.relevanceLink:hover .iconVote {
  stroke: white;
  stroke-width: 3px;
}

.connectingLineElbow {
  height: @relevanceLinkHeight / 2;
  position: absolute; // why is this needed?
  margin-left: unset; //@connectingLineElbowIndent;
}

// not used yet
.dashedLineEvidenceBlock {
  height: 45%;
  margin-left: 1rem;
  margin-top: -1.5rem;
}

// extend arrow pointing to parent
@arrowStemEvidenceHeightAdd: 4rem;
@arrowStemEvidenceHeightHoverDuration: @hoverTransitionDuration * 0.66;
@arrowWidthHoverMultiplier: 2;

/*
.addEvidenceUI .arrowStemEvidence:not(.arrowStemEvidenceShort) {
  height: 32px; // @arrowStemEvidenceHeightDefault + @arrowStemEvidenceHeightAdd + 4px; //extra for bigger arrow head?
}
*/
.addEvidenceUI .arrowStemEvidence {
  transition: @arrowStemEvidenceHeightHoverDuration;
  border-left-width: @connectingLineWidth;
  border-bottom-width: @connectingLineWidth;
  width: @arrowStemEvidenceWidth - @connectingLineWidth;
  height: @arrowEvidenceButtonsHeight -0.125rem; //- 2px;
}

@arrowHeadHoverMultiplier: 1.5;
@arrowHeadHeight: @arrowHeadWidth * 2;
@arrowHeadMargin: 0.125rem; // 2px;
@arrowHeadPosition: @indent - @cardStackWidth - @arrowHeadWidth - @arrowHeadMargin - 0.375rem; //-8px;
.addEvidenceUI .arrowHeadUp, .linkedClaimArrow .arrowHeadUp  {
  border-left-width: @arrowHeadWidth; // * @arrowHeadHoverMultiplier;
  border-right-width: @arrowHeadWidth; // * @arrowHeadHoverMultiplier;
  border-bottom-width: @arrowHeadHeight; // * @arrowHeadHoverMultiplier;
  left: @arrowHeadPosition;
  position: relative;
  transform: rotate(90deg);
}

.addEvidenceUI .arrowHeadUp {
  transition: @arrowStemEvidenceHeightHoverDuration;
  top: (-1 * @arrowHeadWidth);
  left: @arrowHeadPosition + 0.5rem;  // not sure why this is nec
}

.linkedClaimArrow {
  position: relative;
  display: inline-block;
  //top: 4px;
  vertical-align: top;
  padding-left: @linkedClaimArrowIndent;
  z-index: 10; // why is this here? —JF
}

@linkedClaimArrowConnectingLineElbowHeight: 1.5rem; //  @relevanceLinkHeight - @marginVerticalStandard ;
.linkedClaimArrow .connectingLineElbow {
  height: @linkedClaimArrowConnectingLineElbowHeight;
}
.linkedClaimArrow .arrowHeadUp {
  top: @linkedClaimArrowConnectingLineElbowHeight - 0.25rem;
  z-index: @zindexArrow; // on top of the card stack
}

// this seems to prevent the awkard pixel overlap bt this and the line above it when Relevance Rater is not expanded.
.linkedClaimArrow .connectingLineElbow {
  margin-top: 0.0625rem; //1px;
}
.relRaterExpanded .connectingLineElbow {
  margin-top: 0;
}


///////////////////////////////////////////////////////
// STORYBOOK

.sb-show-main {
  padding-left: 3rem;
}

// disable the positioning effect of this class when it appears in storybook
.sbLeftUnset {
  left: unset;
}

.storybookOffsetHeader2, .storybookOffsetHeader3 {
  width: 100vw; //not sure why this is necessary
}
.storybookOffsetHeader2 {
  transform: translate(-16px, 50px);
}
.storybookOffsetHeader3 {
  transform: translate(-16px, 120px);
}

.storybookInstruction {
  margin-bottom: 1rem;
  background-color: @yellowWarning; // lightyellow;
  padding: 1rem;
}


///////////////////////////////////////////////////////
// RESPONSIVE
//  TO DO: Unify thresholds with config.js;
// display utilities

.hidden-wideDisplays { display: none !important; }

.hidden-on-mediumWidthDisplays-andWider { display: none !important; }
.visible-on-mediumWidthDisplays-andWider { display: inherit !important; }

.hidden-onlyOn-smallWidthDisplays { display: inherit;  } // hidden below 767
.visible-onlyOn-smallWidthDisplays { display: none !important; } // visible below 767

.hidden-onlyOn-verySmallWidthDisplays { display: inherit; } // hidden below 640
.visible-onlyOn-verySmallWidthDisplays { display: none; } // visible below 640

.hidden-onlyOn-veryVerySmallWidthDisplays { display: inherit; } // hidden below 468
.visible-onlyOn-veryVerySmallWidthDisplays { display: none; } // visible below 468

@cardWidthLargeScreens: 69vw;
@cardWidthMediumScreens: 69vw;
@cardWidthSmallScreens: 84vw;
@cardWidthExtraSmallScreens: 90vw;
@relWidthSmallScreenOffset: 8vw;
@headerMarginNarrow: 0.6875rem;

// leave room for the vertical scrollbar; would be nice if this was automated
@fullWidthMinusScrollbar: 96.5vw;


@media (min-width: 1200px) {
  .container, .navbar-fixed-top .container {
    width: auto; // removing bootstrap's responsive left margin on wide screens to leave more real estate for argument content
  }

  #mainContainer {
    //margin-left: calc(~"2% + 10.25rem"); // 164px; // eye match to the Make Argument icon
  }

  #explanationRowHomepage {
    //margin-left:   calc(~"-1 * (2% + 156px)"); // -1 * margin-left of container (until we move off of base.html)
    //padding-left:   calc(~"2% + 10rem"); // +160px match margin-left of container
  }

  #userMenuArea .username {
    width: unset;
    max-width: 12rem !important;
  }
  #userMenuSignedIn {
    //left: -64px;
  }

  /*.modal-header, .modal-body, .modal-footer {
    padding-left: 15%;
  }*/
}

// TO DO: can these be merged with the 1048 section below?
@media (max-device-width: 1000px) {
  #header {
    position: fixed;
    //width: 100vw; // viewport-width units
  }

  #mainContainer {
    overflow: scroll; // fixes an issue where the header scrolls horizontally on mobile
  }
}
/*
@media (max-width: 1199px) and (min-width: 1049px)  {

  .modal-header, .modal-body, .modal-footer {
    padding-left: 11%;
  }

}*/

@media (max-width: 1048px) {
  .hidden-onlyOn-mediumWidthDisplays-andWider { display: none !important; }
  .visible-on-mediumWidthDisplays-andWider { display: inherit !important; }
  .hidden-wideDisplays { display: inherit !important; }

  #explanationRowHomepage {
    padding-left: 3rem;
  }
  .contentSectionHeading {
      display: block;
  }

  .contentSection .contentSectionHeading {
     margin-left: @cardHeadingOffsetToUndoStack;
    margin-top: 0.675rem;
  }

  .contentSectionHeading .contentSectionLabel {
    float: none;
    clear: none;
  }
  .contentSectionAfterLink {
    left: unset;
  }
  .contentSectionSecondaryLink {
    margin-top: unset;
    display: inline;
    margin-left: 0.5rem;
  }
  #gotItExplanation {
    margin-left: 0;
  }

  .listedClaimGroup {
    width: @cardWidthLargeScreens + (2 * @indent) + 4vw; //add 4vw for the share area
  }
  .relGroupHilite {
    width: @cardWidthLargeScreens + @relWidthSmallScreenOffset;
    max-width: unset;
  }
  .linkArea {
    width: @cardWidthLargeScreens; // ~"calc(@{cardWidthSmallScreens} + (@{indent} / 2))";
  }
  .stackCard, .upstreamLinks, .wideCard.editImage, .commentsArea, .wideCard.commentsArea, .addEvidenceForm, .contentSectionHeading, #profileDisclaimer {
    width: @cardWidthLargeScreens;
  }

  .moreClaimsDivision {
    //background-color: pink;
    display: inline-block;
    //height: 0.5rem; // what was this for?
    width: ~"calc(@{cardWidthLargeScreens} + (2 * @{cardPadding}))";
  }





}

@media (max-width: 1048px) and (min-width: 768px) {

  .navbar .container {
      width: unset;
  }

  @searchAreaMediumDelta: 630px; // TO DO: make programmatic and account for max-width of long usernames
  #searchArea {
    width: ~"-moz-calc(100% - @{searchAreaMediumDelta})";
    width: ~"-webkit-calc(100% - @{searchAreaMediumDelta})";
    width: ~"calc(100% - @{searchAreaMediumDelta})";
  }

  #notifications {
    //right: 112px;
  }

  #userMenuArea {
    //margin-left: @headerMarginNarrow;
  }
  #signInFromHeader {
    //right: 10px;
  }

}

@media (max-width: 1048px) and (min-width: 640px) {

  .explanationBlock {
    //margin-left: 0;
    margin-right: calc(6% - 2.8125rem); // for 4 illustrations
  }
  .explanationBlock.smallerMarginRight {
    //margin-left: 0;
    margin-right: calc(6% - 3.6rem);
  }
  .explanationTextCentered {
    width: unset;
  }
}

@media (max-width: 767px) {

  .hidden-onlyOn-smallWidthDisplays { display: none !important; }
  .visible-onlyOn-smallWidthDisplays { display: inherit !important; }

  body {
    padding-left: 0px;
    padding-right: 0px;
  }

  // HEADER

  #header {
    position: fixed;
    //width: 100vw; // viewport-width units
  }

   // I wonder why this is needed -- something about the header sizing, or scrollbar, seems to change at this width
  .headerUserName {
    right: 1.35rem;
  }
  #aboutDropdown {
    left: -12px;
  }
  #aboutMenu a:hover {
    text-decoration: none;
  }

  @searchAreaNarrowDelta: 32.5rem; // TO DO: make programmatic and account for max-width of long usernames
  @searchAreaNarrowDeltaNoUser: @searchAreaNarrowDelta - 5rem; //- 84px;
  #searchArea {
    float: none;
    width: ~"-moz-calc(100% - @{searchAreaNarrowDelta})";
    width: ~"-webkit-calc(100% - @{searchAreaNarrowDelta})";
    width: ~"calc(100% - @{searchAreaNarrowDelta})";
  }
  #searchArea.noUser {
    float: none;
    width: ~"-moz-calc(100% - @{searchAreaNarrowDeltaNoUser})";
    width: ~"-webkit-calc(100% - @{searchAreaNarrowDeltaNoUser})";
    width: ~"calc(100% - @{searchAreaNarrowDeltaNoUser})";
  }

  #notifications {
    //right: 25px;
    margin-right: @headerMarginNarrow * 3; // 0.675rem;
    margin-left: 0;
  }
  #notificationCount {
    left: 87%;
  }
  .notificationFreqLabel {
    margin-right: 4%;
  }

  #userMenuArea {
    margin-left: @headerMarginNarrow;
    //right: 0;
    //margin-right: @defaultHeaderMarginH;
  }

  #signInFromHeader {
    right: 1.35rem;
    //background-color: pink;
  }


  // DIALOGS

  // TO DO: integrate design of loginDialog so that all the dialogs are reacting to the same responsie thresholds
  .reactModal, #signupDialog, #loginDialog, #termsModal {
    margin-left: 0;
    margin-right: 0;
    width: 100%;
    position: absolute;
    left: 0;
    top: 0;
    RIGHT: 0;
    box-sizing: border-box;
    border: none;
    height: fit-content;
    padding-left: 0;
    padding-right: 0;
  }

  #signUpSpinner {
    margin-left: 0.5rem;
  }

  @frm_signupDialogOffset: 9.25rem; //164px;
  #frm_signupDialog input, #frm_signupDialog textarea {
    width: 100%; // ~"calc(100% - @{frm_signupDialogOffset})"; // subtract width of labels and padding
  }


  // CONTENT

  #mainContainer {
    padding-left: 0.225rem;
  }
  #mainContainer.userPage {
    padding-left: 4%;
  }

  .infiniteWidth {
    margin-left: 1.35rem;
  }

  .historyContent {
    width: 96vw;
  }

  #explanationRowHomepage {
    // margin-left: -50px; // use this for when there are 4 illustrations
    width: auto;
    padding-left: 1.575rem;
  }
  .explanationTextCentered {
    max-width: 180px;
  }
  .explanationTextCentered.lessWidth {
    max-width: 140px;
  }

  .pointPageContainer {
    margin-top: 1.35rem;
  }

  .point-card {
    display: inline-block;
  }

  .listedClaimGroup {
    width: 100vw; // old: @cardWidthSmallScreens + (2 * @indent) + 4vw; // add 4vw for the share
  }
  .relGroupHilite {
    width: @cardWidthMediumScreens + @relWidthSmallScreenOffset;
    max-width: unset;
  }
  .linkArea {
    width: @cardWidthMediumScreens; // ~"calc(@{cardWidthSmallScreens} + (@{indent} / 2))";
  }
  .stackCard, .upstreamLinks, .wideCard .editImage, .commentsArea,.wideCard.commentsArea, .addEvidenceForm, .contentSectionHeading, #profileDisclaimer {
    width: @cardWidthMediumScreens;
  }
  #profileDisclaimer {
    text-align: center;
    margin-top: 0.35rem;
  }

  .moreClaimsDivision {
    width: ~"calc(@{cardWidthMediumScreens} + (2 * @{cardPadding}))";
  }

  @imageWidth: 22%;
  .contentWithImage {
    width: 100% - @imageWidth;
  }

  .profilePic {
    max-width: 58px;
  }

  #userInfoArea {
    width: @cardWidthMediumScreens;
    text-align: center;
    padding: 0.9rem;
    margin-left: 0;
    margin-right: 0;
  }

  // FULL TEXT PAGES AND FORMS
  .fullPageText, .fullPageForm, .affix {
    padding-left: 0;
    margin-left: @leftMarginMobile !important;
    padding-right: @leftMarginMobile;
  }

  // EDUCATION
  .privateAreaHeader {
    position: relative;
    // undo padding from body added in responsive-767px-max.less
    left: -18px;
    width: -moz-calc(~"100% + 36px");
    width: -webkit-calc(~"100% + 36px");
    width: calc(~"100% + 36px");
  }
  #areaLine {
    padding-left: 1.125rem; //20px; // redo padding from body added in responsive-767px-max.less
    padding-right: 1.125rem; //20px;
  }
  .affix {
    position:relative;
    top:33px;
    margin-bottom: 4rem; //54px
    left: 0;
  }
  // match to .fullPageText
  #educationInfoNav {
    //margin-left: 1.8rem;
    //padding-left: 0.225rem;
    max-width: 432px;
  }

  #commonCoreLogo {
    margin-left: 1.8rem;
    padding-left: 0.225rem;
  }

  #educationInfoNav li {
    display: inline-block;
  }

  .react-alert {
    min-height: 15vw;
  }

}

@media (max-width: 675px) {


    #loginDialog {
      margin-left: 0;
      margin-right: 0;
      width: 100%;
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      border: none;
      height: fit-content;
    }


}

@media (max-width: 640px) {

  .hidden-onlyOn-smallWidthDisplays { display: none !important; }
  .visible-onlyOn-smallWidthDisplays { display: inherit !important; }
  .hidden-onlyOn-verySmallWidthDisplays { display: none !important; }
  .visible-onlyOn-verySmallWidthDisplays { display: inherit !important; }

  .feedbackFooterLink {
    font-size: @fontSizeButtonsUX2;
    transform: rotate(-90deg);
    bottom: 1rem;
    right: -1.75rem;
  }

  #headerLogo {
    margin-right: 0.125rem;
  }

  .createClaimCreationArea {
    width: 100%;
  }

  #makePointIcon {
    font-size: 150%;
  }

  @searchAreaNarrowerDelta: 18.5rem;
  @searchAreaNarrowerDeltaNoUser: @searchAreaNarrowerDelta - 2.5rem; //- 42px;
  #searchArea {
    float: none;
    width: ~"-moz-calc(100% - @{searchAreaNarrowerDelta})";
    width: ~"-webkit-calc(100% - @{searchAreaNarrowerDelta})";
    width: ~"calc(100% - @{searchAreaNarrowerDelta})";
  }
  #searchArea.noUser {
    float: none;
    width: ~"-moz-calc(100% - @{searchAreaNarrowerDeltaNoUser})";
    width: ~"-webkit-calc(100% - @{searchAreaNarrowerDeltaNoUser})";
    width: ~"calc(100% - @{searchAreaNarrowerDeltaNoUser})";
  }

  #explanationRowHomepage {
    //margin-left: -10px; // hack until we move off of base.html ;
    position: relative;
    //padding-top: @marginVerticalStandard * 1.66;
    padding-bottom: 1.8rem;
    padding-left: @cardPadding * 2; // TO DO calculate this properly
    padding-right: @cardPadding * 2; // TO DO calculate this properly
    //left: -12px;
  }

  .explanationLabel {
    //margin-top: 0;
  }
  .explanationBlock.noLeftMargin {
    //margin-left: -48px !important;
    //margin-right: 40%;
  }
  .explanationBlock.smallerMarginRight {
    //margin-right: 36%;
  }
  .explanationText {
    max-width: calc(~"85% - 40px"); //TO DO: calculate this programmatically;
  }
  #gotItExplanation {
    //margin-right: 30%;
    margin-left: 0;
    vertical-align: top;
  }
/*
  // TO DO: is this actually only used for save claim buttons that appear to the right of multi-line textareas?
  // if so, rewrite this line. instead of using :not, refer directly to the buttons it should apply to
  @buttonPaddingExtraSmallScreens: 0.75rem;
  .buttonUX2:not(#submit_emailLoginDialog, #submit_signupDialog, .submit_editImageForm, .imagePickerButton, .editSources button, .editTags button) {
    padding-left: @buttonPaddingExtraSmallScreens;
    padding-right: @buttonPaddingExtraSmallScreens;
  }
*/
  .buttonUX2RespIcon {
    padding-right: 0.45rem; // @buttonPaddingExtraSmallScreens - 2px;
    padding-left: 0.45rem;
  }

  .mainPageClaimCreationArea .claimCreationFormFieldBlock {
    width: ~"calc(@{cardWidthSmallScreens} - 43.2px)"; // subtract width of button
  }

  @titleTextFieldResponsive: 5.4rem;
  .addEvidenceForm .titleTextField {
    height: @titleTextFieldResponsive;
  }
  .addEvidenceForm .titleTextField:active, .addEvidenceForm .titleTextField:focus {
    height: @titleTextFieldResponsive + @titleTextfieldAdditional;
  }

  .stackCard, .upstreamLinks, .wideCard.editImage, .commentsArea,.wideCard.commentsArea, .addEvidenceForm, .contentSectionHeading, #profileDisclaimer  {
    width: @cardWidthSmallScreens;
  }

  .linkArea {
    width: @cardWidthSmallScreens; // ~"calc(@{cardWidthExtraSmallScreens} + (@{indent} / 2))";
  }

  .moreClaimsDivision {
    width: ~"calc(@{cardWidthSmallScreens} + (2 * @{cardPadding}))";
  }

  .relGroupHilite {
    width: @cardWidthSmallScreens + @relWidthSmallScreenOffset;
  }

  @imageWidthNarrow: 24%;
  .imageContainer {
    width: @imageWidthNarrow;
  }
  .imageContainerBig {
    width: 100%;
  }

  .contentWithImage {
    width: 100% - @imageWidthNarrow;
  }

  .policySectionCopy {
    width:85%;
  }

  .profileAreaColumn {
    display: block;
    float: none;
    width: unset;
  }

  .blockUnstackOnNarrow {
    //display: inline-block;
    margin-right: 2.7rem;
  }

  .profilePic {
    //width: 100%;
  }

  /*
  .relVoteLink, .relVoteLink button {
    font-size: 1.125em;
    padding-top: 0.5625rem; //10px;
    padding-bottom: 0.5625rem; //10px;
    padding-left: 0.45rem;
    padding-right: 0.3375rem;
  }
  */

  .commentButton {
    width: 100%;
    display: block;
    box-sizing: border-box;
    //width: 220px;
  }

  #userInfoArea {
    margin-left: 12vw;
  }

  // bigger for mobile
  a .claimShareIcon {
    font-size: 1.0125rem;
    padding-bottom: 0.675rem;
    padding-left: 0.375rem;
  }

  // cause the copy to wrap
  .backToLoginArea {
    //max-width: 140px;
  }

  .modal-header, .modal-body, .modal-footer {
    padding-top: 2.25rem;
    padding-left: 2.25rem;
    padding-right: 2.25rem;
  }


}

@mainContainerMarginLeftSmall: 0.225rem;

@media (max-width: 510px) {


  /*
    // FULL TEXT PAGES AND FORMS
    @leftMarginMobileNarrow: 0.5rem;
    .fullPageText, .fullPageForm {
      margin-left: @leftMarginMobileNarrow !important;
      padding-right: @leftMarginMobileNarrow;
    }*/
  .contactForm .alert, .contactForm input, .contactForm textarea, .settingsForm input, .settingsForm .formInstruction {
    width: 100%;
  }

  #explanationRowHomepage {
    //width: 100vw;
    //margin-left: 2%;
    //width: 395px;
  }

  .explanationBlock .icon {
    margin-right: 1rem;
  }

  @frm_signupDialogOffset: 8.325rem; //148px;
  #frm_signupDialog input, #frm_signupDialog textarea {
    width: -webkit-fill-available;
  }

  .backToLoginArea {
    //max-width: 50%;
    //height: 27px;
    text-overflow: clip;
    overflow: hidden;
  }

  .relGroupHilite {
    width: 100vw; // not sure why this is needed
  }

  // put the image container at the top of the claim card
  .imageContainer {
    display: block;
    position: relative;
    width: 100%;
    height: 172px;
    padding: @cardPadding;
    padding-bottom: @cardPadding * .66;
  }
  .imageContainerBig {
    height: unset;
  }
  .contentWithImage {
    width: 100%; // expand to fill space bc image is shown up top
  }

  .relCtrlGroup {
    width: unset;
  }

  .cardBottomAction {
    font-size:  @baseFontSizeUX2 * 0.875; // ~14px
  }

  .cardBottomActionBlock {
    padding-left: @cardPaddingSmall;
    padding-right: @cardPaddingSmall;
  }

  .seeEvidenceBlock {
    width: unset;
  }

  .moreMenuBlock {
    padding-left: @cardPaddingSmall /2;
  }
  /*
  .notification-menu {
    //width: 340px;
    margin-left: -19.35rem; //-344px; // a bit hacky bc I've tweaked bootstrap's behavior for .pull-right>.dropdown-menu
  }

  #notificationsArea {
    width: 100%;
  }*/

  .notificationText {
    width: calc(~"100% - 2rem");
  }

  .notification-menu {
    //margin-left: -282px;
    //margin: 0; // why was this here? changing the top margin messes it up
    width: 100vw; // @fullWidthMinusScrollbar;
    right: -1px !important;
  }
  #notificationsAllArea {
    margin-left: -1 * (@leftMarginMobile + @mainContainerMarginLeftSmall);
    width: @fullWidthMinusScrollbar;
  }



}

// MOBILE
@media (max-width: 468px) {

  .hidden-onlyOn-veryVerySmallWidthDisplays { display: none !important; }
  .visible-onlyOn-veryVerySmallWidthDisplays { display: inherit !important; }

  .reactModal {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    borderRadius: 0;
  }


  #mainContainer, #mainContainer.userPage {
    padding-left: @mainContainerMarginLeftSmall;
  }

  .mainPageClaimCreationArea .claimCreationFormFieldBlock {
    width: @cardWidthSmallScreens;
  }

  @titleTextFieldResponsive: 8rem; //144px;
  .addEvidenceForm .titleTextField {
    height: @titleTextFieldResponsive;
  }

  .addEvidenceForm .titleTextField:active, .addEvidenceForm .titleTextField:focus {
    height: @titleTextFieldResponsive + @titleTextfieldAdditional;
  }

  #newPointTextField:active, #newPointTextField:focus {
    height: 5.4rem; //96px;
  }

  .stackCard, .upstreamLinks, .wideCard, .editImage, .wideCard.editImage, .commentsArea, .wideCard.commentsArea, .addEvidenceForm, .contentSectionHeading, #profileDisclaimer {
    width: @cardWidthExtraSmallScreens;
  }

  .linkArea {
    width: @cardWidthExtraSmallScreens; // ~"calc(@{cardWidthExtraExtraSmallScreens} + 5vw)";
  }

  .linkArea .editAreaClose {
    margin-right: 0.5625rem; //10px;
  }

  .moreClaimsDivision {
    width: ~"calc(@{cardWidthExtraSmallScreens} + (2 * @{cardPadding}))";
  }

  .cardBottomAction {
    font-size: @fontSizeSecondaryUX2;
    //text-decoration: underline;
  }

  // to make evidence buttons stack on narrow widths
  .dualButtons {
    display: inline-block;
    //width:  @cardWidthExtraSmallScreens;
  }

}

@mainContainerMarginLeftSmaller: 0.125rem;

@media (max-width: 405px) {

  #mainContainer, #mainContainer.userPage {
    padding-left: @mainContainerMarginLeftSmaller;
  }

  .stackCard, .upstreamLinks, .editImage, .commentsArea, .addEvidenceForm, .contentSectionHeading, #profileDisclaimer {
    width: calc(100vw - @mainContainerMarginLeftSmaller - 2.5rem); // minus left margin and minus (right margin + scrollbar)
  }

    .cardBottomAction, .point-card .current-vote {
      font-size: @baseFontSizeUX2 * 0.8125; // ~13px;
    }

    .cardBottomActionBlock, .iconBlock {
      padding-left: @cardPaddingSmall /2 ;
      padding-right: @cardPaddingSmall /2;
      border: none;
    }
    .explanationBlock {
        width: 100%;
    }
    .explanationBlock .icon {
      width: unset;
    }
    .explanationText {
      width: 100%;
      max-width: 100%;
    }
    .iconBlock {
      padding-left: @cardPaddingSmall /1.1 !important;
      padding-right: @cardPaddingSmall /2.25  !important;
    }

    .seeEvidenceBlock {
      padding-left: @cardPaddingSmall ;
    }

    .moreMenuLink {
      padding-right: 0.6rem;
    }

    .addEvidenceButtonsArea button {
      padding-left: @cardPaddingSmall;
      padding-right: @cardPaddingSmall;
    }

    @relVoteButtonPadding: 0.75rem;
    .relVoteLink, .relVoteLink button {
      font-size: 1rem; //18px;
      padding-top: @relVoteButtonPadding;
      padding-bottom: @relVoteButtonPadding;
      padding-right: @relVoteButtonPadding - 0.125rem; // reduce because of the visual impact of the %
      padding-left: @relVoteButtonPadding;
    }

    .backToLoginArea {
      display: block;
      max-width: unset;
      margin-top: 1.25rem;
    }
}

// SMALLER PHONES, eg Galaxy Fold
@media (max-width: 319px) {

  .hidden-xxxs {
    display: none !important;
  }

  .buttonUX2responsiveStack {
    display: block;
    margin-bottom: 0.375rem; // for buttons wrapping to a second line
  }


}
