body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.App {
  text-align: center;
}

.App-logo {
  -webkit-animation: App-logo-spin infinite 20s linear;
          animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

.reposition-helperLayer {
  margin-top: 18px;
}
.reposition-tooltipReferenceLayer {
  margin-bottom: -18px;
  /* mozma; */
}

.introjs-fixParent * {
  will-change: inherit !important;
}


/*!
 * Bootstrap v2.3.1
 *
 * Copyright 2012 Twitter, Inc
 * Licensed under the Apache License v2.0
 * http://www.apache.org/licenses/LICENSE-2.0
 *
 * Designed and built with all the love in the world @twitter by @mdo and @fat.
 */
.clearfix {
  *zoom: 1;
}
.clearfix:before,
.clearfix:after {
  display: table;
  content: "";
  line-height: 0;
}
.clearfix:after {
  clear: both;
}
.hide-text {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
}
.input-block-level {
  display: block;
  width: 100%;
  min-height: 1.875rem;
  box-sizing: border-box;
}
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
nav,
section {
  display: block;
}
audio,
canvas,
video {
  display: inline-block;
  *display: inline;
  *zoom: 1;
}
audio:not([controls]) {
  display: none;
}
html {
  font-size: 100%;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
}
a:focus {
  outline: thin dotted #333;
  outline: 5px auto -webkit-focus-ring-color;
  outline-offset: -2px;
}
a:hover,
a:active {
  outline: 0;
}
sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline;
}
sup {
  top: -0.5em;
}
sub {
  bottom: -0.25em;
}
img {
  /* Responsive images (ensure images don't scale beyond their parents) */
  max-width: 100%;
  /* Part 1: Set a maxium relative to the parent */
  width: auto\9;
  /* IE7-8 need help adjusting responsive images */
  height: auto;
  /* Part 2: Scale the height according to the width, otherwise you get stretching */
  vertical-align: middle;
  border: 0;
  -ms-interpolation-mode: bicubic;
}
#map_canvas img,
.google-maps img {
  max-width: none;
}
button,
input,
select,
textarea {
  margin: 0;
  font-size: 100%;
  vertical-align: middle;
}
button,
input {
  *overflow: visible;
  line-height: normal;
}
button::-moz-focus-inner,
input::-moz-focus-inner {
  padding: 0;
  border: 0;
}
button,
html input[type="button"],
input[type="reset"],
input[type="submit"] {
  -webkit-appearance: button;
  cursor: pointer;
}
label,
select,
button,
input[type="button"],
input[type="reset"],
input[type="submit"],
input[type="radio"],
input[type="checkbox"] {
  cursor: pointer;
}
input[type="search"] {
  box-sizing: content-box;
  -webkit-appearance: textfield;
}
input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button {
  -webkit-appearance: none;
}
textarea {
  overflow: auto;
  vertical-align: top;
}
@media print {
  * {
    text-shadow: none !important;
    color: #000 !important;
    background: transparent !important;
    box-shadow: none !important;
  }
  a,
  a:visited {
    text-decoration: underline;
  }
  a[href]:after {
    content: " (" attr(href) ")";
  }
  abbr[title]:after {
    content: " (" attr(title) ")";
  }
  .ir a:after,
  a[href^="javascript:"]:after,
  a[href^="#"]:after {
    content: "";
  }
  pre,
  blockquote {
    border: 1px solid #999;
    page-break-inside: avoid;
  }
  thead {
    display: table-header-group;
  }
  tr,
  img {
    page-break-inside: avoid;
  }
  img {
    max-width: 100% !important;
  }
  @page {
    margin: 0.5cm;
  }
  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3;
  }
  h2,
  h3 {
    page-break-after: avoid;
  }
}
body {
  margin: 0;
  font-family: "ff-meta-web-pro", sans-serif;
  font-size: 16px;
  line-height: 120%;
  color: #000;
  background-color: #fff;
}
a {
  color: hsl(196, 100%, 45%);
  text-decoration: none;
}
a:hover,
a:focus {
  color: hsl(196, 100%, 60%);
  text-decoration: underline;
}
.img-rounded {
  border-radius: 6px;
}
.img-polaroid {
  padding: 4px;
  background-color: #fff;
  border: 1px solid #ccc;
  border: 1px solid rgba(0, 0, 0, 0.2);
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}
.img-circle {
  border-radius: 500px;
}
p {
  margin: 0;
  margin-bottom: 16px;
  line-height: 120%;
}
.lead {
  margin-bottom: 20px;
  font-size: 24px;
  font-weight: 200;
  line-height: 30px;
}
small {
  font-size: 85%;
}
strong {
  font-weight: bold;
}
em {
  font-style: italic;
}
cite {
  font-style: normal;
}
.muted {
  color: #f2f2f2;
}
a.muted:hover,
a.muted:focus {
  color: #d9d9d9;
}
.text-warning {
  color: #c09853;
}
a.text-warning:hover,
a.text-warning:focus {
  color: #a47e3c;
}
.text-error {
  color: #b94a48;
}
a.text-error:hover,
a.text-error:focus {
  color: #953b39;
}
.text-info {
  color: #3a87ad;
}
a.text-info:hover,
a.text-info:focus {
  color: #2d6987;
}
.text-success {
  color: #468847;
}
a.text-success:hover,
a.text-success:focus {
  color: #356635;
}
.text-left {
  text-align: left;
}
.text-right {
  text-align: right;
}
.text-center {
  text-align: center;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 10px 0;
  font-family: "ff-meta-web-pro", sans-serif;
  font-weight: bold;
  line-height: 120%;
  color: #000;
  text-rendering: optimizelegibility;
}
h1 small,
h2 small,
h3 small,
h4 small,
h5 small,
h6 small {
  font-weight: normal;
  line-height: 1;
  color: #f2f2f2;
}
h1 {
  font-size: 19.008px;
}
h2 {
  font-size: 36.1152px;
  margin: 48px 0 0px 0;
}
h3 {
  font-size: 31.55328px;
}
h4 {
  font-size: 20.9088px;
  margin: 32px 0 10px 0;
}
h5 {
  font-size: 12.54528px;
}
h6 {
  font-size: 9.504px;
}
h1 small {
  font-size: 28px;
}
h2 small {
  font-size: 20px;
}
h3 small {
  font-size: 16px;
}
h4 small {
  font-size: 20px;
}
.firstHeading {
  margin-top: 0;
}
.smallBottomMargin {
  margin-bottom: 2px;
}
.addBottomMargin {
  margin-bottom: 6px;
}
.page-header {
  padding-bottom: 9px;
  margin: 20px 0 30px;
  border-bottom: 1px solid #929497;
}
ul,
ol {
  padding: 0;
  margin: 0 0 10px 25px;
}
ul ul,
ul ol,
ol ol,
ol ul {
  margin-bottom: 0;
}
li {
  line-height: 120%;
}
ul.unstyled,
ol.unstyled {
  margin-left: 0;
  list-style: none;
}
ul.inline,
ol.inline {
  margin-left: 0;
  list-style: none;
}
ul.inline > li,
ol.inline > li {
  display: inline-block;
  *display: inline;
  /* IE7 inline-block hack */
  *zoom: 1;
  padding-left: 5px;
  padding-right: 5px;
}
dl {
  margin-bottom: 20px;
}
dt,
dd {
  line-height: 120%;
}
dt {
  font-weight: bold;
}
dd {
  margin-left: 10px;
}
.dl-horizontal {
  *zoom: 1;
}
.dl-horizontal:before,
.dl-horizontal:after {
  display: table;
  content: "";
  line-height: 0;
}
.dl-horizontal:after {
  clear: both;
}
.dl-horizontal dt {
  float: left;
  width: -12.5rem;
  clear: left;
  text-align: right;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.dl-horizontal dd {
  margin-left: 7.5rem;
}
hr {
  margin: 20px 0;
  border: 0;
  border-top: 1px solid #929497;
  border-bottom: 1px solid #fff;
}
abbr[title],
abbr[data-original-title] {
  cursor: help;
  border-bottom: 1px dotted #f2f2f2;
}
abbr.initialism {
  font-size: 90%;
  text-transform: uppercase;
}
blockquote {
  padding: 0 0 0 15px;
  margin: 0 0 20px;
  border-left: 5px solid #929497;
}
blockquote p {
  margin-bottom: 0;
  font-size: 20px;
  font-weight: 300;
  line-height: 1.25;
}
blockquote small {
  display: block;
  line-height: 120%;
  color: #f2f2f2;
}
blockquote small:before {
  content: '\2014   \A0';
}
blockquote.pull-right {
  float: right;
  padding-right: 15px;
  padding-left: 0;
  border-right: 5px solid #929497;
  border-left: 0;
}
blockquote.pull-right p,
blockquote.pull-right small {
  text-align: right;
}
blockquote.pull-right small:before {
  content: '';
}
blockquote.pull-right small:after {
  content: '\A0   \2014';
}
q:before,
q:after,
blockquote:before,
blockquote:after {
  content: "";
}
address {
  display: block;
  margin-bottom: 20px;
  font-style: normal;
  line-height: 120%;
}
form {
  margin-left: 0;
  margin-right: 0;
  margin-top: 0;
  margin-bottom: 0.5rem;
}
fieldset {
  padding: 0;
  margin: 0;
  border: 0;
}
legend {
  display: block;
  width: 100%;
  padding: 0;
  margin-bottom: 20px;
  font-size: 24px;
  line-height: 240%;
  color: #B9BBBD;
  border: 0;
  border-bottom: 1px solid #e5e5e5;
}
legend small {
  font-size: 12px;
  color: #f2f2f2;
}
label,
input,
button,
select,
textarea {
  font-size: 0.75em;
  font-weight: normal;
  line-height: 20px;
}
select,
textarea {
  font-family: inherit;
}
input,
button {
  font-family: inherit;
}
label {
  display: block;
  margin-bottom: 5px;
}
select,
textarea,
input[type="text"],
input[type="password"],
input[type="datetime"],
input[type="datetime-local"],
input[type="date"],
input[type="month"],
input[type="time"],
input[type="week"],
input[type="number"],
input[type="email"],
input[type="url"]:not(.fsp-url-source__input),
input[type="search"],
input[type="tel"],
input[type="color"],
.uneditable-input {
  display: inline-block;
  height: 24px;
  padding: 4px 6px;
  margin-bottom: 10px;
  font-size: 0.75em;
  line-height: 120%;
  color: #6D6E70;
  border-radius: 0px;
  vertical-align: middle;
}
input,
textarea,
.uneditable-input {
  width: 300px;
}
textarea {
  height: auto;
}
textarea,
input[type="text"],
input[type="password"],
input[type="datetime"],
input[type="datetime-local"],
input[type="date"],
input[type="month"],
input[type="time"],
input[type="week"],
input[type="number"],
input[type="email"],
input[type="url"],
input[type="search"],
input[type="tel"],
input[type="color"],
.uneditable-input {
  background-color: #fff;
  border: 1px solid #ccc;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  transition: border linear .2s, box-shadow linear .2s;
}
textarea:focus,
input[type="text"]:focus,
input[type="password"]:focus,
input[type="datetime"]:focus,
input[type="datetime-local"]:focus,
input[type="date"]:focus,
input[type="month"]:focus,
input[type="time"]:focus,
input[type="week"]:focus,
input[type="number"]:focus,
input[type="email"]:focus,
input[type="url"]:focus,
input[type="search"]:focus,
input[type="tel"]:focus,
input[type="color"]:focus,
.uneditable-input:focus {
  border-color: rgba(82, 168, 236, 0.8);
  outline: 0;
  outline: thin dotted \9;
  /* IE6-9 */
  box-shadow: inset 0 1px 1px rgba(0,0,0,.075), 0 0 8px rgba(82,168,236,.6);
}
input[type="radio"],
input[type="checkbox"] {
  margin: 0;
  margin-right: 4px;
  *margin-top: 0;
  /* IE7 */
  margin-top: 1px \9;
  /* IE8-9 */
  line-height: normal;
}
input[type="file"],
input[type="image"],
input[type="submit"],
input[type="reset"],
input[type="button"],
input[type="radio"],
input[type="checkbox"] {
  width: auto;
}
select,
input[type="file"] {
  height: 1.875rem;
  /* In IE7, the height of the select element cannot be changed by height, only font-size */
  *margin-top: 4px;
  /* For IE7, add top margin to align select with labels */
  line-height: 1.875rem;
}
select {
  width: 220px;
  border: 1px solid #ccc;
  background-color: #fff;
}
select[multiple],
select[size] {
  height: auto;
}
select:focus,
input[type="file"]:focus,
input[type="radio"]:focus,
input[type="checkbox"]:focus {
  outline: thin dotted #333;
  outline: 5px auto -webkit-focus-ring-color;
  outline-offset: -2px;
}
.uneditable-input,
.uneditable-textarea {
  color: #f2f2f2;
  background-color: #fcfcfc;
  border-color: #ccc;
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.025);
  cursor: not-allowed;
}
.uneditable-input {
  overflow: hidden;
  white-space: nowrap;
}
.uneditable-textarea {
  width: auto;
  height: auto;
}
input:-moz-placeholder,
textarea:-moz-placeholder {
  color: #B9BBBD;
}
input:-ms-input-placeholder,
textarea:-ms-input-placeholder {
  color: #B9BBBD;
}
input::-webkit-input-placeholder,
textarea::-webkit-input-placeholder {
  color: #B9BBBD;
}
.radio,
.checkbox {
  min-height: 20px;
  padding-left: 20px;
}
.radio input[type="radio"],
.checkbox input[type="checkbox"] {
  float: left;
  margin-left: -20px;
}
.controls > .radio:first-child,
.controls > .checkbox:first-child {
  padding-top: 5px;
}
.radio.inline,
.checkbox.inline {
  display: inline-block;
  padding-top: 5px;
  margin-bottom: 0;
  vertical-align: middle;
}
.radio.inline + .radio.inline,
.checkbox.inline + .checkbox.inline {
  margin-left: 10px;
}
.input-mini {
  width: 60px;
}
.input-small {
  width: 90px;
}
.input-medium {
  width: 150px;
}
.input-large {
  width: 210px;
}
.input-xlarge {
  width: 270px;
}
.input-xxlarge {
  width: 530px;
}
input[class*="span"],
select[class*="span"],
textarea[class*="span"],
.uneditable-input[class*="span"],
.row-fluid input[class*="span"],
.row-fluid select[class*="span"],
.row-fluid textarea[class*="span"],
.row-fluid .uneditable-input[class*="span"] {
  float: none;
  margin-left: 0;
}
.input-append input[class*="span"],
.input-append .uneditable-input[class*="span"],
.input-prepend input[class*="span"],
.input-prepend .uneditable-input[class*="span"],
.row-fluid input[class*="span"],
.row-fluid select[class*="span"],
.row-fluid textarea[class*="span"],
.row-fluid .uneditable-input[class*="span"],
.row-fluid .input-prepend [class*="span"],
.row-fluid .input-append [class*="span"] {
  display: inline-block;
}
input,
textarea,
.uneditable-input {
  margin-left: 0;
}
.controls-row [class*="span"] + [class*="span"] {
  margin-left: 16px;
}
input.span12,
textarea.span12,
.uneditable-input.span12 {
  width: 954px;
}
input.span11,
textarea.span11,
.uneditable-input.span11 {
  width: 872px;
}
input.span10,
textarea.span10,
.uneditable-input.span10 {
  width: 790px;
}
input.span9,
textarea.span9,
.uneditable-input.span9 {
  width: 708px;
}
input.span8,
textarea.span8,
.uneditable-input.span8 {
  width: 626px;
}
input.span7,
textarea.span7,
.uneditable-input.span7 {
  width: 544px;
}
input.span6,
textarea.span6,
.uneditable-input.span6 {
  width: 462px;
}
input.span5,
textarea.span5,
.uneditable-input.span5 {
  width: 380px;
}
input.span4,
textarea.span4,
.uneditable-input.span4 {
  width: 298px;
}
input.span3,
textarea.span3,
.uneditable-input.span3 {
  width: 216px;
}
input.span2,
textarea.span2,
.uneditable-input.span2 {
  width: 134px;
}
input.span1,
textarea.span1,
.uneditable-input.span1 {
  width: 52px;
}
.controls-row {
  *zoom: 1;
}
.controls-row:before,
.controls-row:after {
  display: table;
  content: "";
  line-height: 0;
}
.controls-row:after {
  clear: both;
}
.controls-row [class*="span"],
.row-fluid .controls-row [class*="span"] {
  float: left;
}
.controls-row .checkbox[class*="span"],
.controls-row .radio[class*="span"] {
  padding-top: 5px;
}
input[disabled],
select[disabled],
textarea[disabled],
input[readonly],
select[readonly],
textarea[readonly] {
  cursor: not-allowed;
  background-color: #929497;
}
input[type="radio"][disabled],
input[type="checkbox"][disabled],
input[type="radio"][readonly],
input[type="checkbox"][readonly] {
  background-color: transparent;
}
.control-group.warning .control-label,
.control-group.warning .help-block,
.control-group.warning .help-inline {
  color: #c09853;
}
.control-group.warning .checkbox,
.control-group.warning .radio,
.control-group.warning input,
.control-group.warning select,
.control-group.warning textarea {
  color: #c09853;
}
.control-group.warning input,
.control-group.warning select,
.control-group.warning textarea {
  border-color: #c09853;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
}
.control-group.warning input:focus,
.control-group.warning select:focus,
.control-group.warning textarea:focus {
  border-color: #a47e3c;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #dbc59e;
}
.control-group.warning .input-prepend .add-on,
.control-group.warning .input-append .add-on {
  color: #c09853;
  background-color: #fcf8e3;
  border-color: #c09853;
}
.control-group.error .control-label,
.control-group.error .help-block,
.control-group.error .help-inline {
  color: #b94a48;
}
.control-group.error .checkbox,
.control-group.error .radio,
.control-group.error input,
.control-group.error select,
.control-group.error textarea {
  color: #b94a48;
}
.control-group.error input,
.control-group.error select,
.control-group.error textarea {
  border-color: #b94a48;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
}
.control-group.error input:focus,
.control-group.error select:focus,
.control-group.error textarea:focus {
  border-color: #953b39;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #d59392;
}
.control-group.error .input-prepend .add-on,
.control-group.error .input-append .add-on {
  color: #b94a48;
  background-color: #f2dede;
  border-color: #b94a48;
}
.control-group.success .control-label,
.control-group.success .help-block,
.control-group.success .help-inline {
  color: #468847;
}
.control-group.success .checkbox,
.control-group.success .radio,
.control-group.success input,
.control-group.success select,
.control-group.success textarea {
  color: #468847;
}
.control-group.success input,
.control-group.success select,
.control-group.success textarea {
  border-color: #468847;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
}
.control-group.success input:focus,
.control-group.success select:focus,
.control-group.success textarea:focus {
  border-color: #356635;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #7aba7b;
}
.control-group.success .input-prepend .add-on,
.control-group.success .input-append .add-on {
  color: #468847;
  background-color: #dff0d8;
  border-color: #468847;
}
.control-group.info .control-label,
.control-group.info .help-block,
.control-group.info .help-inline {
  color: #3a87ad;
}
.control-group.info .checkbox,
.control-group.info .radio,
.control-group.info input,
.control-group.info select,
.control-group.info textarea {
  color: #3a87ad;
}
.control-group.info input,
.control-group.info select,
.control-group.info textarea {
  border-color: #3a87ad;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
}
.control-group.info input:focus,
.control-group.info select:focus,
.control-group.info textarea:focus {
  border-color: #2d6987;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #7ab5d3;
}
.control-group.info .input-prepend .add-on,
.control-group.info .input-append .add-on {
  color: #3a87ad;
  background-color: #d9edf7;
  border-color: #3a87ad;
}
input:focus:invalid,
textarea:focus:invalid,
select:focus:invalid {
  color: #b94a48;
  border-color: #ee5f5b;
}
input:focus:invalid:focus,
textarea:focus:invalid:focus,
select:focus:invalid:focus {
  border-color: #e9322d;
  box-shadow: 0 0 6px #f8b9b7;
}
.form-actions {
  padding: 19px 20px 20px;
  margin-top: 20px;
  margin-bottom: 20px;
  background-color: #f5f5f5;
  border-top: 1px solid #e5e5e5;
  *zoom: 1;
}
.form-actions:before,
.form-actions:after {
  display: table;
  content: "";
  line-height: 0;
}
.form-actions:after {
  clear: both;
}
.help-block,
.help-inline {
  color: #262626;
}
.help-block {
  display: block;
  margin-bottom: 10px;
}
.help-inline {
  display: inline-block;
  *display: inline;
  /* IE7 inline-block hack */
  *zoom: 1;
  vertical-align: middle;
  padding-left: 5px;
}
.input-append,
.input-prepend {
  display: inline-block;
  margin-bottom: 10px;
  vertical-align: middle;
  font-size: 0;
  white-space: nowrap;
}
.input-append input,
.input-prepend input,
.input-append select,
.input-prepend select,
.input-append .uneditable-input,
.input-prepend .uneditable-input,
.input-append .dropdown-menu,
.input-prepend .dropdown-menu,
.input-append .popover,
.input-prepend .popover {
  font-size: 16px;
}
.input-append input,
.input-prepend input,
.input-append select,
.input-prepend select,
.input-append .uneditable-input,
.input-prepend .uneditable-input {
  position: relative;
  margin-bottom: 0;
  *margin-left: 0;
  vertical-align: top;
  border-radius: 0 0px 0px 0;
}
.input-append input:focus,
.input-prepend input:focus,
.input-append select:focus,
.input-prepend select:focus,
.input-append .uneditable-input:focus,
.input-prepend .uneditable-input:focus {
  z-index: 2;
}
.input-append .add-on,
.input-prepend .add-on {
  display: inline-block;
  width: auto;
  height: 20px;
  min-width: 16px;
  padding: 4px 5px;
  font-size: 16px;
  font-weight: normal;
  line-height: 120%;
  text-align: center;
  text-shadow: 0 1px 0 #fff;
  background-color: #929497;
  border: 1px solid #ccc;
}
.input-append .add-on,
.input-prepend .add-on,
.input-append .btn,
.input-prepend .btn,
.input-append .btn-group > .dropdown-toggle,
.input-prepend .btn-group > .dropdown-toggle {
  vertical-align: top;
  border-radius: 0;
}
.input-append .active,
.input-prepend .active {
  background-color: #82ff7a;
  border-color: #0DE000;
}
.input-prepend .add-on,
.input-prepend .btn {
  margin-right: -1px;
}
.input-prepend .add-on:first-child,
.input-prepend .btn:first-child {
  border-radius: 0px 0 0 0px;
}
.input-append input,
.input-append select,
.input-append .uneditable-input {
  border-radius: 0px 0 0 0px;
}
.input-append input + .btn-group .btn:last-child,
.input-append select + .btn-group .btn:last-child,
.input-append .uneditable-input + .btn-group .btn:last-child {
  border-radius: 0 0px 0px 0;
}
.input-append .add-on,
.input-append .btn,
.input-append .btn-group {
  margin-left: -1px;
}
.input-append .add-on:last-child,
.input-append .btn:last-child,
.input-append .btn-group:last-child > .dropdown-toggle {
  border-radius: 0 0px 0px 0;
}
.input-prepend.input-append input,
.input-prepend.input-append select,
.input-prepend.input-append .uneditable-input {
  border-radius: 0;
}
.input-prepend.input-append input + .btn-group .btn,
.input-prepend.input-append select + .btn-group .btn,
.input-prepend.input-append .uneditable-input + .btn-group .btn {
  border-radius: 0 0px 0px 0;
}
.input-prepend.input-append .add-on:first-child,
.input-prepend.input-append .btn:first-child {
  margin-right: -1px;
  border-radius: 0px 0 0 0px;
}
.input-prepend.input-append .add-on:last-child,
.input-prepend.input-append .btn:last-child {
  margin-left: -1px;
  border-radius: 0 0px 0px 0;
}
.input-prepend.input-append .btn-group:first-child {
  margin-left: 0;
}
input.search-query {
  padding-right: 14px;
  padding-right: 4px \9;
  padding-left: 14px;
  padding-left: 4px \9;
  /* IE7-8 doesn't have border-radius, so don't indent the padding */
  margin-bottom: 0;
  border-radius: 15px;
}
/* Allow for input prepend/append in search forms */
.form-search .input-append .search-query,
.form-search .input-prepend .search-query {
  border-radius: 0;
}
.form-search .input-append .search-query {
  border-radius: 14px 0 0 14px;
}
.form-search .input-append .btn {
  border-radius: 0 14px 14px 0;
}
.form-search .input-prepend .search-query {
  border-radius: 0 14px 14px 0;
}
.form-search .input-prepend .btn {
  border-radius: 14px 0 0 14px;
}
.form-search input,
.form-inline input,
.form-horizontal input,
.form-search textarea,
.form-inline textarea,
.form-horizontal textarea,
.form-search select,
.form-inline select,
.form-horizontal select,
.form-search .help-inline,
.form-inline .help-inline,
.form-horizontal .help-inline,
.form-search .uneditable-input,
.form-inline .uneditable-input,
.form-horizontal .uneditable-input,
.form-search .input-prepend,
.form-inline .input-prepend,
.form-horizontal .input-prepend,
.form-search .input-append,
.form-inline .input-append,
.form-horizontal .input-append {
  display: inline-block;
  *display: inline;
  /* IE7 inline-block hack */
  *zoom: 1;
  margin-bottom: 0;
  vertical-align: middle;
}
.form-search .hide,
.form-inline .hide,
.form-horizontal .hide {
  display: none;
}
.form-search label,
.form-inline label,
.form-search .btn-group,
.form-inline .btn-group {
  display: inline-block;
}
.form-search .input-append,
.form-inline .input-append,
.form-search .input-prepend,
.form-inline .input-prepend {
  margin-bottom: 0;
}
.form-search .radio,
.form-search .checkbox,
.form-inline .radio,
.form-inline .checkbox {
  padding-left: 0;
  margin-bottom: 0;
  vertical-align: middle;
}
.form-search .radio input[type="radio"],
.form-search .checkbox input[type="checkbox"],
.form-inline .radio input[type="radio"],
.form-inline .checkbox input[type="checkbox"] {
  float: left;
  margin-right: 3px;
  margin-left: 0;
}
.control-group {
  margin-bottom: 10px;
}
legend + .control-group {
  margin-top: 20px;
  -webkit-margin-top-collapse: separate;
}
.form-horizontal .control-group {
  margin-bottom: 3px;
  *zoom: 1;
}
.form-horizontal .control-group:before,
.form-horizontal .control-group:after {
  display: table;
  content: "";
  line-height: 0;
}
.form-horizontal .control-group:after {
  clear: both;
}
.form-horizontal .control-label {
  float: left;
  width: 7.5rem;
  padding-right: 5px;
  padding-left: 9px;
  background-color: #f2f2f2;
  color: #525252;
  line-height: 120%;
  padding-top: 8px;
  padding-bottom: 8px;
  font-weight: 400;
}
.form-horizontal .controls {
  *display: inline-block;
  *padding-left: 20px;
  *margin-left: 0;
}
.form-horizontal .controls:first-child {
  *padding-left: 7.5rem;
}
.form-horizontal .help-block {
  margin-bottom: 0;
}
.form-horizontal input + .help-block,
.form-horizontal select + .help-block,
.form-horizontal textarea + .help-block,
.form-horizontal .uneditable-input + .help-block,
.form-horizontal .input-prepend + .help-block,
.form-horizontal .input-append + .help-block {
  margin-top: 10px;
}
.form-horizontal .form-actions {
  padding-left: 7.5rem;
}
table {
  max-width: 100%;
  background-color: transparent;
  border-collapse: collapse;
  border-spacing: 0;
}
.table {
  width: 100%;
  margin-bottom: 20px;
}
.table th,
.table td {
  padding: 8px;
  line-height: 120%;
  text-align: left;
  vertical-align: top;
  border-top: 1px solid #ddd;
}
.table th {
  font-weight: bold;
}
.table thead th {
  vertical-align: bottom;
}
.table caption + thead tr:first-child th,
.table caption + thead tr:first-child td,
.table colgroup + thead tr:first-child th,
.table colgroup + thead tr:first-child td,
.table thead:first-child tr:first-child th,
.table thead:first-child tr:first-child td {
  border-top: 0;
}
.table tbody + tbody {
  border-top: 2px solid #ddd;
}
.table .table {
  background-color: #fff;
}
.table-condensed th,
.table-condensed td {
  padding: 4px 5px;
}
.table-bordered {
  border: 1px solid #ddd;
  border-collapse: separate;
  *border-collapse: collapse;
  border-left: 0;
  border-radius: 0px;
}
.table-bordered th,
.table-bordered td {
  border-left: 1px solid #ddd;
}
.table-bordered caption + thead tr:first-child th,
.table-bordered caption + tbody tr:first-child th,
.table-bordered caption + tbody tr:first-child td,
.table-bordered colgroup + thead tr:first-child th,
.table-bordered colgroup + tbody tr:first-child th,
.table-bordered colgroup + tbody tr:first-child td,
.table-bordered thead:first-child tr:first-child th,
.table-bordered tbody:first-child tr:first-child th,
.table-bordered tbody:first-child tr:first-child td {
  border-top: 0;
}
.table-bordered thead:first-child tr:first-child > th:first-child,
.table-bordered tbody:first-child tr:first-child > td:first-child,
.table-bordered tbody:first-child tr:first-child > th:first-child {
  border-top-left-radius: 0px;
}
.table-bordered thead:first-child tr:first-child > th:last-child,
.table-bordered tbody:first-child tr:first-child > td:last-child,
.table-bordered tbody:first-child tr:first-child > th:last-child {
  border-top-right-radius: 0px;
}
.table-bordered thead:last-child tr:last-child > th:first-child,
.table-bordered tbody:last-child tr:last-child > td:first-child,
.table-bordered tbody:last-child tr:last-child > th:first-child,
.table-bordered tfoot:last-child tr:last-child > td:first-child,
.table-bordered tfoot:last-child tr:last-child > th:first-child {
  border-bottom-left-radius: 0px;
}
.table-bordered thead:last-child tr:last-child > th:last-child,
.table-bordered tbody:last-child tr:last-child > td:last-child,
.table-bordered tbody:last-child tr:last-child > th:last-child,
.table-bordered tfoot:last-child tr:last-child > td:last-child,
.table-bordered tfoot:last-child tr:last-child > th:last-child {
  border-bottom-right-radius: 0px;
}
.table-bordered tfoot + tbody:last-child tr:last-child td:first-child {
  border-bottom-left-radius: 0;
}
.table-bordered tfoot + tbody:last-child tr:last-child td:last-child {
  border-bottom-right-radius: 0;
}
.table-bordered caption + thead tr:first-child th:first-child,
.table-bordered caption + tbody tr:first-child td:first-child,
.table-bordered colgroup + thead tr:first-child th:first-child,
.table-bordered colgroup + tbody tr:first-child td:first-child {
  border-top-left-radius: 0px;
}
.table-bordered caption + thead tr:first-child th:last-child,
.table-bordered caption + tbody tr:first-child td:last-child,
.table-bordered colgroup + thead tr:first-child th:last-child,
.table-bordered colgroup + tbody tr:first-child td:last-child {
  border-top-right-radius: 0px;
}
.table-striped tbody > tr:nth-child(odd) > td,
.table-striped tbody > tr:nth-child(odd) > th {
  background-color: #f9f9f9;
}
.table-hover tbody tr:hover > td,
.table-hover tbody tr:hover > th {
  background-color: #f5f5f5;
}
table td[class*="span"],
table th[class*="span"],
.row-fluid table td[class*="span"],
.row-fluid table th[class*="span"] {
  display: table-cell;
  float: none;
  margin-left: 0;
}
.table td.span1,
.table th.span1 {
  float: none;
  width: 50px;
  margin-left: 0;
}
.table td.span2,
.table th.span2 {
  float: none;
  width: 132px;
  margin-left: 0;
}
.table td.span3,
.table th.span3 {
  float: none;
  width: 214px;
  margin-left: 0;
}
.table td.span4,
.table th.span4 {
  float: none;
  width: 296px;
  margin-left: 0;
}
.table td.span5,
.table th.span5 {
  float: none;
  width: 378px;
  margin-left: 0;
}
.table td.span6,
.table th.span6 {
  float: none;
  width: 460px;
  margin-left: 0;
}
.table td.span7,
.table th.span7 {
  float: none;
  width: 542px;
  margin-left: 0;
}
.table td.span8,
.table th.span8 {
  float: none;
  width: 624px;
  margin-left: 0;
}
.table td.span9,
.table th.span9 {
  float: none;
  width: 706px;
  margin-left: 0;
}
.table td.span10,
.table th.span10 {
  float: none;
  width: 788px;
  margin-left: 0;
}
.table td.span11,
.table th.span11 {
  float: none;
  width: 870px;
  margin-left: 0;
}
.table td.span12,
.table th.span12 {
  float: none;
  width: 952px;
  margin-left: 0;
}
.table tbody tr.success > td {
  background-color: #dff0d8;
}
.table tbody tr.error > td {
  background-color: #f2dede;
}
.table tbody tr.warning > td {
  background-color: #fcf8e3;
}
.table tbody tr.info > td {
  background-color: #d9edf7;
}
.table-hover tbody tr.success:hover > td {
  background-color: #d0e9c6;
}
.table-hover tbody tr.error:hover > td {
  background-color: #ebcccc;
}
.table-hover tbody tr.warning:hover > td {
  background-color: #faf2cc;
}
.table-hover tbody tr.info:hover > td {
  background-color: #c4e3f3;
}
.dropup,
.dropdown {
  position: relative;
}
.dropdown-toggle {
  *margin-bottom: -3px;
}
.dropdown-toggle:active,
.open .dropdown-toggle {
  outline: 0;
}
.caret {
  display: inline-block;
  width: 0;
  height: 0;
  vertical-align: top;
  border-top: 4px solid #000;
  border-right: 4px solid transparent;
  border-left: 4px solid transparent;
  content: "";
}
.dropdown .caret {
  margin-top: 8px;
  margin-left: 2px;
}
.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1035;
  display: none;
  float: left;
  min-width: 160px;
  padding: 5px 0;
  margin: 2px 0 0;
  list-style: none;
  background-color: #fff;
  border: 1px solid #ccc;
  border: 1px solid rgba(0, 0, 0, 0.2);
  *border-right-width: 2px;
  *border-bottom-width: 2px;
  border-radius: 6px;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
  -webkit-background-clip: padding-box;
  -moz-background-clip: padding;
  background-clip: padding-box;
}
.dropdown-menu.pull-right {
  right: 0;
  left: auto;
}
.dropdown-menu .divider {
  *width: 100%;
  height: 1px;
  margin: 9px 1px;
  *margin: -5px 0 5px;
  overflow: hidden;
  background-color: #e5e5e5;
  border-bottom: 1px solid #fff;
}
.dropdown-menu > li > a {
  display: block;
  padding: 3px 20px;
  clear: both;
  font-weight: normal;
  line-height: 120%;
  color: #B9BBBD;
  white-space: nowrap;
}
.dropdown-menu > li > a:hover,
.dropdown-menu > li > a:focus,
.dropdown-submenu:hover > a,
.dropdown-submenu:focus > a {
  text-decoration: none;
  color: #fff;
  background-color: #00a1db;
  background-image: linear-gradient(to bottom, hsl(196, 100%, 45%), hsl(196, 100%, 40%));
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ff00a8e6', endColorstr='#ff0096cc', GradientType=0);
}
.dropdown-menu > .active > a,
.dropdown-menu > .active > a:hover,
.dropdown-menu > .active > a:focus {
  color: #fff;
  text-decoration: none;
  outline: 0;
  background-color: #00a1db;
  background-image: linear-gradient(to bottom, hsl(196, 100%, 45%), hsl(196, 100%, 40%));
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ff00a8e6', endColorstr='#ff0096cc', GradientType=0);
}
.dropdown-menu > .disabled > a,
.dropdown-menu > .disabled > a:hover,
.dropdown-menu > .disabled > a:focus {
  color: #f2f2f2;
}
.dropdown-menu > .disabled > a:hover,
.dropdown-menu > .disabled > a:focus {
  text-decoration: none;
  background-color: transparent;
  background-image: none;
  filter: progid:DXImageTransform.Microsoft.gradient(enabled = false);
  cursor: default;
}
.open {
  *z-index: 1035;
}
.open > .dropdown-menu {
  display: block;
}
.pull-right > .dropdown-menu {
  right: 0;
  left: auto;
}
.dropup .caret,
.navbar-fixed-bottom .dropdown .caret {
  border-top: 0;
  border-bottom: 4px solid #000;
  content: "";
}
.dropup .dropdown-menu,
.navbar-fixed-bottom .dropdown .dropdown-menu {
  top: auto;
  bottom: 100%;
  margin-bottom: 1px;
}
.dropdown-submenu {
  position: relative;
}
.dropdown-submenu > .dropdown-menu {
  top: 0;
  left: 100%;
  margin-top: -6px;
  margin-left: -1px;
  border-radius: 0 6px 6px 6px;
}
.dropdown-submenu:hover > .dropdown-menu {
  display: block;
}
.dropup .dropdown-submenu > .dropdown-menu {
  top: auto;
  bottom: 0;
  margin-top: 0;
  margin-bottom: -2px;
  border-radius: 5px 5px 5px 0;
}
.dropdown-submenu > a:after {
  display: block;
  content: " ";
  float: right;
  width: 0;
  height: 0;
  border-color: transparent;
  border-style: solid;
  border-width: 5px 0 5px 5px;
  border-left-color: #000000;
  margin-top: 5px;
  margin-right: -10px;
}
.dropdown-submenu:hover > a:after {
  border-left-color: #fff;
}
.dropdown-submenu.pull-left {
  float: none;
}
.dropdown-submenu.pull-left > .dropdown-menu {
  left: -100%;
  margin-left: 10px;
  border-radius: 6px 0 6px 6px;
}
.dropdown .dropdown-menu .nav-header {
  padding-left: 20px;
  padding-right: 20px;
}
.typeahead {
  z-index: 1051;
  margin-top: 2px;
  border-radius: 0px;
}
.fade {
  opacity: 0;
  transition: opacity 0.15s linear;
}
.fade.in {
  opacity: 1;
}
.collapse {
  position: relative;
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease;
}
.collapse.in {
  height: auto;
}
.btn {
  display: inline-block;
  *display: inline;
  /* IE7 inline-block hack */
  *zoom: 1;
  padding: 8px 15px 8px;
  margin-bottom: 0;
  font-weight: 700;
  font-size: 16px;
  font-style: normal;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  color: #fff;
  background-color: #005E80;
  border: none;
  border-radius: 2px;
}
.btn:hover,
.btn:focus {
  color: #fff;
  background-color: #00ADEE;
  text-decoration: none;
  transition: background-position 0.1s linear;
}
.btn:focus {
  outline: thin dotted #333;
  outline: 5px auto -webkit-focus-ring-color;
  outline-offset: -2px;
}
.btn.active,
.btn:active {
  background-image: none;
}
.btn.disabled,
.btn[disabled] {
  cursor: default;
  background-image: none;
  opacity: 0.65;
  filter: alpha(opacity=65);
  box-shadow: none;
}
.btn-large {
  padding: 0.6875rem 1.1875rem;
  font-size: 19.008px;
  border-radius: 6px;
}
.btn-large [class^="icon-"],
.btn-large [class*=" icon-"] {
  margin-top: 4px;
}
.btn-small {
  padding: 0.125rem 0.625rem;
  font-size: 12px;
}
.btn-small [class^="icon-"],
.btn-small [class*=" icon-"] {
  margin-top: 0;
}
.btn-mini [class^="icon-"],
.btn-mini [class*=" icon-"] {
  margin-top: -1px;
}
.btn-mini {
  padding: 0 0.375rem;
  font-size: 10.8px;
  border-radius: 3px;
}
.btn-block {
  display: block;
  width: 100%;
  padding-left: 0;
  padding-right: 0;
  box-sizing: border-box;
}
.btn-block + .btn-block {
  margin-top: 5px;
}
input[type="submit"].btn-block,
input[type="reset"].btn-block,
input[type="button"].btn-block {
  width: 100%;
}
.btn-primary.active,
.btn-warning.active,
.btn-danger.active,
.btn-success.active,
.btn-info.active,
.btn-inverse.active {
  color: rgba(255, 255, 255, 0.75);
}
.btn-warning {
  color: #fff;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
  background-color: #faa732;
  background-image: linear-gradient(to bottom, #fbb450, #f89406);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#fffbb450', endColorstr='#fff89406', GradientType=0);
  border-color: #f89406 #f89406 #ad6704;
  border-color: rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.25);
  *background-color: #f89406;
  /* Darken IE7 buttons by default so they stand out more given they won't have borders */
  filter: progid:DXImageTransform.Microsoft.gradient(enabled = false);
}
.btn-warning:hover,
.btn-warning:focus,
.btn-warning:active,
.btn-warning.active,
.btn-warning.disabled,
.btn-warning[disabled] {
  color: #fff;
  background-color: #f89406;
  *background-color: #df8505;
}
.btn-warning:active,
.btn-warning.active {
  background-color: #c67605 \9;
}
.btn-danger {
  color: #fff;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
  background-color: #da4f49;
  background-image: linear-gradient(to bottom, #ee5f5b, #bd362f);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffee5f5b', endColorstr='#ffbd362f', GradientType=0);
  border-color: #bd362f #bd362f #802420;
  border-color: rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.25);
  *background-color: #bd362f;
  /* Darken IE7 buttons by default so they stand out more given they won't have borders */
  filter: progid:DXImageTransform.Microsoft.gradient(enabled = false);
}
.btn-danger:hover,
.btn-danger:focus,
.btn-danger:active,
.btn-danger.active,
.btn-danger.disabled,
.btn-danger[disabled] {
  color: #fff;
  background-color: #bd362f;
  *background-color: #a9302a;
}
.btn-danger:active,
.btn-danger.active {
  background-color: #942a25 \9;
}
.btn-success {
  color: #fff;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
  background-color: #5bb75b;
  background-image: linear-gradient(to bottom, #62c462, #51a351);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ff62c462', endColorstr='#ff51a351', GradientType=0);
  border-color: #51a351 #51a351 #387038;
  border-color: rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.25);
  *background-color: #51a351;
  /* Darken IE7 buttons by default so they stand out more given they won't have borders */
  filter: progid:DXImageTransform.Microsoft.gradient(enabled = false);
}
.btn-success:hover,
.btn-success:focus,
.btn-success:active,
.btn-success.active,
.btn-success.disabled,
.btn-success[disabled] {
  color: #fff;
  background-color: #51a351;
  *background-color: #499249;
}
.btn-success:active,
.btn-success.active {
  background-color: #408140 \9;
}
.btn-info {
  color: #fff;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
  background-color: #49afcd;
  background-image: linear-gradient(to bottom, #5bc0de, #2f96b4);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ff5bc0de', endColorstr='#ff2f96b4', GradientType=0);
  border-color: #2f96b4 #2f96b4 #1f6377;
  border-color: rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.25);
  *background-color: #2f96b4;
  /* Darken IE7 buttons by default so they stand out more given they won't have borders */
  filter: progid:DXImageTransform.Microsoft.gradient(enabled = false);
}
.btn-info:hover,
.btn-info:focus,
.btn-info:active,
.btn-info.active,
.btn-info.disabled,
.btn-info[disabled] {
  color: #fff;
  background-color: #2f96b4;
  *background-color: #2a85a0;
}
.btn-info:active,
.btn-info.active {
  background-color: #24748c \9;
}
.btn-inverse {
  color: #fff;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
  background-color: #6a6b6c;
  background-image: linear-gradient(to bottom, #444, #A4A6A9);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ff444444', endColorstr='#ffa4a6a9', GradientType=0);
  border-color: #A4A6A9 #A4A6A9 #7d8084;
  border-color: rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.25);
  *background-color: #A4A6A9;
  /* Darken IE7 buttons by default so they stand out more given they won't have borders */
  filter: progid:DXImageTransform.Microsoft.gradient(enabled = false);
}
.btn-inverse:hover,
.btn-inverse:focus,
.btn-inverse:active,
.btn-inverse.active,
.btn-inverse.disabled,
.btn-inverse[disabled] {
  color: #fff;
  background-color: #A4A6A9;
  *background-color: #97999d;
}
.btn-inverse:active,
.btn-inverse.active {
  background-color: #8a8c90 \9;
}
.btn-cancel {
  background-color: #bdbdbd;
}
.btn-cancel:hover,
.btn-cancel:focus {
  background-color: #00ADEE;
}
button.btn,
input[type="submit"].btn {
  *padding-top: 3px;
  *padding-bottom: 3px;
}
button.btn::-moz-focus-inner,
input[type="submit"].btn::-moz-focus-inner {
  padding: 0;
  border: 0;
}
button.btn.btn-large,
input[type="submit"].btn.btn-large {
  *padding-top: 7px;
  *padding-bottom: 7px;
}
button.btn.btn-small,
input[type="submit"].btn.btn-small {
  *padding-top: 3px;
  *padding-bottom: 3px;
}
button.btn.btn-mini,
input[type="submit"].btn.btn-mini {
  *padding-top: 1px;
  *padding-bottom: 1px;
}
.btn-link,
.btn-link:active,
.btn-link[disabled] {
  background-color: transparent;
  background-image: none;
  box-shadow: none;
}
.btn-link {
  border-color: transparent;
  cursor: pointer;
  color: hsl(196, 100%, 45%);
  border-radius: 0;
}
.btn-link:hover,
.btn-link:focus {
  color: hsl(196, 100%, 60%);
  text-decoration: underline;
  background-color: transparent;
  outline-style: none;
}
.btn-link[disabled]:hover,
.btn-link[disabled]:focus {
  color: #B9BBBD;
  text-decoration: none;
}
.btn-group {
  position: relative;
  display: inline-block;
  *display: inline;
  /* IE7 inline-block hack */
  *zoom: 1;
  font-size: 0;
  vertical-align: middle;
  white-space: nowrap;
  *margin-left: 0.3em;
}
.btn-group:first-child {
  *margin-left: 0;
}
.btn-group + .btn-group {
  margin-left: 5px;
}
.btn-toolbar {
  font-size: 0;
  margin-top: 10px;
  margin-bottom: 10px;
}
.btn-toolbar > .btn + .btn,
.btn-toolbar > .btn-group + .btn,
.btn-toolbar > .btn + .btn-group {
  margin-left: 5px;
}
.btn-group > .btn {
  position: relative;
  border-radius: 0;
}
.btn-group > .btn + .btn {
  margin-left: -1px;
}
.btn-group > .btn,
.btn-group > .dropdown-menu,
.btn-group > .popover {
  font-size: "ff-meta-web-pro-condensed", sans-serif;
}
.btn-group > .btn-mini {
  font-size: 10.8px;
}
.btn-group > .btn-small {
  font-size: 12px;
}
.btn-group > .btn-large {
  font-size: 19.008px;
}
.btn-group > .btn:first-child {
  margin-left: 0;
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
}
.btn-group > .btn:last-child,
.btn-group > .dropdown-toggle {
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
}
.btn-group > .btn.large:first-child {
  margin-left: 0;
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
}
.btn-group > .btn.large:last-child,
.btn-group > .large.dropdown-toggle {
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
}
.btn-group > .btn:hover,
.btn-group > .btn:focus,
.btn-group > .btn:active,
.btn-group > .btn.active {
  z-index: 2;
}
.btn-group .dropdown-toggle:active,
.btn-group.open .dropdown-toggle {
  outline: 0;
}
.btn-group > .btn + .dropdown-toggle {
  padding-left: 8px;
  padding-right: 8px;
  box-shadow: inset 1px 0 0 rgba(255,255,255,.125), inset 0 1px 0 rgba(255,255,255,.2), 0 1px 2px rgba(0,0,0,.05);
  *padding-top: 5px;
  *padding-bottom: 5px;
}
.btn-group > .btn-mini + .dropdown-toggle {
  padding-left: 5px;
  padding-right: 5px;
  *padding-top: 2px;
  *padding-bottom: 2px;
}
.btn-group > .btn-small + .dropdown-toggle {
  *padding-top: 5px;
  *padding-bottom: 4px;
}
.btn-group > .btn-large + .dropdown-toggle {
  padding-left: 12px;
  padding-right: 12px;
  *padding-top: 7px;
  *padding-bottom: 7px;
}
.btn-group.open .dropdown-toggle {
  background-image: none;
  box-shadow: inset 0 2px 4px rgba(0,0,0,.15), 0 1px 2px rgba(0,0,0,.05);
}
.btn-group.open .btn.dropdown-toggle {
  background-color: #e6e6e6;
}
.btn-group.open .btn-primary.dropdown-toggle {
  background-color: #003380;
}
.btn-group.open .btn-warning.dropdown-toggle {
  background-color: #f89406;
}
.btn-group.open .btn-danger.dropdown-toggle {
  background-color: #bd362f;
}
.btn-group.open .btn-success.dropdown-toggle {
  background-color: #51a351;
}
.btn-group.open .btn-info.dropdown-toggle {
  background-color: #2f96b4;
}
.btn-group.open .btn-inverse.dropdown-toggle {
  background-color: #A4A6A9;
}
.btn .caret {
  margin-top: 8px;
  margin-left: 0;
}
.btn-large .caret {
  margin-top: 6px;
}
.btn-large .caret {
  border-left-width: 5px;
  border-right-width: 5px;
  border-top-width: 5px;
}
.btn-mini .caret,
.btn-small .caret {
  margin-top: 8px;
}
.dropup .btn-large .caret {
  border-bottom-width: 5px;
}
.btn-primary .caret,
.btn-warning .caret,
.btn-danger .caret,
.btn-info .caret,
.btn-success .caret,
.btn-inverse .caret {
  border-top-color: #fff;
  border-bottom-color: #fff;
}
.btn-group-vertical {
  display: inline-block;
  *display: inline;
  /* IE7 inline-block hack */
  *zoom: 1;
}
.btn-group-vertical > .btn {
  display: block;
  float: none;
  max-width: 100%;
  border-radius: 0;
}
.btn-group-vertical > .btn + .btn {
  margin-left: 0;
  margin-top: -1px;
}
.btn-group-vertical > .btn:first-child {
  border-radius: 0px 0px 0 0;
}
.btn-group-vertical > .btn:last-child {
  border-radius: 0 0 0px 0px;
}
.btn-group-vertical > .btn-large:first-child {
  border-radius: 6px 6px 0 0;
}
.btn-group-vertical > .btn-large:last-child {
  border-radius: 0 0 6px 6px;
}
.alert {
  padding: 8px 35px 8px 14px;
  margin-bottom: 20px;
  text-shadow: 0 1px 0 rgba(255, 255, 255, 0.5);
  background-color: #fcf8e3;
  border: 1px solid #fbeed5;
  border-radius: 0px;
}
.alert,
.alert h4 {
  color: #c09853;
}
.alert h4 {
  margin: 0;
}
.alert .close {
  position: relative;
  top: -2px;
  right: -21px;
  line-height: 120%;
}
.alert-success {
  background-color: #dff0d8;
  border-color: #d6e9c6;
  color: #468847;
}
.alert-success h4 {
  color: #468847;
}
.alert-danger,
.alert-error {
  background-color: #f2dede;
  border-color: #eed3d7;
  color: #b94a48;
}
.alert-danger h4,
.alert-error h4 {
  color: #b94a48;
}
.alert-info {
  background-color: #d9edf7;
  border-color: #bce8f1;
  color: #3a87ad;
}
.alert-info h4 {
  color: #3a87ad;
}
.alert-block {
  padding-top: 14px;
  padding-bottom: 14px;
}
.alert-block > p,
.alert-block > ul {
  margin-bottom: 0;
}
.alert-block p + p {
  margin-top: 5px;
}
.nav {
  margin-left: 0;
  margin-bottom: 20px;
  list-style: none;
}
.nav > li > a {
  display: block;
}
.nav > li > a:hover,
.nav > li > a:focus {
  text-decoration: none;
  background-color: #929497;
}
.nav > li > a > img {
  max-width: none;
}
.nav > .pull-right {
  float: right;
}
.nav-header {
  display: block;
  padding: 3px 15px;
  font-size: 11px;
  font-weight: bold;
  line-height: 120%;
  color: #f2f2f2;
  text-shadow: 0 1px 0 rgba(255, 255, 255, 0.5);
  text-transform: uppercase;
}
.nav li + .nav-header {
  margin-top: 9px;
}
.nav-list {
  padding-left: 15px;
  padding-right: 15px;
  margin-bottom: 0;
}
.nav-list > li > a,
.nav-list .nav-header {
  margin-left: -15px;
  margin-right: -15px;
  text-shadow: 0 1px 0 rgba(255, 255, 255, 0.5);
}
.nav-list > li > a {
  padding: 3px 15px;
}
.nav-list > .active > a,
.nav-list > .active > a:hover,
.nav-list > .active > a:focus {
  color: #fff;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.2);
  background-color: hsl(196, 100%, 45%);
}
.nav-list [class^="icon-"],
.nav-list [class*=" icon-"] {
  margin-right: 2px;
}
.nav-list .divider {
  *width: 100%;
  height: 1px;
  margin: 9px 1px;
  *margin: -5px 0 5px;
  overflow: hidden;
  background-color: #e5e5e5;
  border-bottom: 1px solid #fff;
}
.nav-tabs,
.nav-pills {
  *zoom: 1;
}
.nav-tabs:before,
.nav-pills:before,
.nav-tabs:after,
.nav-pills:after {
  display: table;
  content: "";
  line-height: 0;
}
.nav-tabs:after,
.nav-pills:after {
  clear: both;
}
.nav-tabs > li,
.nav-pills > li {
  float: left;
}
.nav-tabs > li > a,
.nav-pills > li > a {
  padding-right: 12px;
  padding-left: 12px;
  margin-right: 2px;
  line-height: 14px;
}
.nav-tabs {
  border-bottom: 1px solid #ddd;
}
.nav-tabs > li {
  margin-bottom: -1px;
}
.nav-tabs > li > a {
  padding-top: 8px;
  padding-bottom: 8px;
  line-height: 120%;
  border: 1px solid transparent;
  border-radius: 4px 4px 0 0;
}
.nav-tabs > li > a:hover,
.nav-tabs > li > a:focus {
  border-color: #929497 #929497 #ddd;
}
.nav-tabs > .active > a,
.nav-tabs > .active > a:hover,
.nav-tabs > .active > a:focus {
  color: #6D6E70;
  background-color: #fff;
  border: 1px solid #ddd;
  border-bottom-color: transparent;
  cursor: default;
}
.nav-pills > li > a {
  padding-top: 8px;
  padding-bottom: 8px;
  margin-top: 2px;
  margin-bottom: 2px;
  border-radius: 5px;
}
.nav-pills > .active > a,
.nav-pills > .active > a:hover,
.nav-pills > .active > a:focus {
  color: #fff;
  background-color: hsl(196, 100%, 45%);
}
.nav-stacked > li {
  float: none;
}
.nav-stacked > li > a {
  margin-right: 0;
}
.nav-tabs.nav-stacked {
  border-bottom: 0;
}
.nav-tabs.nav-stacked > li > a {
  border: 1px solid #ddd;
  border-radius: 0;
}
.nav-tabs.nav-stacked > li:first-child > a {
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
}
.nav-tabs.nav-stacked > li:last-child > a {
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
}
.nav-tabs.nav-stacked > li > a:hover,
.nav-tabs.nav-stacked > li > a:focus {
  border-color: #ddd;
  z-index: 2;
}
.nav-pills.nav-stacked > li > a {
  margin-bottom: 3px;
}
.nav-pills.nav-stacked > li:last-child > a {
  margin-bottom: 1px;
}
.nav-tabs .dropdown-menu {
  border-radius: 0 0 6px 6px;
}
.nav-pills .dropdown-menu {
  border-radius: 6px;
}
.nav .dropdown-toggle .caret {
  border-top-color: hsl(196, 100%, 45%);
  border-bottom-color: hsl(196, 100%, 45%);
  margin-top: 6px;
}
.nav .dropdown-toggle:hover .caret,
.nav .dropdown-toggle:focus .caret {
  border-top-color: hsl(196, 100%, 60%);
  border-bottom-color: hsl(196, 100%, 60%);
}
/* move down carets for tabs */
.nav-tabs .dropdown-toggle .caret {
  margin-top: 8px;
}
.nav .active .dropdown-toggle .caret {
  border-top-color: #fff;
  border-bottom-color: #fff;
}
.nav-tabs .active .dropdown-toggle .caret {
  border-top-color: #6D6E70;
  border-bottom-color: #6D6E70;
}
.nav > .dropdown.active > a:hover,
.nav > .dropdown.active > a:focus {
  cursor: pointer;
}
.nav-tabs .open .dropdown-toggle,
.nav-pills .open .dropdown-toggle,
.nav > li.dropdown.open.active > a:hover,
.nav > li.dropdown.open.active > a:focus {
  color: #fff;
  background-color: #f2f2f2;
  border-color: #f2f2f2;
}
.nav li.dropdown.open .caret,
.nav li.dropdown.open.active .caret,
.nav li.dropdown.open a:hover .caret,
.nav li.dropdown.open a:focus .caret {
  border-top-color: #fff;
  border-bottom-color: #fff;
  opacity: 1;
  filter: alpha(opacity=100);
}
.tabs-stacked .open > a:hover,
.tabs-stacked .open > a:focus {
  border-color: #f2f2f2;
}
.tabbable {
  *zoom: 1;
}
.tabbable:before,
.tabbable:after {
  display: table;
  content: "";
  line-height: 0;
}
.tabbable:after {
  clear: both;
}
.tab-content {
  overflow: auto;
}
.tabs-below > .nav-tabs,
.tabs-right > .nav-tabs,
.tabs-left > .nav-tabs {
  border-bottom: 0;
}
.tab-content > .tab-pane,
.pill-content > .pill-pane {
  display: none;
}
.tab-content > .active,
.pill-content > .active {
  display: block;
}
.tabs-below > .nav-tabs {
  border-top: 1px solid #ddd;
}
.tabs-below > .nav-tabs > li {
  margin-top: -1px;
  margin-bottom: 0;
}
.tabs-below > .nav-tabs > li > a {
  border-radius: 0 0 4px 4px;
}
.tabs-below > .nav-tabs > li > a:hover,
.tabs-below > .nav-tabs > li > a:focus {
  border-bottom-color: transparent;
  border-top-color: #ddd;
}
.tabs-below > .nav-tabs > .active > a,
.tabs-below > .nav-tabs > .active > a:hover,
.tabs-below > .nav-tabs > .active > a:focus {
  border-color: transparent #ddd #ddd #ddd;
}
.tabs-left > .nav-tabs > li,
.tabs-right > .nav-tabs > li {
  float: none;
}
.tabs-left > .nav-tabs > li > a,
.tabs-right > .nav-tabs > li > a {
  min-width: 74px;
  margin-right: 0;
  margin-bottom: 3px;
}
.tabs-left > .nav-tabs {
  float: left;
  margin-right: 19px;
  border-right: 1px solid #ddd;
}
.tabs-left > .nav-tabs > li > a {
  margin-right: -1px;
  border-radius: 4px 0 0 4px;
}
.tabs-left > .nav-tabs > li > a:hover,
.tabs-left > .nav-tabs > li > a:focus {
  border-color: #929497 #ddd #929497 #929497;
}
.tabs-left > .nav-tabs .active > a,
.tabs-left > .nav-tabs .active > a:hover,
.tabs-left > .nav-tabs .active > a:focus {
  border-color: #ddd transparent #ddd #ddd;
  *border-right-color: #fff;
}
.tabs-right > .nav-tabs {
  float: right;
  margin-left: 19px;
  border-left: 1px solid #ddd;
}
.tabs-right > .nav-tabs > li > a {
  margin-left: -1px;
  border-radius: 0 4px 4px 0;
}
.tabs-right > .nav-tabs > li > a:hover,
.tabs-right > .nav-tabs > li > a:focus {
  border-color: #929497 #929497 #929497 #ddd;
}
.tabs-right > .nav-tabs .active > a,
.tabs-right > .nav-tabs .active > a:hover,
.tabs-right > .nav-tabs .active > a:focus {
  border-color: #ddd #ddd #ddd transparent;
  *border-left-color: #fff;
}
.nav > .disabled > a {
  color: #f2f2f2;
}
.nav > .disabled > a:hover,
.nav > .disabled > a:focus {
  text-decoration: none;
  background-color: transparent;
  cursor: default;
}
.navbar {
  overflow: visible;
  margin-bottom: 20px;
  *position: relative;
  *z-index: 2;
}
.navbar-inner {
  min-height: 2.5rem;
  padding-left: 20px;
  padding-right: 20px;
  background-color: #fafafa;
  background-image: linear-gradient(to bottom, #ffffff, #f2f2f2);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffffffff', endColorstr='#fff2f2f2', GradientType=0);
  border: 1px solid #d4d4d4;
  border-radius: 0px;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.065);
  *zoom: 1;
}
.navbar-inner:before,
.navbar-inner:after {
  display: table;
  content: "";
  line-height: 0;
}
.navbar-inner:after {
  clear: both;
}
.navbar .container {
  width: auto;
}
.nav-collapse.collapse {
  height: auto;
  overflow: visible;
}
.navbar .brand {
  float: left;
  display: block;
  padding: -8.75rem 20px -8.75rem;
  margin-left: -20px;
  font-size: 20px;
  font-weight: 200;
  color: #777;
  text-shadow: 0 1px 0 #ffffff;
}
.navbar .brand:hover,
.navbar .brand:focus {
  text-decoration: none;
}
.navbar-text {
  margin-bottom: 0;
  line-height: 2.5rem;
  color: #777;
}
.navbar-link {
  color: #777;
}
.navbar-link:hover,
.navbar-link:focus {
  color: #B9BBBD;
}
.navbar .divider-vertical {
  height: 2.5rem;
  margin: 0 9px;
  border-left: 1px solid #f2f2f2;
  border-right: 1px solid #ffffff;
}
.navbar .btn,
.navbar .btn-group {
  margin-top: -13.75rem;
}
.navbar .btn-group .btn,
.navbar .input-prepend .btn,
.navbar .input-append .btn,
.navbar .input-prepend .btn-group,
.navbar .input-append .btn-group {
  margin-top: 0;
}
.navbar-form {
  margin-bottom: 0;
  *zoom: 1;
}
.navbar-form:before,
.navbar-form:after {
  display: table;
  content: "";
  line-height: 0;
}
.navbar-form:after {
  clear: both;
}
.navbar-form input,
.navbar-form select,
.navbar-form .radio,
.navbar-form .checkbox {
  margin-top: -13.75rem;
}
.navbar-form input,
.navbar-form select,
.navbar-form .btn {
  display: inline-block;
  margin-bottom: 0;
}
.navbar-form input[type="image"],
.navbar-form input[type="checkbox"],
.navbar-form input[type="radio"] {
  margin-top: 3px;
}
.navbar-form .input-append,
.navbar-form .input-prepend {
  margin-top: 5px;
  white-space: nowrap;
}
.navbar-form .input-append input,
.navbar-form .input-prepend input {
  margin-top: 0;
}
.navbar-search {
  position: relative;
  float: left;
  margin-top: -13.75rem;
  margin-bottom: 0;
}
.navbar-search .search-query {
  margin-bottom: 0;
  padding: 4px 14px;
  font-family: "ff-meta-web-pro", sans-serif;
  font-size: 13px;
  font-weight: normal;
  line-height: 1;
  border-radius: 15px;
}
.navbar-static-top {
  position: static;
  margin-bottom: 0;
}
.navbar-static-top .navbar-inner {
  border-radius: 0;
}
.navbar-fixed-top,
.navbar-fixed-bottom {
  position: fixed;
  right: 0;
  left: 0;
  z-index: 1030;
  margin-bottom: 0;
}
.navbar-fixed-top .navbar-inner,
.navbar-static-top .navbar-inner {
  border-width: 0 0 1px;
}
.navbar-fixed-bottom .navbar-inner {
  border-width: 1px 0 0;
}
.navbar-fixed-top .navbar-inner,
.navbar-fixed-bottom .navbar-inner {
  padding-left: 0;
  padding-right: 0;
  border-radius: 0;
}
.navbar-fixed-top {
  top: 0;
}
.navbar-fixed-top .navbar-inner,
.navbar-static-top .navbar-inner {
  box-shadow: 0 1px 10px rgba(0,0,0,.1);
}
.navbar-fixed-bottom {
  bottom: 0;
}
.navbar-fixed-bottom .navbar-inner {
  box-shadow: 0 -1px 10px rgba(0,0,0,.1);
}
.navbar .nav {
  position: relative;
  left: 0;
  display: block;
  float: left;
  margin: 0 10px 0 0;
}
.navbar .nav.pull-right {
  float: right;
  margin-right: 0;
}
.navbar .nav > li {
  float: left;
}
.navbar .nav > li > a {
  float: none;
  padding: -8.75rem 15px -8.75rem;
  color: #777;
  text-decoration: none;
  text-shadow: 0 1px 0 #ffffff;
}
.navbar .nav .dropdown-toggle .caret {
  margin-top: 8px;
}
.navbar .nav > li > a:focus,
.navbar .nav > li > a:hover {
  background-color: transparent;
  color: #B9BBBD;
  text-decoration: none;
}
.navbar .nav > .active > a,
.navbar .nav > .active > a:hover,
.navbar .nav > .active > a:focus {
  color: #6D6E70;
  text-decoration: none;
  background-color: #e5e5e5;
  box-shadow: inset 0 3px 8px rgba(0, 0, 0, 0.125);
}
.navbar .btn-navbar {
  display: none;
  float: right;
  padding: 7px 10px;
  margin-left: 5px;
  margin-right: 5px;
  color: #fff;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
  background-color: #ededed;
  background-image: linear-gradient(to bottom, #f2f2f2, #e5e5e5);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#fff2f2f2', endColorstr='#ffe5e5e5', GradientType=0);
  border-color: #e5e5e5 #e5e5e5 #bfbfbf;
  border-color: rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.25);
  *background-color: #e5e5e5;
  /* Darken IE7 buttons by default so they stand out more given they won't have borders */
  filter: progid:DXImageTransform.Microsoft.gradient(enabled = false);
  box-shadow: inset 0 1px 0 rgba(255,255,255,.1), 0 1px 0 rgba(255,255,255,.075);
}
.navbar .btn-navbar:hover,
.navbar .btn-navbar:focus,
.navbar .btn-navbar:active,
.navbar .btn-navbar.active,
.navbar .btn-navbar.disabled,
.navbar .btn-navbar[disabled] {
  color: #fff;
  background-color: #e5e5e5;
  *background-color: #d9d9d9;
}
.navbar .btn-navbar:active,
.navbar .btn-navbar.active {
  background-color: #cccccc \9;
}
.navbar .btn-navbar .icon-bar {
  display: block;
  width: 18px;
  height: 2px;
  background-color: #f5f5f5;
  border-radius: 1px;
  box-shadow: 0 1px 0 rgba(0, 0, 0, 0.25);
}
.btn-navbar .icon-bar + .icon-bar {
  margin-top: 3px;
}
.navbar .nav > li > .dropdown-menu:before {
  content: '';
  display: inline-block;
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
  border-bottom: 7px solid #ccc;
  border-bottom-color: rgba(0, 0, 0, 0.2);
  position: absolute;
  top: -7px;
  left: 9px;
}
.navbar .nav > li > .dropdown-menu:after {
  content: '';
  display: inline-block;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-bottom: 6px solid #fff;
  position: absolute;
  top: -6px;
  left: 10px;
}
.navbar-fixed-bottom .nav > li > .dropdown-menu:before {
  border-top: 7px solid #ccc;
  border-top-color: rgba(0, 0, 0, 0.2);
  border-bottom: 0;
  bottom: -7px;
  top: auto;
}
.navbar-fixed-bottom .nav > li > .dropdown-menu:after {
  border-top: 6px solid #fff;
  border-bottom: 0;
  bottom: -6px;
  top: auto;
}
.navbar .nav li.dropdown > a:hover .caret,
.navbar .nav li.dropdown > a:focus .caret {
  border-top-color: #B9BBBD;
  border-bottom-color: #B9BBBD;
}
.navbar .nav li.dropdown.open > .dropdown-toggle,
.navbar .nav li.dropdown.active > .dropdown-toggle,
.navbar .nav li.dropdown.open.active > .dropdown-toggle {
  background-color: #e5e5e5;
  color: #6D6E70;
}
.navbar .nav li.dropdown > .dropdown-toggle .caret {
  border-top-color: #777;
  border-bottom-color: #777;
}
.navbar .nav li.dropdown.open > .dropdown-toggle .caret,
.navbar .nav li.dropdown.active > .dropdown-toggle .caret,
.navbar .nav li.dropdown.open.active > .dropdown-toggle .caret {
  border-top-color: #6D6E70;
  border-bottom-color: #6D6E70;
}
.navbar .pull-right > li > .dropdown-menu,
.navbar .nav > li > .dropdown-menu.pull-right {
  left: auto;
  right: 0;
}
.navbar .pull-right > li > .dropdown-menu:before,
.navbar .nav > li > .dropdown-menu.pull-right:before {
  left: auto;
  right: 12px;
}
.navbar .pull-right > li > .dropdown-menu:after,
.navbar .nav > li > .dropdown-menu.pull-right:after {
  left: auto;
  right: 13px;
}
.navbar .pull-right > li > .dropdown-menu .dropdown-menu,
.navbar .nav > li > .dropdown-menu.pull-right .dropdown-menu {
  left: auto;
  right: 100%;
  margin-left: 0;
  margin-right: -1px;
  border-radius: 6px 0 6px 6px;
}
.navbar-inverse .navbar-inner {
  background-color: #1b1b1b;
  background-image: linear-gradient(to bottom, #222222, #111111);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ff222222', endColorstr='#ff111111', GradientType=0);
  border-color: #252525;
}
.navbar-inverse .brand,
.navbar-inverse .nav > li > a {
  color: #f2f2f2;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
}
.navbar-inverse .brand:hover,
.navbar-inverse .nav > li > a:hover,
.navbar-inverse .brand:focus,
.navbar-inverse .nav > li > a:focus {
  color: #fff;
}
.navbar-inverse .brand {
  color: #f2f2f2;
}
.navbar-inverse .navbar-text {
  color: #f2f2f2;
}
.navbar-inverse .nav > li > a:focus,
.navbar-inverse .nav > li > a:hover {
  background-color: transparent;
  color: #fff;
}
.navbar-inverse .nav .active > a,
.navbar-inverse .nav .active > a:hover,
.navbar-inverse .nav .active > a:focus {
  color: #fff;
  background-color: #111111;
}
.navbar-inverse .navbar-link {
  color: #f2f2f2;
}
.navbar-inverse .navbar-link:hover,
.navbar-inverse .navbar-link:focus {
  color: #fff;
}
.navbar-inverse .divider-vertical {
  border-left-color: #111111;
  border-right-color: #222222;
}
.navbar-inverse .nav li.dropdown.open > .dropdown-toggle,
.navbar-inverse .nav li.dropdown.active > .dropdown-toggle,
.navbar-inverse .nav li.dropdown.open.active > .dropdown-toggle {
  background-color: #111111;
  color: #fff;
}
.navbar-inverse .nav li.dropdown > a:hover .caret,
.navbar-inverse .nav li.dropdown > a:focus .caret {
  border-top-color: #fff;
  border-bottom-color: #fff;
}
.navbar-inverse .nav li.dropdown > .dropdown-toggle .caret {
  border-top-color: #f2f2f2;
  border-bottom-color: #f2f2f2;
}
.navbar-inverse .nav li.dropdown.open > .dropdown-toggle .caret,
.navbar-inverse .nav li.dropdown.active > .dropdown-toggle .caret,
.navbar-inverse .nav li.dropdown.open.active > .dropdown-toggle .caret {
  border-top-color: #fff;
  border-bottom-color: #fff;
}
.navbar-inverse .navbar-search .search-query {
  color: #fff;
  background-color: #515151;
  border-color: #111111;
  box-shadow: inset 0 1px 2px rgba(0,0,0,.1), 0 1px 0 rgba(255,255,255,.15);
  transition: none;
}
.navbar-inverse .navbar-search .search-query:-moz-placeholder {
  color: #ccc;
}
.navbar-inverse .navbar-search .search-query:-ms-input-placeholder {
  color: #ccc;
}
.navbar-inverse .navbar-search .search-query::-webkit-input-placeholder {
  color: #ccc;
}
.navbar-inverse .navbar-search .search-query:focus,
.navbar-inverse .navbar-search .search-query.focused {
  padding: 5px 15px;
  color: #B9BBBD;
  text-shadow: 0 1px 0 #fff;
  background-color: #fff;
  border: 0;
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.15);
  outline: 0;
}
.navbar-inverse .btn-navbar {
  color: #fff;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
  background-color: #0e0e0e;
  background-image: linear-gradient(to bottom, #151515, #040404);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ff151515', endColorstr='#ff040404', GradientType=0);
  border-color: #040404 #040404 #000000;
  border-color: rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.25);
  *background-color: #040404;
  /* Darken IE7 buttons by default so they stand out more given they won't have borders */
  filter: progid:DXImageTransform.Microsoft.gradient(enabled = false);
}
.navbar-inverse .btn-navbar:hover,
.navbar-inverse .btn-navbar:focus,
.navbar-inverse .btn-navbar:active,
.navbar-inverse .btn-navbar.active,
.navbar-inverse .btn-navbar.disabled,
.navbar-inverse .btn-navbar[disabled] {
  color: #fff;
  background-color: #040404;
  *background-color: #000000;
}
.navbar-inverse .btn-navbar:active,
.navbar-inverse .btn-navbar.active {
  background-color: #000000 \9;
}
.modal-backdrop {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1040;
  background-color: #000;
}
.modal-backdrop.fade {
  opacity: 0;
}
.modal-backdrop,
.modal-backdrop.fade.in {
  opacity: 0.8;
  filter: alpha(opacity=80);
}
.modal {
  position: fixed;
  top: 10%;
  left: 50%;
  z-index: 1050;
  width: 480px;
  margin-left: -225px;
  background-color: #fff;
  border: 1px solid #999;
  border: 1px solid rgba(0, 0, 0, 0.3);
  *border: 1px solid #999;
  /* IE6-7 */
  box-shadow: 0 3px 7px rgba(0, 0, 0, 0.3);
  -webkit-background-clip: padding-box;
  -moz-background-clip: padding-box;
  background-clip: padding-box;
  outline: none;
}
.modal.fade {
  transition: opacity .3s linear, top .3s ease-out;
  top: -25%;
}
.modal.fade.in {
  top: 2%;
}
.modal-header {
  padding-top: 1rem;
  padding-bottom: 1rem;
  padding-left: 1rem;
  padding-right: 1rem;
}
.modal-header .close {
  margin-top: 2px;
}
.modal-header h3 {
  margin: 0;
  size: 1.3125em;
  line-height: 125%;
}
.modal-body {
  position: relative;
  overflow-y: auto;
  padding-top: 1rem;
  padding-bottom: 1rem;
  padding-left: 1rem;
  padding-right: 1rem;
}
.modal-form {
  margin-bottom: 0;
}
.modal-footer {
  padding-top: 1rem;
  padding-bottom: 1rem;
  padding-left: 1rem;
  padding-right: 1rem;
  margin-bottom: 0;
  background-color: #fff;
  border-radius: 0 0 6px 6px;
  box-shadow: inset 0 1px 0 #fff;
  *zoom: 1;
}
.modal-footer:before,
.modal-footer:after {
  display: table;
  content: "";
  line-height: 0;
}
.modal-footer:after {
  clear: both;
}
.modal-footer .btn + .btn {
  margin-left: 5px;
  margin-bottom: 0;
}
.modal-footer .btn-group .btn + .btn {
  margin-left: -1px;
}
.modal-footer .btn-block + .btn-block {
  margin-left: 0;
}
.pull-right {
  float: right;
}
.pull-left {
  float: left;
}
.hide {
  display: none;
}
.show {
  display: block;
}
.invisible {
  visibility: hidden;
}
.affix {
  position: fixed;
}

/*!
 * Bootstrap Responsive v2.3.1
 *
 * Copyright 2012 Twitter, Inc
 * Licensed under the Apache License v2.0
 * http://www.apache.org/licenses/LICENSE-2.0
 *
 * Designed and built with all the love in the world @twitter by @mdo and @fat.
 */
.clearfix {
  *zoom: 1;
}
.clearfix:before,
.clearfix:after {
  display: table;
  content: "";
  line-height: 0;
}
.clearfix:after {
  clear: both;
}
.hide-text {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
}
.input-block-level {
  display: block;
  width: 100%;
  min-height: 1.875rem;
  box-sizing: border-box;
}
@media (min-width: 1200px) {
  .row {
    margin-left: -16px;
    *zoom: 1;
  }
  .row:before,
  .row:after {
    display: table;
    content: "";
    line-height: 0;
  }
  .row:after {
    clear: both;
  }
  [class*="span"] {
    float: left;
    min-height: 1px;
    margin-left: 16px;
  }
  .container,
  .navbar-static-top .container,
  .navbar-fixed-top .container,
  .navbar-fixed-bottom .container {
    width: 1184px;
  }
  .span12 {
    width: 1184px;
  }
  .span11 {
    width: 1084px;
  }
  .span10 {
    width: 984px;
  }
  .span9 {
    width: 884px;
  }
  .span8 {
    width: 784px;
  }
  .span7 {
    width: 684px;
  }
  .span6 {
    width: 584px;
  }
  .span5 {
    width: 484px;
  }
  .span4 {
    width: 384px;
  }
  .span3 {
    width: 284px;
  }
  .span2 {
    width: 184px;
  }
  .span1 {
    width: 84px;
  }
  .offset12 {
    margin-left: 1216px;
  }
  .offset11 {
    margin-left: 1116px;
  }
  .offset10 {
    margin-left: 1016px;
  }
  .offset9 {
    margin-left: 916px;
  }
  .offset8 {
    margin-left: 816px;
  }
  .offset7 {
    margin-left: 716px;
  }
  .offset6 {
    margin-left: 616px;
  }
  .offset5 {
    margin-left: 516px;
  }
  .offset4 {
    margin-left: 416px;
  }
  .offset3 {
    margin-left: 316px;
  }
  .offset2 {
    margin-left: 216px;
  }
  .offset1 {
    margin-left: 116px;
  }
  .row-fluid {
    width: 100%;
    *zoom: 1;
  }
  .row-fluid:before,
  .row-fluid:after {
    display: table;
    content: "";
    line-height: 0;
  }
  .row-fluid:after {
    clear: both;
  }
  .row-fluid [class*="span"] {
    display: block;
    width: 100%;
    min-height: 1.875rem;
    box-sizing: border-box;
    float: left;
    margin-left: 1.35135135%;
    *margin-left: 1.29969846%;
  }
  .row-fluid [class*="span"]:first-child {
    margin-left: 0;
  }
  .row-fluid .controls-row [class*="span"] + [class*="span"] {
    margin-left: 1.35135135%;
  }
  .row-fluid .span12 {
    width: 100%;
    *width: 99.94834711%;
  }
  .row-fluid .span11 {
    width: 91.55405405%;
    *width: 91.50240116%;
  }
  .row-fluid .span10 {
    width: 83.10810811%;
    *width: 83.05645522%;
  }
  .row-fluid .span9 {
    width: 74.66216216%;
    *width: 74.61050927%;
  }
  .row-fluid .span8 {
    width: 66.21621622%;
    *width: 66.16456332%;
  }
  .row-fluid .span7 {
    width: 57.77027027%;
    *width: 57.71861738%;
  }
  .row-fluid .span6 {
    width: 49.32432432%;
    *width: 49.27267143%;
  }
  .row-fluid .span5 {
    width: 40.87837838%;
    *width: 40.82672549%;
  }
  .row-fluid .span4 {
    width: 32.43243243%;
    *width: 32.38077954%;
  }
  .row-fluid .span3 {
    width: 23.98648649%;
    *width: 23.93483359%;
  }
  .row-fluid .span2 {
    width: 15.54054054%;
    *width: 15.48888765%;
  }
  .row-fluid .span1 {
    width: 7.09459459%;
    *width: 7.0429417%;
  }
  .row-fluid .offset12 {
    margin-left: 102.7027027%;
    *margin-left: 102.59939692%;
  }
  .row-fluid .offset12:first-child {
    margin-left: 101.35135135%;
    *margin-left: 101.24804557%;
  }
  .row-fluid .offset11 {
    margin-left: 94.25675676%;
    *margin-left: 94.15345097%;
  }
  .row-fluid .offset11:first-child {
    margin-left: 92.90540541%;
    *margin-left: 92.80209962%;
  }
  .row-fluid .offset10 {
    margin-left: 85.81081081%;
    *margin-left: 85.70750503%;
  }
  .row-fluid .offset10:first-child {
    margin-left: 84.45945946%;
    *margin-left: 84.35615367%;
  }
  .row-fluid .offset9 {
    margin-left: 77.36486486%;
    *margin-left: 77.26155908%;
  }
  .row-fluid .offset9:first-child {
    margin-left: 76.01351351%;
    *margin-left: 75.91020773%;
  }
  .row-fluid .offset8 {
    margin-left: 68.91891892%;
    *margin-left: 68.81561313%;
  }
  .row-fluid .offset8:first-child {
    margin-left: 67.56756757%;
    *margin-left: 67.46426178%;
  }
  .row-fluid .offset7 {
    margin-left: 60.47297297%;
    *margin-left: 60.36966719%;
  }
  .row-fluid .offset7:first-child {
    margin-left: 59.12162162%;
    *margin-left: 59.01831584%;
  }
  .row-fluid .offset6 {
    margin-left: 52.02702703%;
    *margin-left: 51.92372124%;
  }
  .row-fluid .offset6:first-child {
    margin-left: 50.67567568%;
    *margin-left: 50.57236989%;
  }
  .row-fluid .offset5 {
    margin-left: 43.58108108%;
    *margin-left: 43.4777753%;
  }
  .row-fluid .offset5:first-child {
    margin-left: 42.22972973%;
    *margin-left: 42.12642394%;
  }
  .row-fluid .offset4 {
    margin-left: 35.13513514%;
    *margin-left: 35.03182935%;
  }
  .row-fluid .offset4:first-child {
    margin-left: 33.78378378%;
    *margin-left: 33.680478%;
  }
  .row-fluid .offset3 {
    margin-left: 26.68918919%;
    *margin-left: 26.5858834%;
  }
  .row-fluid .offset3:first-child {
    margin-left: 25.33783784%;
    *margin-left: 25.23453205%;
  }
  .row-fluid .offset2 {
    margin-left: 18.24324324%;
    *margin-left: 18.13993746%;
  }
  .row-fluid .offset2:first-child {
    margin-left: 16.89189189%;
    *margin-left: 16.78858611%;
  }
  .row-fluid .offset1 {
    margin-left: 9.7972973%;
    *margin-left: 9.69399151%;
  }
  .row-fluid .offset1:first-child {
    margin-left: 8.44594595%;
    *margin-left: 8.34264016%;
  }
  input,
  textarea,
  .uneditable-input {
    margin-left: 0;
  }
  .controls-row [class*="span"] + [class*="span"] {
    margin-left: 16px;
  }
  input.span12,
  textarea.span12,
  .uneditable-input.span12 {
    width: 1170px;
  }
  input.span11,
  textarea.span11,
  .uneditable-input.span11 {
    width: 1070px;
  }
  input.span10,
  textarea.span10,
  .uneditable-input.span10 {
    width: 970px;
  }
  input.span9,
  textarea.span9,
  .uneditable-input.span9 {
    width: 870px;
  }
  input.span8,
  textarea.span8,
  .uneditable-input.span8 {
    width: 770px;
  }
  input.span7,
  textarea.span7,
  .uneditable-input.span7 {
    width: 670px;
  }
  input.span6,
  textarea.span6,
  .uneditable-input.span6 {
    width: 570px;
  }
  input.span5,
  textarea.span5,
  .uneditable-input.span5 {
    width: 470px;
  }
  input.span4,
  textarea.span4,
  .uneditable-input.span4 {
    width: 370px;
  }
  input.span3,
  textarea.span3,
  .uneditable-input.span3 {
    width: 270px;
  }
  input.span2,
  textarea.span2,
  .uneditable-input.span2 {
    width: 170px;
  }
  input.span1,
  textarea.span1,
  .uneditable-input.span1 {
    width: 70px;
  }
  .thumbnails {
    margin-left: -16px;
  }
  .thumbnails > li {
    margin-left: 16px;
  }
  .row-fluid .thumbnails {
    margin-left: 0;
  }
}
@media (min-width: 768px) and (max-width: 979px) {
  .row {
    margin-left: -16px;
    *zoom: 1;
  }
  .row:before,
  .row:after {
    display: table;
    content: "";
    line-height: 0;
  }
  .row:after {
    clear: both;
  }
  [class*="span"] {
    float: left;
    min-height: 1px;
    margin-left: 16px;
  }
  .container,
  .navbar-static-top .container,
  .navbar-fixed-top .container,
  .navbar-fixed-bottom .container {
    width: 752px;
  }
  .span12 {
    width: 752px;
  }
  .span11 {
    width: 688px;
  }
  .span10 {
    width: 624px;
  }
  .span9 {
    width: 560px;
  }
  .span8 {
    width: 496px;
  }
  .span7 {
    width: 432px;
  }
  .span6 {
    width: 368px;
  }
  .span5 {
    width: 304px;
  }
  .span4 {
    width: 240px;
  }
  .span3 {
    width: 176px;
  }
  .span2 {
    width: 112px;
  }
  .span1 {
    width: 48px;
  }
  .offset12 {
    margin-left: 784px;
  }
  .offset11 {
    margin-left: 720px;
  }
  .offset10 {
    margin-left: 656px;
  }
  .offset9 {
    margin-left: 592px;
  }
  .offset8 {
    margin-left: 528px;
  }
  .offset7 {
    margin-left: 464px;
  }
  .offset6 {
    margin-left: 400px;
  }
  .offset5 {
    margin-left: 336px;
  }
  .offset4 {
    margin-left: 272px;
  }
  .offset3 {
    margin-left: 208px;
  }
  .offset2 {
    margin-left: 144px;
  }
  .offset1 {
    margin-left: 80px;
  }
  .row-fluid {
    width: 100%;
    *zoom: 1;
  }
  .row-fluid:before,
  .row-fluid:after {
    display: table;
    content: "";
    line-height: 0;
  }
  .row-fluid:after {
    clear: both;
  }
  .row-fluid [class*="span"] {
    display: block;
    width: 100%;
    min-height: 1.875rem;
    box-sizing: border-box;
    float: left;
    margin-left: 2.12765957%;
    *margin-left: 2.07600668%;
  }
  .row-fluid [class*="span"]:first-child {
    margin-left: 0;
  }
  .row-fluid .controls-row [class*="span"] + [class*="span"] {
    margin-left: 2.12765957%;
  }
  .row-fluid .span12 {
    width: 100%;
    *width: 99.94834711%;
  }
  .row-fluid .span11 {
    width: 91.4893617%;
    *width: 91.43770881%;
  }
  .row-fluid .span10 {
    width: 82.9787234%;
    *width: 82.92707051%;
  }
  .row-fluid .span9 {
    width: 74.46808511%;
    *width: 74.41643221%;
  }
  .row-fluid .span8 {
    width: 65.95744681%;
    *width: 65.90579392%;
  }
  .row-fluid .span7 {
    width: 57.44680851%;
    *width: 57.39515562%;
  }
  .row-fluid .span6 {
    width: 48.93617021%;
    *width: 48.88451732%;
  }
  .row-fluid .span5 {
    width: 40.42553191%;
    *width: 40.37387902%;
  }
  .row-fluid .span4 {
    width: 31.91489362%;
    *width: 31.86324072%;
  }
  .row-fluid .span3 {
    width: 23.40425532%;
    *width: 23.35260243%;
  }
  .row-fluid .span2 {
    width: 14.89361702%;
    *width: 14.84196413%;
  }
  .row-fluid .span1 {
    width: 6.38297872%;
    *width: 6.33132583%;
  }
  .row-fluid .offset12 {
    margin-left: 104.25531915%;
    *margin-left: 104.15201336%;
  }
  .row-fluid .offset12:first-child {
    margin-left: 102.12765957%;
    *margin-left: 102.02435379%;
  }
  .row-fluid .offset11 {
    margin-left: 95.74468085%;
    *margin-left: 95.64137507%;
  }
  .row-fluid .offset11:first-child {
    margin-left: 93.61702128%;
    *margin-left: 93.51371549%;
  }
  .row-fluid .offset10 {
    margin-left: 87.23404255%;
    *margin-left: 87.13073677%;
  }
  .row-fluid .offset10:first-child {
    margin-left: 85.10638298%;
    *margin-left: 85.00307719%;
  }
  .row-fluid .offset9 {
    margin-left: 78.72340426%;
    *margin-left: 78.62009847%;
  }
  .row-fluid .offset9:first-child {
    margin-left: 76.59574468%;
    *margin-left: 76.4924389%;
  }
  .row-fluid .offset8 {
    margin-left: 70.21276596%;
    *margin-left: 70.10946017%;
  }
  .row-fluid .offset8:first-child {
    margin-left: 68.08510638%;
    *margin-left: 67.9818006%;
  }
  .row-fluid .offset7 {
    margin-left: 61.70212766%;
    *margin-left: 61.59882187%;
  }
  .row-fluid .offset7:first-child {
    margin-left: 59.57446809%;
    *margin-left: 59.4711623%;
  }
  .row-fluid .offset6 {
    margin-left: 53.19148936%;
    *margin-left: 53.08818358%;
  }
  .row-fluid .offset6:first-child {
    margin-left: 51.06382979%;
    *margin-left: 50.960524%;
  }
  .row-fluid .offset5 {
    margin-left: 44.68085106%;
    *margin-left: 44.57754528%;
  }
  .row-fluid .offset5:first-child {
    margin-left: 42.55319149%;
    *margin-left: 42.4498857%;
  }
  .row-fluid .offset4 {
    margin-left: 36.17021277%;
    *margin-left: 36.06690698%;
  }
  .row-fluid .offset4:first-child {
    margin-left: 34.04255319%;
    *margin-left: 33.93924741%;
  }
  .row-fluid .offset3 {
    margin-left: 27.65957447%;
    *margin-left: 27.55626868%;
  }
  .row-fluid .offset3:first-child {
    margin-left: 25.53191489%;
    *margin-left: 25.42860911%;
  }
  .row-fluid .offset2 {
    margin-left: 19.14893617%;
    *margin-left: 19.04563039%;
  }
  .row-fluid .offset2:first-child {
    margin-left: 17.0212766%;
    *margin-left: 16.91797081%;
  }
  .row-fluid .offset1 {
    margin-left: 10.63829787%;
    *margin-left: 10.53499209%;
  }
  .row-fluid .offset1:first-child {
    margin-left: 8.5106383%;
    *margin-left: 8.40733251%;
  }
  input,
  textarea,
  .uneditable-input {
    margin-left: 0;
  }
  .controls-row [class*="span"] + [class*="span"] {
    margin-left: 16px;
  }
  input.span12,
  textarea.span12,
  .uneditable-input.span12 {
    width: 738px;
  }
  input.span11,
  textarea.span11,
  .uneditable-input.span11 {
    width: 674px;
  }
  input.span10,
  textarea.span10,
  .uneditable-input.span10 {
    width: 610px;
  }
  input.span9,
  textarea.span9,
  .uneditable-input.span9 {
    width: 546px;
  }
  input.span8,
  textarea.span8,
  .uneditable-input.span8 {
    width: 482px;
  }
  input.span7,
  textarea.span7,
  .uneditable-input.span7 {
    width: 418px;
  }
  input.span6,
  textarea.span6,
  .uneditable-input.span6 {
    width: 354px;
  }
  input.span5,
  textarea.span5,
  .uneditable-input.span5 {
    width: 290px;
  }
  input.span4,
  textarea.span4,
  .uneditable-input.span4 {
    width: 226px;
  }
  input.span3,
  textarea.span3,
  .uneditable-input.span3 {
    width: 162px;
  }
  input.span2,
  textarea.span2,
  .uneditable-input.span2 {
    width: 98px;
  }
  input.span1,
  textarea.span1,
  .uneditable-input.span1 {
    width: 34px;
  }
}
@media (max-width: 767px) {
  body {
    padding-left: 20px;
    padding-right: 20px;
  }
  .navbar-fixed-top,
  .navbar-fixed-bottom,
  .navbar-static-top {
    margin-left: -20px;
    margin-right: -20px;
  }
  .container-fluid {
    padding: 0;
  }
  .dl-horizontal dt {
    float: none;
    clear: none;
    width: auto;
    text-align: left;
  }
  .dl-horizontal dd {
    margin-left: 0;
  }
  .container {
    width: auto;
  }
  .row-fluid {
    width: 100%;
  }
  .row,
  .thumbnails {
    margin-left: 0;
  }
  .thumbnails > li {
    float: none;
    margin-left: 0;
  }
  [class*="span"],
  .uneditable-input[class*="span"],
  .row-fluid [class*="span"] {
    float: none;
    display: block;
    width: 100%;
    margin-left: 0;
    box-sizing: border-box;
  }
  .span12,
  .row-fluid .span12 {
    width: 100%;
    box-sizing: border-box;
  }
  .row-fluid [class*="offset"]:first-child {
    margin-left: 0;
  }
  .input-large,
  .input-xlarge,
  .input-xxlarge,
  input[class*="span"],
  select[class*="span"],
  textarea[class*="span"],
  .uneditable-input {
    display: block;
    width: 100%;
    min-height: 1.875rem;
    box-sizing: border-box;
  }
  .input-prepend input,
  .input-append input,
  .input-prepend input[class*="span"],
  .input-append input[class*="span"] {
    display: inline-block;
    width: auto;
  }
  .controls-row [class*="span"] + [class*="span"] {
    margin-left: 0;
  }
  .modal {
    position: fixed;
    top: 20px;
    left: 20px;
    right: 20px;
    width: auto;
    margin: 0;
  }
  .modal.fade {
    top: -100px;
  }
  .modal.fade.in {
    top: 20px;
  }
}
@media (max-width: 480px) {
  .nav-collapse {
    -webkit-transform: translate3d(0, 0, 0);
  }
  .page-header h1 small {
    display: block;
    line-height: 120%;
  }
  input[type="checkbox"],
  input[type="radio"] {
    border: 1px solid #ccc;
  }
  .form-horizontal .control-label {
    float: none;
    width: auto;
    padding-top: 0;
    text-align: left;
  }
  .form-horizontal .controls {
    margin-left: 0;
  }
  .form-horizontal .control-list {
    padding-top: 0;
  }
  .form-horizontal .form-actions {
    padding-left: 10px;
    padding-right: 10px;
  }
  .media .pull-left,
  .media .pull-right {
    float: none;
    display: block;
    margin-bottom: 10px;
  }
  .media-object {
    margin-right: 0;
    margin-left: 0;
  }
  .modal {
    top: 10px;
    left: 10px;
    right: 10px;
  }
  .modal-header .close {
    padding: 10px;
    margin: -10px;
  }
  .carousel-caption {
    position: static;
  }
}
@media (max-width: 979px) {
  body {
    padding-top: 0;
  }
  .navbar-fixed-top {
    margin-bottom: 20px;
  }
  .navbar-fixed-bottom {
    margin-top: 20px;
  }
  .navbar-fixed-top .navbar-inner,
  .navbar-fixed-bottom .navbar-inner {
    padding: 5px;
  }
  .navbar .container {
    width: auto;
    padding: 0;
  }
  .navbar .brand {
    padding-left: 10px;
    padding-right: 10px;
    margin: 0 0 0 -5px;
  }
  .nav-collapse {
    clear: both;
  }
  .nav-collapse .nav {
    float: none;
    margin: 0 0 10px;
  }
  .nav-collapse .nav > li {
    float: none;
  }
  .nav-collapse .nav > li > a {
    margin-bottom: 2px;
  }
  .nav-collapse .nav > .divider-vertical {
    display: none;
  }
  .nav-collapse .nav .nav-header {
    color: #777;
    text-shadow: none;
  }
  .nav-collapse .nav > li > a,
  .nav-collapse .dropdown-menu a {
    padding: 9px 15px;
    font-weight: bold;
    color: #777;
    border-radius: 3px;
  }
  .nav-collapse .btn {
    padding: 4px 10px 4px;
    font-weight: normal;
    border-radius: 0px;
  }
  .nav-collapse .dropdown-menu li + li a {
    margin-bottom: 2px;
  }
  .nav-collapse .nav > li > a:hover,
  .nav-collapse .nav > li > a:focus,
  .nav-collapse .dropdown-menu a:hover,
  .nav-collapse .dropdown-menu a:focus {
    background-color: #f2f2f2;
  }
  .navbar-inverse .nav-collapse .nav > li > a,
  .navbar-inverse .nav-collapse .dropdown-menu a {
    color: #f2f2f2;
  }
  .navbar-inverse .nav-collapse .nav > li > a:hover,
  .navbar-inverse .nav-collapse .nav > li > a:focus,
  .navbar-inverse .nav-collapse .dropdown-menu a:hover,
  .navbar-inverse .nav-collapse .dropdown-menu a:focus {
    background-color: #111111;
  }
  .nav-collapse.in .btn-group {
    margin-top: 5px;
    padding: 0;
  }
  .nav-collapse .dropdown-menu {
    position: static;
    top: auto;
    left: auto;
    float: none;
    display: none;
    max-width: none;
    margin: 0 15px;
    padding: 0;
    background-color: transparent;
    border: none;
    border-radius: 0;
    box-shadow: none;
  }
  .nav-collapse .open > .dropdown-menu {
    display: block;
  }
  .nav-collapse .dropdown-menu:before,
  .nav-collapse .dropdown-menu:after {
    display: none;
  }
  .nav-collapse .dropdown-menu .divider {
    display: none;
  }
  .nav-collapse .nav > li > .dropdown-menu:before,
  .nav-collapse .nav > li > .dropdown-menu:after {
    display: none;
  }
  .nav-collapse .navbar-form,
  .nav-collapse .navbar-search {
    float: none;
    padding: 10px 15px;
    margin: 10px 0;
    border-top: 1px solid #f2f2f2;
    border-bottom: 1px solid #f2f2f2;
    box-shadow: inset 0 1px 0 rgba(255,255,255,.1), 0 1px 0 rgba(255,255,255,.1);
  }
  .navbar-inverse .nav-collapse .navbar-form,
  .navbar-inverse .nav-collapse .navbar-search {
    border-top-color: #111111;
    border-bottom-color: #111111;
  }
  .navbar .nav-collapse .nav.pull-right {
    float: none;
    margin-left: 0;
  }
  .nav-collapse,
  .nav-collapse.collapse {
    overflow: hidden;
    height: 0;
  }
  .navbar .btn-navbar {
    display: block;
  }
  .navbar-static .navbar-inner {
    padding-left: 10px;
    padding-right: 10px;
  }
}
@media (min-width: 980px) {
  .nav-collapse.collapse {
    height: auto !important;
    overflow: visible !important;
  }
}

@-webkit-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
body,
p,
ul,
ol {
  font-family: "ff-meta-web-pro", sans-serif;
  font-size: 0.95625rem;
  line-height: 150%;
  letter-spacing: 0.01em;
  word-break: break-word;
}
h1,
h2,
h3,
h4,
h5,
h6,
body {
  color: hsl(0, 0%, 18%);
}
.fullPageText > h1,
.fullPageForm > h1,
.fullPageText > h2,
.fullPageForm > h2,
.fullPageText > h3,
.fullPageForm > h3,
.fullPageText > h4,
.fullPageForm > h4,
.fullPageText > h5,
.fullPageForm > h5,
.fullPageText > body,
.fullPageForm > body {
  color: black;
}
h1 {
  font-size: 2.25em;
}
h2 {
  font-size: 1.125rem;
}
h3 {
  font-size: 0.95625rem;
}
h4 {
  font-size: 0.95625rem;
}
h5 {
  font-size: 0.84375rem;
}
h6 {
  font-size: 0.74997rem;
}
.fullPageText h1,
.fullPageForm h1 {
  line-height: 125%;
  margin-top: 3.125rem;
}
.fullPageText h2,
.fullPageForm h2 {
  line-height: 125%;
}
h2 {
  margin-bottom: 0.675rem;
  margin-top: 0;
  padding-top: 3.5rem;
}
h3 {
  margin-top: 1.5rem;
}
a {
  text-decoration: none;
  font-weight: 500;
}
a:hover {
  color: hsl(196, 100%, 39%);
  cursor: pointer;
}
a:focus {
  outline-offset: 0.225rem;
  outline: thin dotted #333;
  text-decoration: unset;
}
a .blackLink,
.blackLink {
  color: black;
  font-weight: 800;
}
a .blackLink:hover {
  color: hsl(196, 100%, 39%);
  text-decoration: underline;
}
ul,
ol {
  line-height: 140%;
  margin-top: 0.75rem;
  margin-bottom: 1.25rem !important;
  margin-left: 1.5rem;
}
li {
  display: list-item;
  line-height: 140%;
  margin: 0;
  padding: 0;
  overflow: visible;
}
ul.biggerMargin {
  margin-top: 1rem;
}
ul.biggerMargin li {
  margin-bottom: 1rem;
}
li .bullet {
  margin-right: 0.225rem;
}
.faqList {
  margin-left: 2rem;
}
.liExtraSpaceAfterBullet {
  position: relative;
  left: 0.5rem;
}
.guideline {
  text-decoration: underline;
}
::selection {
  background-color: yellow;
  color: black;
}
.fullPageText,
.fullPageForm {
  margin-left: 5.4375rem;
  max-width: 440px;
  margin-bottom: 6.75rem;
}
/*
.fullPageText p, .fullPageForm p
 {
  line-height: 140%;
  font-size: 1rem;
}*/
.fullPageFormHeading {
  margin-left: -0.1125rem;
}
.fullPageText .bullet {
  margin-right: 0.45rem;
}
.textSection {
  padding-top: 3.375rem;
}
.textSection h1 {
  margin-bottom: 1.25rem;
  margin-top: 0;
  padding-top: 0;
}
.number {
  font-family: "usual", sans-serif !important;
}
.byline .number,
.cardTopRow .number,
.stats .number,
.commentLink .number {
  font-size: 85%;
}
.commentLink .number {
  font-size: 80%;
  margin-right: 0.25rem;
}
img {
  color: hsl(0, 0%, 18%);
  font-size: 0.74997rem;
  font-family: "ff-meta-web-pro", sans-serif;
}
html {
  overflow-y: scroll;
}
body {
  padding-top: 2.7rem;
  background-color: hsl(0, 0%, 99%);
  background-size: 5px 5px;
  background-image: linear-gradient(to right, hsl(0, 0%, 95.5%) 1px, transparent 1px), linear-gradient(to bottom, hsl(0, 0%, 95.5%) 1px, transparent 1px);
}
/* Mozilla renders ugly dark line artifacts with the grid method above, so using a different approach for that browser */
@-moz-document url-prefix() {
  body {
    background-image: linear-gradient(to right, hsla(0, 0%, 0%, 0.03) 1px, transparent 1px), linear-gradient(to bottom, hsla(0, 0%, 0%, 0.03) 1px, transparent 1px);
  }
}
.test {
  color: hsl(358, 100%, 30%) !important;
}
.quickTestRect {
  width: 100px;
  height: 100px;
  color: yellow;
  background-color: teal;
}
.error {
  color: hsl(358, 100%, 30%);
}
.hidden {
  display: none;
}
.disabled {
  color: hsl(0, 0%, 66%) !important;
}
.flop {
  -webkit-transform: scaleX(-1);
          transform: scaleX(-1);
}
.flip {
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
}
.admin {
  background-color: hsl(35, 100%, 90%);
  width: -webkit-max-content;
  width: max-content;
}
.relGroupHilite .admin {
  background-color: hsl(50, 100%, 65%);
}
.dropdown-menu .admin,
.stats .admin,
#userInfoArea .admin {
  width: 100%;
  box-sizing: border-box;
}
.inlineBlock {
  display: inline-block;
}
.inlineflexBox {
  display: -webkit-inline-flex;
  display: inline-flex;
  vertical-align: top;
  width: 100%;
}
.blockUnstackOnNarrow {
  display: block;
  vertical-align: top;
}
.fullWidth {
  display: block;
  width: 100%;
  border-style: none;
  margin-top: 0px;
  padding-top: 0.9rem;
  margin-left: 0;
}
.noMarginTop {
  margin-top: 0 !important;
}
.noMarginBottom {
  margin-bottom: 0 !important;
}
.noLeftMargin,
.noMarginLeft {
  margin-left: 0 !important;
}
.noRightMargin,
.noMarginRight {
  margin-right: 0 !important;
}
.smallerVerticalMargin {
  margin-bottom: 0.49995rem;
}
.hideBorderTop {
  border-top-style: none !important;
}
.hideBorderBottom {
  border-bottom-style: none !important;
}
.easierToClickOn,
.bylineAdminItem {
  position: relative;
  padding: 0.3375rem;
}
.transparentBG {
  background-color: transparent !important;
}
#mainContainer {
  width: auto;
  margin-top: 2.5rem;
  padding-left: 3rem;
  margin-bottom: 3.375rem;
  min-height: 960px;
}
.infiniteWidth {
  width: -webkit-max-content;
  width: max-content;
  width: -moz-max-content;
}
.hideScrollbar {
  overflow: hidden;
}
.infinite-scroll-component {
  overflow: unset !important;
}
/*
a[title]:hover:after {
  content: attr(title);
  background-color: pink;
  //position: absolute;
}
*/
.hover {
  position: relative;
  cursor: pointer;
}
.hover:hover .hover__no-hover {
  text-decoration: underline;
}
.hover:hover .hover__hover {
  opacity: 1;
  -webkit-transform: scale(1);
          transform: scale(1);
}
.hover:hover .hover__hover1 {
  opacity: 1;
  -webkit-transform: scale(1);
          transform: scale(1);
}
.hover__hover {
  position: absolute;
  opacity: 0;
  -webkit-transform: scale(0);
          transform: scale(0);
}
.hover__hover1 {
  position: absolute;
  opacity: 0;
  -webkit-transform: scale(0);
          transform: scale(0);
  transition-delay: 0.75s;
}
.hover__no-hover {
  opacity: 1;
}
.hoverProps {
  position: absolute;
  opacity: 0;
  -webkit-transform: scale(0);
          transform: scale(0);
}
.hoverAction {
  opacity: 1;
  -webkit-transform: scale(1);
          transform: scale(1);
}
.tipBringToFront {
  z-index: 1030;
}
.hoverTip {
  font-family: "ff-meta-web-pro", sans-serif;
  font-size: 0.74997rem;
  font-weight: normal;
  color: hsl(0, 0%, 94%);
  background-color: black;
  padding-top: 0.16875rem;
  padding-right: 0.75rem;
  padding-bottom: 0.225rem;
  padding-left: 0.75rem;
  border-radius: 4px;
  position: relative;
  left: -2.1375rem;
  top: -2.19375rem;
  width: -webkit-max-content;
  width: max-content;
  -webkit-animation-name: fadein;
          animation-name: fadein;
  -webkit-animation-duration: 2s;
          animation-duration: 2s;
  -webkit-animation-iteration-count: 1;
          animation-iteration-count: 1;
}
.linkArea .hoverTip {
  left: 2.19375rem;
  top: -4.33125rem;
}
.cardBottomActionRow .hoverTip {
  left: -3rem;
  top: -2.4rem;
}
.byline .hoverTip {
  padding-top: 0.28125rem;
  padding-bottom: 0.375rem;
}
.contributors .hoverTip {
  left: -1.6875rem;
}
.contributors .caret-outer {
  top: 1px !important;
}
.lastEdit .hoverTip {
  left: -1.6875rem;
}
.emojiBlock .hoverTip {
  left: -1.35rem;
  top: -2.25rem;
}
.commentBottomRowActions .hoverTip {
  left: -3.375rem;
}
.quickCommentsList .hoverTip {
  left: -5.5rem;
  top: -1.5rem;
}
.react-alert {
  background-color: hsl(0, 0%, 18%);
  color: white;
  padding: 0.875rem;
  padding-left: 1.75rem;
  padding-right: 1.75rem;
  display: block;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-align-items: center;
          align-items: center;
  box-sizing: border-box;
  margin: 0px;
  width: 100vw;
  overflow-wrap: break-word;
  min-height: 8vw;
}
.react-alert-msg {
  display: inline-block;
  width: 90%;
}
.alertCloseButton {
  border: none;
  background-color: transparent;
  cursor: pointer;
  color: white;
  font-size: 1.25rem;
  float: right;
}
.alertCloseButton:active,
.alertCloseButton:focus {
  border: none;
  outline: none;
}
.ys-editor + .alert,
.optionsContainer .alert,
.contactForm .alert,
.addSourceArea .alert {
  margin-top: -1.35rem;
}
.progressFeedback {
  position: relative;
  margin-left: 0.675rem;
  font-size: 0.84375rem;
  color: hsl(0, 0%, 18%);
  min-height: 3.15rem;
  display: block;
  margin-bottom: 0;
}
.progressFeedback.counter {
  color: hsl(358, 100%, 30%);
}
.addEvidenceControlsArea .progressStateFeedback {
  position: relative;
  display: block;
}
.unlinkProgressFeedback {
  margin-left: 1.6875rem;
}
.spinner {
  display: inline-block;
  position: relative;
  top: 0;
  left: 0;
  margin-left: 0.225rem;
  margin-right: 0.225rem;
  width: 1.575rem;
}
.spinnerPointList {
  margin-left: 1.35rem;
}
.spinnerLabel {
  color: hsl(0, 0%, 18%);
  line-height: 133%;
  letter-spacing: 0.01em;
  font-size: 0.95625rem;
}
.iconButton,
.sectionIcon {
  margin-right: 0.36rem;
}
.fa-dice,
.fa-fire {
  font-size: 110%;
}
.fa-star,
.fa-user {
  font-size: 85%;
}
.fa-info-circle {
  font-size: 1rem;
  top: 0.0625rem;
  position: relative;
}
.fa-bars {
  position: relative;
  top: 0.0625rem;
}
.relevanceLearnMore .fa-info-circle {
  margin-left: 0.16875rem;
  margin-right: 0.225rem;
}
.scoreLearnMore .fa-info-circle {
  margin-right: 0.1875rem;
}
.emojiBlock .fa-plus {
  font-size: 85%;
}
.controversyIcon {
  color: hsl(358, 100%, 30%);
  margin-left: 0.225em;
  font-size: 1em;
  padding-bottom: 0.0625rem;
}
.stats .controversyIcon {
  color: white;
  margin-left: -0.0625rem;
  margin-right: 0.125rem;
  padding: 0;
}
.iconBlock {
  padding-left: 0.675rem !important;
  padding-right: 0.675rem !important;
}
.emojiMenuBlock {
  padding-right: 0.6375rem !important;
  padding-top: 0.75rem !important;
}
.emojiMenuLink {
  font-size: 0.675em;
  position: relative;
  vertical-align: top;
}
.emojiMenuLink .emoji {
  font-size: 1em;
}
.emojiMenuLink i {
  margin-right: 0.09rem !important;
}
.emojiMenuPlus {
  position: relative;
  bottom: 0.125rem;
}
#emojiMenu {
  width: -webkit-max-content;
  width: max-content;
  left: -45px !important;
  top: 38px;
}
#emojiMenu.question {
  left: 148px;
}
#emojiMenu .dropdown-caret {
  left: 0.61875rem;
}
#emojiMenu .caret-inner {
  left: 0.0625rem;
}
.emoji {
  display: inline-block;
}
#emojiMenu .emoji {
  font-size: 175%;
}
#emojiMenu li {
  margin: 0;
}
#emojiMenu .emojiUserSelected {
  background-color: black;
  color: white;
}
.emojiUserSelected .emoji,
.emojiUserSelected .menuItemLabel {
  color: white;
}
#emojiMenu .menuItemLabel {
  padding-left: 0.54rem;
  vertical-align: text-top;
  position: relative;
  bottom: 0.0625rem;
}
.emojiList {
  display: inline-block;
  position: relative;
  left: -0.315rem;
}
.emojiBlock {
  color: hsl(0, 0%, 18%);
  font-weight: bold;
  font-size: 1.3em;
  display: inline-block;
  padding-top: 0.125rem;
  padding-left: 0.25rem;
  padding-right: 0.25rem;
  padding-bottom: 0.125rem;
  margin-right: 0.25rem;
  outline: none;
  position: relative;
  margin-top: -0.375rem;
}
.emojiBlock:hover {
  background-color: hsl(196, 100%, 57%);
}
.emojiBlock .number {
  margin-left: 0.125rem;
  font-size: 0.675rem;
  color: hsl(0, 0%, 66%);
  font-weight: normal;
}
.emojiBlock:hover .number {
  color: black;
}
.emojiMindBlown {
  position: relative;
  top: 0.1875rem;
}
.emojiMindBlown svg {
  vertical-align: 0 !important;
}
.emojiLaugh {
  position: relative;
  top: 0.0625rem;
}
.emojiLaugh svg {
  width: 1em !important;
}
.byline .emojiLaugh,
.byline .emojiDelta,
.byline .emojiLove {
  opacity: 0.45;
}
/*
#emojiMenu .emojiLaugh, #emojiMenu .emojiDelta, #emojiMenu .emojiLove {
  opacity: 0.85;
}*/
.emojiDelta {
  -webkit-filter: brightness(0);
          filter: brightness(0);
  -webkit-transform: scale(1.55);
          transform: scale(1.55);
}
.emojiBlock .emojiDelta {
  -webkit-transform: scale(1.75);
          transform: scale(1.75);
  position: relative;
}
#profileDisclaimer .emoji {
  width: 1.18125rem;
  margin-left: 0.36rem;
}
.emojiHoverIcon {
  opacity: 0;
}
.emoji:hover .emojiHoverIcon {
  opacity: 1;
}
.modal ul {
  margin-top: 0.9rem;
  margin-bottom: 1.35rem;
  margin-left: 0.9rem;
}
/* UNUSED I THINK
// the dark overlay behind react modals
.popup-overlay {
  background: transparent !important; // making it darker; using !important to step on style tags being added in the html by react-popup;  is there a better way to do this?
  z-index: 1000 !important;
}
*/
#termsModal {
  width: unset;
  margin-left: -320px;
}
.reactModal,
#signupDialog,
#loginDialog,
#termsModal {
  position: absolute;
  left: 50%;
  margin-left: -320px;
  top: 2.25rem;
  border: 1px solid hsl(0deg 0% 84%);
  background: white;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  outline: none;
  padding: 1.125rem;
}
.reactModal {
  width: 640px;
}
#signupDialog,
#loginDialog {
  width: 560px;
  margin-left: -280px;
}
.reactModalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: hsla(0, 0%, 0%, 0.75);
  z-index: 1040;
}
.modal-header,
.modal-body,
.modal-footer {
  padding-top: 2.25rem;
  padding-bottom: 1.5rem;
  padding-left: 3.5rem;
  padding-right: 3.5rem;
}
/*
// extra padding on the right looks good with text
.modal-header {
  padding-right: @modalPaddingH;
}*/
.modalHeadlineCopy {
  font-size: 2rem !important;
  font-weight: bold;
}
.modal .heading {
  font-size: 0.84375rem;
  margin-bottom: 1.25rem;
}
.returningUserSignIn .heading {
  margin-bottom: 1.5rem;
}
.modal .heading.extraMarginTop {
  margin-top: 1.35rem;
}
.modal-body,
.modal-footer {
  padding-top: 1rem;
}
.modal-footer {
  padding-bottom: 3.6rem;
}
.model-section {
  margin-bottom: 0.9rem;
}
.modalHeadlineCopy {
  display: inline-block;
  width: -webkit-max-content;
  width: max-content;
}
.modal .dialogSubHeading {
  margin-top: 2.5rem;
}
.modal-body.text-content {
  padding-right: 3.5rem;
  margin-top: 0.3375rem;
  max-width: 440px;
}
.modal-body.text-content li {
  margin-bottom: 0.9rem;
}
.modal-body ol {
  margin-left: 0.9rem;
  margin-bottom: 1.25rem;
  margin-top: 0.36rem;
}
.modal-body li {
  margin-bottom: 0.36rem;
}
#termsModal {
  position: absolute;
}
#termsPopup .header {
  max-width: 440px;
}
#termsPopup .modal-header,
#termsPopup .modal-body,
#termsPopup .modal-footer {
  padding-right: 4rem;
}
#termsPopup h1 {
  font-size: 1.875em;
}
#termsPopup h2 {
  padding-top: 1.5075rem;
  margin-bottom: 0.225rem;
  font-size: 0.95625rem;
}
#termsPopup .firstItem {
  padding-top: 0.75rem;
  margin-top: 0;
}
.termsValuesPageLink {
  margin-top: 1.8rem;
  margin-bottom: 0.225rem;
}
#loginDialog .modal-body {
  overflow-y: unset;
}
#loginDialog .signInNewUsers {
  margin-bottom: 2rem;
}
#logInSpinner {
  margin-top: 0.675rem;
}
#frm_signupDialog input,
#frm_signupDialog textarea {
  width: 100%;
  box-sizing: border-box;
}
#login_userPassword {
  margin-bottom: 1rem;
}
.signWithInButtons {
  width: auto;
  padding-bottom: 0;
  padding-left: 0;
  padding-right: 0;
  padding-top: 0;
  display: block;
  margin-top: 0;
  margin-bottom: 2rem;
  margin-left: auto;
  margin-right: auto;
}
.signWithInButtons .buttonUX2,
.signInNewUsers .buttonUX2 {
  width: -webkit-max-content;
  width: max-content;
}
.modal-body.modalBodySignInWith {
  padding-top: 0;
}
.modalBodySignInWith a {
  color: black;
  text-decoration: none;
}
/*
@redGoogleBranding: hsl(5, 81%, 56%); //Google branding red
#signInWithGoogle_Dlg {
  background-color: @redGoogleBranding;
  border-color: @redGoogleBranding;
}
@redGoogleBrandingHover: lighten(@redGoogleBranding, 5);
#signInWithGoogle_Dlg:hover {
  background-color: @redGoogleBrandingHover;
  border-color: @redGoogleBrandingHover;
}*/
#signInWithGoogle_Dlg {
  background-color: white;
}
#signInWithGoogle_Dlg .logoGoogle {
  width: 24px;
  margin-left: -0.25rem;
  margin-right: 0.75rem;
}
#signInWithGoogle_Dlg:hover a {
  color: white;
}
#signInWithEmail_Dlg:hover {
  background-color: hsl(0, 0%, 24%);
}
#signInWithEmail_Dlg:hover a {
  color: white;
}
.loginDialogDividerLine {
  width: 100%;
  display: inline-block;
  vertical-align: top;
  border-style: none;
  border-top-style: solid;
  border-color: hsl(0deg 0% 84%);
  border-width: 1px;
}
.signInFooter {
  padding-right: 0;
  padding-bottom: 0.9rem;
  padding-left: 0 !important;
  padding-right: 0 !important;
  padding-top: 0 !important;
  margin-bottom: 1.75rem;
  margin-top: 0.5rem;
}
.signInFooterItems {
  display: block;
  width: -webkit-max-content;
  width: max-content;
}
#signupDialog {
  position: absolute;
}
#showSignupDialog {
  margin-top: 0.45rem;
  margin-bottom: 0.9rem;
}
#signUpSpinner {
  display: inline-block;
  margin-top: 0.625rem;
  margin-left: 2rem;
}
#forgot_emailLoginDialog {
  margin-left: 1.5rem;
  font-size: 0.84375rem;
  font-weight: 400;
  color: black;
  position: relative;
  top: 0.8125rem;
}
#submit_signupDialog {
  margin-right: 1rem;
}
.backToLoginArea {
  display: inline-block;
  max-width: calc(100% - 130px);
  vertical-align: middle;
  top: 9px;
  position: relative;
}
#backToLogin {
  display: inline-block;
}
/* unused django only?
.modalSubheading {
  padding-top: 1.35rem;
  margin-bottom: 0.675rem;
  color: @grayTextDark;
  font-size: 0.875rem; // 14px
  //font-family:"ff-meta-web-pro-condensed", sans-serif;
}
*/
.modalIndent {
  padding-left: 0.45rem;
}
.returningUsersHeading {
  padding-top: 0;
}
.eyeMask {
  position: absolute;
  z-index: 10;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  right: 1rem;
  top: 0;
  bottom: 0;
  width: 1rem;
  -webkit-justify-content: center;
          justify-content: center;
}
.eyeMask .fa-eye-slash,
.eyeMask .fa-eye {
  position: relative;
  top: 1px;
}
.fsp-picker input {
  margin-bottom: 0;
}
.fsp-url-source__form {
  bottom: 1.8rem;
}
label {
  font-size: unset;
  margin-bottom: 0.3375rem;
}
form label {
  margin-bottom: 0.675rem;
}
input {
  height: 1.35rem;
  padding: 0.225rem 0.3375rem;
  margin-bottom: 0.9rem;
}
input[type=radio],
.settingsForm input[type=radio] {
  width: unset;
}
.inputFieldUX2,
.DraftEditor-root,
input[type="text"],
input[type="email"],
input[type="password"] {
  display: block;
  width: 100%;
  overflow: hidden;
  height: unset;
  position: relative;
  box-sizing: border-box;
  font-size: 0.95625rem;
  line-height: 125%;
  color: hsl(0, 0%, 18%);
  padding: 0.875rem;
  padding-top: 0.9375rem;
  padding-bottom: 1rem;
  margin-bottom: 1.35rem;
  border-color: hsl(0, 0%, 75%);
  border-width: 1px;
  border-style: solid;
  border-radius: 0;
  box-shadow: none;
  background-color: white;
}
.radioGroup {
  display: block;
  margin-bottom: 1.665rem;
}
.radioOptions label {
  display: inline-block;
  font-weight: 400;
  margin-right: 0.3125rem;
  margin-bottom: 0.9rem;
}
.settingsForm input[type=radio] {
  width: 1.5rem;
  height: 1.5rem;
  vertical-align: top;
}
.settingsForm input[value=Default] {
  margin-right: 0 !important;
}
/*
.radioButtonLabelDefault {
  display: inline-block;
  font-size:@fontSizeMediumCond; // update this to our current system
  color: @nearBlack;
  background:none; // is this nec?
  background-color: transparent;
  height: unset;
  margin-bottom: 0.45rem !important;
  margin-right: 1.35rem;
}*/
.control-label,
.form-horizontal .control-label {
  color: hsl(0, 0%, 18%);
}
/* deprecated I think
.modal label, .accordion-group-heading {
  color: @nearBlack;
  font-weight: normal;
  line-height: 150%;
}
.form-horizontal .control-label {
  float: none;
  line-height: 100%;
  padding-bottom: 0.7875rem;
  padding-top: 0.7875rem;
  padding-left: 0;
  margin-bottom: 0;
  font-size: 0.875em;
  background-color: transparent;
  width: unset;
}
*/
#signupDialog .heading {
  margin-bottom: 1.75rem;
}
/* don't think I want this anymore
.modal input {
  //height: 32px;
  margin-bottom: 0.9rem;
}
*/
.formInstruction {
  width: 100%;
  margin-bottom: 1.8rem;
}
textarea {
  resize: none;
}
.ys-editor .DraftEditor-root {
  /*
    background-color: white;
    border: 1px solid @stackCardDefaultBorderColor;
    width: 100%;
    font-size: @fontSizePointTitle;
    line-height: 125%;
    color: hsl(0, 0%, 18%);
    padding: 16px;
    box-sizing: border-box;
*/
}
.editorMention {
  color: hsl(196, 100%, 39%);
}
.editorMention:hover {
  text-decoration: underline;
}
.commentText .DraftEditor-root {
  background: transparent;
  border: none;
  padding: 0;
  margin-top: 0;
}
.dropdown-menu {
  cursor: pointer;
  padding-top: 0.3125rem;
  padding-bottom: 0.3125rem;
  display: block;
  min-width: unset;
  margin-top: 0.225rem;
  border-radius: 0;
  border: 1px solid hsl(0deg 0% 84%);
  box-shadow: 0 1px 10px rgba(0, 0, 0, 0.1);
  -webkit-box-shadow: 0 1px 10px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0 1px 10px rgba(0, 0, 0, 0.1);
}
.dropdown-menu .divider {
  border-bottom-color: hsl(0deg 0% 84%);
  border: none;
  height: 1px;
  margin-left: 0;
  margin-right: 0;
  margin-top: 0;
  margin-bottom: 0;
  padding: 0;
}
.dropdown-menu > li > a,
.dropdown-menu > li > span,
.dropdown-menu > li > .dropdown-heading {
  padding-top: 0.18rem;
  padding-right: 1.125rem;
  padding-bottom: 0.18rem;
  padding-left: 0.9rem;
}
.dropdown-menu > li > a,
.dropdown-menu > li > span {
  padding-top: 0.3375rem;
  padding-bottom: 0.3375rem;
  display: inline-block;
  width: 100%;
}
.infoNonToggle,
.infoToggle,
.subInfoToggle {
  margin: 0;
  overflow: hidden;
}
.dropdown-menu > li > a:focus,
.dropdown-menu > li > a:hover,
.dropdown-submenu:focus > a,
.dropdown-submenu:hover > a {
  font-weight: 800;
  background-image: none;
}
.dropdown-menu > li > .menuShareLink {
  width: auto;
  padding-left: 0.45rem;
  padding-right: 0.45rem;
}
.dropdown-menu .inlineIcons {
  padding-left: 0.45rem;
}
.dropdown-menu > li > a:hover,
.dropdown-menu > li > a:focus,
.dropdown-submenu:hover > a,
.dropdown-submenu:focus > a {
  background-color: hsl(196, 100%, 57%);
  color: black;
}
.dropdown-menu > li > a,
.dropdown-menu > li > span {
  padding-top: 0.63rem;
  padding-bottom: 0.63rem;
  color: black;
  box-sizing: border-box;
}
.dropdown-menu-with-caret {
  top: 1.625rem;
  left: -0.675rem;
  margin: 0;
}
.dropdown-caret,
.hoverTip-caret {
  position: absolute;
  z-index: 1030;
  left: 0.3375rem;
  top: 1.675rem;
}
.dropdown-caret {
  top: -0.57375rem;
  left: 97px;
  width: 1rem;
  height: 0.675rem;
  overflow: hidden;
}
.contributorsMenu .dropdown-caret {
  left: 18px;
}
.dropdown-caret .caret-outer,
.hoverTip-caret .caret-outer {
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  height: auto;
  left: 0;
  top: 0;
  width: auto;
  display: inline-block;
  position: absolute;
}
.dropdown-caret .caret-outer {
  border-bottom: 8px solid black;
  border-bottom-color: hsl(0deg 0% 84%);
  margin-left: -0.0625rem;
}
.hoverTip-caret .caret-outer {
  border-top: 8px solid black;
  border-bottom-color: black;
}
.dropdown-caret .caret-inner,
.hoverTip-caret .caret-inner {
  top: 1.25px;
  left: 0;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-bottom: 8px solid white;
  border-bottom-color: white;
  display: inline-block;
  margin-left: -0.0625rem;
  position: absolute;
}
.stats .dropdown-caret {
  left: 9px;
}
.stats .caret-inner {
  border-bottom-color: black;
  top: 2px;
}
.menuDivider {
  width: 100%;
  border-bottom-color: hsl(0deg 0% 84%);
  border-bottom-style: solid;
  border-bottom-width: 0.0625rem;
}
#settingsPage {
  max-width: 460px;
}
#changePasswordLink {
  display: block;
  margin-top: 1.8rem;
  width: -webkit-max-content;
  width: max-content;
}
#submit_changePasswordButton {
  width: unset;
}
.settingsForm {
  margin-top: 1.8rem;
  margin-bottom: 7.2rem;
}
.settingsForm h2 {
  padding-top: 0;
  margin-top: 3.15rem;
  margin-bottom: 2.25rem;
}
.settingsForm input {
  width: 100%;
  margin-right: 0;
}
.notificationFrequencyRadios {
  display: block;
  margin-bottom: 1.575rem;
}
.notificationFrequencyRadios .radioGroup > label {
  font-weight: 800;
}
.notificationFrequencyRadios .radioButtonLabelDefault {
  font-weight: 400;
}
.radioOptionBlock {
  display: inline-block;
  margin-bottom: 1rem;
  margin-right: 1.25rem;
}
.radioOptionBlock label {
  margin-bottom: 0 !important;
}
.editAreaClose,
.emojiClose {
  float: right;
  font-size: 1.35em;
  position: relative;
  cursor: pointer;
  font-family: serif;
  font-weight: bold;
  margin-right: 0;
}
.editAreaClose a {
  color: hsl(0, 0%, 66%);
  position: relative;
  top: -7px;
}
#gotItExplanation a {
  color: hsl(0, 0%, 18%);
}
#gotItExplanation .buttonGotIt {
  margin-right: 0.5rem;
  margin-bottom: 0.5rem;
}
.editAreaClose a:hover {
  color: hsl(196, 100%, 39%);
  text-decoration: none;
}
.emojiClose {
  font-size: 1.125em;
  float: none;
  vertical-align: middle;
}
.buttonUX2,
.tabUX2,
.buttonSecondaryUX2blue,
.buttonUX2 a,
.modalBodySignInWith a {
  margin-bottom: 0;
  font-weight: 700;
  font-size: 0.84375rem;
  font-style: normal;
  height: unset;
}
.buttonUX2,
.tabUX2,
.buttonSecondaryUX2blue {
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  border-style: solid !important;
  border-width: 0.0625rem;
  border-radius: 0;
}
.buttonUX2,
.buttonSecondaryUX2blue {
  padding-top: 0.75rem;
  padding-left: 1.25rem;
  padding-right: 1.25rem;
  padding-bottom: 0.8125rem;
}
.buttonUX2marginR {
  margin-right: 0.36rem;
}
.buttonUX2responsiveStack {
  display: inline-block;
}
.tabUX2 {
  padding-top: 0.4995rem;
  padding-left: 1.3125rem;
  padding-right: 1.3125rem;
  padding-bottom: 0.541125rem;
  color: hsl(196, 100%, 39%);
  border: none !important;
  background: none;
  display: inline-block;
  margin-right: 0.225rem;
  font-size: 0.95625rem;
}
.tabUX2:hover,
.tabUX2:focus {
  background-color: hsl(196, 100%, 57%);
  border-color: hsl(196, 100%, 57%);
  color: white;
  outline: none;
  text-decoration: none;
}
.tabUX2.selectedTab {
  -webkit-text-decoration-line: underline;
          text-decoration-line: underline;
  -webkit-text-decoration-style: solid;
          text-decoration-style: solid;
  text-decoration-thickness: 4px;
  -webkit-text-decoration-color: hsl(196, 100%, 39%);
          text-decoration-color: hsl(196, 100%, 39%);
}
.tabUX2.selectedTab:hover,
.tabUX2.selectedTab:active,
.tabUX2.selectedTab:focus {
  -webkit-text-decoration-color: white;
          text-decoration-color: white;
  -webkit-text-decoration-line: underline;
          text-decoration-line: underline;
  -webkit-text-decoration-style: solid;
          text-decoration-style: solid;
  text-decoration-thickness: 4px;
}
.buttonUX2 {
  position: relative;
  color: white;
  background-color: black;
  border-color: black;
  outline: none !important;
  vertical-align: top;
}
.buttonUX2:focus:enabled {
  outline: 2px solid hsl(196, 100%, 39%);
}
.buttonUX2:hover:enabled {
  background-color: black;
}
.buttonUX2:active:enabled,
.buttonSecondaryUX2blue:active:enabled,
[id^=signInWith]:active,
#header button:active:enabled {
  -webkit-transform: scale(0.975);
          transform: scale(0.975);
  outline-style: none;
}
.buttonUX2:disabled {
  color: hsl(0, 0%, 94%);
  background-color: hsl(0, 0%, 66%);
  border-color: hsl(0, 0%, 66%);
}
.buttonUX2Red:disabled {
  background-color: hsl(358, 60%, 75%);
  border-color: hsl(358, 60%, 75%);
}
.buttonUX2:hover:enabled,
.buttonUX2:focus:enabled {
  background-color: hsl(0, 0%, 24%);
  border-color: hsl(0, 0%, 24%);
  transition: 0.2s;
}
a.buttonUX2:hover:enabled,
a.buttonUX2:focus:enabled {
  color: white;
}
a.buttonUX2:active:enabled,
a.buttonUX2:focus:enabled {
  outline: none !important;
  border: none !important;
  box-shadow: none !important;
}
.buttonUX2Blue {
  background-color: hsl(196, 100%, 39%);
  border-color: hsl(196, 100%, 39%);
}
.buttonUX2Blue:hover:enabled,
.buttonSecondaryUX2blue:hover:enabled,
.buttonUX2Blue:focus:enabled {
  background-color: hsl(196, 100%, 57%);
  border-color: hsl(196, 100%, 57%);
  color: white;
}
/*
.buttonUX2Blue:focus {
  box-shadow: @blueLight;
  outline: none;
}*/
/*
.buttonUX2Red:focus:enabled {
  box-shadow: @redLight;
  outline: none;
}*/
.buttonSecondaryUX2blue {
  position: relative;
  color: hsl(196, 100%, 39%);
  border-color: hsl(196, 100%, 39%);
  outline: none !important;
  background: none;
}
.buttonSecondaryUX2black,
.buttonSecondaryUX2Transp {
  color: hsl(0, 0%, 18%);
  border-color: hsl(0, 0%, 18%);
  outline: none !important;
  background: white;
}
.buttonSecondaryUX2Transp,
.claimEditArea .buttonSecondaryUX2black {
  background: transparent;
}
.buttonSecondaryUX2black:hover,
.buttonSecondaryUX2black:focus:enabled,
.buttonSecondaryUX2Transp:hover {
  color: white;
  background-color: hsl(0, 0%, 18%) !important;
  border-color: hsl(0, 0%, 18%) !important;
}
.buttonSecondaryUX2red {
  color: hsl(358, 100%, 30%);
  outline: none !important;
  border-color: hsl(358, 100%, 30%);
  background-color: hsl(10, 100%, 96%);
}
.buttonSecondaryUX2red:hover {
  color: white;
  background: hsl(358, 100%, 30%) !important;
  border-color: hsl(358, 100%, 30%) !important;
}
.cancelButton {
  background: none;
  border: none;
  font-size: 0.84375rem;
  outline-style: none;
  display: inline-block;
  padding: 0;
  padding-left: 0.3375rem;
  color: hsl(0, 0%, 18%);
  margin-left: 0.45rem;
}
.cancelButton:hover:enabled,
.cancelButton:focus:enabled {
  text-decoration: underline;
  outline-style: none;
}
.cancelButton:active {
  -webkit-transform: scale(0.975);
          transform: scale(0.975);
}
.createClaimFormButton,
.editClaimFormButton {
  display: inline-block;
}
#imageUploadSuccessMessage {
  margin-left: 1rem;
}
label[for=ys-form-imageDescription] {
  margin-top: 1.675rem;
}
.imageEditForm .imagePickerButton {
  margin-bottom: 1.51875rem;
  position: relative;
  padding-top: 20px;
  padding-bottom: 20px;
  font-size: 125%;
  width: 200px;
}
.createClaimCreationArea .addEvidenceAreaNote {
  margin-top: 0.9rem;
}
.createClaimCreationArea .imagePickerButton {
  -webkit-transform: none;
          transform: none;
  left: 0;
  margin-bottom: 0;
  margin-top: 0;
  color: hsl(0, 0%, 18%);
  border-color: hsl(0, 0%, 18%);
  outline: none !important;
  background: white;
}
.createClaimCreationArea .imagePickerButton:hover {
  color: white;
}
/*@buttonUX2BigScaleDownOnClick: 0.98; //.992;
.imageEditForm .imagePickerButton:active:enabled {
  transform: scale(@buttonUX2BigScaleDownOnClick * @buttonUX2BigScale);
}*/
.buttonLower {
  margin-top: 1.125rem;
}
.introjs-helperNumberLayer {
  text-shadow: none;
  background: none;
  background-color: hsl(0, 0%, 18%);
  box-shadow: none;
}
.onboardTipHiliteSeeEvidence {
  background-color: pink !important;
}
.introjs-helperLayer {
  border-radius: 0;
  border: none;
  box-shadow: none;
  -webkit-transform: scale(0.91);
          transform: scale(0.91);
}
.introjs-tooltipbuttons {
  text-align: center;
}
.introjs-tooltip {
  border-radius: 0;
  padding: 1.25rem;
}
.introjs-bullets ul {
  margin-top: 1.75rem;
  margin-bottom: 1.875rem !important;
}
.introjs-bullets ul li a {
  width: 0.6125rem;
  height: 0.6125rem;
}
.introjs-skipbutton {
  display: none;
}
.introjs-nextbutton,
.introjs-donebutton {
  float: right;
}
.introjs-donebutton {
  display: block !important;
}
.introjs-tooltipbuttons a[role=button] {
  background-image: none;
  text-shadow: none;
  border: hsl(0, 0%, 18%);
  background-color: hsl(0, 0%, 18%);
  color: white;
  width: 48%;
  box-sizing: border-box;
  margin: 0.125rem;
  padding: 0.675rem 0.875rem;
}
.introjs-tooltipbuttons a.introjs-prevbutton {
  border: black;
  background-color: white;
  color: black;
}
.introjs-tooltipbuttons a[role=button]:hover {
  border: black;
  background-color: black;
  color: white;
}
.headerUserName {
  color: black;
  font-weight: bold;
  position: relative;
}
.headerUserName a {
  color: black;
}
.headerLinkIcon {
  max-height: 1.25rem;
  padding: 0;
  margin: 0;
  margin-right: 0.125rem;
  position: relative;
}
#header {
  background: hsl(0, 0%, 94%);
  height: 2.7rem;
  margin-bottom: 0;
  border-width: 1px;
  border-color: hsl(0, 0%, 75%);
  border-bottom-style: solid !important;
  margin-left: 0;
}
#header form {
  margin-bottom: 0;
}
.headerItem {
  display: inline-block;
  color: black !important;
  font-size: 0.95625rem;
  font-weight: 700;
  line-height: 120%;
  margin-top: 0.25rem;
  margin-right: 0;
  margin-left: 0.675rem;
  vertical-align: middle;
}
.headerItem > a {
  color: black ;
}
.headerItem > a:hover,
.headerItem > a:focus,
.btn-link:hover,
.btn-link:focus {
  color: hsl(196, 100%, 39%);
}
.headerLinkIcon {
  font-size: 1rem;
  margin-right: 0;
  color: black;
}
.headerLinkIcon:hover,
a:hover .headerLinkIcon,
.headerLinkIcon:focus,
a:focus .headerLinkIcon {
  color: hsl(196, 100%, 39%);
  outline: none;
  text-decoration: none !important;
}
#headerLogo {
  margin-left: 1.5%;
  margin-right: 0.75rem;
}
#headerLogo .logoDino {
  height: 2.2rem;
  margin-right: 0.375rem;
}
#headerLogo .logoText {
  height: 0.9rem;
}
#CreatePoint {
  margin-left: 0;
}
#CreatePoint:hover,
.userMenuLink:hover {
  text-decoration: none;
}
#CreatePoint:hover .headerLinkLabel,
.userMenuLink:hover .headerLinkLabel {
  text-decoration: underline;
}
#makePointIcon {
  margin-right: 0.225rem;
}
#searchArea {
  height: 2.7rem;
  position: relative;
  width: 33%;
  display: inline-block;
  vertical-align: top;
  margin-top: 0;
  /*form {
    margin-top: -4px;
  }*/
}
#searchArea .searchIcon {
  position: relative;
  height: 0.9rem;
  background-image: none;
  margin-right: 0;
  margin-left: 0.675rem;
  width: auto;
  float: left;
  -webkit-transform: scaleX(-1);
          transform: scaleX(-1);
  top: -1.755rem;
}
#searchBox {
  padding-left: 2.15rem;
  padding-top: 0.75rem;
  padding-bottom: 0.7875rem;
  background-color: hsl(0, 0%, 94%);
  height: 2.7rem;
  border-top: none;
  border-bottom: none;
  margin-bottom: 0;
}
#searchBox:active,
#searchBox:focus {
  background-color: white;
  border-width: 1px;
  border-color: unset;
}
#searchBox::-webkit-input-placeholder {
  color: hsl(0deg 0% 50%);
  padding-top: 0.144rem;
}
#searchBox::placeholder,
#searchBox::-webkit-input-placeholder,
#searchBox:-moz-placeholder,
#searchBox::-moz-placeholder,
#searchBox:-ms-input-placeholder {
  color: hsl(0deg 0% 50%);
  padding-top: 0.144rem;
}
#aboutMenu {
  position: relative;
}
#aboutMenu .headerLinkIcon {
  font-size: 1.1rem;
}
.menuCaret {
  margin-left: 0.125rem;
  font-size: 120%;
  position: relative;
  top: 1.5px;
}
#aboutMenu a:hover {
  text-decoration: none !important;
}
a:hover #headerLabelAbout {
  text-decoration: underline;
}
#aboutDropdown {
  margin-top: 0.7875rem;
  left: -11.5px;
}
.headerSocialMediaIconArea {
  margin-top: 0.9rem;
  margin-left: 0;
  margin-right: 0.135rem;
}
.headerSocialMediaIcon {
  font-size: 0.85rem;
  margin-left: 0.3375rem;
}
.dropdownSocialMediaIconArea .headerSocialMediaIcon {
  margin-right: 0.225rem;
  margin-left: 0;
  top: 3px;
}
.dropdownSocialMediaIconArea .headerLinkIcon {
  font-size: 0.9rem;
}
a.headerSocialMediaIcon:hover {
  text-decoration: none !important;
}
#headerIconFb {
  font-size: 0.78rem;
  top: -1.8px;
}
#notifications {
  margin-top: 0.625rem;
  position: relative;
  cursor: pointer;
}
#notifications li,
#notificationsAllArea li {
  margin: 0;
}
#notificationIcon {
  max-width: 1.575rem;
}
.notificationsSeeAll,
.notificationsMarkAllRead {
  text-transform: capitalize;
}
.notificationsSeeAll {
  margin-right: 1.25rem;
}
.notificationsMarkAllRead {
  margin-right: 0.45rem;
}
#notificationCount {
  padding-top: 0px;
  padding-right: 0.16875rem;
  padding-bottom: 0.225rem;
  padding-left: 0.16875rem;
  position: absolute;
  top: 20%;
  text-align: center;
  background-color: hsl(196, 100%, 57%);
  color: white;
  left: 77%;
  font-family: "ff-meta-web-pro-condensed", sans-serif;
  font-size: 0.74997rem;
  font-weight: 800;
  border-radius: 2px;
}
.notification-menu {
  margin-left: -272px;
  border-radius: 0px;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  -moz-overflow-scrolling: touch;
  -ms-overflow-scrolling: touch;
  -o-overflow-scrolling: touch;
  overflow-scrolling: touch;
  height: auto;
  max-height: calc(100vh - 2.7rem - 0.125rem);
  width: 480px;
  padding-top: 0;
  padding-bottom: 0;
  top: unset;
  margin-top: 0.61875rem;
  position: fixed;
  right: 0 !important;
}
.notification-menu li {
  margin-left: 0;
}
.notification-menu .notificationMenuItem {
  padding-left: 0.675rem;
}
.notificationLeft {
  width: 1.25rem;
  display: inline-block;
  min-height: 1.6875rem;
  box-sizing: border-box;
  vertical-align: top;
}
.notificationText {
  display: inline-block;
  min-height: 1.6875rem;
  box-sizing: border-box;
  margin-left: 0.625rem;
  padding-left: 0;
  width: calc(100% - 0.225rem - 1.25rem - 0.625rem);
  margin-top: -0.0625rem;
  white-space: normal;
  line-height: 150%;
  font-size: 0.84375rem;
}
.notificationMenuItem:hover,
.notificationMenuItem:focus {
  font-weight: normal !important;
}
.notificationMenuItem,
#notificationMenuHeader,
#notificationMenuFooter {
  cursor: pointer;
  padding: 0.225rem;
  border-bottom: 1px solid #d7d7d7;
  width: auto;
  display: block;
}
#notificationMenuHeader,
#notificationMenuFooter {
  padding-left: 2.25rem;
  padding-bottom: 0.7875rem;
  padding-top: 0.7875rem;
}
.notificationMenuItem {
  padding-top: 0.45rem !important;
}
.notificationMenuItem:hover {
  background-color: hsl(196, 100%, 57%);
  color: black;
}
.notificationTimeStamp {
  margin-top: 0.0625rem;
  margin-bottom: 0.45rem;
  font-size: 0.74997rem;
  color: hsl(0, 0%, 66%);
  position: relative;
  top: 0.16875rem;
  margin-right: 0.9rem;
}
li:hover .notificationTimeStamp {
  color: hsl(0, 0%, 30%);
}
.notificationAvatar {
  float: right;
  margin-top: 0.0625rem;
  max-width: 1.2375rem;
}
.notificationCleared {
  background-color: hsl(0, 0%, 94%);
}
#userMenuArea {
  margin-right: 0.45rem;
  margin-left: 1.125rem;
  margin-top: 0.9rem;
}
#userMenuArea #avatar {
  margin-left: 0.16875rem;
  height: 2rem;
}
#userMenuArea.headerUserName {
  margin-top: 0.3rem;
}
#userMenuArea .username {
  position: relative;
  top: 0.333rem;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  max-width: 6.375rem;
  display: inline-block;
}
#userMenuSignedIn {
  min-width: unset;
  margin-top: 0.4rem;
  right: -8px;
}
#userMenuSignedIn a {
  padding-right: 1.5rem;
}
#usernameInMenu {
  font-weight: 800;
}
#signInFromHeader {
  position: relative;
  color: black;
}
#signInFromHeader:hover {
  color: hsl(196, 100%, 39%);
}
.areaChoose {
  margin-top: 0px;
  margin-bottom: -0.25rem;
}
.menuHeading {
  height: auto;
  padding-top: 0.1875rem;
  padding-right: 1.25rem;
  padding-bottom: 0.1875rem;
  padding-left: 0.625rem;
}
#header.privateSandbox {
  background: #deefff;
}
.privateAreaHeader {
  background: #deefff;
  line-height: 400%;
}
#areaLine {
  font-family: Georgia, Garamond, serif;
  font-size: 1.575rem;
  font-weight: bold;
  color: black;
  margin-top: 0.625rem;
  margin-bottom: 0.625rem;
  text-align: center;
}
.topTagsListArea {
  background-color: hsl(0, 0%, 94%);
  padding-top: 0 !important;
  padding-bottom: 0;
  border-top-style: solid !important;
  border-width: 1px;
  border-color: hsl(0, 0%, 75%);
  border-bottom-style: solid !important;
}
.topTagsList {
  display: block;
  margin-bottom: 0;
  margin-left: 2%;
  height: 50px;
  overflow: hidden;
}
.topTagsList .tabUX2 {
  font-weight: 500;
  color: hsl(0, 0%, 18%);
  font-size: 0.84375rem;
  padding: 0.875rem 0.75rem;
  margin-right: 0;
}
#explanationRowHomepage {
  padding-left: 5rem;
  padding-right: 1rem;
  box-sizing: border-box;
  margin-bottom: 0.45rem;
  padding-bottom: 2.75rem;
  background-color: hsl(178, 100%, 94%);
}
.explanationBlock {
  display: block;
  float: none;
  margin-bottom: 2.75rem;
  margin-left: 0;
}
.explanationBlock.smallerMarginRight {
  margin-right: 1.575em;
}
.explanationBlock .icon {
  font-size: 2.75rem;
  margin-right: 2.5rem;
  display: inline-block;
  width: 4rem;
  text-align: center;
  vertical-align: top;
  margin-bottom: 1.125rem;
}
.explanationText {
  color: black;
  font-size: 0.95625rem;
  display: inline-block;
  text-align: left;
  vertical-align: top;
  margin-bottom: 0.5rem;
  margin-left: 0;
  max-width: 415px;
  margin-top: -0.4rem;
}
.explanationBlock .headline {
  font-weight: 800;
  display: block;
}
.explanationBlock .secondary {
  display: block;
}
.explanationImageCentered {
  display: inline-block;
  vertical-align: top;
  height: 2.25rem;
  margin-right: 0.9em;
}
#gotItExplanation {
  margin-left: 6.375rem;
  margin-right: 0;
  margin-bottom: 0;
}
.explanationLabel {
  display: inline-block;
  position: relative;
  font-weight: 800;
  margin-top: 0.75rem;
  margin-bottom: 3.25rem;
  font-size: 2.4rem;
  line-height: 133%;
  margin-left: 0.5rem;
}
.explanationTextCentered {
  color: black;
  font-size: 0.9rem;
  text-align: center;
  max-width: 166px;
  margin: auto;
  margin-bottom: 0.5625rem;
}
.explanationTextCentered.lessWidth {
  max-width: 135px;
}
.explanationImageCentered {
  display: block;
  margin: auto;
}
.mainPageClaimCreationArea {
  margin-left: -0.9375rem;
  margin-top: 1.8rem;
  margin-bottom: 0.9rem;
}
.mainPageClaimCreationLabel {
  font-size: 2rem;
  margin-bottom: 2rem;
}
.claimCreationFormFieldBlock {
  display: inline-block;
  margin-right: 0.3375rem;
}
.mainPageClaimCreationArea .claimCreationFormFieldBlock {
  width: 27.75rem;
}
.createClaimCreationArea {
  width: 27.75rem;
  margin-top: 3.375rem;
}
.addEvidenceForm .claimCreationFormFieldBlock {
  width: 100%;
}
.editPointTextForm .claimCreationFormFieldBlock {
  width: 100%;
}
.claimCreationFormButtonBlock {
  display: inline-block;
  vertical-align: top;
  margin-top: 2.5rem;
  margin-bottom: 0.9rem;
}
.claimCreationFormButtonBlock .formInstruction {
  margin-top: 2rem;
}
.createClaimFormFirstOptionLabel {
  margin-top: 1.675rem;
}
#mainPageClaimCreationForm .claimCreationFormButtonBlock,
.claimEditArea .claimCreationFormButtonBlock {
  margin-top: 0;
}
.contentSection {
  margin-bottom: 1.4994rem;
}
.contentSectionCards {
  display: inline-block;
}
.contentSectionLabel {
  display: inline-block;
  padding: 0.875rem;
  margin-bottom: 0;
  font-size: 0.95625rem;
  font-weight: 500;
  margin-top: 0;
  margin-right: 1rem;
}
.contentSectionHeading .contentSectionLabel {
  float: right;
  clear: right;
}
.contentSectionLabel a:hover {
  text-decoration: underline;
}
.contentSectionLoading {
  display: inline-block;
  padding-left: 0.875rem;
  padding-top: 0;
  padding-bottom: 0;
  margin-left: 0.225rem;
  top: -3.6px;
  position: relative;
}
.contentSectionLabelCopy {
  font-size: 0.84375rem;
  display: inline-block;
  max-width: 8rem;
}
.contentSectionSeeMore {
  margin-bottom: 1rem;
}
.contentSectionLabelSeeMore {
  font-weight: normal;
}
.contentSectionMoreStack:hover .contentSectionLabelSeeMore {
  text-decoration: underline;
}
.contentSectionTitleDot {
  margin-left: 0.225rem;
  margin-right: 0.225rem;
  font-weight: normal;
}
.contentSectionSecondaryLink {
  font-weight: normal;
  display: block;
  margin-top: 0.25rem;
}
.contentSectionMoreStack,
.contentSectionSeeLessLink {
  display: block;
}
.contentSectionAfterLink {
  display: block;
  margin-bottom: 0.9rem;
  position: relative;
  top: -2.5rem;
  left: 9rem;
  width: -webkit-max-content;
  width: max-content;
  margin-left: 0.875rem;
}
.contentSectionSeeLessLink {
  float: right;
  padding: 0.875rem;
}
.sectionIcon {
  margin-right: 0.375rem;
  font-size: 150%;
  display: inline-block;
  vertical-align: top;
}
.iconBig {
  font-size: 3.75rem;
  display: inline-block;
  margin-right: 0.45rem;
  margin-bottom: 0.75rem;
  margin-left: -0.09rem;
}
.hashtagBig {
  font-size: 120%;
  font-weight: bold;
  margin-right: 0.1125rem;
}
.buttonSection .buttonUX2 {
  box-sizing: border-box;
  text-align: left;
  padding-right: 1.35rem;
}
.recPointsContainer {
  margin-top: 1.8rem;
}
.recPointsContainer .contentSectionHeading {
  width: unset;
}
.recPointsContainer .contentSectionLabel {
  float: none;
  clear: none;
}
.recPointsContainer .contentSectionLabelCopy {
  max-width: unset;
}
#mainContainer.userPage {
  padding-left: unset;
}
.profileAreaColumn {
  display: inline-block;
  float: left;
}
#userInfoArea {
  margin-top: 2.3625rem;
  padding: 2.475rem;
  box-sizing: border-box;
  width: 230px;
  padding-right: 1.8em;
}
#display_userName {
  display: inline-block;
  margin-right: 0.2835rem;
  margin-top: 1rem;
  margin-bottom: 1rem;
  font-weight: bold;
  font-size: 1.1953125rem;
}
.profileBodyText {
  font-size: 0.95625rem;
  font-weight: normal;
}
.profilePic {
  margin-top: 0;
}
#profileTabbedArea {
  width: 360px;
  width: calc(100% - 230px);
}
.profileStatsArea {
  margin-bottom: 0.9rem;
}
.profileItem {
  display: block;
  margin-bottom: 0.9rem;
}
.profileItem a {
  overflow: hidden;
  text-overflow: ellipsis;
}
#profileTabbedArea .contentSectionHeading {
  margin-bottom: 0.9rem;
}
#profileDisclaimer {
  margin-top: 2.3625rem;
  background-color: pink;
  padding: 0.875rem;
  padding-left: 1.00625rem;
  border-radius: 4px;
  width: 640px;
  box-sizing: border-box;
  margin-left: -0.875rem;
  margin-bottom: 1.8rem;
}
.editProfileLink {
  display: block;
  margin-top: 0.9rem;
}
#notificationsPage {
  max-width: 480px;
}
#notificationsPage .fullPageFormHeading {
  margin-bottom: 1.8rem;
}
#notificationsAllArea {
  background-color: white;
  margin-left: 0;
  margin-bottom: 0;
  width: 100%;
  border-color: hsl(0, 0%, 75%);
  border-width: 0.0625rem;
  border-style: solid;
}
#notificationsAllArea > li > a {
  padding-left: 0.5625rem;
  padding-top: 0.45rem !important;
  padding-right: 1.5rem;
}
#notificationsAllArea li {
  list-style: none;
}
#footer {
  position: fixed;
  bottom: 0;
  right: 0;
  padding: 0.675rem;
}
.feedbackFooterLink {
  display: block;
  position: relative;
  padding: 0.45rem;
  right: 0.9rem;
  font-weight: 700;
  font-size: 1.0125rem;
  font-style: normal;
  color: black;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}
.contactTitle {
  margin-bottom: 2.3625rem;
}
.contactForm {
  margin-top: 1.8rem;
}
.contactForm,
.contactForm .alert,
.contactForm input,
.contactForm input[type=text],
.contactForm input[type=email],
.contactForm textarea,
.settingsForm .formInstruction {
  width: 100%;
  box-sizing: border-box;
}
.contactForm input.buttonUX2 {
  width: auto !important;
}
.contactForm #instruction {
  margin-bottom: 2.25rem;
}
.historyHeadline,
.valuesHeadline {
  margin-top: 0.675rem;
}
.valuesHeadline {
  margin-bottom: 0.675rem;
}
.historyHeadline {
  margin-bottom: 2.7rem;
}
.historyContent {
  margin-left: 0.9rem;
  margin-top: 2.7rem;
  width: 640px;
}
.historyVersion {
  width: 100%;
}
.historyClaimText {
  font-size: 2rem;
  margin-top: 0.675rem;
  margin-bottom: 1rem;
}
.historySection {
  margin-bottom: 1rem;
}
.historySubheading {
  margin-top: 1.35rem;
  margin-bottom: 0.45rem;
}
.historyItem {
  margin: 0;
  padding: 0;
}
.historyClaimVersionTag {
  font-size: 0.74997rem;
  color: hsl(0, 0%, 66%);
  text-transform: uppercase;
  font-weight: 500;
  margin-left: 0.45rem;
}
.historyDivision {
  width: 50%;
  height: 0.16875rem;
  margin-top: 4rem;
  margin-bottom: 1.8rem;
  background-color: hsl(0, 0%, 18%);
}
.policyHeader {
  margin-left: 2.5rem;
}
.policyContent {
  position: relative;
  left: -1.35rem;
}
.policySectionNumber {
  display: inline-block;
  width: 2rem;
  margin-right: 0.9rem;
  vertical-align: top;
}
h4 .policySectionNumber {
  margin-right: 0.9rem;
}
li .policySectionNumber {
  margin-right: 0.3375rem;
  width: 1.333rem;
}
.policySectionCopy {
  display: inline-block;
  width: calc(100% - 2rem - 0.9rem);
}
.policyBulletList {
  margin-left: 3rem;
  list-style: none;
}
.manifestoShareButtons button {
  margin-right: 0.5rem;
}
.manifestoPrinciples {
  margin-bottom: 1.75rem;
}
.apushistory h2 {
  margin-bottom: 1.6875rem;
}
.walkthrough {
  margin-bottom: 6.75rem;
}
.walkthrough img {
  width: 60%;
}
.walkthrough h2 {
  margin-bottom: 1.6875rem;
}
.walkthrough h4 {
  margin-top: 2.25rem;
  margin-bottom: 1.125rem;
}
.screenshot {
  margin-bottom: 2rem;
}
.screenshotCaption {
  margin-bottom: 0.45rem;
}
.affix,
.affix-top {
  padding: 0;
  padding-right: 0.9rem;
}
.affix-top {
  margin-top: 3rem;
}
.affix {
  top: 135px;
  left: 2rem;
  width: inherit;
}
.besideAffix {
  margin-left: 7rem;
}
#educationInfoNav {
  list-style-position: outside;
  margin: 0;
  padding-right: 0.45rem;
}
#educationInfoNav li {
  font-size: 0.84375rem;
  line-height: 175%;
  list-style-type: none;
  list-style-position: outside;
}
.introQuote {
  font-style: italic;
}
h2.idLinkOffset:before {
  display: block;
  content: " ";
  margin-top: -3.9375rem;
  height: 3.9375rem;
  visibility: hidden;
}
.infoToggle,
.infoNonToggle,
.superInfoToggle {
  margin: 0px;
  list-style-type: none;
  list-style-position: outside;
}
.adminTopButtons a,
.adminPageTitle {
  display: inline-block;
  margin-right: 1rem;
  margin-left: 1rem;
}
.adminTopButtons {
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}
.adminTopLink {
  margin-left: 0.5rem;
  margin-right: 0.5rem;
}
.adminTable {
  table-layout: fixed;
  white-space: nowrap;
}
.adminTable td {
  border: 1px solid hsl(0, 0%, 44%);
  padding: 0.125rem 0.5rem;
  white-space: normal;
  overflow: hidden;
  text-overflow: ellipsis;
  min-width: 90px;
}
.adminTable th {
  border: 1px solid hsl(0, 0%, 44%);
  background-color: hsl(35, 100%, 90%);
  padding: 0.5rem;
}
.adminTableDetail {
  font-size: 0.74997rem;
  color: hsl(0, 0%, 30%);
  font-family: "ff-meta-web-pro", sans-serif;
  font-weight: 400;
  line-height: 125%;
  margin-top: 0.5rem;
  display: block;
  font-style: italic;
}
.adminTableDateCol {
  text-align: center;
}
.adminTableSimpleNumber {
  white-space: normal !important;
  max-width: 68px;
  text-align: center;
}
.adminTableClassrooms {
  white-space: normal !important;
  max-width: 500px;
  text-align: center;
}
.adminTableEllipsisObvi {
  white-space: normal;
}
.role-chooser {
  width: 100px;
  margin: 0.125rem;
}
.upstreamLinks {
  display: block;
  font-size: 0.84375rem;
  color: hsl(0, 0%, 18%);
  margin-bottom: 1.35rem;
  width: 640px;
  padding-right: 2rem;
  box-sizing: border-box;
  position: relative;
  left: -1.09875rem;
}
.upstreamLinks .dropdown-caret {
  left: 1.2375rem;
}
.showParentLabel {
  font-weight: 500;
}
.parentClaimTitle {
  margin-left: 1.125rem;
}
.upstreamLinks > .dropdown-menu {
  left: 0.45rem;
  top: 42px;
  max-width: 93vw;
}
.upstreamLinks li {
  width: 100%;
}
.upstreamLinks li > a {
  text-overflow: ellipsis;
  width: 100%;
  display: block;
  overflow: auto;
  box-sizing: border-box;
}
.pointPageContainer {
  margin-left: 0px;
}
.profileAreaColumn .pointPageContainer {
  margin-top: 0.9rem;
}
.listedClaimGroup {
  width: auto;
}
.listedClaimGroup.expanded {
  margin-bottom: 0;
}
.evidenceRow {
  display: block;
  position: relative;
  left: -0.75rem;
}
@-webkit-keyframes evidenceBlockReveal {
  0% {
    max-height: 1.125rem;
    opacity: 0;
  }
  40% {
    opacity: 0;
    -webkit-transform: translateY(-1.125rem);
            transform: translateY(-1.125rem);
  }
  80% {
    max-height: 2000px;
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
  100% {
    max-height: 5000px;
    opacity: 1;
  }
}
@keyframes evidenceBlockReveal {
  0% {
    max-height: 1.125rem;
    opacity: 0;
  }
  40% {
    opacity: 0;
    -webkit-transform: translateY(-1.125rem);
            transform: translateY(-1.125rem);
  }
  80% {
    max-height: 2000px;
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
  100% {
    max-height: 5000px;
    opacity: 1;
  }
}
.evidenceBlock,
.qAnswers {
  display: inline-block;
  -webkit-transform-origin: top left;
          transform-origin: top left;
  -webkit-animation-name: evidenceBlockReveal;
          animation-name: evidenceBlockReveal;
  -webkit-animation-duration: 0.36s;
          animation-duration: 0.36s;
  -webkit-animation-timing-function: ease;
          animation-timing-function: ease;
  -webkit-animation-iteration-count: 1;
          animation-iteration-count: 1;
  position: relative;
  top: -0.5rem;
}
.evidenceBlock {
  margin-left: 2.25rem;
}
.contentSectionHeading,
.buttonSection,
#tagSearchResultsArea {
  margin-left: -0.9375rem;
  margin-bottom: 0.225rem;
  width: 640px;
}
.contentSectionHeading {
  padding-top: 0;
  margin-top: -0.5rem;
  display: inline-block;
  width: 12.25rem;
  vertical-align: top;
}
.contentSection .contentSectionHeading {
  margin-left: -3rem;
}
.contentSectionHeading:focus {
  border: none;
  outline: none;
}
.contentSectionHeadingSeeLess {
  margin-top: 1.197rem;
}
.contentSectionFooter {
  margin-top: 0.9rem;
  margin-bottom: 0.9rem;
}
.buttonSection {
  display: block;
  margin-left: -0.8125rem;
  margin-top: 2.675rem;
  margin-bottom: 1.75rem;
  width: -webkit-max-content;
  width: max-content;
}
/* I think this is from when Quick Create was on the home page, and is now longer needed —JF
@keyframes latestQuickCreateReveal {
   0% { max-height: 1.125rem; opacity: 0;  }
   40% { opacity: 0; ; } //  transform: scaleY(.5)
   85% {  max-height: 315px; } //  transform: scaleY(1
   100% { max-height: 315px; opacity: 1; }
}
.latestQuickCreate {
  transform-origin: top left;
  animation-name: latestQuickCreateReveal;
  animation-duration: (@evidenceBlockRevealDuration * .8);
  animation-timing-function: ease;
}*/
.optionsContainer {
  margin-top: 0.9rem;
  margin-bottom: 0.675rem;
}
.heading {
  color: hsl(0, 0%, 30%);
  text-transform: uppercase;
  font-size: 0.74997rem;
  font-weight: 500;
}
.evidenceTypeLabel {
  text-transform: uppercase;
  vertical-align: top;
}
.evidenceBlockHeading {
  margin-left: 4.375rem;
  margin-bottom: 0.9rem;
}
.counterHeading {
  color: hsl(358, 100%, 30%);
}
.headingPlus {
  position: relative;
  top: -1px;
}
.sortBy {
  text-transform: normal;
  float: right;
}
.evidenceBlockCounter,
.evidenceBlockSupport,
.evidenceBlockBoth,
.evidenceBlockEmpty {
  display: inline-block;
  position: relative;
  vertical-align: top;
}
.evidenceBlockCounter,
.evidenceBlockSupport,
.evidenceBlockBoth {
  margin-bottom: 1.35rem;
}
.evidenceBlockCounter {
  margin-left: 1.25rem;
}
.evidenceBlockCounter.evidenceBlockFirstColAlignment {
  margin-left: 0;
}
.stackMarginBottom0 {
  margin-bottom: 0.675rem;
}
.stackMarginBottom1 {
  margin-bottom: 0.8625rem;
}
.stackMarginBottom2 {
  margin-bottom: 1.05rem;
}
.stackMarginBottom3 {
  margin-bottom: 2.2375rem;
}
.listedClaim {
  display: inline-block;
  padding-right: 1.75rem;
  outline-style: none;
}
.removeOneIndent {
  position: relative;
  left: -2.25rem;
}
.addOneIndent {
  position: relative;
  left: 2.25rem;
}
.pointListEndMessage {
  margin-top: 1.6875rem;
  margin-left: 1.6875rem;
  margin-bottom: 10.125rem;
  display: block;
  font-size: 0.74997rem;
  color: hsl(0, 0%, 30%);
}
@-webkit-keyframes moreClaimsDivisionFadeOn {
  0% {
    opacity: 0;
  }
  40% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes moreClaimsDivisionFadeOn {
  0% {
    opacity: 0;
  }
  40% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.moreClaimsDivision {
  display: none;
}
.moreClaimsDivision {
  width: calc(640px + (2 * 0.875rem));
  display: block;
  position: relative;
  margin-left: 0;
  padding-bottom: 0;
  padding-left: 0;
  margin-top: 1.8rem;
  margin-bottom: 1rem;
  background-color: hsl(0, 0%, 75%);
  height: 1px;
  -webkit-animation-name: moreClaimsDivisionFadeOn;
          animation-name: moreClaimsDivisionFadeOn;
  -webkit-animation-iteration-count: 1;
          animation-iteration-count: 1;
  -webkit-animation-timing-function: ease;
          animation-timing-function: ease;
  -webkit-animation-duration: 0.504s;
          animation-duration: 0.504s;
}
.evidenceBlockEmpty .moreClaimsDivision {
  margin-left: 2rem;
  margin-top: 87px;
}
@-webkit-keyframes stackCardDealBottomKeys {
  0% {
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0);
  }
  100% {
    -webkit-transform: translate(0, 18rem);
            transform: translate(0, 18rem);
  }
}
@keyframes stackCardDealBottomKeys {
  0% {
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0);
  }
  100% {
    -webkit-transform: translate(0, 18rem);
            transform: translate(0, 18rem);
  }
}
.stackCardDealBottom {
  -webkit-animation-name: stackCardDealBottomKeys;
          animation-name: stackCardDealBottomKeys;
  -webkit-animation-duration: 0.18s;
          animation-duration: 0.18s;
  -webkit-animation-timing-function: cubic-bezier(0.25, 0, 0.9, 0.4);
          animation-timing-function: cubic-bezier(0.25, 0, 0.9, 0.4);
  -webkit-animation-iteration-count: 1;
          animation-iteration-count: 1;
}
@-webkit-keyframes stackCardDealInvertXform {
  0% {
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0);
  }
  100% {
    -webkit-transform: translate(0, -6rem);
            transform: translate(0, -6rem);
  }
}
@keyframes stackCardDealInvertXform {
  0% {
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0);
  }
  100% {
    -webkit-transform: translate(0, -6rem);
            transform: translate(0, -6rem);
  }
}
.stackCardDealInvertXform {
  -webkit-animation-name: stackCardDealInvertXform;
          animation-name: stackCardDealInvertXform;
  -webkit-animation-duration: 0.18s;
          animation-duration: 0.18s;
  -webkit-animation-timing-function: cubic-bezier(0.25, 0, 0.9, 0.4);
          animation-timing-function: cubic-bezier(0.25, 0, 0.9, 0.4);
  -webkit-animation-iteration-count: 1;
          animation-iteration-count: 1;
}
.stackCardDealFade {
  border-color: transparent !important;
  background: none !important;
  transition: 0.18s;
  transition-timing-function: cubic-bezier(0.25, 0, 0.9, 0.4);
}
@-webkit-keyframes relGroupHiliteReveal {
  0% {
    background-color: transparent;
  }
  100% {
    background-color: hsl(52, 95%, 93%);
  }
}
@keyframes relGroupHiliteReveal {
  0% {
    background-color: transparent;
  }
  100% {
    background-color: hsl(52, 95%, 93%);
  }
}
.relGroupHilite {
  -webkit-animation-name: relGroupHiliteReveal;
          animation-name: relGroupHiliteReveal;
  -webkit-animation-duration: 0.33s;
          animation-duration: 0.33s;
  -webkit-animation-timing-function: ease;
          animation-timing-function: ease;
  -webkit-animation-iteration-count: 1;
          animation-iteration-count: 1;
  margin-bottom: -0.9rem;
  background-color: hsl(52, 95%, 93%);
  max-width: 30.525rem;
}
.relevanceCtrlArea {
  padding-bottom: 0.9rem;
  margin-bottom: -0.9rem;
  -webkit-animation-name: relevanceCtrlAreaReveal;
          animation-name: relevanceCtrlAreaReveal;
  -webkit-animation-duration: 0.33s;
          animation-duration: 0.33s;
  -webkit-animation-timing-function: ease;
          animation-timing-function: ease;
  -webkit-animation-iteration-count: 1;
          animation-iteration-count: 1;
  background-color: hsl(178, 100%, 94%);
  position: relative;
  display: block;
}
@-webkit-keyframes relevanceCtrlAreaReveal {
  0% {
    max-height: 0;
    opacity: 0;
  }
  12% {
    opacity: 1;
  }
  60% {
    max-height: 432px;
  }
  100% {
    opacity: 1;
  }
}
@keyframes relevanceCtrlAreaReveal {
  0% {
    max-height: 0;
    opacity: 0;
  }
  12% {
    opacity: 1;
  }
  60% {
    max-height: 432px;
  }
  100% {
    opacity: 1;
  }
}
.linkArea {
  width: 27.75rem;
  /* ...and now for the proper property */
  transition: 0.066s;
  -webkit-transform-origin: bottom center;
          transform-origin: bottom center;
  margin-bottom: -2rem;
}
.linkArea:hover .number {
  text-decoration: underline;
  font-weight: 800;
}
.linkArea .number {
  text-decoration: none !important;
  vertical-align: top;
}
.relevanceLink:hover .arrowHeadUp {
  border-bottom-color: white;
}
.relevanceLink:hover .arrowStemEvidence {
  border-left-color: white;
  border-bottom-color: white;
}
.relLinkAndStackCards {
  width: -webkit-max-content;
  width: max-content;
  width: -moz-max-content;
}
.linkAreaBase {
  display: inline-block;
  position: relative;
  height: 2.475rem;
  width: 2.25rem;
}
.linkAreaBase.relRaterExpanded {
  background-color: hsl(178, 100%, 94%) !important;
}
.relevanceLink .commentIcon {
  display: inline-block;
  padding-left: 0.875rem;
  padding-right: 0.875rem;
  padding-bottom: 0.7rem;
  padding-top: 0.58275rem;
  vertical-align: top;
  top: -11px;
  position: relative;
}
.relevanceLink,
.relevanceCtrlArea,
.linkAreaBase {
  border-style: solid;
  border-width: 0.0625rem;
  border-color: hsl(0, 0%, 75%);
}
.relevanceLink.counter,
.relevanceCtrlArea.counter,
.linkAreaBase.counter {
  border-color: hsl(358, 60%, 75%);
}
.relevanceCtrlArea,
.relevanceLink {
  border-bottom-style: none;
}
.relevanceCtrlArea,
.linkAreaBase {
  border-top-style: none;
}
.relevanceLink {
  display: block;
  position: relative;
  height: 2.475rem;
  margin-bottom: -1px;
}
.relevanceLink,
.linkAreaBase {
  color: hsl(0, 0%, 30%);
  background-color: hsl(0, 0%, 94%);
}
.relevanceLink:hover:not(.relRaterExpanded),
.linkArea:hover:not(.relRaterExpanded) .linkAreaBase {
  color: white;
  background-color: black;
}
.relevanceLink.counter,
.linkAreaBase.counter {
  color: hsl(358, 100%, 30%);
  background-color: hsl(10, 100%, 96%);
}
.relevanceLink.counter:hover:not(.relRaterExpanded),
.linkArea:hover:not(.relRaterExpanded) .linkAreaBase.counter {
  color: white ;
  background-color: hsl(358, 100%, 30%);
}
.relevanceDisplay {
  display: inline-block;
  position: relative;
  font-size: 0.84375rem;
  padding-top: 0.7rem;
  left: 2.41875rem;
  vertical-align: top;
}
.relCtrlGroup {
  margin-left: 2.41875rem;
  padding-top: 0.7rem;
  padding-right: 0.7rem;
}
.relCtrlVoteArea,
.relCtrlCommentsArea .commentsArea {
  padding-bottom: 1.5rem;
}
.relevanceDisplayCopy {
  display: inline-block;
  vertical-align: top;
}
.relevanceDisplay .number {
  font-size: 88%;
}
.relCtrlVoteOptions {
  position: relative;
}
.relVoteLink,
.relVoteLink button {
  font-size: 1.35rem;
  font-weight: bold;
  background-color: inherit;
  color: hsl(196, 100%, 39%);
  border-color: hsl(196, 100%, 39%);
  margin-right: -0.0625rem;
  margin-bottom: 0.375rem;
  display: inline-block;
  padding-top: 1rem;
  padding-bottom: 1rem;
  padding-right: 0.875rem;
  padding-left: 1rem;
  min-width: 40px;
  height: 22.5px;
  vertical-align: middle;
  text-align: center;
  border-radius: 0;
  border-width: 0.0625rem;
  border-style: solid;
  outline: none;
  box-sizing: content-box;
}
.relVoteLink:hover {
  border-color: hsl(196, 100%, 57%);
  background-color: hsl(196, 100%, 57%);
  color: white;
}
.relVoteLink:active {
  -webkit-transform: scale(0.975);
          transform: scale(0.975);
}
.relVoteLink0,
.relVoteLink33,
.relVoteLink66 {
  border-left: none;
}
/* color hover on each relevance button—black, grey, pink, red—but I think this adds too much cognitive load
.relVoteLink0:hover {
  background-color: @red; // @blueLight;
  border-color: @red;
}
.relVoteLink33:hover {
  background-color: lighten(@red, 50); // @blueLight;
  border-color: lighten(@red, 50);
}
.relVoteLink66:hover {
  background-color: lighten(@black, 45); // @blueLight;
  border-color: lighten(@black, 45);
}
.relVoteLink100:hover {
  background-color: @black; // @blueLight;
  border-color: @black;
}
*/
.myRelevanceVote100 {
  color: white;
  background-color: hsl(196, 100%, 39%);
  border-color: hsl(196, 100%, 39%);
}
.myRelevanceVote66 {
  color: white;
  background-color: hsl(196, 100%, 39%);
  border-color: hsl(196, 100%, 39%);
}
.myRelevanceVote33 {
  color: white;
  background-color: hsl(196, 100%, 39%);
  border-color: hsl(196, 100%, 39%);
}
.myRelevanceVote0 {
  color: white;
  background-color: hsl(196, 100%, 39%);
  border-color: hsl(196, 100%, 39%);
}
/*
.myRelevanceVoteHigh {
  color: black !important ;
  border-style: solid;
    border-width: @relLinkBoxWidth;
    border-color: black;
  //border-color: black;
}
.myRelevanceVoteLow {
  color: @red ;
  border-style: solid;
    border-width: @relLinkBoxWidth;
    border-color: @red;
  //border-color: @red;
}
*/
.relevanceExplanation,
.relevanceStats {
  font-size: 0.74997rem;
  color: hsl(0, 0%, 18%);
  margin-top: 0.5rem;
}
.relevanceExplanation {
  margin-top: 1rem;
  margin-bottom: 1rem;
}
.perctSignSmall {
  font-size: 60%;
  vertical-align: top;
  position: relative;
  top: -3px;
  text-decoration: none !important;
}
.perctSignSmallRelLink {
  font-size: 75%;
  vertical-align: top;
  position: relative;
  top: -1px;
  left: 1px;
  text-decoration: none !important;
  margin-right: 5px;
}
a:hover .perctSignSmall {
  display: inline-block;
  text-decoration: none !important;
}
.noRelevantClaimsArea {
  font-size: 0.84375rem;
  font-weight: 500;
  margin-bottom: 3.6rem;
  display: block;
}
.toggleLowRelClaims {
  display: block;
  margin-left: 2.40625rem;
  margin-bottom: 2.8125rem;
  position: relative;
  bottom: 0.3375rem;
}
a.toggleLowRelClaims {
  color: hsl(196, 100%, 39%);
}
a.toggleLowRelClaims:hover {
  text-decoration: underline;
}
.noRelevantClaimsMessage {
  display: block;
  margin-left: 2.48125rem;
  color: hsl(0, 0%, 18%);
}
.stackCardGroup {
  position: relative;
  display: inline-block;
  margin-bottom: -0.45rem;
  outline-style: none;
}
.removeOneIndent .stackCardGroup {
  left: 2.25rem;
}
.stackCardGroup:focus {
  outline-style: none;
}
.stackCard {
  border-color: hsl(0, 0%, 75%);
  border-width: 0.0625rem;
  border-style: solid;
  background-color: white;
  width: 27.75rem;
  position: relative;
  left: -0.25rem;
  top: -0.1875rem;
  box-sizing: border-box;
  -webkit-transform-origin: top left;
          transform-origin: top left;
  vertical-align: top;
}
.wideCard {
  width: 640px;
}
.stackCardHidden {
  border-color: transparent !important;
  background: none !important;
}
.point-card {
  display: block;
  overflow-wrap: break-word;
}
.pointCardPaddingH {
  padding-left: 0.875rem;
  padding-right: 0.7rem;
}
.pointCardPaddingHExtra {
  padding-right: 1.04375rem;
}
.relExtraMarginBottom {
  margin-bottom: 0.45rem;
}
.stackCardGroupActive:hover .stackCard,
.contentSectionMoreStack:hover .stackCard {
  border-color: hsl(0, 0%, 44%);
}
.stackCardGroupActive:hover {
  cursor: pointer;
}
.pointCardActive:hover .pointTitle a {
  text-decoration: underline;
}
.stackCardGroupActive:focus .stackCard {
  /*.listedClaim:focus .stackCard {*/
  background-color: hsl(0, 0%, 94%);
  outline-style: none;
}
.stackCard.counter {
  border-color: hsl(358, 60%, 75%);
}
.stackCardGroupActive:focus .stackCard.counter {
  background-color: hsl(10, 100%, 96%);
}
.stackCardGroupActive:hover .stackCard.counter {
  border-color: hsl(358, 100%, 30%);
}
.cardBody {
  display: block;
}
.contentNoImage {
  display: inline-block;
  width: 100%;
}
.claimTextDisplay.wideCard {
  padding-right: 2rem;
  width: unset;
}
.contentWithImage {
  display: table-cell;
  width: 76%;
}
.imageContainer {
  display: block;
  padding: 0.5rem;
  width: 24%;
  height: calc(100% - 42px);
  position: absolute;
  right: 0;
  box-sizing: border-box;
}
.imageContainerBig {
  position: relative;
  width: 100%;
  padding: 0.875rem;
  padding-bottom: 1.4525rem;
}
.claimImage {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.imageFaded {
  opacity: 0.24;
}
.imageCaption {
  display: inline-block;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  font-size: 0.74997rem;
  color: hsl(0, 0%, 66%);
  margin-top: 0.3375rem;
}
.claimTextDisplay {
  display: block;
  padding-top: 0.7rem;
  margin-bottom: 0.375rem;
}
.claimTextDisplay.wideCard {
  padding-right: 1.8125rem;
  width: unset;
}
.pointTitle,
h1.pointTitle,
h2.pointTitle,
h3.pointTitle,
h4.pointTitle,
h5.pointTitle,
h6.pointTitle {
  display: inline;
  color: black;
  font-weight: 500;
  line-height: 150%;
  letter-spacing: 0.01em;
  font-size: 0.95625rem;
  margin: 0;
  padding-top: 0;
}
.pointTitle a {
  color: black;
}
.pointTitle a:focus {
  outline: none;
}
.score {
  font-size: 100%;
}
.ux2ScoreInLine {
  display: inline-block;
  font-weight: bold;
  padding-left: 0.225rem;
}
.scorePreAnimate {
  display: inline-block;
}
.scoreAnimContainerMax {
  font-size: 125%;
  line-height: 0;
}
.scoreAnimContainerReset {
  font-size: 80%;
}
.negativeScore {
  color: hsl(358, 100%, 30%);
}
.Score--bounce {
  -webkit-animation: Score-keyframes--bounce 0.4s ease;
          animation: Score-keyframes--bounce 0.4s ease;
  -webkit-transform-origin: left bottom;
          transform-origin: left bottom;
}
@-webkit-keyframes Score-keyframes--bounce {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
  40% {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}
@keyframes Score-keyframes--bounce {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
  40% {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}
.stats {
  width: 190px;
  position: relative;
  left: -1.35rem;
  top: 1.8125rem;
  padding: 0;
}
.stats .admin {
  color: hsl(0, 0%, 18%);
}
.stats p {
  padding: 0.675rem;
  margin-bottom: 0;
  font-size: 0.84375rem;
}
.scoreLearnMore i {
  display: inline-block;
  vertical-align: top;
  top: 4px;
}
.hashLinkCopy {
  display: inline-block;
  vertical-align: top;
}
.cardTopRowItem {
  margin-right: 0.315rem;
  vertical-align: top;
}
.cardTopRowItem a,
.cardTopRowItem button {
  color: hsl(0, 0%, 66%);
}
.icon,
.iconWithStat {
  margin-right: 0.125rem;
}
li .iconWithStat {
  width: 0.675rem;
  display: inline-block;
  margin-right: 0.3375rem;
}
.bylineItemHighlighted {
  padding-top: 1px !important;
  padding-bottom: 1px !important;
  border-radius: 4px;
}
.recentlyUpdated {
  color: hsl(0, 0%, 18%);
  background-color: hsl(60deg 100% 81%);
}
.claimCardSecondaryInfo {
  margin-bottom: 0.9rem;
}
.byline {
  font-size: 0.74997rem;
  color: hsl(0, 0%, 66%);
  width: inherit;
  display: inline-block;
  float: none;
  margin-bottom: -2px;
}
.byline a {
  color: hsl(0, 0%, 66%);
}
.bylineAuthor {
  display: inline-block;
  vertical-align: top;
  max-width: 130px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  /*margin-right: 2px;*/
}
.bylineAuthor:hover {
  text-decoration: underline;
}
.moderatorIcon {
  margin-left: 0.125rem;
}
.cardTopRowItem a:hover {
  color: hsl(196, 100%, 39%);
}
.cardTopRowItem .easierToClickOn {
  padding-top: 0.225rem;
  padding-bottom: 0.225rem;
}
.contributorsMenu {
  width: -webkit-max-content;
  width: max-content;
}
.cardTopRowItem .dropdown-menu {
  width: -webkit-max-content;
  width: max-content;
}
.sources {
  margin-bottom: 0.3375rem;
}
.flareLabel {
  margin-right: 0.3375rem;
}
.sourceLabel {
  max-width: calc(100% - 2rem);
  display: inline-block;
  overflow: hidden;
  vertical-align: bottom;
  text-overflow: ellipsis;
  white-space: nowrap;
}
a.source {
  font-size: 0.74997rem;
  color: hsl(0, 0%, 66%);
  display: inline;
  margin-bottom: 0.25rem;
  margin-right: 0.25rem;
  width: 100%;
}
.source:hover,
a.source:hover {
  color: hsl(196, 100%, 39%);
}
.iconSourcesSmall,
.iconTagsSmall,
.iconEditorsPick {
  margin-right: 0.0625rem;
  position: relative;
  display: inline-block;
}
.iconSourcesSmall,
.iconEditorsPick {
  margin-right: 0.375rem;
}
.iconSourcesSmall,
.iconTagsSmall {
  font-size: 1.1em;
}
.iconEditorsPick {
  font-size: 1.1em;
}
.iconTagsSmall {
  margin-left: 0.0625rem;
}
.iconSourcesSmall {
  vertical-align: top;
  margin-left: 0.0625rem;
}
.sources .fa-book-open {
  position: relative;
  top: 2px;
}
.iconWithStat .fa-book-open {
  position: relative;
  left: -1px;
}
.bylineAdminItem {
  padding-top: 0.09rem;
  padding-bottom: 0.18rem;
  border-radius: 4px;
}
.tags {
  display: inline;
}
.hashtag {
  font-size: 120%;
  font-weight: 500;
  vertical-align: top;
}
.tagLabel {
  max-width: 225px;
  display: inline-block;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  vertical-align: top;
}
a.tag {
  font-size: 0.74997rem;
  color: hsl(0, 0%, 66%);
  display: inline-block;
  margin-bottom: 0.225rem;
  max-width: 100%;
  width: -webkit-max-content;
  width: max-content;
}
.tags .tag {
  margin-right: 0.45rem;
}
.tag:hover,
a.tag:hover {
  color: hsl(196, 100%, 39%);
}
.buttonUX2 .icon {
  margin-right: 0.3375rem;
}
.cardBottomActionRow,
.cardBottomActionBlock,
.cardBottomAction {
  border-color: hsla(0, 0%, 75%, 0.45);
  border-width: 0.0625rem;
  -webkit-transform-origin: bottom left;
          transform-origin: bottom left;
}
.cardBottomActionRow {
  border-top-style: solid;
  width: 100%;
}
.cardBottomActionBlock {
  display: inline-block;
  vertical-align: top;
  padding-left: 0.875rem;
  padding-right: 0.875rem;
  padding-bottom: 0.3125rem;
  padding-top: 0.5125rem;
  border-right-style: solid;
  height: 42px;
  box-sizing: border-box;
}
.agreeDisagree {
  display: inline-block;
}
.cardBottomActionBlock.agree,
.cardBottomActionBlock.moreMenuBlock {
  border-right-style: none;
}
.seeEvidenceBlock {
  padding-right: 0.65625rem;
}
.cardBottomActionBlock.agree {
  padding-right: 0.595rem;
  padding-left: 0.65625rem;
}
.cardBottomActionBlock.disagree {
  padding-right: 0.65625rem;
  padding-left: 0.595rem;
}
.stackCardGroupActive:focus .cardBottomActionRow,
.stackCardGroupActive:focus .cardBottomAction {
  /*.listedClaim:focus .cardBottomActionRow, .listedClaim:focus .cardBottomAction  {*/
  font-weight: bold;
  color: hsl(196, 100%, 39%);
}
/*.stackCardGroup:hover .cardBottomActionRow, .stackCardGroup:hover .cardBottomAction  {
    transform: scale(@cardBottomActionScaleUpOnHover) ;
  font-weight: bold;
  color: @blue ;
}*/
.cardBottomAction {
  font-size: 0.84375rem;
  color: hsl(0, 0%, 18%);
  font-weight: 500;
  margin-right: 0;
  display: inline-block;
  z-index: 100;
  line-height: 0;
}
.pointCardActive:hover .seeEvidenceBlock {
  color: black ;
  text-decoration: none;
}
.pointCardActive .cardBottomActionBlock:hover .cardBottomAction {
  color: black ;
  text-decoration: none;
}
.stackCard .current-vote {
  color: white !important;
}
.stackCard .cardBottomActionBlock.agree.current-vote {
  background-color: black;
}
.stackCard .cardBottomActionBlock.disagree.current-vote {
  background-color: hsl(358, 100%, 30%);
}
.pointCardActive .cardBottomActionBlock:hover {
  background-color: hsl(196, 100%, 57%);
}
.cardBottomAction:active,
.cardBottomActionBlock:active .cardBottomAction {
  -webkit-transform: scale(0.96) !important;
          transform: scale(0.96) !important;
  -webkit-transform-origin: bottom;
          transform-origin: bottom;
}
.cardBottomAction i {
  margin-right: 0.225rem;
}
.seeEvidenceBlock {
  width: 120px;
  box-sizing: border-box;
}
.commentLink {
  position: relative;
}
.commentLink .iconWithStat {
  margin-right: 0.175rem;
}
.cardBottomActionRow .commentLink {
  font-size: 0.875em;
  color: hsl(0, 0%, 30%);
}
.cardTopRowItem .hasComments,
.cardBottomActionRow .hasComments {
  color: hsl(0, 0%, 18%);
}
.moreMenuLink {
  color: hsl(0, 0%, 18%);
  font-size: 0.75em !important;
  position: relative;
  margin-left: 0.1125rem;
  padding: 0;
  vertical-align: top;
  top: 2px;
}
.moreMenuLink:hover {
  text-decoration: none;
}
.moreMenu,
#emojiMenu {
  font-size: 0.74997rem;
}
.moreMenu {
  width: 124px;
  left: auto !important;
  top: 36px;
  margin-right: -1px;
}
.bottomActionDot {
  margin-right: 0.5rem;
  margin-left: 0.5rem;
  vertical-align: top;
}
.bottomActionDot:hover {
  text-decoration: none !important;
}
@-webkit-keyframes editAreaReveal {
  0% {
    max-height: 0;
    opacity: 0;
    padding-top: 0;
  }
  75% {
    max-height: 810px;
    padding-top: 0.525rem;
  }
  100% {
    opacity: 1;
  }
}
@keyframes editAreaReveal {
  0% {
    max-height: 0;
    opacity: 0;
    padding-top: 0;
  }
  75% {
    max-height: 810px;
    padding-top: 0.525rem;
  }
  100% {
    opacity: 1;
  }
}
@-webkit-keyframes commentsAreaReveal {
  0% {
    max-height: 0;
    opacity: 0;
    padding-top: 0;
  }
  75% {
    max-height: 920px;
    padding-top: 0.525rem;
  }
  100% {
    opacity: 1;
  }
}
@keyframes commentsAreaReveal {
  0% {
    max-height: 0;
    opacity: 0;
    padding-top: 0;
  }
  75% {
    max-height: 920px;
    padding-top: 0.525rem;
  }
  100% {
    opacity: 1;
  }
}
.editClaimText,
.editImage,
.editSources,
.commentsArea {
  -webkit-animation-duration: 0.33s;
          animation-duration: 0.33s;
  -webkit-animation-timing-function: ease;
          animation-timing-function: ease;
  -webkit-animation-iteration-count: 1;
          animation-iteration-count: 1;
}
.editClaimText,
.editImage,
.editSources {
  -webkit-animation-name: editAreaReveal;
          animation-name: editAreaReveal;
}
.editImage,
.commentsArea {
  width: 27.75rem;
  box-sizing: border-box;
  position: relative;
  left: -0.8125rem;
  border-color: hsl(0, 0%, 75%);
  border-width: 1px;
  border-style: solid;
}
.commentsArea.wideCard,
.editImage.wideCard {
  width: 640px;
}
.editImage {
  margin-top: -0.45rem !important;
  margin-bottom: 0 !important;
}
.claimEditArea.commentsArea {
  -webkit-animation-name: commentsAreaReveal;
          animation-name: commentsAreaReveal;
  display: block;
  top: -0.46875rem;
  margin-bottom: 0px;
  margin-top: 0 ;
}
.relCtrlCommentsArea .commentsArea {
  left: 0;
  top: 0;
  border-style: none;
  border-top-style: solid !important;
  border-bottom-style: solid;
  background-color: transparent;
  padding-left: 0;
  padding-right: 0;
  margin-right: 1.25rem;
  width: unset;
  -webkit-animation-name: unset;
          animation-name: unset;
}
.commentsList,
.commentButtons {
  width: 100%;
  display: block;
}
.newCommentTextField {
  height: 5rem;
}
.commentIndent {
  width: 94%;
  margin-left: 6%;
}
.commentText,
.commentText .DraftEditor-root {
  font-size: 0.84375rem;
  color: hsl(0, 0%, 18%);
  margin-bottom: 0.84375rem;
  word-break: break-word;
}
.commentArchived .commentText,
.commentResolved .commentText,
.commentArchived .DraftEditor-root,
.commentResolved .DraftEditor-root {
  color: hsl(0, 0%, 66%);
}
.commentsArea .divider {
  border: none;
  height: 1px;
  overflow: hidden;
  background-color: hsl(0deg 0% 84%);
  margin-top: 1.1875rem;
  margin-bottom: 1rem;
}
.commentButton {
  margin-right: 0.25rem;
  margin-bottom: 0.25rem;
  font-size: 0.84375rem;
  padding: 0.875rem 1rem;
  box-sizing: content-box;
}
.commentButton i {
  margin-right: 0.225rem;
}
.newCommentPublishButton {
  margin-top: 0;
  margin-bottom: 0 !important;
}
.reportCommentIcon {
  font-size: 80%;
}
.quickCommentBadge {
  display: inline-block;
  color: hsl(0, 0%, 18%);
  font-size: 0.74997rem;
  font-weight: 500;
  border-color: hsl(0, 0%, 18%);
  border-width: 1px;
  border-style: dashed;
  /*border-radius: @borderRadiusBadges;*/
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  padding-top: 0.375rem;
  padding-bottom: 0.5rem;
  margin-right: -1px;
  margin-top: 0.225rem;
  margin-bottom: 0.1125rem;
}
.comment .quickCommentBadge {
  margin-top: 0.9rem;
  margin-bottom: 0.9rem;
}
.commentResolved .quickCommentBadge,
.commentArchived .quickCommentBadge {
  color: hsl(0, 0%, 66%);
  border-color: hsl(0, 0%, 66%);
}
.newCommentCreationForm,
.newCommentCreationForm textarea,
.newCommentCreationForm .DraftEditor-root {
  font-size: 0.84375rem;
}
.newCommentCreationForm .quickCommentBadge {
  margin-bottom: 1.125rem;
}
.commentsList .quickCommentBadge {
  padding-top: 0.3125rem;
}
.myQuickComment {
  background-color: hsl(60deg 100% 81%);
}
.quickCommentsList {
  display: inline-block;
}
.quickCommentsList .quickCommentBadge:hover {
  background-color: hsl(196, 100%, 57%);
}
.quickCommentsList .quickCommentBadge.myQuickComment:hover {
  background-color: hsl(196, 100%, 57%);
}
.quickCommentBadgeText {
  margin-left: 0.125rem;
  margin-right: 0.125rem;
}
.quickCommentBadge .icon {
  margin-right: 0.15rem;
  font-size: 115%;
}
.quickCommentBadgeCount {
  margin-left: 0.16875rem;
  margin-right: 0.16875rem;
}
.quickCommentBadgeClose {
  font-size: 150%;
  padding-left: 0.125rem;
  vertical-align: text-bottom;
}
.proposalCopy {
  margin-top: 0.5rem;
  margin-bottom: 1.5rem;
}
.proposalSection {
  margin-bottom: 0.9rem;
}
.proposedEditVoteFor,
.proposedEditVoteAgainst {
  margin-right: 0.0625rem;
  color: black;
}
.proposedEditVoteFor:hover,
.proposedEditVoteAgainst:hover {
  color: hsl(196, 100%, 39%);
  text-decoration: none;
}
.proposedEditVoteCount {
  margin-right: 0.75rem;
}
.editAreaLink {
  font-size: 0.84375rem;
  margin-right: 0.8675rem;
}
.editAreaLink:hover {
  text-decoration: underline;
}
.claimEditArea {
  background-color: hsl(178, 100%, 94%);
  padding-top: 0.875rem;
  padding-bottom: 1.05rem;
  padding-left: 0.875rem;
  padding-right: 0.875rem;
  margin-bottom: 0.9rem;
  margin-top: 0.45rem;
  display: inline-block;
  box-sizing: border-box;
}
.editClaimText {
  margin-top: 0;
}
.claimEditAreaHeading,
.addEvidenceAreaHeading {
  width: 100%;
  display: block;
}
.claimEditAreaHeading {
  margin-bottom: 0.875rem;
}
.editSourcesList {
  margin-bottom: 0.875rem;
}
.editSourcesList .source {
  width: 90%;
  display: inline-block;
}
.removeSourceLink a {
  color: hsl(358, 100%, 30%);
  font-size: 90%;
  vertical-align: middle;
  position: relative;
  bottom: 0.0625rem;
}
.removeSourceLink a:hover {
  text-decoration: none;
  color: hsl(196, 100%, 39%);
}
.editTagsList {
  margin-bottom: 0.875rem;
}
.editTagsList .source {
  font-size: 0.95625rem;
  display: inline-block;
}
.removeTagLink {
  font-size: 1.5em;
  right: 0;
  top: -0.45rem;
  vertical-align: top;
  display: inline-block;
  color: hsl(358, 100%, 30%);
}
.claimEditAreaSavingFeedback {
  margin-top: 0.45rem;
  display: inline-block;
  margin-left: -0.3375rem;
  padding-bottom: 0.8675rem;
}
.claimEditAreaNote {
  display: inline-block;
  color: hsl(0, 0%, 18%);
  font-weight: 500;
  margin-bottom: 1.035rem;
  margin-top: 0.25rem;
  width: 100%;
  /* border-bottom-style: solid;
  border-width: 1px;
  border-color:  hsl(0, 0%, 75%);
  padding-bottom: 1rem;
  margin-bottom: 1.5rem;*/
}
.claimEditAreaNote .number {
  font-size: 90%;
}
.hotTip {
  width: 100%;
  display: inline-block;
  margin-top: 1.35rem;
  font-style: italic;
}
.hotTip p {
  margin-bottom: 0;
}
.formInstruction {
  margin-bottom: 1.25rem;
}
.quickCommentInstruction.last {
  margin-bottom: 1.8rem;
}
.imageEditForm {
  margin-top: 0.5625rem;
  margin-bottom: 0.5625rem;
  display: inline-block;
  width: 100%;
}
.imageBigDisplay {
  margin-bottom: 1.125rem;
}
.imageEditForm .imageBigDisplay {
  display: block;
}
.ys-editor:focus,
.ys-editor:active {
  background: pink !important;
}
/*
.public-DraftEditorPlaceholder-hasFocus {
  background: green !important;
}*/
.addEvidenceAreaHeading {
  margin-bottom: 0.9rem;
  padding-top: 0.291375rem;
  box-sizing: border-box;
}
.editPointTextForm {
  display: inline-block;
  width: 100%;
  margin-top: 0.125rem;
  margin-bottom: 0;
  z-index: 1;
  position: relative;
}
@-webkit-keyframes editPointTextFieldReveal {
  0% {
    max-height: 0;
    opacity: 0;
  }
  75% {
    max-height: 172px;
  }
  100% {
    opacity: 1;
  }
}
@keyframes editPointTextFieldReveal {
  0% {
    max-height: 0;
    opacity: 0;
  }
  75% {
    max-height: 172px;
  }
  100% {
    opacity: 1;
  }
}
#editPointTextField {
  padding-top: 0.5625rem;
  -webkit-animation-name: editPointTextFieldReveal;
          animation-name: editPointTextFieldReveal;
  -webkit-animation-duration: 0.33s;
          animation-duration: 0.33s;
  -webkit-animation-timing-function: ease;
          animation-timing-function: ease;
  -webkit-animation-iteration-count: 1;
          animation-iteration-count: 1;
}
.twoLines {
  height: 74px !important;
}
.threeLines {
  height: 99px !important;
}
.fourLines {
  height: 115px !important;
}
.fiveLines {
  height: 133px !important;
}
.sixLines {
  height: 148px !important;
}
.addEvidenceForm {
  margin-top: 0;
  display: inline-block;
  position: relative;
  margin-bottom: 1.35rem;
  border-color: hsl(0, 0%, 75%);
  border-width: 0.0625rem;
  border-style: solid;
  width: 27.75rem;
  box-sizing: border-box;
  background-color: hsl(178, 100%, 94%);
  padding: 0.875rem;
}
.addEvidenceForm > .heading {
  display: block;
  margin-bottom: 0.9rem;
  padding-left: 0.875rem;
}
.addEvidenceControlsArea {
  vertical-align: top;
  display: inline-block;
  position: relative;
  top: 0.534375rem;
}
.addEvidencePrompt {
  padding-left: 0.875rem;
  margin-bottom: 0.72rem;
  position: relative;
}
.addEvidenceButtonsArea {
  display: block;
  position: relative;
}
.inputLabel {
  display: block;
  position: relative;
  font-size: 0.84375rem;
  color: hsl(0, 0%, 30%);
  padding: 0.875rem;
  padding-bottom: 0.375rem;
  padding-left: 0;
}
.inputFieldUX2multi {
  margin-bottom: 0.5625rem;
}
.titleTextField {
  height: 4rem;
  padding-bottom: 1.75rem;
  padding-top: 0.5625rem;
  margin-bottom: 0;
}
.titleTextField:active,
.titleTextField:focus {
  height: 5.625rem;
}
#mainPageClaimCreationForm .titleTextField {
  height: 3.75rem;
}
#mainPageClaimCreationForm .titleTextField:active,
#mainPageClaimCreationForm .titleTextField:focus {
  height: 5.375rem;
}
.inputFieldUX2::-webkit-input-placeholder, input::-webkit-input-placeholder, textarea::-webkit-input-placeholder {
  color: hsl(0deg 0% 50%);
  -webkit-font-smoothing: antialiased ;
  vertical-align: top;
  display: block;
  position: relative;
  top: 0;
}
.inputFieldUX2::placeholder,
input::placeholder,
textarea::placeholder {
  color: hsl(0deg 0% 50%);
  -webkit-font-smoothing: antialiased ;
  vertical-align: top;
  display: block;
  position: relative;
  top: 0;
}
.inputFieldUX2::-ms-input-placeholder,
input[type="text"]::-ms-input-placeholder,
input[type="email"]::-ms-input-placeholder {
  /* IE 10-11*/
  color: hsl(0deg 0% 50%);
}
input:focus::-webkit-input-placeholder {
  color: hsl(0, 0%, 66%);
}
.public-DraftEditorPlaceholder-root {
  color: hsl(0deg 0% 50%);
}
.public-DraftEditorPlaceholder-hasFocus {
  color: hsl(0deg 0% 70%);
}
.inputFieldUX2:active,
.inputFieldUX2:focus,
input[type="text"]:active,
input[type="text"]:focus,
input[type="email"]:active,
input[type="email"]:focus,
input[type="password"]:active,
input[type="password"]:focus {
  box-shadow: none;
  border-color: hsl(0, 0%, 75%);
  outline: none;
}
.inputFieldUX2multi:active,
.inputFieldUX2multi:focus {
  margin-bottom: -1.4375rem;
}
.addEvidenceForm .titleTextField {
  border-color: hsl(0, 0%, 18%);
  border-width: 2px;
}
.titleTextField.counter {
  border-color: hsl(358, 100%, 30%);
  border-width: 2px;
}
.titleTextField.counter:focus {
  border-color: hsl(358, 100%, 30%);
}
.claimFeedbackArea {
  font-size: 0.95625rem;
  background-color: white;
  border-color: hsl(0, 0%, 18%);
  border-width: 2px;
  border-style: solid;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
  width: 100%;
  box-sizing: border-box;
  padding: 0px;
  margin: 0;
  margin-top: -0.45rem;
  margin-bottom: 0.9rem;
}
.claimFeedbackArea.counter {
  border-color: hsl(358, 100%, 30%);
}
.existingClaimPicker {
  padding: 0.875rem;
  padding-top: 1.1rem;
  background-color: hsla(178, 85%, 91%, 0.3);
}
.existingClaimPickerHeading {
  margin-bottom: 0.9rem;
}
.addEvidenceAreaHeadingText {
  font-weight: bold;
  font-size: 0.95625rem;
}
.existingClaimList {
  margin: 0;
}
.existingClaimList li {
  line-height: 125%;
  margin-bottom: 0.75rem;
  text-decoration: underline;
  list-style-type: none;
}
.existingClaimList li:hover {
  text-decoration: underline;
  cursor: pointer;
}
/*
.existingClaimList li:hover::after {
  content: "- add this"
}
*/
.addEvidenceForm .error {
  padding-left: 0.875rem;
  padding-bottom: 0.29166667rem;
}
.titleTextErrorArea {
  box-sizing: border-box;
}
.titleTextErrorAreaContent {
  background-color: hsl(60deg 100% 81%);
  display: block;
  font-size: 0.84375rem;
  width: 100%;
  padding: 0.875rem;
  padding-top: 1.04375rem;
}
.addSourceArea {
  display: block;
  margin-left: 1px;
  margin-top: 0.45rem;
}
.addEvidenceAreaNote {
  display: inline-block;
  color: hsl(0, 0%, 18%);
  width: 100%;
  margin-top: 0.675rem;
  margin-bottom: 0.675rem;
}
.addSourceArea .addEvidenceAreaNote {
  margin-bottom: 0.45rem;
  padding-left: 0;
}
.addEvidenceUI {
  margin-bottom: 1.575rem;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}
.addEvidenceUI:hover {
  transition: 0.04356s;
}
.claimTitleField {
  display: inline-block;
  width: 100%;
}
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  background-color: pink;
}
.charCounter {
  font-size: 0.74997rem;
  color: hsl(0, 0%, 66%);
  margin-right: 0.675rem;
  position: relative;
  max-width: 1.35rem;
  float: right;
  -webkit-transform: translateY(-1.5rem);
          transform: translateY(-1.5rem);
  margin-bottom: -0.225rem;
}
.overMaxChars {
  color: hsl(358, 100%, 30%);
}
#tagSearchResultsArea {
  margin-bottom: 1.8rem;
}
#searchResultsHeader,
#tagHeader {
  margin-top: 0.9rem;
  margin-bottom: 1.8rem;
}
.searchResultsTag {
  padding: 0.875rem;
}
.searchResultsSectionHeading {
  color: hsl(0, 0%, 30%);
  text-transform: uppercase;
  font-size: 0.74997rem;
  padding: 0.875rem;
  margin-bottom: 0;
  font-weight: 500;
  padding-bottom: 0.7rem;
  padding-top: 0;
}
@-webkit-keyframes shareIconAreaReveal {
  0% {
    opacity: 0;
  }
  25% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes shareIconAreaReveal {
  0% {
    opacity: 0;
  }
  25% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.shareIconArea {
  display: inline-block;
  position: relative;
  left: -2.625rem;
  margin-left: 0.3465rem;
  top: -5px;
  font-size: 1em;
  vertical-align: top;
  -webkit-animation-name: shareIconAreaReveal;
          animation-name: shareIconAreaReveal;
  -webkit-animation-duration: 0.216s;
          animation-duration: 0.216s;
  -webkit-animation-timing-function: ease;
          animation-timing-function: ease;
  -webkit-animation-iteration-count: 1;
          animation-iteration-count: 1;
}
.listedClaimAndShare:hover .shareIconArea {
  display: inline-block;
}
.shareIconArea a:hover {
  text-decoration: none !important;
}
a .claimShareIcon {
  display: block;
  padding: 0.3125rem;
  color: hsl(0, 0%, 66%);
}
.claimShareIcon:hover {
  color: hsl(196, 100%, 39%);
  cursor: pointer;
  -webkit-transform: scale(1.33);
          transform: scale(1.33);
}
.claimShareIcon:active {
  -webkit-transform: scale(1.15);
          transform: scale(1.15);
}
.arrowHeadUp {
  width: 0;
  height: 0;
  border-left: 0.3125rem solid transparent;
  border-right: 0.3125rem solid transparent;
  border-bottom: 0.625rem solid hsl(0, 0%, 18%);
}
.arrowHeadUp.counter {
  border-bottom-color: hsl(358, 100%, 30%);
}
.connectingLine {
  width: 1px;
  border-left-style: dotted;
  border-width: 1px;
  position: absolute;
}
.linkArea.relRaterExpanded .connectingLine,
.linkAreaBase.relRaterExpanded .connectingLineElbow {
  border-left-style: solid;
}
.linkAreaBase.relRaterExpanded .connectingLineElbow {
  border-bottom-style: solid;
}
.connectingLineHoriz {
  border-top-style: dotted;
  border-right-style: dotted;
  border-width: 1px;
  position: absolute;
  top: -1px;
  border-top-right-radius: 0;
}
.arrowStemEvidence {
  width: 0.675rem;
  height: 2.25rem;
  margin-left: 0.325rem;
  border-style: none;
  border-left: 1px dotted hsl(0, 0%, 18%);
  border-bottom: 1px dotted hsl(0, 0%, 18%);
  border-bottom-left-radius: 0;
}
.arrowStemEvidence.counter {
  border-bottom-color: hsl(358, 100%, 30%) !important;
  border-left-color: hsl(358, 100%, 30%) !important;
}
/*
.evidenceBlockCounter .arrowStemEvidence.counter {
  position: relative;
  margin-top: @arrowStemEvidenceCounterOffset;
  height: @arrowEvidenceButtonsHeight - @arrowStemEvidenceCounterOffset;
} */
.arrowStemEvidenceShort {
  height: 1.875rem;
}
.arrowEvidence {
  display: inline-block;
  vertical-align: top;
  position: relative;
  left: -0.25rem;
  margin-right: 0.0625rem;
  padding-left: 0.50625rem;
}
.arrowBacklinks {
  display: inline-block;
  margin-left: 0;
  margin-right: 0.3125rem;
  position: relative;
  top: -3px;
}
.arrowBacklinks > .arrowStemEvidence {
  border-color: unset;
  height: 1.35rem;
  width: 0.375rem;
}
.arrowBacklinks > .arrowHeadUp {
  border-bottom-color: unset;
}
@-webkit-keyframes connectCounterReveal {
  0% {
    opacity: 0;
    width: 0;
    z-index: -100;
  }
  80% {
    opacity: 0;
    width: 0;
  }
  100% {
    opacity: 1;
    width: 100%;
    z-index: -100;
  }
}
@keyframes connectCounterReveal {
  0% {
    opacity: 0;
    width: 0;
    z-index: -100;
  }
  80% {
    opacity: 0;
    width: 0;
  }
  100% {
    opacity: 1;
    width: 100%;
    z-index: -100;
  }
}
.connectCounter {
  color: hsl(358, 100%, 30%);
  width: calc(100% - 27.75rem + 1.25rem + 0.50625rem + 1.5px);
  margin-left: 27.75rem;
  z-index: -100;
  /*animation: connectCounterReveal;
  animation-duration: @connectCounterRevealDuration;
  animation-iteration-count: 1;*/
}
.connectingLineExpansionIndicator {
  height: calc(100% + 71px);
  opacity: 0.33;
  margin-left: -0.9rem;
  bottom: 0.9rem;
}
.connectingLineRelevanceLink,
.connectingLineRelevanceRater {
  left: 0.50625rem;
}
.connectingLineRelevanceLink {
  height: 2.4125rem;
}
.connectingLineRelevanceRater {
  height: 100%;
}
.connectingLineRelevanceRater.counter {
  color: hsl(358, 100%, 30%);
}
.connectingLineRelevanceLink.aboveRelLink {
  margin-top: -1.6875rem;
  height: 1.6875rem;
  margin-left: 0.0625rem;
}
.connectingLineRelevanceLink.support {
  color: hsl(0, 0%, 18%) !important;
}
.connectingLineRelevanceLink.counter {
  color: hsl(358, 100%, 30%) !important;
}
.relevanceLink:hover .connectingLineRelevanceLink {
  color: white !important;
}
.linkArea:hover:not(.relRaterExpanded) .arrowStemEvidence {
  border-color: white !important;
}
.linkArea:hover:not(.relRaterExpanded) .arrowHeadUp {
  border-bottom-color: white;
}
.connectingLineElbow.counter {
  color: hsl(358, 100%, 30%);
}
.iconVote {
  display: inline-block;
  color: white;
  width: 1.125rem;
  position: relative;
  margin-left: 0.5625rem;
  stroke: hsl(0, 0%, 18%);
  stroke-width: 2px;
}
.iconVote.counter {
  stroke: hsl(358, 100%, 30%);
}
.iconVote.counter polygon {
  fill: hsl(358, 100%, 30%);
}
.iconVote.support .iconVoteSlot {
  fill: hsl(0, 0%, 18%);
}
.iconVote.counter .iconVoteSlot {
  fill: hsl(358, 100%, 30%);
}
.iconVote.counter .iconVoteSlot:hover {
  fill: white;
}
.relevanceLink:hover .iconVote {
  stroke: white;
  stroke-width: 3px;
}
.connectingLineElbow {
  height: 1.2375rem;
  position: absolute;
  margin-left: unset;
}
.dashedLineEvidenceBlock {
  height: 45%;
  margin-left: 1rem;
  margin-top: -1.5rem;
}
/*
.addEvidenceUI .arrowStemEvidence:not(.arrowStemEvidenceShort) {
  height: 32px; // @arrowStemEvidenceHeightDefault + @arrowStemEvidenceHeightAdd + 4px; //extra for bigger arrow head?
}
*/
.addEvidenceUI .arrowStemEvidence {
  transition: 0.04356s;
  border-left-width: 1px;
  border-bottom-width: 1px;
  width: -0.325rem;
  height: 1.6875rem -0.125rem;
}
.addEvidenceUI .arrowHeadUp,
.linkedClaimArrow .arrowHeadUp {
  border-left-width: 0.3125rem;
  border-right-width: 0.3125rem;
  border-bottom-width: 0.625rem;
  left: 0.25rem;
  position: relative;
  -webkit-transform: rotate(90deg);
          transform: rotate(90deg);
}
.addEvidenceUI .arrowHeadUp {
  transition: 0.04356s;
  top: -0.3125rem;
  left: 0.75rem;
}
.linkedClaimArrow {
  position: relative;
  display: inline-block;
  vertical-align: top;
  padding-left: 0.50625rem;
  z-index: 10;
}
.linkedClaimArrow .connectingLineElbow {
  height: 1.5rem;
}
.linkedClaimArrow .arrowHeadUp {
  top: 1.25rem;
  z-index: 10;
}
.linkedClaimArrow .connectingLineElbow {
  margin-top: 0.0625rem;
}
.relRaterExpanded .connectingLineElbow {
  margin-top: 0;
}
.sb-show-main {
  padding-left: 3rem;
}
.sbLeftUnset {
  left: unset;
}
.storybookOffsetHeader2,
.storybookOffsetHeader3 {
  width: 100vw;
}
.storybookOffsetHeader2 {
  -webkit-transform: translate(-16px, 50px);
          transform: translate(-16px, 50px);
}
.storybookOffsetHeader3 {
  -webkit-transform: translate(-16px, 120px);
          transform: translate(-16px, 120px);
}
.storybookInstruction {
  margin-bottom: 1rem;
  background-color: hsl(60deg 100% 81%);
  padding: 1rem;
}
.hidden-wideDisplays {
  display: none !important;
}
.hidden-on-mediumWidthDisplays-andWider {
  display: none !important;
}
.visible-on-mediumWidthDisplays-andWider {
  display: inherit !important;
}
.hidden-onlyOn-smallWidthDisplays {
  display: inherit;
}
.visible-onlyOn-smallWidthDisplays {
  display: none !important;
}
.hidden-onlyOn-verySmallWidthDisplays {
  display: inherit;
}
.visible-onlyOn-verySmallWidthDisplays {
  display: none;
}
.hidden-onlyOn-veryVerySmallWidthDisplays {
  display: inherit;
}
.visible-onlyOn-veryVerySmallWidthDisplays {
  display: none;
}
@media (min-width: 1200px) {
  .container,
  .navbar-fixed-top .container {
    width: auto;
  }
  #userMenuArea .username {
    width: unset;
    max-width: 12rem !important;
  }
  /*.modal-header, .modal-body, .modal-footer {
    padding-left: 15%;
  }*/
}
@media (max-device-width: 1000px) {
  #header {
    position: fixed;
  }
  #mainContainer {
    overflow: scroll;
  }
}
/*
@media (max-width: 1199px) and (min-width: 1049px)  {

  .modal-header, .modal-body, .modal-footer {
    padding-left: 11%;
  }

}*/
@media (max-width: 1048px) {
  .hidden-onlyOn-mediumWidthDisplays-andWider {
    display: none !important;
  }
  .visible-on-mediumWidthDisplays-andWider {
    display: inherit !important;
  }
  .hidden-wideDisplays {
    display: inherit !important;
  }
  #explanationRowHomepage {
    padding-left: 3rem;
  }
  .contentSectionHeading {
    display: block;
  }
  .contentSection .contentSectionHeading {
    margin-left: -0.9375rem;
    margin-top: 0.675rem;
  }
  .contentSectionHeading .contentSectionLabel {
    float: none;
    clear: none;
  }
  .contentSectionAfterLink {
    left: unset;
  }
  .contentSectionSecondaryLink {
    margin-top: unset;
    display: inline;
    margin-left: 0.5rem;
  }
  #gotItExplanation {
    margin-left: 0;
  }
  .listedClaimGroup {
    width: 77.5vw;
  }
  .relGroupHilite {
    width: 77vw;
    max-width: unset;
  }
  .linkArea {
    width: 69vw;
  }
  .stackCard,
  .upstreamLinks,
  .wideCard.editImage,
  .commentsArea,
  .wideCard.commentsArea,
  .addEvidenceForm,
  .contentSectionHeading,
  #profileDisclaimer {
    width: 69vw;
  }
  .moreClaimsDivision {
    display: inline-block;
    width: calc(69vw + (2 * 0.875rem));
  }
}
@media (max-width: 1048px) and (min-width: 768px) {
  .navbar .container {
    width: unset;
  }
  #searchArea {
    width: calc(100% - 630px);
  }
}
@media (max-width: 1048px) and (min-width: 640px) {
  .explanationBlock {
    margin-right: calc(6% - 2.8125rem);
  }
  .explanationBlock.smallerMarginRight {
    margin-right: calc(6% - 3.6rem);
  }
  .explanationTextCentered {
    width: unset;
  }
}
@media (max-width: 767px) {
  .hidden-onlyOn-smallWidthDisplays {
    display: none !important;
  }
  .visible-onlyOn-smallWidthDisplays {
    display: inherit !important;
  }
  body {
    padding-left: 0px;
    padding-right: 0px;
  }
  #header {
    position: fixed;
  }
  .headerUserName {
    right: 1.35rem;
  }
  #aboutDropdown {
    left: -12px;
  }
  #aboutMenu a:hover {
    text-decoration: none;
  }
  #searchArea {
    float: none;
    width: calc(100% - 32.5rem);
  }
  #searchArea.noUser {
    float: none;
    width: calc(100% - 27.5rem);
  }
  #notifications {
    margin-right: 2.0625rem;
    margin-left: 0;
  }
  #notificationCount {
    left: 87%;
  }
  .notificationFreqLabel {
    margin-right: 4%;
  }
  #userMenuArea {
    margin-left: 0.6875rem;
  }
  #signInFromHeader {
    right: 1.35rem;
  }
  .reactModal,
  #signupDialog,
  #loginDialog,
  #termsModal {
    margin-left: 0;
    margin-right: 0;
    width: 100%;
    position: absolute;
    left: 0;
    top: 0;
    RIGHT: 0;
    box-sizing: border-box;
    border: none;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    padding-left: 0;
    padding-right: 0;
  }
  #signUpSpinner {
    margin-left: 0.5rem;
  }
  #frm_signupDialog input,
  #frm_signupDialog textarea {
    width: 100%;
  }
  #mainContainer {
    padding-left: 0.225rem;
  }
  #mainContainer.userPage {
    padding-left: 4%;
  }
  .infiniteWidth {
    margin-left: 1.35rem;
  }
  .historyContent {
    width: 96vw;
  }
  #explanationRowHomepage {
    width: auto;
    padding-left: 1.575rem;
  }
  .explanationTextCentered {
    max-width: 180px;
  }
  .explanationTextCentered.lessWidth {
    max-width: 140px;
  }
  .pointPageContainer {
    margin-top: 1.35rem;
  }
  .point-card {
    display: inline-block;
  }
  .listedClaimGroup {
    width: 100vw;
  }
  .relGroupHilite {
    width: 77vw;
    max-width: unset;
  }
  .linkArea {
    width: 69vw;
  }
  .stackCard,
  .upstreamLinks,
  .wideCard .editImage,
  .commentsArea,
  .wideCard.commentsArea,
  .addEvidenceForm,
  .contentSectionHeading,
  #profileDisclaimer {
    width: 69vw;
  }
  #profileDisclaimer {
    text-align: center;
    margin-top: 0.35rem;
  }
  .moreClaimsDivision {
    width: calc(69vw + (2 * 0.875rem));
  }
  .contentWithImage {
    width: 78%;
  }
  .profilePic {
    max-width: 58px;
  }
  #userInfoArea {
    width: 69vw;
    text-align: center;
    padding: 0.9rem;
    margin-left: 0;
    margin-right: 0;
  }
  .fullPageText,
  .fullPageForm,
  .affix {
    padding-left: 0;
    margin-left: 1.5rem !important;
    padding-right: 1.5rem;
  }
  .privateAreaHeader {
    position: relative;
    left: -18px;
    width: calc(100% + 36px);
  }
  #areaLine {
    padding-left: 1.125rem;
    padding-right: 1.125rem;
  }
  .affix {
    position: relative;
    top: 33px;
    margin-bottom: 4rem;
    left: 0;
  }
  #educationInfoNav {
    max-width: 432px;
  }
  #commonCoreLogo {
    margin-left: 1.8rem;
    padding-left: 0.225rem;
  }
  #educationInfoNav li {
    display: inline-block;
  }
  .react-alert {
    min-height: 15vw;
  }
}
@media (max-width: 675px) {
  #loginDialog {
    margin-left: 0;
    margin-right: 0;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    border: none;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
  }
}
@media (max-width: 640px) {
  .hidden-onlyOn-smallWidthDisplays {
    display: none !important;
  }
  .visible-onlyOn-smallWidthDisplays {
    display: inherit !important;
  }
  .hidden-onlyOn-verySmallWidthDisplays {
    display: none !important;
  }
  .visible-onlyOn-verySmallWidthDisplays {
    display: inherit !important;
  }
  .feedbackFooterLink {
    font-size: 0.84375rem;
    -webkit-transform: rotate(-90deg);
            transform: rotate(-90deg);
    bottom: 1rem;
    right: -1.75rem;
  }
  #headerLogo {
    margin-right: 0.125rem;
  }
  .createClaimCreationArea {
    width: 100%;
  }
  #makePointIcon {
    font-size: 150%;
  }
  #searchArea {
    float: none;
    width: calc(100% - 18.5rem);
  }
  #searchArea.noUser {
    float: none;
    width: calc(100% - 16rem);
  }
  #explanationRowHomepage {
    position: relative;
    padding-bottom: 1.8rem;
    padding-left: 1.75rem;
    padding-right: 1.75rem;
  }
  .explanationText {
    max-width: calc(85% - 40px);
  }
  #gotItExplanation {
    margin-left: 0;
    vertical-align: top;
  }
  /*
  // TO DO: is this actually only used for save claim buttons that appear to the right of multi-line textareas?
  // if so, rewrite this line. instead of using :not, refer directly to the buttons it should apply to
  @buttonPaddingExtraSmallScreens: 0.75rem;
  .buttonUX2:not(#submit_emailLoginDialog, #submit_signupDialog, .submit_editImageForm, .imagePickerButton, .editSources button, .editTags button) {
    padding-left: @buttonPaddingExtraSmallScreens;
    padding-right: @buttonPaddingExtraSmallScreens;
  }
*/
  .buttonUX2RespIcon {
    padding-right: 0.45rem;
    padding-left: 0.45rem;
  }
  .mainPageClaimCreationArea .claimCreationFormFieldBlock {
    width: calc(84vw - 43.2px);
  }
  .addEvidenceForm .titleTextField {
    height: 5.4rem;
  }
  .addEvidenceForm .titleTextField:active,
  .addEvidenceForm .titleTextField:focus {
    height: 7.025rem;
  }
  .stackCard,
  .upstreamLinks,
  .wideCard.editImage,
  .commentsArea,
  .wideCard.commentsArea,
  .addEvidenceForm,
  .contentSectionHeading,
  #profileDisclaimer {
    width: 84vw;
  }
  .linkArea {
    width: 84vw;
  }
  .moreClaimsDivision {
    width: calc(84vw + (2 * 0.875rem));
  }
  .relGroupHilite {
    width: 92vw;
  }
  .imageContainer {
    width: 24%;
  }
  .imageContainerBig {
    width: 100%;
  }
  .contentWithImage {
    width: 76%;
  }
  .policySectionCopy {
    width: 85%;
  }
  .profileAreaColumn {
    display: block;
    float: none;
    width: unset;
  }
  .blockUnstackOnNarrow {
    margin-right: 2.7rem;
  }
  /*
  .relVoteLink, .relVoteLink button {
    font-size: 1.125em;
    padding-top: 0.5625rem; //10px;
    padding-bottom: 0.5625rem; //10px;
    padding-left: 0.45rem;
    padding-right: 0.3375rem;
  }
  */
  .commentButton {
    width: 100%;
    display: block;
    box-sizing: border-box;
  }
  #userInfoArea {
    margin-left: 12vw;
  }
  a .claimShareIcon {
    font-size: 1.0125rem;
    padding-bottom: 0.675rem;
    padding-left: 0.375rem;
  }
  .modal-header,
  .modal-body,
  .modal-footer {
    padding-top: 2.25rem;
    padding-left: 2.25rem;
    padding-right: 2.25rem;
  }
}
@media (max-width: 510px) {
  /*
    // FULL TEXT PAGES AND FORMS
    @leftMarginMobileNarrow: 0.5rem;
    .fullPageText, .fullPageForm {
      margin-left: @leftMarginMobileNarrow !important;
      padding-right: @leftMarginMobileNarrow;
    }*/
  .contactForm .alert,
  .contactForm input,
  .contactForm textarea,
  .settingsForm input,
  .settingsForm .formInstruction {
    width: 100%;
  }
  .explanationBlock .icon {
    margin-right: 1rem;
  }
  #frm_signupDialog input,
  #frm_signupDialog textarea {
    width: -webkit-fill-available;
  }
  .backToLoginArea {
    text-overflow: clip;
    overflow: hidden;
  }
  .relGroupHilite {
    width: 100vw;
  }
  .imageContainer {
    display: block;
    position: relative;
    width: 100%;
    height: 172px;
    padding: 0.875rem;
    padding-bottom: 0.5775rem;
  }
  .imageContainerBig {
    height: unset;
  }
  .contentWithImage {
    width: 100%;
  }
  .relCtrlGroup {
    width: unset;
  }
  .cardBottomAction {
    font-size: 0.7875rem;
  }
  .cardBottomActionBlock {
    padding-left: 0.525rem;
    padding-right: 0.525rem;
  }
  .seeEvidenceBlock {
    width: unset;
  }
  .moreMenuBlock {
    padding-left: 0.2625rem;
  }
  /*
  .notification-menu {
    //width: 340px;
    margin-left: -19.35rem; //-344px; // a bit hacky bc I've tweaked bootstrap's behavior for .pull-right>.dropdown-menu
  }

  #notificationsArea {
    width: 100%;
  }*/
  .notificationText {
    width: calc(100% - 2rem);
  }
  .notification-menu {
    width: 100vw;
    right: -1px !important;
  }
  #notificationsAllArea {
    margin-left: -1.725rem;
    width: 96.5vw;
  }
}
@media (max-width: 468px) {
  .hidden-onlyOn-veryVerySmallWidthDisplays {
    display: none !important;
  }
  .visible-onlyOn-veryVerySmallWidthDisplays {
    display: inherit !important;
  }
  .reactModal {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    borderRadius: 0;
  }
  #mainContainer,
  #mainContainer.userPage {
    padding-left: 0.225rem;
  }
  .mainPageClaimCreationArea .claimCreationFormFieldBlock {
    width: 84vw;
  }
  .addEvidenceForm .titleTextField {
    height: 8rem;
  }
  .addEvidenceForm .titleTextField:active,
  .addEvidenceForm .titleTextField:focus {
    height: 9.625rem;
  }
  #newPointTextField:active,
  #newPointTextField:focus {
    height: 5.4rem;
  }
  .stackCard,
  .upstreamLinks,
  .wideCard,
  .editImage,
  .wideCard.editImage,
  .commentsArea,
  .wideCard.commentsArea,
  .addEvidenceForm,
  .contentSectionHeading,
  #profileDisclaimer {
    width: 90vw;
  }
  .linkArea {
    width: 90vw;
  }
  .linkArea .editAreaClose {
    margin-right: 0.5625rem;
  }
  .moreClaimsDivision {
    width: calc(90vw + (2 * 0.875rem));
  }
  .cardBottomAction {
    font-size: 0.84375rem;
  }
  .dualButtons {
    display: inline-block;
  }
}
@media (max-width: 405px) {
  #mainContainer,
  #mainContainer.userPage {
    padding-left: 0.125rem;
  }
  .stackCard,
  .upstreamLinks,
  .editImage,
  .commentsArea,
  .addEvidenceForm,
  .contentSectionHeading,
  #profileDisclaimer {
    width: calc(100vw - 0.125rem - 2.5rem);
  }
  .cardBottomAction,
  .point-card .current-vote {
    font-size: 0.73125rem;
  }
  .cardBottomActionBlock,
  .iconBlock {
    padding-left: 0.2625rem;
    padding-right: 0.2625rem;
    border: none;
  }
  .explanationBlock {
    width: 100%;
  }
  .explanationBlock .icon {
    width: unset;
  }
  .explanationText {
    width: 100%;
    max-width: 100%;
  }
  .iconBlock {
    padding-left: 0.47727273rem !important;
    padding-right: 0.23333333rem !important;
  }
  .seeEvidenceBlock {
    padding-left: 0.525rem;
  }
  .moreMenuLink {
    padding-right: 0.6rem;
  }
  .addEvidenceButtonsArea button {
    padding-left: 0.525rem;
    padding-right: 0.525rem;
  }
  .relVoteLink,
  .relVoteLink button {
    font-size: 1rem;
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
    padding-right: 0.625rem;
    padding-left: 0.75rem;
  }
  .backToLoginArea {
    display: block;
    max-width: unset;
    margin-top: 1.25rem;
  }
}
@media (max-width: 319px) {
  .hidden-xxxs {
    display: none !important;
  }
  .buttonUX2responsiveStack {
    display: block;
    margin-bottom: 0.375rem;
  }
}


