/*!
 * Bootstrap Responsive v2.3.1
 *
 * Copyright 2012 Twitter, Inc
 * Licensed under the Apache License v2.0
 * http://www.apache.org/licenses/LICENSE-2.0
 *
 * Designed and built with all the love in the world @twitter by @mdo and @fat.
 */
.clearfix {
  *zoom: 1;
}
.clearfix:before,
.clearfix:after {
  display: table;
  content: "";
  line-height: 0;
}
.clearfix:after {
  clear: both;
}
.hide-text {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
}
.input-block-level {
  display: block;
  width: 100%;
  min-height: 1.875rem;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
@media (min-width: 1200px) {
  .row {
    margin-left: -16px;
    *zoom: 1;
  }
  .row:before,
  .row:after {
    display: table;
    content: "";
    line-height: 0;
  }
  .row:after {
    clear: both;
  }
  [class*="span"] {
    float: left;
    min-height: 1px;
    margin-left: 16px;
  }
  .container,
  .navbar-static-top .container,
  .navbar-fixed-top .container,
  .navbar-fixed-bottom .container {
    width: 1184px;
  }
  .span12 {
    width: 1184px;
  }
  .span11 {
    width: 1084px;
  }
  .span10 {
    width: 984px;
  }
  .span9 {
    width: 884px;
  }
  .span8 {
    width: 784px;
  }
  .span7 {
    width: 684px;
  }
  .span6 {
    width: 584px;
  }
  .span5 {
    width: 484px;
  }
  .span4 {
    width: 384px;
  }
  .span3 {
    width: 284px;
  }
  .span2 {
    width: 184px;
  }
  .span1 {
    width: 84px;
  }
  .offset12 {
    margin-left: 1216px;
  }
  .offset11 {
    margin-left: 1116px;
  }
  .offset10 {
    margin-left: 1016px;
  }
  .offset9 {
    margin-left: 916px;
  }
  .offset8 {
    margin-left: 816px;
  }
  .offset7 {
    margin-left: 716px;
  }
  .offset6 {
    margin-left: 616px;
  }
  .offset5 {
    margin-left: 516px;
  }
  .offset4 {
    margin-left: 416px;
  }
  .offset3 {
    margin-left: 316px;
  }
  .offset2 {
    margin-left: 216px;
  }
  .offset1 {
    margin-left: 116px;
  }
  .row-fluid {
    width: 100%;
    *zoom: 1;
  }
  .row-fluid:before,
  .row-fluid:after {
    display: table;
    content: "";
    line-height: 0;
  }
  .row-fluid:after {
    clear: both;
  }
  .row-fluid [class*="span"] {
    display: block;
    width: 100%;
    min-height: 1.875rem;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    float: left;
    margin-left: 1.35135135%;
    *margin-left: 1.29969846%;
  }
  .row-fluid [class*="span"]:first-child {
    margin-left: 0;
  }
  .row-fluid .controls-row [class*="span"] + [class*="span"] {
    margin-left: 1.35135135%;
  }
  .row-fluid .span12 {
    width: 100%;
    *width: 99.94834711%;
  }
  .row-fluid .span11 {
    width: 91.55405405%;
    *width: 91.50240116%;
  }
  .row-fluid .span10 {
    width: 83.10810811%;
    *width: 83.05645522%;
  }
  .row-fluid .span9 {
    width: 74.66216216%;
    *width: 74.61050927%;
  }
  .row-fluid .span8 {
    width: 66.21621622%;
    *width: 66.16456332%;
  }
  .row-fluid .span7 {
    width: 57.77027027%;
    *width: 57.71861738%;
  }
  .row-fluid .span6 {
    width: 49.32432432%;
    *width: 49.27267143%;
  }
  .row-fluid .span5 {
    width: 40.87837838%;
    *width: 40.82672549%;
  }
  .row-fluid .span4 {
    width: 32.43243243%;
    *width: 32.38077954%;
  }
  .row-fluid .span3 {
    width: 23.98648649%;
    *width: 23.93483359%;
  }
  .row-fluid .span2 {
    width: 15.54054054%;
    *width: 15.48888765%;
  }
  .row-fluid .span1 {
    width: 7.09459459%;
    *width: 7.0429417%;
  }
  .row-fluid .offset12 {
    margin-left: 102.7027027%;
    *margin-left: 102.59939692%;
  }
  .row-fluid .offset12:first-child {
    margin-left: 101.35135135%;
    *margin-left: 101.24804557%;
  }
  .row-fluid .offset11 {
    margin-left: 94.25675676%;
    *margin-left: 94.15345097%;
  }
  .row-fluid .offset11:first-child {
    margin-left: 92.90540541%;
    *margin-left: 92.80209962%;
  }
  .row-fluid .offset10 {
    margin-left: 85.81081081%;
    *margin-left: 85.70750503%;
  }
  .row-fluid .offset10:first-child {
    margin-left: 84.45945946%;
    *margin-left: 84.35615367%;
  }
  .row-fluid .offset9 {
    margin-left: 77.36486486%;
    *margin-left: 77.26155908%;
  }
  .row-fluid .offset9:first-child {
    margin-left: 76.01351351%;
    *margin-left: 75.91020773%;
  }
  .row-fluid .offset8 {
    margin-left: 68.91891892%;
    *margin-left: 68.81561313%;
  }
  .row-fluid .offset8:first-child {
    margin-left: 67.56756757%;
    *margin-left: 67.46426178%;
  }
  .row-fluid .offset7 {
    margin-left: 60.47297297%;
    *margin-left: 60.36966719%;
  }
  .row-fluid .offset7:first-child {
    margin-left: 59.12162162%;
    *margin-left: 59.01831584%;
  }
  .row-fluid .offset6 {
    margin-left: 52.02702703%;
    *margin-left: 51.92372124%;
  }
  .row-fluid .offset6:first-child {
    margin-left: 50.67567568%;
    *margin-left: 50.57236989%;
  }
  .row-fluid .offset5 {
    margin-left: 43.58108108%;
    *margin-left: 43.4777753%;
  }
  .row-fluid .offset5:first-child {
    margin-left: 42.22972973%;
    *margin-left: 42.12642394%;
  }
  .row-fluid .offset4 {
    margin-left: 35.13513514%;
    *margin-left: 35.03182935%;
  }
  .row-fluid .offset4:first-child {
    margin-left: 33.78378378%;
    *margin-left: 33.680478%;
  }
  .row-fluid .offset3 {
    margin-left: 26.68918919%;
    *margin-left: 26.5858834%;
  }
  .row-fluid .offset3:first-child {
    margin-left: 25.33783784%;
    *margin-left: 25.23453205%;
  }
  .row-fluid .offset2 {
    margin-left: 18.24324324%;
    *margin-left: 18.13993746%;
  }
  .row-fluid .offset2:first-child {
    margin-left: 16.89189189%;
    *margin-left: 16.78858611%;
  }
  .row-fluid .offset1 {
    margin-left: 9.7972973%;
    *margin-left: 9.69399151%;
  }
  .row-fluid .offset1:first-child {
    margin-left: 8.44594595%;
    *margin-left: 8.34264016%;
  }
  input,
  textarea,
  .uneditable-input {
    margin-left: 0;
  }
  .controls-row [class*="span"] + [class*="span"] {
    margin-left: 16px;
  }
  input.span12,
  textarea.span12,
  .uneditable-input.span12 {
    width: 1170px;
  }
  input.span11,
  textarea.span11,
  .uneditable-input.span11 {
    width: 1070px;
  }
  input.span10,
  textarea.span10,
  .uneditable-input.span10 {
    width: 970px;
  }
  input.span9,
  textarea.span9,
  .uneditable-input.span9 {
    width: 870px;
  }
  input.span8,
  textarea.span8,
  .uneditable-input.span8 {
    width: 770px;
  }
  input.span7,
  textarea.span7,
  .uneditable-input.span7 {
    width: 670px;
  }
  input.span6,
  textarea.span6,
  .uneditable-input.span6 {
    width: 570px;
  }
  input.span5,
  textarea.span5,
  .uneditable-input.span5 {
    width: 470px;
  }
  input.span4,
  textarea.span4,
  .uneditable-input.span4 {
    width: 370px;
  }
  input.span3,
  textarea.span3,
  .uneditable-input.span3 {
    width: 270px;
  }
  input.span2,
  textarea.span2,
  .uneditable-input.span2 {
    width: 170px;
  }
  input.span1,
  textarea.span1,
  .uneditable-input.span1 {
    width: 70px;
  }
  .thumbnails {
    margin-left: -16px;
  }
  .thumbnails > li {
    margin-left: 16px;
  }
  .row-fluid .thumbnails {
    margin-left: 0;
  }
}
@media (min-width: 768px) and (max-width: 979px) {
  .row {
    margin-left: -16px;
    *zoom: 1;
  }
  .row:before,
  .row:after {
    display: table;
    content: "";
    line-height: 0;
  }
  .row:after {
    clear: both;
  }
  [class*="span"] {
    float: left;
    min-height: 1px;
    margin-left: 16px;
  }
  .container,
  .navbar-static-top .container,
  .navbar-fixed-top .container,
  .navbar-fixed-bottom .container {
    width: 752px;
  }
  .span12 {
    width: 752px;
  }
  .span11 {
    width: 688px;
  }
  .span10 {
    width: 624px;
  }
  .span9 {
    width: 560px;
  }
  .span8 {
    width: 496px;
  }
  .span7 {
    width: 432px;
  }
  .span6 {
    width: 368px;
  }
  .span5 {
    width: 304px;
  }
  .span4 {
    width: 240px;
  }
  .span3 {
    width: 176px;
  }
  .span2 {
    width: 112px;
  }
  .span1 {
    width: 48px;
  }
  .offset12 {
    margin-left: 784px;
  }
  .offset11 {
    margin-left: 720px;
  }
  .offset10 {
    margin-left: 656px;
  }
  .offset9 {
    margin-left: 592px;
  }
  .offset8 {
    margin-left: 528px;
  }
  .offset7 {
    margin-left: 464px;
  }
  .offset6 {
    margin-left: 400px;
  }
  .offset5 {
    margin-left: 336px;
  }
  .offset4 {
    margin-left: 272px;
  }
  .offset3 {
    margin-left: 208px;
  }
  .offset2 {
    margin-left: 144px;
  }
  .offset1 {
    margin-left: 80px;
  }
  .row-fluid {
    width: 100%;
    *zoom: 1;
  }
  .row-fluid:before,
  .row-fluid:after {
    display: table;
    content: "";
    line-height: 0;
  }
  .row-fluid:after {
    clear: both;
  }
  .row-fluid [class*="span"] {
    display: block;
    width: 100%;
    min-height: 1.875rem;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    float: left;
    margin-left: 2.12765957%;
    *margin-left: 2.07600668%;
  }
  .row-fluid [class*="span"]:first-child {
    margin-left: 0;
  }
  .row-fluid .controls-row [class*="span"] + [class*="span"] {
    margin-left: 2.12765957%;
  }
  .row-fluid .span12 {
    width: 100%;
    *width: 99.94834711%;
  }
  .row-fluid .span11 {
    width: 91.4893617%;
    *width: 91.43770881%;
  }
  .row-fluid .span10 {
    width: 82.9787234%;
    *width: 82.92707051%;
  }
  .row-fluid .span9 {
    width: 74.46808511%;
    *width: 74.41643221%;
  }
  .row-fluid .span8 {
    width: 65.95744681%;
    *width: 65.90579392%;
  }
  .row-fluid .span7 {
    width: 57.44680851%;
    *width: 57.39515562%;
  }
  .row-fluid .span6 {
    width: 48.93617021%;
    *width: 48.88451732%;
  }
  .row-fluid .span5 {
    width: 40.42553191%;
    *width: 40.37387902%;
  }
  .row-fluid .span4 {
    width: 31.91489362%;
    *width: 31.86324072%;
  }
  .row-fluid .span3 {
    width: 23.40425532%;
    *width: 23.35260243%;
  }
  .row-fluid .span2 {
    width: 14.89361702%;
    *width: 14.84196413%;
  }
  .row-fluid .span1 {
    width: 6.38297872%;
    *width: 6.33132583%;
  }
  .row-fluid .offset12 {
    margin-left: 104.25531915%;
    *margin-left: 104.15201336%;
  }
  .row-fluid .offset12:first-child {
    margin-left: 102.12765957%;
    *margin-left: 102.02435379%;
  }
  .row-fluid .offset11 {
    margin-left: 95.74468085%;
    *margin-left: 95.64137507%;
  }
  .row-fluid .offset11:first-child {
    margin-left: 93.61702128%;
    *margin-left: 93.51371549%;
  }
  .row-fluid .offset10 {
    margin-left: 87.23404255%;
    *margin-left: 87.13073677%;
  }
  .row-fluid .offset10:first-child {
    margin-left: 85.10638298%;
    *margin-left: 85.00307719%;
  }
  .row-fluid .offset9 {
    margin-left: 78.72340426%;
    *margin-left: 78.62009847%;
  }
  .row-fluid .offset9:first-child {
    margin-left: 76.59574468%;
    *margin-left: 76.4924389%;
  }
  .row-fluid .offset8 {
    margin-left: 70.21276596%;
    *margin-left: 70.10946017%;
  }
  .row-fluid .offset8:first-child {
    margin-left: 68.08510638%;
    *margin-left: 67.9818006%;
  }
  .row-fluid .offset7 {
    margin-left: 61.70212766%;
    *margin-left: 61.59882187%;
  }
  .row-fluid .offset7:first-child {
    margin-left: 59.57446809%;
    *margin-left: 59.4711623%;
  }
  .row-fluid .offset6 {
    margin-left: 53.19148936%;
    *margin-left: 53.08818358%;
  }
  .row-fluid .offset6:first-child {
    margin-left: 51.06382979%;
    *margin-left: 50.960524%;
  }
  .row-fluid .offset5 {
    margin-left: 44.68085106%;
    *margin-left: 44.57754528%;
  }
  .row-fluid .offset5:first-child {
    margin-left: 42.55319149%;
    *margin-left: 42.4498857%;
  }
  .row-fluid .offset4 {
    margin-left: 36.17021277%;
    *margin-left: 36.06690698%;
  }
  .row-fluid .offset4:first-child {
    margin-left: 34.04255319%;
    *margin-left: 33.93924741%;
  }
  .row-fluid .offset3 {
    margin-left: 27.65957447%;
    *margin-left: 27.55626868%;
  }
  .row-fluid .offset3:first-child {
    margin-left: 25.53191489%;
    *margin-left: 25.42860911%;
  }
  .row-fluid .offset2 {
    margin-left: 19.14893617%;
    *margin-left: 19.04563039%;
  }
  .row-fluid .offset2:first-child {
    margin-left: 17.0212766%;
    *margin-left: 16.91797081%;
  }
  .row-fluid .offset1 {
    margin-left: 10.63829787%;
    *margin-left: 10.53499209%;
  }
  .row-fluid .offset1:first-child {
    margin-left: 8.5106383%;
    *margin-left: 8.40733251%;
  }
  input,
  textarea,
  .uneditable-input {
    margin-left: 0;
  }
  .controls-row [class*="span"] + [class*="span"] {
    margin-left: 16px;
  }
  input.span12,
  textarea.span12,
  .uneditable-input.span12 {
    width: 738px;
  }
  input.span11,
  textarea.span11,
  .uneditable-input.span11 {
    width: 674px;
  }
  input.span10,
  textarea.span10,
  .uneditable-input.span10 {
    width: 610px;
  }
  input.span9,
  textarea.span9,
  .uneditable-input.span9 {
    width: 546px;
  }
  input.span8,
  textarea.span8,
  .uneditable-input.span8 {
    width: 482px;
  }
  input.span7,
  textarea.span7,
  .uneditable-input.span7 {
    width: 418px;
  }
  input.span6,
  textarea.span6,
  .uneditable-input.span6 {
    width: 354px;
  }
  input.span5,
  textarea.span5,
  .uneditable-input.span5 {
    width: 290px;
  }
  input.span4,
  textarea.span4,
  .uneditable-input.span4 {
    width: 226px;
  }
  input.span3,
  textarea.span3,
  .uneditable-input.span3 {
    width: 162px;
  }
  input.span2,
  textarea.span2,
  .uneditable-input.span2 {
    width: 98px;
  }
  input.span1,
  textarea.span1,
  .uneditable-input.span1 {
    width: 34px;
  }
}
@media (max-width: 767px) {
  body {
    padding-left: 20px;
    padding-right: 20px;
  }
  .navbar-fixed-top,
  .navbar-fixed-bottom,
  .navbar-static-top {
    margin-left: -20px;
    margin-right: -20px;
  }
  .container-fluid {
    padding: 0;
  }
  .dl-horizontal dt {
    float: none;
    clear: none;
    width: auto;
    text-align: left;
  }
  .dl-horizontal dd {
    margin-left: 0;
  }
  .container {
    width: auto;
  }
  .row-fluid {
    width: 100%;
  }
  .row,
  .thumbnails {
    margin-left: 0;
  }
  .thumbnails > li {
    float: none;
    margin-left: 0;
  }
  [class*="span"],
  .uneditable-input[class*="span"],
  .row-fluid [class*="span"] {
    float: none;
    display: block;
    width: 100%;
    margin-left: 0;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
  }
  .span12,
  .row-fluid .span12 {
    width: 100%;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
  }
  .row-fluid [class*="offset"]:first-child {
    margin-left: 0;
  }
  .input-large,
  .input-xlarge,
  .input-xxlarge,
  input[class*="span"],
  select[class*="span"],
  textarea[class*="span"],
  .uneditable-input {
    display: block;
    width: 100%;
    min-height: 1.875rem;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
  }
  .input-prepend input,
  .input-append input,
  .input-prepend input[class*="span"],
  .input-append input[class*="span"] {
    display: inline-block;
    width: auto;
  }
  .controls-row [class*="span"] + [class*="span"] {
    margin-left: 0;
  }
  .modal {
    position: fixed;
    top: 20px;
    left: 20px;
    right: 20px;
    width: auto;
    margin: 0;
  }
  .modal.fade {
    top: -100px;
  }
  .modal.fade.in {
    top: 20px;
  }
}
@media (max-width: 480px) {
  .nav-collapse {
    -webkit-transform: translate3d(0, 0, 0);
  }
  .page-header h1 small {
    display: block;
    line-height: 120%;
  }
  input[type="checkbox"],
  input[type="radio"] {
    border: 1px solid #ccc;
  }
  .form-horizontal .control-label {
    float: none;
    width: auto;
    padding-top: 0;
    text-align: left;
  }
  .form-horizontal .controls {
    margin-left: 0;
  }
  .form-horizontal .control-list {
    padding-top: 0;
  }
  .form-horizontal .form-actions {
    padding-left: 10px;
    padding-right: 10px;
  }
  .media .pull-left,
  .media .pull-right {
    float: none;
    display: block;
    margin-bottom: 10px;
  }
  .media-object {
    margin-right: 0;
    margin-left: 0;
  }
  .modal {
    top: 10px;
    left: 10px;
    right: 10px;
  }
  .modal-header .close {
    padding: 10px;
    margin: -10px;
  }
  .carousel-caption {
    position: static;
  }
}
@media (max-width: 979px) {
  body {
    padding-top: 0;
  }
  .navbar-fixed-top {
    margin-bottom: 20px;
  }
  .navbar-fixed-bottom {
    margin-top: 20px;
  }
  .navbar-fixed-top .navbar-inner,
  .navbar-fixed-bottom .navbar-inner {
    padding: 5px;
  }
  .navbar .container {
    width: auto;
    padding: 0;
  }
  .navbar .brand {
    padding-left: 10px;
    padding-right: 10px;
    margin: 0 0 0 -5px;
  }
  .nav-collapse {
    clear: both;
  }
  .nav-collapse .nav {
    float: none;
    margin: 0 0 10px;
  }
  .nav-collapse .nav > li {
    float: none;
  }
  .nav-collapse .nav > li > a {
    margin-bottom: 2px;
  }
  .nav-collapse .nav > .divider-vertical {
    display: none;
  }
  .nav-collapse .nav .nav-header {
    color: #777;
    text-shadow: none;
  }
  .nav-collapse .nav > li > a,
  .nav-collapse .dropdown-menu a {
    padding: 9px 15px;
    font-weight: bold;
    color: #777;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    border-radius: 3px;
  }
  .nav-collapse .btn {
    padding: 4px 10px 4px;
    font-weight: normal;
    -webkit-border-radius: 0px;
    -moz-border-radius: 0px;
    border-radius: 0px;
  }
  .nav-collapse .dropdown-menu li + li a {
    margin-bottom: 2px;
  }
  .nav-collapse .nav > li > a:hover,
  .nav-collapse .nav > li > a:focus,
  .nav-collapse .dropdown-menu a:hover,
  .nav-collapse .dropdown-menu a:focus {
    background-color: #f2f2f2;
  }
  .navbar-inverse .nav-collapse .nav > li > a,
  .navbar-inverse .nav-collapse .dropdown-menu a {
    color: #f2f2f2;
  }
  .navbar-inverse .nav-collapse .nav > li > a:hover,
  .navbar-inverse .nav-collapse .nav > li > a:focus,
  .navbar-inverse .nav-collapse .dropdown-menu a:hover,
  .navbar-inverse .nav-collapse .dropdown-menu a:focus {
    background-color: #111111;
  }
  .nav-collapse.in .btn-group {
    margin-top: 5px;
    padding: 0;
  }
  .nav-collapse .dropdown-menu {
    position: static;
    top: auto;
    left: auto;
    float: none;
    display: none;
    max-width: none;
    margin: 0 15px;
    padding: 0;
    background-color: transparent;
    border: none;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    border-radius: 0;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
  }
  .nav-collapse .open > .dropdown-menu {
    display: block;
  }
  .nav-collapse .dropdown-menu:before,
  .nav-collapse .dropdown-menu:after {
    display: none;
  }
  .nav-collapse .dropdown-menu .divider {
    display: none;
  }
  .nav-collapse .nav > li > .dropdown-menu:before,
  .nav-collapse .nav > li > .dropdown-menu:after {
    display: none;
  }
  .nav-collapse .navbar-form,
  .nav-collapse .navbar-search {
    float: none;
    padding: 10px 15px;
    margin: 10px 0;
    border-top: 1px solid #f2f2f2;
    border-bottom: 1px solid #f2f2f2;
    -webkit-box-shadow: inset 0 1px 0 rgba(255,255,255,.1), 0 1px 0 rgba(255,255,255,.1);
    -moz-box-shadow: inset 0 1px 0 rgba(255,255,255,.1), 0 1px 0 rgba(255,255,255,.1);
    box-shadow: inset 0 1px 0 rgba(255,255,255,.1), 0 1px 0 rgba(255,255,255,.1);
  }
  .navbar-inverse .nav-collapse .navbar-form,
  .navbar-inverse .nav-collapse .navbar-search {
    border-top-color: #111111;
    border-bottom-color: #111111;
  }
  .navbar .nav-collapse .nav.pull-right {
    float: none;
    margin-left: 0;
  }
  .nav-collapse,
  .nav-collapse.collapse {
    overflow: hidden;
    height: 0;
  }
  .navbar .btn-navbar {
    display: block;
  }
  .navbar-static .navbar-inner {
    padding-left: 10px;
    padding-right: 10px;
  }
}
@media (min-width: 980px) {
  .nav-collapse.collapse {
    height: auto !important;
    overflow: visible !important;
  }
}
