//
// Alerts
// --------------------------------------------------


// Base styles
// -------------------------

.alert {
  padding: 8px 35px 8px 14px;
  margin-bottom: 20px;
  text-shadow: 0 1px 0 rgba(255,255,255,.5);
  background-color: @warningBackground;
  border: 1px solid @warningBorder;
  .border-radius(@baseBorderRadius);
}
.alert,
.alert h4 {
  // Specified for the h4 to prevent conflicts of changing @headingsColor
  color: @warningText;
}
.alert h4 {
  margin: 0;
}

// Adjust close link position
.alert .close {
  position: relative;
  top: -2px;
  right: -21px;
  line-height: @baseLineHeight;
}


// Alternate styles
// -------------------------

.alert-success {
  background-color: @successBackground;
  border-color: @successBorder;
  color: @successText;
}
.alert-success h4 {
  color: @successText;
}
.alert-danger,
.alert-error {
  background-color: @errorBackground;
  border-color: @errorBorder;
  color: @errorText;
}
.alert-danger h4,
.alert-error h4 {
  color: @errorText;
}
.alert-info {
  background-color: @infoBackground;
  border-color: @infoBorder;
  color: @infoText;
}
.alert-info h4 {
  color: @infoText;
}


// Block alerts
// -------------------------

.alert-block {
  padding-top: 14px;
  padding-bottom: 14px;
}
.alert-block > p,
.alert-block > ul {
  margin-bottom: 0;
}
.alert-block p + p {
  margin-top: 5px;
}
